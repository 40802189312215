import { addMonths, getMonth, getYear, parseISO, subMonths } from "date-fns";
import { fetchApi, uniq } from "../../utils/functions";
import {
  getRecommendationServiceMockedData,
  inputsChartApiResponse,
  netWorthAccumulatedChartApiResponse,
  notAccumulatedNetWorthApiResponse,
} from "./propuesta.data";
// eslint-disable-next-line import/no-cycle
import { getObjectives } from "../goal/goal.service";
import { goalsApiResponse } from "../goal/goal.data";
import { goalColorByType } from "../../utils/componentData";

export const getConfig = async () => {
  const config = fetchApi("setting/v1/settings", "get", undefined);
  return config;
};

export const getRecommendationsService = (planId: string) =>
  fetchApi(
    `plan/v1/plans/${planId}/recommendations?include=FinancialServices`,
    "get",
    {}
  ).catch(() => ({ ...getRecommendationServiceMockedData }));

export const getFinancialServicesService = (serviceId: string) =>
  fetchApi(
    `plan/v1/financial-services/${serviceId}?include=FinancialServiceType,FinancialProducts,FinancialProductsFinancialServices`,
    "get",
    {}
  );

export const createPlan = async () => {
  const response = fetchApi("plan/v1/plans", "post", {
    data: {
      type: "Plan",
      attributes: {
        initial_investment: 0,
      },
    },
  });
  return response;
};

export const getPlan = async () => {
  const response = fetchApi("plan/v1/plans", "get", undefined);
  return response;
};

export const getTirExistingUser = (planId: number) =>
  fetchApi(`plan/v1/plans/${planId}/tir`, "get", {});

export const getPlanWithGlobalConfig = () =>
  fetchApi("plan/v1/plans?include=GlobalInfo", "get", undefined);

export const getPlanWithGoalResult = () =>
  fetchApi("plan/v1/plans?include=GoalResult", "get", undefined);

export const getGlobalConfigWithoutInveert = (id: string) =>
  fetchApi(`plan/v1/plans/${id}/saving-without-investment`, "get", undefined);

export const getOutputChartAllObjectives = (planId: number) =>
  fetchApi(`plan/v1/plans/${planId}/charts/goal-costs`, "get", undefined);

export const getAccumulatedNetWorthChartData = (planId: number) =>
  fetchApi(`plan/v1/plans/${planId}/charts/annual-net-worth`, "get", undefined)
    .then((res) => {
      const goalIdArray = res.map(
        (item: { attributes: { goal_id: any } }) => item.attributes.goal_id
      );
      const uniqId = uniq(goalIdArray);
      const accumulatedByGoal = uniqId.map((id) =>
        res.filter(
          (notAccumulatedData: { attributes: { goal_id: any } }) =>
            notAccumulatedData.attributes.goal_id === id
        )
      );
      const longestArrayIndex = accumulatedByGoal.reduce(
        (dataOfLongest, current, index) => {
          let value = dataOfLongest;
          if (current.length > value.size) {
            value = { size: current.length, index };
          }
          return value;
        },
        { size: 0, index: 0 }
      );
      const data = accumulatedByGoal[longestArrayIndex.index].map(
        (item: any, index: number) => {
          const indexYear = getYear(new Date()) + index;
          const areaValue = accumulatedByGoal.reduce(
            (value, current) => ({
              bar1:
                value.bar1 +
                (current[index]?.attributes?.input ??
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  current[(current?.length ?? 1) - 1]?.attributes?.input),
              bar2:
                value.bar2 +
                (current[index]?.attributes?.performance ??
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  current[(current?.length ?? 1) - 1]?.attributes?.performance),
            }),
            { bar1: 0, bar2: 0 }
          );
          return { ...areaValue, name: indexYear };
        }
      );
      return data;
    })
    .catch(() => {
      const goalIdArray = netWorthAccumulatedChartApiResponse.data.map(
        (item) => item.attributes.goal_id
      );
      const uniqId = uniq(goalIdArray);
      const accumulatedByGoal = uniqId.map((id) =>
        netWorthAccumulatedChartApiResponse.data.filter(
          (notAccumulatedData) => notAccumulatedData.attributes.goal_id === id
        )
      );
      const longestArrayIndex = accumulatedByGoal.reduce(
        (dataOfLongest, current, index) => {
          let value = dataOfLongest;
          if (current.length > value.size) {
            value = { size: current.length, index };
          }
          return value;
        },
        { size: 0, index: 0 }
      );
      const data = accumulatedByGoal[longestArrayIndex.index].map(
        (item, index) => {
          const indexYear = getYear(new Date()) + index;
          const areaValue = accumulatedByGoal.reduce(
            (value, current) => ({
              bar1: value.bar1 + (current[index]?.attributes?.input ?? 0),
              bar2: value.bar2 + (current[index]?.attributes?.performance ?? 0),
            }),
            { bar1: 0, bar2: 0 }
          );
          return { ...areaValue, name: indexYear };
        }
      );
      return data;
    });

export const getNotAccumulatedNetWorthChartData = (planId: number) => {
  const objectiveRes = getObjectives();
  const notAccumulatedRes = fetchApi(
    `plan/v1/plans/${planId}/charts/annual-net-worth-not-accumulated`,
    "get",
    undefined
  );

  return Promise.all([objectiveRes, notAccumulatedRes])
    .then((res) => {
      const goalIdArray = res[1].map(
        (item: { attributes: { goal_id: any } }) => item.attributes.goal_id
      );
      const uniqId = uniq(goalIdArray);
      const notAccumulatedByGoal = uniqId.map((id) =>
        res[1].filter(
          (notAccumulatedData: { attributes: { goal_id: any } }) =>
            notAccumulatedData.attributes.goal_id === id
        )
      );
      const longestArrayIndex = notAccumulatedByGoal.reduce(
        (dataOfLongest, current, index) => {
          let value = dataOfLongest;
          if (current.length > value.size) {
            value = { size: current.length, index };
          }
          return value;
        },
        { size: 0, index: 0 }
      );
      let monthlyDate = new Date();
      const data = notAccumulatedByGoal[longestArrayIndex.index].map(
        (item: any, index: number) => {
          const indexYear = getYear(monthlyDate);
          // add 1 because in getMonth January is 0
          const indexMonth = getMonth(monthlyDate) + 1;
          const date = `${indexYear}-${indexMonth}`;
          monthlyDate = addMonths(monthlyDate, 1);
          const areaValue = notAccumulatedByGoal.reduce(
            (value, current) =>
              value + (current[index]?.attributes?.annual_net_worth ?? 0),
            0
          );
          return { area1: areaValue, name: indexYear, date };
        }
      );
      const dotsData = res[0].map(
        (objective: { attributes: { init_date: string; type: any } }) =>
          data.reduce(
            (
              areaValue: {
                name: number;
                area1: number;
                type: string;
                date: string;
              },
              current: { name: number; area1: any; date: string },
              index: number
            ) => {
              let value = areaValue;
              const objectiveDate = parseISO(objective.attributes.init_date);
              const objectiveInitYear = getYear(objectiveDate);
              /*
                 add 1 because in getMonth January is 0
                 substract 1 because the chart ends a month after the end
                 */
              const objectiveInitMonth =
                getMonth(subMonths(objectiveDate, 1)) + 1;
              const date = `${objectiveInitYear}-${objectiveInitMonth}`;
              if (current.date === date) {
                value = {
                  name: data[index - 1].name,
                  area1: data[index - 1].area1,
                  type: objective.attributes.type,
                  date,
                };
              }
              return value;
            },
            { area1: 0, name: 0, type: "NONE" }
          )
      );
      return { data, dotsData };
    })
    .catch(() => {
      const goalIdArray = notAccumulatedNetWorthApiResponse.data.map(
        (item) => item.attributes.goal_id
      );
      const uniqId = uniq(goalIdArray);
      const notAccumulatedByGoal = uniqId.map((id) =>
        notAccumulatedNetWorthApiResponse.data.filter(
          (notAccumulatedData) => notAccumulatedData.attributes.goal_id === id
        )
      );
      const longestArrayIndex = notAccumulatedByGoal.reduce(
        (dataOfLongest, current, index) => {
          let value = dataOfLongest;
          if (current.length > value.size) {
            value = { size: current.length, index };
          }
          return value;
        },
        { size: 0, index: 0 }
      );
      const data = notAccumulatedByGoal[longestArrayIndex.index].map(
        (item, index) => {
          const indexYear = getYear(new Date()) + index;
          const areaValue = notAccumulatedByGoal.reduce(
            (value, current) =>
              value + (current[index]?.attributes?.annual_net_worth ?? 0),
            0
          );
          return { area1: areaValue, name: indexYear };
        }
      );
      const dotsData = goalsApiResponse.data.map((objective) =>
        data.reduce(
          (
            areaValue: { name: number; area1: number; type: string },
            current
          ) => {
            let value = areaValue;
            const objectiveInitYear = getYear(
              parseISO(objective.attributes.init_date)
            );
            if (current.name === objectiveInitYear) {
              value = {
                name: objectiveInitYear,
                area1: current.area1,
                type: objective.attributes.type,
              };
            }
            return value;
          },
          { area1: 0, name: 0, type: "NONE" }
        )
      );
      return { data, dotsData };
    });
};

export const getInputChartSaving = (planId: number) => {
  const objectivesRes = getObjectives();
  const inputRes = fetchApi(
    `plan/v1/plans/${planId}/charts/monthly-savings?include=Saving`,
    "get",
    undefined
  );
  return Promise.all([inputRes, objectivesRes])
    .then((res) => {
      const inputChartData = res[0].data
        .map((objective: { attributes: { goal_id: any } }) =>
          res[0].included.filter(
            (chartData: { attributes: { goal_id: any } }) =>
              chartData.attributes.goal_id === objective.attributes.goal_id
          )
        )
        .sort((a: string | any[], b: string | any[]) => b.length - a.length);
      const longestInputChartIndex = inputChartData.reduce(
        (longestArrayIndex: any, data: string | any[], index: any) => {
          let indexOfLongestArray = longestArrayIndex;
          if (inputChartData[longestArrayIndex] < data.length) {
            indexOfLongestArray = index;
          }
          return indexOfLongestArray;
        },
        0
      );
      const barsWithGoalId = {
        bar1: inputChartData[0]
          ? inputChartData[0][0].attributes.goal_id.toString()
          : undefined,
        bar2: inputChartData[1]
          ? inputChartData[1][0].attributes.goal_id.toString()
          : undefined,
        bar3: inputChartData[2]
          ? inputChartData[2][0].attributes.goal_id.toString()
          : undefined,
        bar4: inputChartData[3]
          ? inputChartData[3][0].attributes.goal_id.toString()
          : undefined,
      };
      const barsColorByType: {
        bar1?: string;
        bar2?: string;
        bar3?: string;
        bar4?: string;
      } = res[1].reduce(
        (
          objectiveData: {},
          item: { attributes: { type: string }; id: string }
        ) => {
          let barsColor = objectiveData;
          if (item.id === barsWithGoalId.bar1) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar1: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar2) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar2: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar3) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar3: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar4) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar4: goalColorByType[item.attributes.type],
            };
          }
          return barsColor;
        },
        { bar1: undefined, bar2: undefined, bar3: undefined, bar4: undefined }
      );
      const finalInput = inputChartData[longestInputChartIndex].map(
        (data: { attributes: { year: any } }, index: string | number) => ({
          name: data.attributes.year,
          bar1: inputChartData[0]
            ? inputChartData[0][index]?.attributes?.monthly_saving
            : 0,
          bar2: inputChartData[1]
            ? inputChartData[1][index]?.attributes?.monthly_saving
            : 0,
          bar3: inputChartData[2]
            ? inputChartData[2][index]?.attributes?.monthly_saving
            : 0,
          bar4: inputChartData[3]
            ? inputChartData[3][index]?.attributes?.monthly_saving
            : 0,
        })
      );
      return {
        inputData: finalInput,
        barsColor: barsColorByType,
        barsId: barsWithGoalId,
      };
    })
    .catch(() => {
      const inputChartData = inputsChartApiResponse.data
        .map((objective: { attributes: { goal_id: any } }) =>
          inputsChartApiResponse.included.filter(
            (chartData: { attributes: { goal_id: any } }) =>
              chartData.attributes.goal_id === objective.attributes.goal_id
          )
        )
        .sort((a, b) => b.length - a.length);
      const longestInputChartIndex = inputChartData.reduce(
        (longestArrayIndex: any, data: string | any[], index: any) => {
          let indexOfLongestArray = longestArrayIndex;
          if (inputChartData[longestArrayIndex].length <= data.length) {
            indexOfLongestArray = index;
          }
          return indexOfLongestArray;
        },
        0
      );
      const barsWithGoalId = {
        bar1: inputChartData[0]
          ? inputChartData[0][0].attributes.goal_id.toString()
          : undefined,
        bar2: inputChartData[1]
          ? inputChartData[1][0].attributes.goal_id.toString()
          : undefined,
        bar3: inputChartData[2]
          ? inputChartData[2][0].attributes.goal_id.toString()
          : undefined,
        bar4: inputChartData[3]
          ? inputChartData[3][0].attributes.goal_id.toString()
          : undefined,
      };
      const barsColorByType: {
        bar1?: string;
        bar2?: string;
        bar3?: string;
        bar4?: string;
      } = goalsApiResponse.data.reduce(
        (
          objectiveData: {},
          item: { attributes: { type: string }; id: string }
        ) => {
          let barsColor = objectiveData;
          if (item.id === barsWithGoalId.bar1) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar1: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar2) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar2: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar3) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar3: goalColorByType[item.attributes.type],
            };
          } else if (item.id === barsWithGoalId.bar4) {
            barsColor = {
              ...barsColor,
              // @ts-ignore
              bar4: goalColorByType[item.attributes.type],
            };
          }
          return barsColor;
        },
        { bar1: undefined, bar2: undefined, bar3: undefined, bar4: undefined }
      );
      return {
        inputData: inputChartData[longestInputChartIndex].map(
          (data, index) => ({
            name: data.attributes.year,
            bar1: inputChartData[0]
              ? inputChartData[0][index]?.attributes?.monthly_saving
              : 0,
            bar2: inputChartData[1]
              ? inputChartData[1][index]?.attributes?.monthly_saving
              : 0,
            bar3: inputChartData[2]
              ? inputChartData[2][index]?.attributes?.monthly_saving
              : 0,
            bar4: inputChartData[3]
              ? inputChartData[3][index]?.attributes?.monthly_saving
              : 0,
          })
        ),
        barsColor: barsColorByType,
        barsId: barsWithGoalId,
      };
    });
};
export default getConfig;
