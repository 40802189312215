import * as React from "react";

import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import TextInput from "../../elements/input/TextInput";
import { color } from "../../styles/color";
import PageLoading from "../../elements/loading/PageLoading";
import { setIsAuthDialogOpen } from "./auth.slice";
import { reloadApi, resetAlert } from "../app/app.slice";
import { setUserDataLayer } from "../gtm/gtm.script";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-xs";

interface IAuthLoginFormComponent {
  formik: any;
}
export function AuthLoginFormComponent(props: IAuthLoginFormComponent) {
  const location = useLocation();
  const dispatch = useDispatch();
  const router = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.error?.message) {
        setLoading(false);
        setErrorMessage(event.data.error.message);
        setErrorOpen(true);
      } else if (event.data?.data?.token) {
        setUserDataLayer(
          event.data?.data?.email,
          undefined,
          undefined,
          undefined
        );
        dispatch(resetAlert());
        if (location.pathname === "/login") {
          const storage = globalThis?.sessionStorage;
          if (storage) {
            storage.setItem("token", event.data?.data?.token ?? "");
            router("/propuesta");
          } else {
            setErrorMessage("No se ha podido iniciar sesión");
            setErrorOpen(true);
          }
        } else {
          dispatch(setIsAuthDialogOpen(false));
          dispatch(reloadApi());
        }
      }
    });
  }, []);
  const { formik } = props;
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };
  return (
    <>
      <PageLoading open={loading} />
      <Snackbar
        open={errorOpen}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        {/* @ts-ignore */}
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <div>
        <inv-grid-row style={{ gap: "0.4em", justifyContent: "space-between" }}>
          <inv-grid-col class="col-7">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/v2/oauth/v1/provider/google/request-token`}
              rel="opener"
              target="_blank"
            >
              <inv-grid-row>
                <inv-grid-col
                  class="col"
                  style={{
                    borderRadius: "0.4em",
                    display: "flex",
                    background: color.blue[95],
                    alignItems: "center",
                    padding: "0.95em 1.95em",
                    gap: "0.8em",
                    cursor: "pointer",
                  }}
                >
                  <inv-icon name="google-logo" height="0.85em" width="0.85em" />
                  <inv-text-xs
                    style={{
                      "--inv-text-xs-color": color.greyscale.white[100],
                      "--inv-text-xs-font-weight": 700,
                    }}
                  >
                    Iniciar sesión con Google
                  </inv-text-xs>
                </inv-grid-col>
              </inv-grid-row>
            </a>
          </inv-grid-col>
          <inv-grid-col class="col-1">
            <a
              href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/v2/oauth/v1/provider/twitter/request-token`}
              rel="opener noreferrer"
              target="_blank"
            >
              <inv-grid-row>
                <inv-grid-col
                  class="col-auto"
                  style={{
                    justifyContent: "center",
                    borderRadius: "0.4em",
                    display: "flex",
                    background: "rgba(0, 133, 255, 0.08)",
                    alignItems: "center",
                    padding: "0.95em 1.2em",
                    gap: "0.8em",
                    cursor: "pointer",
                  }}
                >
                  <inv-icon
                    name="social-twitter-color"
                    height="0.85em"
                    width="0.85em"
                  />
                </inv-grid-col>
              </inv-grid-row>
            </a>
          </inv-grid-col>
          <inv-grid-col class="col-1">
            <a
              href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/v2/oauth/v1/provider/facebook/request-token`}
              rel="opener noreferrer"
              target="_blank"
            >
              <inv-grid-row>
                <inv-grid-col
                  class="col-auto"
                  style={{
                    justifyContent: "center",
                    borderRadius: "0.4em",
                    display: "flex",
                    background: "rgba(0, 133, 255, 0.08)",
                    alignItems: "center",
                    padding: "0.95em 1.2em",
                    gap: "0.8em",
                    cursor: "pointer",
                  }}
                >
                  <inv-icon
                    name="facebook-logo"
                    height="0.85em"
                    width="0.85em"
                  />
                </inv-grid-col>
              </inv-grid-row>
            </a>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1.7em",
          }}
        >
          <inv-grid-col
            class="col-5"
            style={{
              background: color.greyscale.black[30],
              height: "0.3px",
              width: "calc(41.66666% - var(--inv-gutter-x,1.5em)",
            }}
          />
          <inv-grid-col class="col-auto">
            <inv-text-xs
              style={{ "--inv-text-xs-color": color.greyscale.black[50] }}
            >
              o
            </inv-text-xs>
          </inv-grid-col>
          <inv-grid-col
            class="col-5"
            style={{
              width: "calc(41.66666% - var(--inv-gutter-x,1.5em)",
              background: color.greyscale.black[30],
              height: "0.3px",
            }}
          />
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "0.55em" }}>
          <inv-grid-col class="col-12">
            <TextInput
              value={formik.values.email ?? ""}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="email"
              label="Correo electrónico"
              // @ts-ignore
              error={formik.errors.email}
              placeholder="luis@gmail.com"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "0.8em" }}>
          <inv-grid-col class="col-12">
            <TextInput
              isPassword
              value={formik.values.password ?? ""}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="password"
              label="Contraseña"
              // @ts-ignore
              error={formik.errors.password}
              placeholder="*******"
            />
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </>
  );
}

// @ts-ignore
export default AuthLoginFormComponent;
