import {
  Area,
  ReferenceLine,
  XAxis,
  YAxis,
  ReferenceDot,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Line,
} from "recharts";
import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import AreasChartTooltip from "./AreasChartTooltip";

interface IAreasChart {
  name: string;
  data: Array<{
    name: string | number;
    area1?: number;
    date?: string | number;
    real?: number | null;
  }>;
  isRisk?: boolean;
  height?: number;
  customDotsData?: Array<{
    name: string | number;
    area1?: number;
    type: string;
  }>;
  xPropertyName?: string;
}

export function CustomReferenceDot(props: any) {
  const { cx, cy, fillColor } = props;
  return (
    <circle
      cx={cx}
      r="3"
      cy={cy}
      fill={color.greyscale.white[100]}
      stroke={fillColor ?? color.turquoise[100]}
    />
  );
}

export function CustomReferenceDotEducation(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_975_49192)">
        <g clipPath="url(#clip0_975_49192)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_975_49192)">
            <path
              d="M28.0003 28.0781L12.417 20.6575L28.0003 13.2031L43.5837 20.6575L28.0003 28.0781Z"
              stroke="#F5931F"
              strokeWidth="2.4"
              strokeLinejoin="round"
            />
            <path
              d="M19.5 23.4766V31.5273C19.5 31.5273 22.3333 34.8099 28 34.8099C33.6667 34.8099 36.5 31.5273 36.5 31.5273V23.4766"
              stroke="#F5931F"
              strokeWidth="2.4"
              strokeLinejoin="round"
            />
            <path
              d="M28 19.75L40.75 26.6633V29.6667"
              stroke="#F5931F"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse
              cx="40.7503"
              cy="30.7279"
              rx="1.77083"
              ry="1.77083"
              fill="#F5931F"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_975_49192"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49192"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49192"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_975_49192"
          x="-13"
          y="-9"
          width="82"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49192"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49192"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_975_49192">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CustomReferenceDotSafetyNet(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_975_49191)">
        <g clipPath="url(#clip0_975_49191)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_975_49191)">
            <ellipse
              cx="28.3138"
              cy="24.0013"
              rx="12.9153"
              ry="13.4583"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
            <ellipse
              cx="28.3141"
              cy="24"
              rx="6.11779"
              ry="6.375"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
            <path
              d="M17.4385 17.625L22.1968 22.5833M34.4323 35.3333L29.6741 30.375"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
            <path
              d="M21.5166 12.668L26.2749 17.6263M39.1902 31.0846L34.4319 26.1263"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
            <path
              d="M34.4316 12.668L29.6734 17.6263M17.0979 30.7305L22.1961 25.418"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
            <path
              d="M38.8506 17.2695L34.4322 21.8737M21.8567 34.9779L26.2751 30.3737"
              stroke="#E22E59"
              strokeWidth="2.4"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_975_49191"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49191"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49191"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_975_49191"
          x="-12"
          y="-9"
          width="80.6279"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49191"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49191"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_975_49191">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CustomReferenceDotRetirement(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_975_49193)">
        <g clipPath="url(#clip0_975_49193)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_975_49193)">
            <path
              d="M14.1074 34.6719H41.2976"
              stroke="#4DC98D"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.2187 34.6719V37.5052M18.1855 34.6719V37.5052"
              stroke="#4DC98D"
              strokeWidth="2.4"
              strokeLinecap="round"
            />
            <path
              d="M14.1074 19.0898L20.905 30.4232H39.9381"
              stroke="#4DC98D"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40.1342 21.1127C40.1451 21.4671 40.1333 21.8191 40.0993 22.1672L31.2456 18.5101L22.5126 14.9028C22.7206 14.6555 22.9445 14.4186 23.1837 14.1936C24.0423 13.3858 25.0829 12.7444 26.253 12.3157C27.4234 11.8868 28.697 11.6807 30.001 11.7172C31.3051 11.7538 32.6069 12.0321 33.8293 12.537C35.0517 13.042 36.1638 13.7606 37.1033 14.646C38.0427 15.5311 38.7875 16.5618 39.3039 17.6725C39.82 18.7827 40.0985 19.9517 40.1342 21.1127ZM39.9161 24.6813C40.9243 25.0978 42.084 24.6301 42.3055 23.5636C42.4738 22.7532 42.5464 21.9207 42.5206 21.0808C42.4745 19.5792 42.1146 18.0834 41.4617 16.679C40.8087 15.2745 39.8754 13.9889 38.7151 12.8955C37.5547 11.8021 36.19 10.9223 34.6989 10.3064C33.2078 9.69049 31.6195 9.3505 30.0246 9.30584C28.4298 9.26118 26.8596 9.51273 25.4038 10.0461C23.9481 10.5795 22.6351 11.3843 21.54 12.4146C20.9462 12.9732 20.4228 13.5922 19.9775 14.2603C19.3597 15.1871 19.8751 16.4032 20.9137 16.8322L29.2499 20.2756L23.1744 37.1581C22.95 37.7817 23.2736 38.4691 23.8972 38.6935C24.5208 38.9179 25.2082 38.5943 25.4327 37.9707L31.4705 21.1928L39.9161 24.6813Z"
              fill="#4DC98D"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_975_49193"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49193"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49193"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_975_49193"
          x="-12"
          y="-9"
          width="80.6279"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49193"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49193"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_975_49193">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CustomReferenceDotRealState(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_979_49199)">
        <g clipPath="url(#clip0_979_49199)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_979_49199)">
            <path
              d="M17.4385 20.4583L16.6631 19.5425L16.2385 19.902V20.4583H17.4385ZM17.4385 36.75H16.2385C16.2385 37.0683 16.3649 37.3735 16.5899 37.5985C16.815 37.8236 17.1202 37.95 17.4385 37.95L17.4385 36.75ZM39.1906 36.75V37.95C39.8534 37.95 40.3906 37.4127 40.3906 36.75H39.1906ZM39.1906 20.4583H40.3906V19.902L39.966 19.5425L39.1906 20.4583ZM28.3146 11.25L29.09 10.3342C28.6424 9.95528 27.9867 9.95528 27.5391 10.3342L28.3146 11.25ZM16.2385 20.4583V36.75H18.6385V20.4583H16.2385ZM40.3906 36.75V20.4583H37.9906V36.75H40.3906ZM17.4385 37.95C23.7829 37.95 32.8462 37.95 39.1906 37.95V35.55C32.8462 35.55 23.7829 35.55 17.4385 35.55L17.4385 37.95ZM27.5391 10.3342L16.6631 19.5425L18.2139 21.3742L29.09 12.1658L27.5391 10.3342ZM39.966 19.5425L29.09 10.3342L27.5391 12.1658L38.4152 21.3742L39.966 19.5425Z"
              fill="#D3CC20"
            />
            <path
              d="M26.795 26.8333C26.795 25.4352 25.6952 24.2167 24.2355 24.2167C22.7758 24.2167 21.676 25.4352 21.676 26.8333C21.676 28.2314 22.7758 29.45 24.2355 29.45C25.6952 29.45 26.795 28.2314 26.795 26.8333Z"
              stroke="#D3CC20"
              strokeWidth="2.4"
            />
            <path
              d="M33.7526 28.032C34.4154 28.032 34.9526 27.4948 34.9526 26.832C34.9526 26.1693 34.4154 25.632 33.7526 25.632L33.7526 28.032ZM26.9551 28.032L33.7526 28.032L33.7526 25.632L26.9551 25.632L26.9551 28.032Z"
              fill="#D3CC20"
            />
            <path
              d="M32.3926 29.8086L32.3926 26.9753"
              stroke="#D3CC20"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_979_49199"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_979_49199"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_979_49199"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_979_49199"
          x="-12"
          y="-9"
          width="80.6279"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_979_49199"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_979_49199"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_979_49199">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function CustomReferenceDotHeritage(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_975_49223)">
        <g clipPath="url(#clip0_975_49223)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_975_49223)">
            <path
              d="M17.4385 21.8763V10.543H39.1906V21.8763H17.4385Z"
              stroke="#DA1BA4"
              strokeWidth="2.4"
              strokeLinejoin="round"
            />
            <ellipse
              cx="28.3137"
              cy="16.2083"
              rx="2.71902"
              ry="2.83333"
              stroke="#DA1BA4"
              strokeWidth="2.4"
            />
            <path
              d="M17.6829 26.125H14.9639V34.625H17.6829V26.125Z"
              stroke="#DA1BA4"
              strokeWidth="3.4"
              strokeLinejoin="round"
            />
            <path
              d="M17.6826 33.9148L26.5194 36.7481C26.5194 36.7481 28.1703 37.4565 29.0442 37.4565C29.9182 37.4565 31.6661 36.6936 31.6661 36.6936L40.7943 31.7898C40.7943 31.7898 42.1538 31.0815 41.474 29.6648C40.7943 28.2481 39.4348 28.9565 39.4348 28.9565L31.9575 31.7898M31.9575 31.7898H25.8397M31.9575 31.7898C31.9575 31.7898 31.9575 31.7898 31.9575 30.3731C31.9575 28.9565 30.598 28.9565 30.598 28.9565L25.8397 28.2481C24.9657 27.4853 22.2467 25.4148 17.6826 26.8315"
              stroke="#DA1BA4"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_975_49223"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49223"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49223"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_975_49223"
          x="-12"
          y="-9"
          width="80.6279"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_975_49223"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_975_49223"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_975_49223">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CustomReferenceDotCustom(props: any) {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 20}
      y={cy - 34}
      width="40"
      height="40"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_979_49214)">
        <g clipPath="url(#clip0_979_49214)">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
          <g filter="url(#filter1_d_979_49214)">
            <ellipse
              cx="27.0191"
              cy="25.3487"
              rx="12.3003"
              ry="12.8175"
              stroke="#0E4DAB"
              strokeWidth="2.4"
            />
            <ellipse
              cx="27.019"
              cy="25.3511"
              rx="4.5317"
              ry="4.72222"
              stroke="#0E4DAB"
              strokeWidth="2.4"
            />
            <ellipse
              cx="27.0194"
              cy="25.3498"
              rx="8.53402"
              ry="8.89281"
              stroke="#0E4DAB"
              strokeWidth="2.4"
            />
            <path
              d="M26.1537 24.517C25.6948 24.9952 25.7105 25.7548 26.1886 26.2137C26.6668 26.6726 27.4265 26.657 27.8853 26.1788L26.1537 24.517ZM39.9672 11.8558H38.7672V13.0558H39.9672V11.8558ZM41.9094 11.8558L42.7752 12.6867C43.1082 12.3398 43.2018 11.8276 43.0133 11.3852C42.8247 10.9429 42.3903 10.6558 41.9094 10.6558V11.8558ZM40.0263 13.8181L39.1605 12.9872L40.0263 13.8181ZM39.7951 10.0114L40.6609 10.8423L39.7951 10.0114ZM38.0808 11.7978L38.9466 12.6287L38.0808 11.7978ZM27.8853 26.1788L38.8909 14.7105L37.1593 13.0488L26.1537 24.517L27.8853 26.1788ZM39.2251 13.8797V11.9363H36.8251V13.8797H39.2251ZM38.9466 12.6287L40.6609 10.8423L38.9293 9.18054L37.215 10.9669L38.9466 12.6287ZM38.7672 10.0807V11.8558H41.1672V10.0807H38.7672ZM39.9672 13.0558H41.9094V10.6558H39.9672V13.0558ZM41.0436 11.025L39.1605 12.9872L40.8921 14.649L42.7752 12.6867L41.0436 11.025ZM39.882 12.6797H38.0251V15.0797H39.882V12.6797ZM39.1605 12.9872C39.349 12.7907 39.6096 12.6797 39.882 12.6797V15.0797C40.2633 15.0797 40.6281 14.9241 40.8921 14.649L39.1605 12.9872ZM40.6609 10.8423C39.9748 11.5573 38.7672 11.0716 38.7672 10.0807H41.1672C41.1672 8.90953 39.7402 8.33556 38.9293 9.18054L40.6609 10.8423ZM39.2251 11.9363C39.2251 12.1944 39.1253 12.4425 38.9466 12.6287L37.215 10.9669C36.9648 11.2276 36.8251 11.575 36.8251 11.9363H39.2251Z"
              fill="#0E4DAB"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_979_49214"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_979_49214"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_979_49214"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_979_49214"
          x="-12"
          y="-9"
          width="80.6279"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_979_49214"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_979_49214"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_979_49214">
          <rect x="4" width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
const getCustomDotByType = (type: string) => {
  let customDot;
  if (type === "RETIREMENT") {
    customDot = CustomReferenceDotRetirement;
  } else if (type === "CUSTOM") {
    customDot = CustomReferenceDotCustom;
  } else if (type === "EDUCATION") {
    customDot = CustomReferenceDotEducation;
  } else if (type === "REAL_STATE") {
    customDot = CustomReferenceDotRealState;
  } else if (type === "HERITAGE") {
    customDot = CustomReferenceDotHeritage;
  } else {
    customDot = CustomReferenceDotSafetyNet;
  }
  return customDot;
};

function CustomLabel(props: any) {
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { viewBox } = props;
  const { x, y } = viewBox;
  const { value, yOffset, xOffset, noText } = props;
  return (
    <g>
      <foreignObject
        x={xOffset ? x - xOffset : x}
        y={yOffset ? y - yOffset : y}
        width={downXl ? 140 : 160}
        height={70}
      >
        <Grid container>
          <Grid item>
            <Text
              size="xs"
              weight="bold"
              fontColor={color.turquoise[100]}
              display="inline"
            >
              <NumberFormat
                value={value}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                allowNegative={false}
                displayType="text"
                suffix="% "
              />
            </Text>
            {noText !== true && (
              <Text
                size={downMobile ? "xs" : "s"}
                weight="regular"
                fontColor={color.greyscale.black[50]}
                display="inline"
              >
                Renta variable
              </Text>
            )}
          </Grid>
        </Grid>
      </foreignObject>
    </g>
  );
}

export function CustomDot(props: any) {
  const { cx, cy } = props;

  return (
    cx &&
    cy && (
      <g transform={`translate(${cx},${cy})`}>
        <circle r="0.6em" fill="#083577" />
        <circle r="0.27em" fill="#fff" />
      </g>
    )
  );
}

export function AreasChart(props: IAreasChart) {
  const { name, data, isRisk, customDotsData, height, xPropertyName } = props;
  const maxValue = data?.reduce((maxValueData, dataValue) => {
    let thisMaxValueData = maxValueData;

    // @ts-ignore
    if (dataValue.area1 > maxValueData) {
      // @ts-ignore
      thisMaxValueData = dataValue.area1;
    }
    return thisMaxValueData;
  }, 0);
  const digitsOfMaxValue = Math.round(maxValue).toString().length ?? 0;
  const yAxisWidth = 25 + digitsOfMaxValue * 7;
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const fontSize = downXl ? 12 : 14;
  const [marginLeft, setMarginLeft] = React.useState(0);
  const [marginTop, setMarginTop] = React.useState(0);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const areaRef = React.useRef(null);
  const lineRef = React.useRef(null);
  const tooltipRef = React.useRef(null);

  return (
    <ResponsiveContainer width="100%" height={height} key={name}>
      <ComposedChart
        data={data}
        onMouseMove={(e) => {
          const ref = data[0]?.real ? lineRef : areaRef;
          if (e?.isTooltipActive && ref?.current) {
            // @ts-ignore
            const { x, y } = ref.current.state.curPoints.find(
              (point: any) => point?.payload?.date === e?.activeLabel
            );
            setPosition({ x: x ?? null, y: y ?? null });
            // @ts-ignore
            setMarginLeft(-(tooltipRef?.current?.state?.boxWidth ?? 0) / 2);
            const offset = fontSize + 0.6 * fontSize;
            setMarginTop(
              // @ts-ignore
              -((tooltipRef?.current?.state?.boxHeight ?? 0) + offset)
            );
          }
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#33C5D1" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#33C5D1" stopOpacity={0} />
          </linearGradient>
        </defs>
        {!isRisk && (
          <CartesianGrid
            strokeDasharray="1.5"
            vertical={false}
            stroke={color.greyscale.black[30]}
          />
        )}
        <Tooltip
          ref={tooltipRef}
          // @ts-ignore
          content={<AreasChartTooltip />}
          wrapperStyle={{ outline: "none", marginLeft, marginTop }}
          position={position}
          cursor={false}
        />
        <XAxis
          padding={isRisk ? { left: 12, right: 28 } : undefined}
          fontWeight={400}
          dataKey={xPropertyName}
          fontSize={fontSize}
          tickMargin={5}
          axisLine={isRisk}
          ticks={[
            data[0].date ?? 0,
            data[Math.trunc(data.length / 6)].date ?? 0,
            data[Math.trunc(data.length / 3)].date ?? 0,
            data[Math.trunc(data.length / 2)].date ?? 0,
            data[Math.trunc((2 * data.length) / 3)].date ?? 0,
            data[Math.trunc((5 * data.length) / 6)].date ?? 0,
            data[data.length - 1].date ?? 0,
          ]}
          domain={[data[0].date ?? 0, data[data.length - 1].date ?? 0]}
          tickLine={isRisk ? { stroke: color.greyscale.black[50] } : false}
          tick={{ fill: color.greyscale.black[50] }}
          tickFormatter={(tick: string, index: number) =>
            `${index === 0 ? "Año " : ""}${
              typeof tick === "string" ? tick?.split("-")[0] : ""
            }`
          }
          allowDuplicatedCategory={!isRisk}
          type="category"
        />
        {isRisk ? (
          <YAxis hide padding={{ top: 25 }} />
        ) : (
          <YAxis
            domain={[
              0,
              () => Math.ceil((maxValue + maxValue * 0.3) / 100) * 100,
            ]}
            ticks={[
              (Math.ceil((maxValue + maxValue * 0.3) / 100) * 100) / 2,
              Math.ceil((maxValue + maxValue * 0.3) / 100) * 100,
            ]}
            dx={-6}
            type="number"
            interval="preserveStartEnd"
            axisLine={false}
            unit="€"
            fontWeight={400}
            tickLine={false}
            fontSize={fontSize}
            orientation="left"
            tickCount={4}
            width={yAxisWidth}
            tick={{ fill: color.greyscale.black[50] }}
            tickFormatter={(tick: number | bigint) =>
              new Intl.NumberFormat("es").format(tick)
            }
          />
        )}
        <Area
          activeDot={data[0]?.real ? <div /> : CustomDot}
          ref={areaRef}
          type={isRisk ? "stepAfter" : "basis"}
          dataKey="area1"
          strokeWidth={2}
          stroke={color.turquoise[100]}
          fill={isRisk ? color.turquoise[40] : "url(#gradient)"}
        />
        {customDotsData?.length &&
          customDotsData.map((customDot) => (
            <ReferenceDot
              key={`objective-${customDot.type}${customDot.area1 ?? 0}`}
              // @ts-ignore
              x={customDot[xPropertyName]}
              y={(customDot.area1 ?? 0) + (maxValue ?? 0) * 0.07}
              shape={getCustomDotByType(customDot.type)}
            />
          ))}
        {isRisk && (
          <>
            <ReferenceLine
              segment={[
                { x: 1, y: 0 },
                { x: 1, y: data[0].area1 },
              ]}
              strokeWidth={2}
              stroke={color.turquoise[100]}
            />
            {data.length > 1 && (
              <ReferenceLine
                segment={[
                  // @ts-ignore
                  { x: data[data.length - 1][xPropertyName], y: 0 },
                  {
                    // @ts-ignore
                    x: data[data.length - 1][xPropertyName],
                    y: data[data.length - 2].area1,
                  },
                ]}
                strokeWidth={2}
                stroke={color.turquoise[100]}
              />
            )}
            <ReferenceDot
              x={1}
              y={data[0].area1}
              shape={CustomReferenceDot}
              label={<CustomLabel value={data[0].area1} yOffset={15} />}
            />
            {data.length > 1 && (
              <ReferenceDot
                // @ts-ignore
                x={data[data.length - 1][xPropertyName]}
                y={data[data.length - 1].area1}
                shape={CustomReferenceDot}
                label={
                  <CustomLabel
                    yOffset={15}
                    xOffset={-15}
                    value={data[data.length - 1].area1}
                    noText
                  />
                }
              />
            )}
          </>
        )}
        <Line
          ref={lineRef}
          dot={false}
          activeDot={CustomDot}
          dataKey="real"
          stroke="#083577"
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

AreasChart.defaultProps = {
  isRisk: false,
  customDotsData: undefined,
  height: 180,
  xPropertyName: "name",
};
