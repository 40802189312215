import * as React from "react";
import {
  planAportaciones,
  planAportacionesMore,
} from "../../utils/componentData";
import { ContributionsTable } from "../../components/tables/ContributionsTable";
import styles from "./plan-contribution.module.css";
import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";

function PlanContributionsComponent() {
  const [isLastContributionsSelected, setIsLastContributionsSelected] =
    React.useState(true);
  const [selectedPaginationPage, setSelectedPaginationPage] = React.useState(1);
  const [paginationElements, setPaginationElements] = React.useState([
    1,
    2,
    3,
    "...",
    12,
  ]);
  const [elementsToRender, setElementsToRender] =
    React.useState(planAportaciones);
  const numberOfElements = planAportacionesMore.length;
  const numberOfElementsPerPage = 5;
  const numberOfPages = Math.ceil(numberOfElements / numberOfElementsPerPage);
  React.useEffect(() => {
    // eslint-disable-next-line
    let arr = [];
    // eslint-disable-next-line
    for (let i = 1; i <= numberOfPages; i++) {
      if (i === 4) {
        arr.push("...");
      } else if (i < 4) {
        arr.push(i);
      } else if (i === numberOfPages) {
        arr.push(i);
        // eslint-disable-next-line no-empty
      } else {
      }
    }
    setPaginationElements(arr);
  }, []);
  const handlePaginationItemsChange = (currentPage: number) => {
    if (currentPage > 3 && currentPage + 1 === numberOfPages) {
      setPaginationElements([1, 2, 3, "...", currentPage, numberOfPages]);
    } else if (currentPage > 3 && currentPage + 1 < numberOfPages) {
      setPaginationElements([
        1,
        2,
        3,
        "...",
        currentPage,
        "...",
        numberOfPages,
      ]);
    } else if (currentPage <= 3 || currentPage === numberOfPages) {
      setPaginationElements([1, 2, 3, "...", numberOfPages]);
    }
  };
  const handleArrowPageClick = (pageChangeNumber: number) => {
    handlePaginationItemsChange(selectedPaginationPage + pageChangeNumber);
    setSelectedPaginationPage(selectedPaginationPage + pageChangeNumber);
  };
  React.useEffect(() => {
    const startIndex =
      selectedPaginationPage * numberOfElementsPerPage -
      numberOfElementsPerPage;
    const endIndex = selectedPaginationPage * numberOfElementsPerPage;
    const aportacionesElements = planAportacionesMore.slice(
      startIndex,
      endIndex
    );
    setElementsToRender(aportacionesElements);
  }, [selectedPaginationPage]);
  return (
    <>
      <inv-grid-row
        style={{ justifyContent: "space-between", marginBottom: "1.75em" }}
      >
        <inv-grid-col class="col-auto" style={{ display: "flex" }}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <p
            onClick={() => setIsLastContributionsSelected(true)}
            className={
              isLastContributionsSelected
                ? styles.contributionLinkActive
                : styles.contributionLink
            }
          >
            Últimas aportaciones
          </p>
          <p style={{ fontSize: "0.8em", fontWeight: 700, margin: 0 }}>
            &nbsp;/&nbsp;
          </p>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <p
            onClick={() => setIsLastContributionsSelected(false)}
            className={
              !isLastContributionsSelected
                ? styles.contributionLinkActive
                : styles.contributionLink
            }
          >
            Próximas aportaciones
          </p>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-button>Resolver pendientes</inv-button>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row>
        <inv-grid-col class="col-12">
          <ContributionsTable rows={elementsToRender} />
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={styles.paginationContainer}>
        <inv-grid-col
          onClick={() => {
            if (selectedPaginationPage !== 1) {
              handleArrowPageClick(-1);
            }
          }}
          class={`col-auto ${
            selectedPaginationPage === 1
              ? styles.paginationArrowContainer
              : styles.paginationArrowContainerActive
          }`}
        >
          <inv-icon name="arrow-left" width="0.8em" height="0.8em" />
        </inv-grid-col>
        {paginationElements.map((element) => {
          if (typeof element === "number") {
            return (
              <inv-grid-col
                onClick={() => {
                  setSelectedPaginationPage(element);
                  handlePaginationItemsChange(element);
                }}
                key={`selectable-${element}`}
                class={`col-auto ${
                  selectedPaginationPage === element
                    ? styles.paginationNumberContainerActive
                    : styles.paginationNumberContainer
                }`}
              >
                <inv-text-s>{element}</inv-text-s>
              </inv-grid-col>
            );
          }
          return (
            <inv-grid-col
              key={`not-selectable-${element}`}
              class={`col-auto ${styles.paginationNumberContainer}`}
              style={{ cursor: "default", background: "transparent" }}
            >
              <inv-text-s>{element}</inv-text-s>
            </inv-grid-col>
          );
        })}
        <inv-grid-col
          onClick={() => {
            if (selectedPaginationPage !== numberOfPages) {
              handleArrowPageClick(1);
            }
          }}
          class={`col-auto ${
            selectedPaginationPage === numberOfPages
              ? styles.paginationArrowContainer
              : styles.paginationArrowContainerActive
          }`}
        >
          <inv-icon name="arrow-right" width="0.8em" height="0.8em" />
        </inv-grid-col>
      </inv-grid-row>
    </>
  );
}

export default PlanContributionsComponent;
