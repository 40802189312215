// @ts-ignore
import { Pie, PieChart, Cell } from "recharts";
import React from "react";

interface ICircleChart {
  data: Array<{ name: string; value: number; color: string }>;
  width: number;
  height: number;
}

export default function CircleChart(props: ICircleChart) {
  const { data, width, height } = props;
  return (
    <PieChart width={width} height={height}>
      <Pie
        startAngle={90}
        endAngle={-270}
        data={data}
        dataKey="value"
        innerRadius={width / 6}
        outerRadius={width / 2.5}
        fill="#82ca9d"
      >
        {data.map((entry) => (
          <Cell
            key={`cell-${entry.name}`}
            fill={entry.color}
            strokeWidth={width / 30}
          />
        ))}
      </Pie>
    </PieChart>
  );
}
