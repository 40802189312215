import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import SolidDividers from "../../elements/dividers/SolidDividers";
import CircleChart from "../../components/chart/CircleChart";
import WCIcons from "../../elements/icons/WCIcons";
import AdviceAlert from "../../elements/alert/AdviceAlert";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xs";
import "@inveert/ui-kit/src/text/text-xxs";
import "@inveert/ui-kit/src/icon/index";
import styles from "./plan-goals-dropdown.module.css";
import PropuestaTextStructureComponent from "../propuesta/propuesta-text-structure.component";
import LineChartPerformance from "../../components/chart/LineChartPerformance";
import { lineChartPerformanceDataMock } from "./plan.data";
import PlanContributionsComponent from "./plan-contributions.component";
import FunnelChart from "../../components/chart/FunnelChart";

interface PlanGoalsDropdownComponentProps {
  title: string;
  fixedIncome: number;
  equity: number;
  defaultExpanded?: boolean;
}

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledAccordion = styled((props: any) => <Accordion {...props} />)(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ({ theme }) => ({
    background: color.greyscale.white[100],
    border: `0.05em solid ${color.greyscale.black[10]}`,
    boxShadow: "none",
    padding: "1.6em 1.2em",
    borderRadius: "0.4em",
    [theme.breakpoints.down("mobile")]: {
      padding: "0.8em 0.4em",
    },
  })
);

export default function PlanGoalsDropdownComponent(
  props: PlanGoalsDropdownComponentProps
) {
  const { title, equity, fixedIncome, defaultExpanded } = props;
  const lineChartRef = React.useRef();
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const [isSelected, setIsSelected] = React.useState(false);
  const [lineChartFontSize, setLineChartFontSize] = React.useState(0);
  React.useEffect(() => {
    if (lineChartRef.current) {
      const size = window
        .getComputedStyle(lineChartRef.current, null)
        .getPropertyValue("font-size");

      setLineChartFontSize(parseInt(size, 10));
    }
  }, []);
  return (
    <div className={styles.container}>
      <inv-grid-row>
        <inv-grid-col class="col-12">
          <StyledAccordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
              onClick={() => setIsSelected(!isSelected)}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                margin: 0,
                ".MuiAccordionSummary-content": {
                  margin: 0,
                  "&Mui-expanded": {},
                },
              }}
            >
              <div style={{ width: "100%" }}>
                <inv-grid-row
                  style={{
                    justifyContent: "space-between",
                    "--inv-gutter-x": 0,
                  }}
                >
                  <inv-grid-col class="col-auto">
                    <inv-text-s
                      style={{
                        "--inv-text-s-font-weight": 700,
                      }}
                    >
                      {title}
                    </inv-text-s>
                  </inv-grid-col>
                  {downMobile && (
                    <inv-grid-col class="col-auto">
                      <inv-grid-row style={{ "--inv-gutter-x": "0.15em" }}>
                        <inv-grid-col class="col-auto">
                          <CircleChart
                            width={30}
                            height={30}
                            data={[
                              {
                                name: "fixed",
                                value: fixedIncome * 100,
                                color: color.blue[100],
                              },
                              {
                                name: "equity",
                                value: equity * 100,
                                color: color.turquoise[100],
                              },
                            ]}
                          />
                        </inv-grid-col>
                        <inv-grid-col
                          class="col-auto"
                          style={{ lineHeight: 0 }}
                        >
                          <inv-grid-row>
                            <inv-grid-col class="col-auto">
                              <inv-text-xxs
                                style={{
                                  "--inv-text-xxs-color": color.turquoise[100],
                                  "--inv-text-xxs-font-weight": 700,
                                }}
                              >
                                <NumberFormat
                                  value={equity * 100}
                                  thousandSeparator={
                                    process.env.REACT_APP_THOUSAND_SEPARATOR
                                  }
                                  decimalSeparator={
                                    process.env.REACT_APP_DECIMAL_SEPARATOR
                                  }
                                  decimalScale={0}
                                  allowNegative={false}
                                  displayType="text"
                                  suffix="% "
                                />
                              </inv-text-xxs>
                              <inv-text-xxs
                                style={{
                                  "--inv-text-xxs-color":
                                    color.greyscale.black[50],
                                }}
                              >
                                Renta variable
                              </inv-text-xxs>
                            </inv-grid-col>
                          </inv-grid-row>
                          <inv-grid-row>
                            <inv-grid-col class="col-auto">
                              <inv-text-xxs
                                style={{
                                  "--inv-text-xxs-color": color.blue[100],
                                  "--inv-text-xxs-font-weight": 700,
                                }}
                              >
                                <NumberFormat
                                  value={(1 - equity) * 100}
                                  thousandSeparator={
                                    process.env.REACT_APP_THOUSAND_SEPARATOR
                                  }
                                  decimalSeparator={
                                    process.env.REACT_APP_DECIMAL_SEPARATOR
                                  }
                                  decimalScale={0}
                                  allowNegative={false}
                                  displayType="text"
                                  suffix="% "
                                />
                              </inv-text-xxs>
                              <inv-text-xxs
                                style={{
                                  "--inv-text-xxs-color":
                                    color.greyscale.black[50],
                                }}
                              >
                                Renta fija
                              </inv-text-xxs>
                            </inv-grid-col>
                          </inv-grid-row>
                        </inv-grid-col>
                      </inv-grid-row>
                    </inv-grid-col>
                  )}
                  <inv-grid-col
                    class="col-auto"
                    style={{
                      background: "rgba(100, 191, 120, 0.25)",
                      padding: "0.625em 0.85em",
                      "--inv-text-s-font-weight": 600,
                      "--inv-text-s-color": "#54AA67",
                      "--inv-text-s-font-size": "0.8em",
                      lineHeight: 0,
                      borderRadius: "0.4em",
                    }}
                  >
                    <inv-text-s>Muy probable</inv-text-s>
                  </inv-grid-col>
                </inv-grid-row>
                {downMobile && (
                  <inv-grid-row style={{ marginTop: "0.8em" }}>
                    <inv-grid-col
                      class="col-12"
                      style={{ width: "width: calc(100% - 1.4em)" }}
                    >
                      <SolidDividers orientation="horizontal" />
                    </inv-grid-col>
                  </inv-grid-row>
                )}
                <inv-grid-row class={styles.secondRowContainer}>
                  <inv-grid-col class="col-auto">
                    <inv-grid-row style={{ alignItems: "center" }}>
                      <inv-grid-col
                        class={`col-auto ${styles.textStructureContainer}`}
                      >
                        <PropuestaTextStructureComponent
                          label="Aportado"
                          unit="%"
                          componentAfterUnit={
                            <div
                              style={{
                                marginLeft: "0.5em",
                                background: color.greyscale.black[5],
                                borderRadius: "1.35em",
                                width: "8.3em",
                                height: "0.65em",
                              }}
                            >
                              <div
                                style={{
                                  background: color.blue[105],
                                  borderRadius: "1.35em",
                                  width: "10%",
                                  height: "0.65em",
                                }}
                              />
                            </div>
                          }
                        >
                          <NumberFormat
                            value={6}
                            thousandSeparator={
                              process.env.REACT_APP_THOUSAND_SEPARATOR
                            }
                            decimalSeparator={
                              process.env.REACT_APP_DECIMAL_SEPARATOR
                            }
                            decimalScale={0}
                            displayType="text"
                          />
                        </PropuestaTextStructureComponent>
                      </inv-grid-col>
                      <inv-grid-col
                        class={`col-auto ${styles.textStructureContainer}`}
                      >
                        <PropuestaTextStructureComponent
                          label={
                            downMobile ? "Cap. aportado" : "Capital aportado"
                          }
                        >
                          <NumberFormat
                            value={1265}
                            thousandSeparator={
                              process.env.REACT_APP_THOUSAND_SEPARATOR
                            }
                            decimalSeparator={
                              process.env.REACT_APP_DECIMAL_SEPARATOR
                            }
                            decimalScale={0}
                            displayType="text"
                          />
                        </PropuestaTextStructureComponent>
                      </inv-grid-col>
                      <inv-grid-col
                        class="col-auto"
                        style={{ height: "2.2em" }}
                      >
                        <SolidDividers orientation="vertical" />
                      </inv-grid-col>
                      <inv-grid-col class="col-auto" ref={lineChartRef}>
                        <LineChartPerformance
                          name="performance"
                          data={lineChartPerformanceDataMock}
                          width={lineChartFontSize * 5}
                          height={lineChartFontSize * 2.5}
                        />
                      </inv-grid-col>
                      <inv-grid-col
                        class={`col-auto ${styles.textStructureContainer}`}
                        style={{
                          "--inv-text-l-color": color.turquoise[100],
                          "--inv-text-xs-color": color.turquoise[100],
                        }}
                      >
                        <PropuestaTextStructureComponent
                          label={
                            downMobile ? "Rent. media" : "Rentabilidad media"
                          }
                          unit="€"
                        >
                          <NumberFormat
                            value={1265}
                            thousandSeparator={
                              process.env.REACT_APP_THOUSAND_SEPARATOR
                            }
                            decimalSeparator={
                              process.env.REACT_APP_DECIMAL_SEPARATOR
                            }
                            decimalScale={0}
                            displayType="text"
                          />
                        </PropuestaTextStructureComponent>
                      </inv-grid-col>
                      <inv-grid-col
                        class={`col-auto ${styles.textStructureContainer}`}
                        style={{
                          "--inv-text-l-color": color.turquoise[100],
                          "--inv-text-xs-color": color.turquoise[100],
                        }}
                      >
                        <PropuestaTextStructureComponent
                          label={
                            downMobile
                              ? "Rent. acumulada"
                              : "Rentabilidad acumulada"
                          }
                          unit="€"
                        >
                          <NumberFormat
                            value={1265}
                            thousandSeparator={
                              process.env.REACT_APP_THOUSAND_SEPARATOR
                            }
                            decimalSeparator={
                              process.env.REACT_APP_DECIMAL_SEPARATOR
                            }
                            decimalScale={0}
                            displayType="text"
                          />
                        </PropuestaTextStructureComponent>
                      </inv-grid-col>
                    </inv-grid-row>
                  </inv-grid-col>
                  <inv-grid-col
                    class="col-auto"
                    style={{
                      height: downMobile ? "1.2em" : "2em",
                      transform:
                        isSelected === true ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  >
                    <inv-icon
                      name="accordion-down"
                      width={downMobile ? "1.2em" : "2em"}
                      height={downMobile ? "1.2em" : "2em"}
                    />
                  </inv-grid-col>
                </inv-grid-row>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <inv-grid-row
                style={{ "--inv-gutter-x": 0, margin: "1.6em 0 3.2em" }}
              >
                <inv-grid-col class="col-12">
                  <inv-grid-row class={styles.adviceContainer}>
                    <inv-grid-col
                      class="col-12"
                      style={{
                        "--inv-advice-background": color.greyscale.black[5],
                      }}
                    >
                      <AdviceAlert
                        isCentered
                        icon={<WCIcons name="info" color="inherit" />}
                        text="Hasta hoy, has aportado en todos los meses. Continúa así"
                      />
                    </inv-grid-col>
                  </inv-grid-row>
                  <inv-grid-row style={{ margin: "3.2em 0" }}>
                    <inv-grid-col class="col-12">
                      <PlanContributionsComponent />
                    </inv-grid-col>
                  </inv-grid-row>
                  <inv-grid-row
                    className={styles.adviceContainer}
                    style={{ marginBottom: "2.7em" }}
                  >
                    <inv-grid-col
                      class="col-12"
                      style={{
                        "--inv-advice-background": color.greyscale.black[5],
                      }}
                    >
                      <AdviceAlert
                        isCentered
                        icon={<WCIcons name="info" color="inherit" />}
                        text="Tienes una rentabilidad mejor que la esperada "
                      />
                    </inv-grid-col>
                  </inv-grid-row>
                  <inv-grid-row
                    style={{
                      position: "absolute",
                      marginTop: "1em",
                    }}
                  >
                    <inv-grid-col class="col-auto">
                      <inv-text-s
                        style={{
                          "--inv-text-s-font-weight": 600,
                          "--inv-text-s-font-size": "0.9em",
                        }}
                      >
                        Rentabilidad
                      </inv-text-s>
                    </inv-grid-col>
                  </inv-grid-row>
                  <inv-grid-row>
                    <inv-grid-col class="col-12">
                      <div
                        style={{
                          maxWidth: `calc(100% - ${lineChartFontSize * 5}px)`,
                        }}
                      >
                        <FunnelChart name="funnel" />
                      </div>
                    </inv-grid-col>
                  </inv-grid-row>
                  <inv-grid-row
                    style={{
                      justifyContent: "center",
                      "--inv-gutter-x": "2.535em",
                      marginTop: "2em",
                    }}
                  >
                    <inv-grid-col
                      class="col-auto"
                      style={{ textAlign: "center", lineHeight: 0 }}
                    >
                      <div
                        style={{
                          height: "0.5em",
                          marginBottom: "0.25em",
                        }}
                      >
                        <div
                          style={{
                            height: "0.15em",
                            width: "6.4em",
                            backgroundColor: color.turquoise[100],
                          }}
                        />
                      </div>
                      <div style={{ maxWidth: "5em", margin: "auto" }}>
                        <inv-text-xxs
                          style={{
                            "--inv-text-xxs-color": color.greyscale.black[50],
                          }}
                        >
                          Rentabilidad real
                        </inv-text-xxs>
                      </div>
                    </inv-grid-col>
                    <inv-grid-col
                      class="col-auto"
                      style={{ textAlign: "center", lineHeight: 0 }}
                    >
                      <div
                        style={{
                          height: "0.5em",
                          marginBottom: "0.25em",
                        }}
                      >
                        <div
                          style={{
                            height: "0.15em",
                            width: "6.4em",
                            backgroundColor: color.blue[100],
                          }}
                        />
                      </div>
                      <div style={{ maxWidth: "5em", margin: "auto" }}>
                        <inv-text-xxs
                          style={{
                            "--inv-text-xxs-color": color.greyscale.black[50],
                          }}
                        >
                          Rentabilidad esperada
                        </inv-text-xxs>
                      </div>
                    </inv-grid-col>
                    <inv-grid-col
                      class="col-auto"
                      style={{ textAlign: "center", lineHeight: 0 }}
                    >
                      <div
                        style={{
                          height: "0.5em",
                          width: "6.4em",
                          backgroundColor: "#CCF2F5",
                          marginBottom: "0.25em",
                        }}
                      />
                      <div style={{ maxWidth: "6.2em", margin: "auto" }}>
                        <inv-text-xxs
                          style={{
                            "--inv-text-xxs-color": color.greyscale.black[50],
                          }}
                        >
                          Escenario mejor al esperado
                        </inv-text-xxs>
                      </div>
                    </inv-grid-col>
                    <inv-grid-col
                      class="col-auto"
                      style={{ textAlign: "center", lineHeight: 0 }}
                    >
                      <div
                        style={{
                          height: "0.5em",
                          width: "6.4em",
                          backgroundColor: "#EBF9FA",
                          marginBottom: "0.25em",
                        }}
                      />
                      <div style={{ maxWidth: "6.2em", margin: "auto" }}>
                        <inv-text-xxs
                          style={{
                            "--inv-text-xxs-color": color.greyscale.black[50],
                          }}
                        >
                          Escenario peor al esperado
                        </inv-text-xxs>
                      </div>
                    </inv-grid-col>
                  </inv-grid-row>
                </inv-grid-col>
              </inv-grid-row>
            </AccordionDetails>
          </StyledAccordion>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

PlanGoalsDropdownComponent.defaultProps = {
  defaultExpanded: false,
};
