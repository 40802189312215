import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import "./styles/globals.css";
import "./styles/scss/global.scss";
import LayoutComplete from "./styles/layouts/LayoutComplete";
import EmptyLayoutComplete from "./styles/layouts/EmptyLayoutComplete";
import { createPageEvent } from "./modules/gtm/gtm.script";

import PageLoading from "./elements/loading/PageLoading";
import Resultado from "./pages/propuesta/resultado";
import InicioPerfil from "./pages/perfil/inicio";
import InicioContratacion from "./pages/contratacion/inicio";
import InformacionUsuarioContratacion from "./pages/contratacion/informacion-usuario";
import CumplimientoNormativoContratacion from "./pages/contratacion/cumplimiento-normativo";
import FirmaContratacion from "./pages/contratacion/firma";
import DinamicoPerfil from "./pages/perfil/dinamico";
import Plan from "./pages/plan";
import PlanMeta from "./pages/plan/meta";

const DeleteUser = React.lazy(() => import("./pages/dev/delete-user"));
const Registro = React.lazy(() => import("./pages/registro"));
const Meta = React.lazy(() => import("./pages/meta"));
const Perfil = React.lazy(() => import("./pages/perfil"));
const Custom404 = React.lazy(() => import("./pages/404"));
const Jubilacion = React.lazy(() => import("./pages/meta/jubilacion"));
const Educacion = React.lazy(() => import("./pages/meta/educacion"));
const Vivienda = React.lazy(() => import("./pages/meta/vivienda"));
const Emergencia = React.lazy(() => import("./pages/meta/emergencia"));
const Herencia = React.lazy(() => import("./pages/meta/herencia"));
const Personalizada = React.lazy(() => import("./pages/meta/personalizada"));
const Fin = React.lazy(() => import("./pages/meta/fin"));
const Propuesta = React.lazy(() => import("./pages/propuesta"));
const Login = React.lazy(() => import("./pages/login"));
const RestorePassword = React.lazy(() => import("./pages/restore-password"));
const Verificacion = React.lazy(() => import("./pages/verificacion"));
const PlanesAsesoramiento = React.lazy(
  () => import("./pages/planes-asesoramiento")
);

const titles: { [key: string]: string } = {
  "/": process.env.REACT_APP_SITE_TITLE ?? "",
  "/meta": "Añade una meta",
  "/meta/educacion": "Meta de educación",
  "/meta/herencia": "Meta de herencia",
  "/meta/jubilacion": "Meta de jubilacion",
  "/meta/viaje": "Meta de herencia",
  "/meta/vivienda": "Meta de vivienda",
  "/meta/emergencia": "Meta de fondo de emergencia",
  "/meta/personalizada": "Meta personalizada",
  "/meta/fin": "Meta añadida",
  "/propuesta": "Propuesta",
  "/registro": `Regístrate en ${process.env.REACT_APP_SITE_TITLE ?? "la web"}`,
  "/login": "Login",
  "/restore-password": "Cambiar contraseña",
  "/verificacion": "Verificacion",
  "/planes-asesoramiento": "Planes de asesoramiento",
};

function App() {
  const location = useLocation();
  const [finanbestPass, setFinanbestPass] = React.useState("");
  React.useEffect(
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    () => {
      // @ts-ignore
      document.title =
        titles[location.pathname] ?? process.env.REACT_APP_SITE_TITLE;
      createPageEvent(location.pathname);
    },
    [location]
  );
  return (
    <Routes>
      <Route
        path="*"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Custom404 />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      {process.env.REACT_APP_ENVIRONMENT !== "prod" && (
        <>
          <Route
            path="/perfil/dinamico"
            element={
              <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                  <DinamicoPerfil />
                </React.Suspense>
              </EmptyLayoutComplete>
            }
          />
          <Route
            path="/dev/delete-user"
            element={
              <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                  <DeleteUser />
                </React.Suspense>
              </EmptyLayoutComplete>
            }
          />
          <Route
            path="/plan"
            element={
              <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                  <Plan />
                </React.Suspense>
              </EmptyLayoutComplete>
            }
          />
          <Route
            path="/plan/meta"
            element={
              <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                  <PlanMeta />
                </React.Suspense>
              </EmptyLayoutComplete>
            }
          />
        </>
      )}

      <Route path="/" element={<Navigate to="/meta" replace />} />
      <Route
        path="/meta"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Meta />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/jubilacion"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Jubilacion />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/educacion"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Educacion />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/vivienda"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Vivienda />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/emergencia"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Emergencia />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/herencia"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Herencia />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/personalizada"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Personalizada />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/fin"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Fin />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/perfil"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Perfil />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/propuesta"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Propuesta />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/planes-asesoramiento"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <PlanesAsesoramiento />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/registro"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Registro />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/login"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Login />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/restore-password"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <RestorePassword />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/verificacion"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Verificacion />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/propuesta/resultado"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Resultado />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/perfil/inicio"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <InicioPerfil />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/contratacion/inicio"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <InicioContratacion setFinanbestPass={setFinanbestPass} />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/contratacion/informacion-usuario"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <InformacionUsuarioContratacion finanbestPass={finanbestPass} />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/contratacion/cumplimiento-normativo"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <CumplimientoNormativoContratacion />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/contratacion/firma"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <FirmaContratacion />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
    </Routes>
  );
}

export default App;
