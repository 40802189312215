import Box from "@mui/material/Box";
import { FormHelperText, InputBase, InputLabel, Select } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";

interface ITextInput {
  value: string | number | null;
  handleChange: (
    name: string,
    value: string | null | number,
    validation: boolean
  ) => Promise<any>;
  formik: any;
  error?: string;
  name: string;
  label?: string;
  helperLabel?: string;
  focused?: boolean;
  disabled?: boolean;
  options: Array<{ value: number | string; label: string }>;
  defaultValue?: any;
}

export const StyledInputBase = styled(InputBase)({
  "& .MuiInputBase-input": {
    padding: "15.5px 17px",
    height: 18,
    borderRadius: 8,
    "&.Mui-disabled": {
      border: `2px solid ${color.greyscale.black[10]}`,
      background: color.greyscale.black[5],
      "&:hover": {
        borderRadius: 8,
        border: `2px solid ${color.greyscale.black[10]}`,
      },
    },
    border: `2px solid ${color.greyscale.black[30]}`,
    "&:focus": {
      borderRadius: 8,
      border: `2px solid ${color.turquoise[100]}`,
    },
    "&:hover": {
      borderRadius: 8,
      border: `2px solid ${color.blue[100]}`,
    },
  },
  "&.Mui-error": {
    "& .MuiInputBase-input": {
      borderRadius: 8,
      border: `2px solid ${color.validation.error}`,
    },
  },
});

function SelectInput(props: ITextInput) {
  const {
    name,
    value,
    handleChange,
    formik,
    error,
    label,
    helperLabel,
    focused,
    disabled,
    options,
    defaultValue,
  } = props;
  // @ts-ignore
  return (
    <Box sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginBottom: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginBottom: theme.spacing(2),
          },
        }}
      >
        <Text size="s" weight="medium" fontColor={color.greyscale.black[50]}>
          {label}
        </Text>
      </InputLabel>
      <Select
        defaultValue={defaultValue}
        input={<StyledInputBase />}
        value={value}
        onChange={(event) => {
          if (formik.touched[name] === true) {
            handleChange(name, event.target.value, false).then(() =>
              formik.validateField(name)
            );
          } else {
            handleChange(name, event.target.value, false).then(() => null);
          }
        }}
        native
        error={Boolean(error)}
        displayEmpty
        disabled={disabled}
        fullWidth
        inputRef={(input: { focus: () => any }) =>
          focused === true ? input && input.focus() : null
        }
        id={name}
        name={name}
        onBlur={() =>
          formik
            .setFieldTouched(name, true, false)
            .then(() => formik.validateField(name))
        }
      >
        <>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </>
      </Select>
      <FormHelperText
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginTop: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginTop: theme.spacing(2),
          },
        }}
      >
        <Text size="xxs" weight="regular" fontColor={color.greyscale.black[40]}>
          {helperLabel}
        </Text>
      </FormHelperText>
      {Boolean(formik.errors[name]) && (
        <FormHelperText
          error
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginTop: theme.spacing(1),
            },
            [theme.breakpoints.up("xl")]: {
              marginTop: theme.spacing(2),
            },
          }}
        >
          <Text size="xs" weight="regular" fontColor="inherit">
            {error}
          </Text>
        </FormHelperText>
      )}
    </Box>
  );
}

SelectInput.defaultProps = {
  error: undefined,
  label: undefined,
  helperLabel: undefined,
  focused: undefined,
  disabled: undefined,
  defaultValue: undefined,
};

export default SelectInput;
