import * as React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceDot,
  ReferenceLine,
} from "recharts";
import { color } from "../../styles/color";

interface LineChartPerformanceProps {
  name: string;
  data: Array<any>;
  width?: number;
  height?: number;
}

function CustomDot(props: any) {
  const { cx, cy } = props;

  return (
    cx &&
    cy && (
      <g transform={`translate(${cx},${cy})`}>
        <circle r="0.45em" fill={color.turquoise[100]} />
        <circle r="0.2em" fill="#fff" />
      </g>
    )
  );
}

export default function LineChartPerformance(props: LineChartPerformanceProps) {
  const { name, width, height, data } = props;
  return (
    <ResponsiveContainer width={width} height={height} key={name}>
      <LineChart data={data}>
        <Line
          dot={false}
          type="monotone"
          dataKey="real"
          stroke={color.turquoise[100]}
          strokeWidth={2}
        />
        <ReferenceLine
          stroke={color.turquoise[100]}
          strokeDasharray="1 1"
          x={data.length - 1 - Math.round((data.length - 1) * 0.01)}
        />
        <ReferenceDot
          key="final-data"
          // @ts-ignore
          x={data.length - 1 - Math.round((data.length - 1) * 0.01)}
          y={data[data.length - 1].real}
          shape={CustomDot}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

LineChartPerformance.defaultProps = {
  width: "100%",
  height: 400,
};
