import * as React from "react";
import { color } from "../../styles/color";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";

interface IContractSignCards {
  title: string;
  description: string;
  isSigned: boolean;
  buttonAction: () => void;
}

function ContractSignCards(props: IContractSignCards) {
  const { title, description, isSigned, buttonAction } = props;

  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-l.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-m.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-xs.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/button/index"), []);
  return (
    <div
      style={{
        background: color.greyscale.black[1],
        border: `0.05em solid ${color.greyscale.black[10]}`,
        borderRadius: "0.4em",
        padding: "2.1em 1.95em 2em",
      }}
    >
      <inv-grid-row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <inv-grid-col class="col-6">
          <inv-grid-row>
            <inv-grid-col class="col-auto">
              <inv-text-l style={{ "--inv-text-l-font-weight": 500 }}>
                {title}
              </inv-text-l>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row style={{ marginTop: "0.55em" }}>
            <inv-grid-col class="col-auto">
              <inv-text-m
                style={{
                  "--inv-text-m-color": color.greyscale.black[50],
                }}
              >
                {description}
              </inv-text-m>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-grid-row
            style={{ alignItems: "center", "--inv-gutter-x": "0.8em" }}
          >
            <inv-grid-col class="col-auto">
              {isSigned ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.5em",
                    borderRadius: "1.8em",
                    background: "rgba(74, 219, 175, 0.14)",
                    padding: "0.5em 0.9em",
                  }}
                >
                  <inv-icon
                    style={{ height: "1.4em" }}
                    name="message-success"
                    height="1em"
                    width="1em"
                  />
                  <inv-text-xs
                    style={{
                      "--inv-text-xs-color": color.validation.success,
                      "--inv-text-xs-font-weight": 600,
                    }}
                  >
                    Firmado
                  </inv-text-xs>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.5em",
                    borderRadius: "1.8em",
                    background: "#FAEEFC",
                    padding: "0.5em 0.9em",
                  }}
                >
                  <inv-icon
                    style={{ height: "1.4em" }}
                    name="message-warning"
                    height="1em"
                    width="1em"
                  />
                  <inv-text-xs
                    style={{
                      "--inv-text-xs-color": color.validation.error,
                      "--inv-text-xs-font-weight": 600,
                    }}
                  >
                    Pendiente
                  </inv-text-xs>
                </div>
              )}
            </inv-grid-col>
            <inv-grid-col class="col-auto">
              <inv-button
                onClick={buttonAction}
                variant="outlined"
                style={{ "--inv-button-font-size": "0.8em" }}
              >
                <inv-icon
                  style={{ height: "1.2em" }}
                  color={color.blue[100]}
                  name={isSigned ? "investment-tracing" : "edit"}
                  width="1.2em"
                  height="1.2em"
                />
                {isSigned === true ? "Ver contrato" : "Firmar contrato"}
              </inv-button>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

export default ContractSignCards;
