import * as React from "react";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/text/text-xxs";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/icon/index";
import NumberFormat from "react-number-format";
import styles from "./plan-meta.module.css";
import PropuestaTextStructureComponent from "../propuesta/propuesta-text-structure.component";
import { color } from "../../styles/color";
import BarsChart from "../../components/chart/BarsChart";

interface PlanMetaProps {
  outputChartData: Array<{ name: number; bar1: number }>;
  isOutputMonthly: boolean;
}

function PlanMetaComponent(props: PlanMetaProps) {
  const { outputChartData, isOutputMonthly } = props;
  const [firstChartLabelData, setFirstChartLabelData] = React.useState([
    outputChartData[outputChartData.length - 1].bar1,
    0,
    0,
    0,
  ]);
  const [firstChartTotalLabel, setFirstChartTotalLabel] = React.useState(
    outputChartData[outputChartData.length - 1].name
  );
  React.useEffect(
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    () => {
      setFirstChartLabelData([outputChartData[0].bar1, 0, 0, 0]);
      setFirstChartTotalLabel(outputChartData[0].name);
    },
    [outputChartData]
  );
  const goalColor = "#20D35D";
  return (
    <>
      <div
        style={{ margin: "auto", display: "flex", justifyContent: "center" }}
      >
        <inv-grid-row
          style={{ alignItems: "center", "--inv-gutter-x": "3.2em" }}
        >
          <inv-grid-col class="col-auto">
            <inv-grid-row style={{ "--inv-gutter-x": 0, cursor: "pointer" }}>
              <inv-grid-col class="col-auto">
                <div
                  style={{
                    width: "1.6em",
                    height: "1.6em",
                  }}
                >
                  <inv-icon height="1.6em" width="1.6em" name="chevron-left" />
                </div>
              </inv-grid-col>
              <inv-grid-col class="col-auto">
                <inv-text-xxs
                  style={{
                    "--inv-text-xxs-color": "rgba(255, 255, 255, 0.7)",
                    "--inv-text-xxs-font-weight": "400",
                  }}
                >
                  Anterior meta
                </inv-text-xxs>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
          <inv-grid-col class="col-auto">
            <inv-text-xxxl
              style={{
                "--inv-text-xxxl-color": goalColor,
                "--inv-text-xxxl-font-weight": "700",
              }}
            >
              Complemento de jubilación
            </inv-text-xxxl>
          </inv-grid-col>
          <inv-grid-col class="col-auto">
            <inv-grid-row style={{ "--inv-gutter-x": 0, cursor: "pointer" }}>
              <inv-grid-col class="col-auto">
                <inv-text-xxs
                  style={{
                    "--inv-text-xxs-color": "rgba(255, 255, 255, 0.7)",
                    "--inv-text-xxs-font-weight": "400",
                  }}
                >
                  Siguiente meta
                </inv-text-xxs>
              </inv-grid-col>
              <inv-grid-col class="col-auto">
                <div
                  style={{
                    width: "1.6em",
                    height: "1.6em",
                  }}
                >
                  <inv-icon height="1.6em" width="1.6em" name="chevron-right" />
                </div>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
      </div>
      <div className={styles.headerContainer}>
        <inv-grid-row
          style={{ alignItems: "center", justifyContent: "space-around" }}
        >
          <inv-grid-col
            class={`${styles.textStructureContainer} col-auto`}
            style={{
              "--inv-text-l-color": goalColor,
              "--inv-text-xs-color": goalColor,
            }}
          >
            <PropuestaTextStructureComponent label="Debes aportar" unit="€/mes">
              <NumberFormat
                value={345}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
            <PropuestaTextStructureComponent
              label="Aportado"
              unit="%"
              componentAfterUnit={
                <div
                  style={{
                    marginLeft: "0.5em",
                    background: goalColor + 30,
                    borderRadius: "1.35em",
                    width: "13.05em",
                    height: "1.2em",
                  }}
                >
                  <div
                    style={{
                      background: goalColor,
                      borderRadius: "1.35em",
                      width: "10%",
                      height: "1.2em",
                    }}
                  />
                </div>
              }
            >
              <NumberFormat
                value={6}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
            <PropuestaTextStructureComponent label="Total aportado" unit="€">
              <NumberFormat
                value={3411}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class={`${styles.textStructureContainer} col-auto`}
            style={{
              "--inv-text-l-color": color.turquoise[100],
              "--inv-text-xs-color": color.turquoise[100],
            }}
          >
            <PropuestaTextStructureComponent
              label="Rentabilidad hasta hoy"
              unit="€"
            >
              <NumberFormat
                value={135645}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
        </inv-grid-row>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.whiteContainer} style={{ right: 0 }} />
        <div className={styles.whiteContainer} style={{ left: 0 }} />
        <div className={styles.titleWithSubtitleContainer}>
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <inv-text-m
                style={{
                  "--inv-text-m-font-weight": 700,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                Así retirarás tu dinero
              </inv-text-m>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <inv-grid-col class="col-8">
              <inv-text-xs
                style={{
                  position: "relative",
                  zIndex: 1,
                  "--inv-text-xs-font-weight": 300,
                  "--inv-text-xs-color": color.greyscale.black[50],
                }}
              >
                Has planificado 5 anualidades, a partir de Febrero 2025.
              </inv-text-xs>
            </inv-grid-col>
          </inv-grid-row>
        </div>
        {outputChartData.length > 6 && (
          <inv-grid-row
            class={styles.goalCostChartValue}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <inv-grid-col class="col-10">
              <inv-grid-row
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  lineHeight: 0,
                }}
              >
                <inv-grid-col class="col-auto">
                  <inv-text-xxs
                    style={{
                      "--in-text-xxs-font-weight": 500,
                      "--inv-text-xxs-color": color.greyscale.black[50],
                    }}
                  >
                    {`Año ${firstChartTotalLabel}`}
                  </inv-text-xxs>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <inv-grid-col class="col-auto">
                  <inv-text-xs
                    style={{
                      "--in-text-xs-font-weight": 600,
                      "--inv-text-xs-color": goalColor,
                    }}
                  >
                    <NumberFormat
                      value={firstChartLabelData[0]}
                      thousandSeparator={
                        process.env.REACT_APP_THOUSAND_SEPARATOR
                      }
                      decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                      decimalScale={0}
                      allowNegative={false}
                      displayType="text"
                    />
                  </inv-text-xs>
                  <inv-text-xxs
                    class={styles.goalCostChartValueUnit}
                    style={{
                      "--in-text-xxs-font-weight": 700,
                      "--inv-text-xxs-color": goalColor,
                    }}
                  >
                    {` ${isOutputMonthly ? "€/mes" : "€"}`}
                  </inv-text-xxs>
                </inv-grid-col>
              </inv-grid-row>
            </inv-grid-col>
          </inv-grid-row>
        )}
        <inv-grid-row
          class={styles.goalCostChart}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <inv-grid-col
            class={outputChartData.length > 6 ? "col-10" : "col-4 col-sm-4"}
          >
            <BarsChart
              name="output"
              barColors={{ bar1: color.greyscale.black[50] }}
              data={outputChartData}
              barsHandler={setFirstChartLabelData}
              helperHandler={setFirstChartTotalLabel}
            />
          </inv-grid-col>
          <inv-grid-col
            class="col-5 col-sm-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            {outputChartData.length <= 6 && (
              <inv-grid-row class={styles.goalCostChartValue}>
                {outputChartData.map(
                  (data: { name: React.Key | null | undefined; bar1: any }) => (
                    <inv-grid-col
                      class={
                        outputChartData.length === 1 ? "col-auto" : "col-4"
                      }
                      key={data.name}
                    >
                      <inv-grid-row
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          lineHeight: 0,
                        }}
                      >
                        <inv-grid-col class="col-auto">
                          <inv-text-xxs
                            style={{
                              "--in-text-xxs-font-weight": 500,
                              "--inv-text-xxs-color": color.greyscale.black[50],
                            }}
                          >
                            {`Año ${data.name}`}
                          </inv-text-xxs>
                        </inv-grid-col>
                      </inv-grid-row>
                      <inv-grid-row
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <inv-grid-col class="col-auto">
                          <inv-text-xs
                            style={{
                              "--in-text-xs-font-weight": 600,
                              "--inv-text-xs-color": goalColor,
                            }}
                          >
                            <NumberFormat
                              value={data.bar1}
                              thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                              }
                              decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                              }
                              decimalScale={0}
                              allowNegative={false}
                              displayType="text"
                            />
                          </inv-text-xs>
                          <inv-text-xs
                            class={styles.goalCostChartValueUnit}
                            style={{
                              "--in-text-xs-font-weight": 700,
                              "--inv-text-xs-color": goalColor,
                            }}
                          >
                            {` ${isOutputMonthly ? "€/mes" : "€"}`}
                          </inv-text-xs>
                        </inv-grid-col>
                      </inv-grid-row>
                    </inv-grid-col>
                  )
                )}
              </inv-grid-row>
            )}
          </inv-grid-col>
        </inv-grid-row>
        <div className={styles.titleWithSubtitleContainer}>
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <inv-text-m
                style={{
                  "--inv-text-m-font-weight": 700,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                También estimamos los impuestos
              </inv-text-m>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <inv-grid-col class="col-7">
              <inv-text-xs
                style={{
                  position: "relative",
                  zIndex: 1,
                  "--inv-text-xs-font-weight": 300,
                  "--inv-text-xs-color": color.greyscale.black[50],
                }}
              >
                Todo está calculado para que, al rescatar tus inversiones,
                puedas obtener las cantidades planificadas para tu meta.
              </inv-text-xs>
            </inv-grid-col>
          </inv-grid-row>
        </div>
        <inv-grid-row
          style={{
            alignItems: "center",
            justifyContent: "center",
            "--inv-gutter-x": "1.6em",
          }}
        >
          <inv-grid-col
            class={`${styles.textStructureContainer} col-auto`}
            style={{
              "--inv-text-l-color": color.greyscale.black[30],
              "--inv-text-xs-color": color.greyscale.black[30],
            }}
          >
            <PropuestaTextStructureComponent
              label="Aportando en total"
              unit="€"
            >
              <NumberFormat
                value={345}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class="col-auto"
            style={{
              alignSelf: "flex-end",
            }}
          >
            <inv-text-m
              style={{
                "--inv-text-m-font-weight": 600,
                "--inv-text-m-color": color.greyscale.black[30],
              }}
            >
              +
            </inv-text-m>
          </inv-grid-col>
          <inv-grid-col
            class={`${styles.textStructureContainer} col-auto`}
            style={{
              "--inv-text-l-color": color.turquoise[100],
              "--inv-text-xs-color": color.turquoise[100],
            }}
          >
            <PropuestaTextStructureComponent
              label="Rentabilizando al 5%"
              unit="€"
            >
              <NumberFormat
                value={184137}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class="col-auto"
            style={{
              alignSelf: "flex-end",
            }}
          >
            <inv-text-m
              style={{
                "--inv-text-m-font-weight": 600,
                "--inv-text-m-color": color.greyscale.black[30],
              }}
            >
              -
            </inv-text-m>
          </inv-grid-col>
          <inv-grid-col
            class={`${styles.textStructureContainer} col-auto`}
            style={{
              "--inv-text-l-color": "#FF5F29",
              "--inv-text-xs-color": "#FF5F29",
            }}
          >
            <PropuestaTextStructureComponent
              label="Proyección impuestos"
              unit="€"
            >
              <NumberFormat
                value={206295}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class="col-auto"
            style={{
              alignSelf: "flex-end",
            }}
          >
            <inv-text-m
              style={{
                "--inv-text-m-font-weight": 600,
                "--inv-text-m-color": color.greyscale.black[30],
              }}
            >
              =
            </inv-text-m>
          </inv-grid-col>
          <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
            <PropuestaTextStructureComponent
              label="Total que necesitas"
              unit="€"
            >
              <NumberFormat
                value={324244}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "7em" }}>
          <inv-grid-col
            class="col-6"
            style={{
              background: "rgba(255, 242, 227, 0.6)",
              border: "0.05em solid #F5931F",
              borderRadius: "0.8em",
              padding: "0.8em",
            }}
          >
            <inv-grid-row
              style={{ "--inv-gutter-x": "0.8em", alignItems: "center" }}
            >
              <inv-grid-col class="col-auto">
                <div
                  style={{
                    height: "1.6em",
                    width: "1.6em",
                  }}
                >
                  <inv-icon
                    color="#F5931F"
                    name="info"
                    height="1.6em"
                    width="1.6em"
                  />
                </div>
              </inv-grid-col>
              <inv-grid-col class="col-10">
                <inv-text-xxs
                  style={{
                    "--inv-text-xxs-color": color.greyscale.black[50],
                  }}
                >
                  Esto no puede considerarse como asesoramiento fiscal. Se trata
                  de una estimación, que asume numerosas hipótesis.
                </inv-text-xxs>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </>
  );
}

export default PlanMetaComponent;
