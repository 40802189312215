// @ts-ignore
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  authChangePassUser,
  authLoginUser,
  authRegisterUser,
  authResendVerificationUser,
  authSendChangePassUser,
} from "./auth.service";
import { addAlert } from "../app/app.slice";
import { store } from "../../store";
import { setUserDataLayer } from "../gtm/gtm.script";
import { resetPropuesta } from "../propuesta/propuesta.slice";
import { resetUser } from "../user/user.slice";
import { deleteAllObjectives } from "../goal/goal.slice";
import { getErrorMessage } from "../../utils/functions";

interface ILoginFormik {
  dispatch: any;
  redirectionPath?: string;
  reloadPage?: boolean;
}

export const LoginFormik = (props: ILoginFormik) => {
  const { dispatch, redirectionPath, reloadPage } = props;
  const router = useNavigate();
  const validationSchema = yup.object({
    password: yup.string().required("Introduce tu contraseña"),
    email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"),
  });
  return useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { password, email } = values;
      const login = {
        email,
        password,
      };
      authLoginUser(login)
        .then(async () => {
          dispatch(resetPropuesta());
          dispatch(resetUser());
          dispatch(deleteAllObjectives());
          setUserDataLayer(email, undefined, undefined, undefined);
          if (reloadPage) {
            router(0);
          } else {
            router(redirectionPath ?? "/propuesta");
          }
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
};

export const RegisterFormik = () => {
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Introduce tu contraseña")
      .min(
        8,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      )
      .matches(
        /^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      ),
    email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"),
    passwordConfirmation: yup
      .string()
      .nullable()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
      .required("Confirma la contraseña"),
  });
  return useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { password, email } = values;
      const register = {
        email,
        password,
        accept_terms: true,
        accept_communication: true,
        is_active: true,
      };
      authRegisterUser(register)
        .then(() => {
          store.dispatch(
            addAlert({
              message: "Correo de verificación enviado",
              isError: false,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          store.dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
};

export const ResendVerificationFormik = () => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"),
  });
  return useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      authResendVerificationUser(values.email)
        .then(() => {
          store.dispatch(
            addAlert({
              message: "Verificacion reenviada",
              isError: false,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          store.dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
};

export const SendChangePassFormik = () => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"),
  });
  return useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { email } = values;
      const resendVerification = {
        email,
      };
      authSendChangePassUser(resendVerification)
        .then(() => {
          store.dispatch(
            addAlert({
              message: "Correo de cambio de contraseña enviado",
              isError: false,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          store.dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
};

export const ChangePassFormik = (props: {
  setErrorMessage: (value: ((prevState: string) => string) | string) => void;
  setErrorOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setSuccessOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setSuccessMessage: (value: ((prevState: string) => string) | string) => void;
  token: string | string[] | undefined | any;
}) => {
  const { token } = props;
  const router = useNavigate();
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Introduce tu contraseña")
      .min(
        8,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      )
      .matches(
        /^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      ),
    passwordConfirmation: yup
      .string()
      .nullable()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
      .required("Confirma la contraseña"),
  });
  return useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { password } = values;
      const changePass = {
        nuevaContraseña: password,
        uuid: token,
      };
      authChangePassUser(changePass)
        .then(() => {
          store.dispatch(
            addAlert({
              message: "Contraseña cambiada",
              isError: false,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
          setTimeout(() => {
            router("/");
          }, 3000);
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          store.dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
};
