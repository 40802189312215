import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { color } from "../../styles/color";
import styles from "../../styles/Contratacion.module.css";
import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xs";
import "@inveert/ui-kit/src/text/text-xxs";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/dropdown/dropdown";
import "@inveert/ui-kit/src/form/label/label";
import "@inveert/ui-kit/src/input/input-text";
import "@inveert/ui-kit/src/input/input-date";
import "@inveert/ui-kit/src/input/input-checkbox";

import SolidDividers from "../../elements/dividers/SolidDividers";
import {
  countries,
  laboral,
  maritalStatus,
  municipalities,
  professions,
  provinces,
  regions,
  streets,
} from "../../modules/contract/contract.data";
import {
  getErrorMessage,
  ibanValidator,
  validateDniNifNie,
} from "../../utils/functions";
import {
  postLoginFinanbestService,
  postRegistrationFinanbestService,
} from "../../modules/contract/contract.service";
import DotStepsFragment from "../../elements/fragments/DotStepsFragment";
import {
  selectContract,
  setUserFormData,
} from "../../modules/contract/contract.slice";
import { getUserDataService } from "../../modules/user/user.service";
import { addAlert } from "../../modules/app/app.slice";
import PageLoading from "../../elements/loading/PageLoading";

function InformacionUsuarioContratacion(props: { finanbestPass: string }) {
  const { finanbestPass } = props;
  const [userId, setUserId] = React.useState<null | number>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const router = useNavigate();
  const dispatch = useDispatch();
  const { userForm } = useSelector(selectContract);
  const maritalIds = maritalStatus.map((status) => status.value);
  const { email } = useSelector(selectContract);
  const documentTypeIdOptions = [
    { value: "", label: "" },
    { value: 0, label: "DNI" },
    { value: 1, label: "NIE" },
  ];
  const notSelectedOption = [{ value: "", label: "" }];
  const provinceNames = provinces.map((province) => province.label);
  const cityNames = municipalities.map((municipality) => municipality.name);
  const streetCodes = streets.map((street) => street.value);
  const laboralIds = laboral.map((job) => job.value);
  const professionIds = professions.map((profession) => profession.value);
  const processedCountries = notSelectedOption.concat(
    countries.map((country) => ({
      value: country.alpha2.toUpperCase(),
      label: country.name,
    }))
  );
  const cities = notSelectedOption.concat(
    municipalities.map((municipality) => ({
      value: municipality.name,
      label: municipality.name,
    }))
  );
  const processedRegions = notSelectedOption.concat(
    regions.map((region) => ({
      value: region,
      label: region,
    }))
  );
  const provincesProcessed = notSelectedOption.concat(
    provinces.map((province) => ({
      value: province.label,
      label: province.label,
    }))
  );
  const validationSchema = yup.object({
    name: yup.string().max(20).required("Introduce tu nombre"),
    lastName1: yup.string().max(20).required("Introduce tu primer apellido"),
    lastName2: yup.string().max(20).required("Introduce tu segundo apellido"),
    type_id: yup
      .number()
      .nullable()
      .typeError("Dinos el tipo de documento")
      .required("Dinos el tipo de documento")
      .oneOf([0, 1], "Dinos el tipo de documento"),
    document: yup
      .string()
      .nullable()
      .required("Dinos tu numero de documento")
      .test("documentValid", "Número de documento inválido", (val) =>
        validateDniNifNie(val)
      ),
    gender: yup
      .string()
      .nullable()
      .required("Introduce tu género")
      .oneOf(["MALE", "FEMALE"], "Introduce tu género"),
    expiration_date: yup
      .date()
      .typeError("La fecha no es valida")
      .nullable()
      .min(new Date(), "El documento no puede estar caducado")
      .required("Fecha de expiración requerida"),
    marital_status: yup
      .string()
      .nullable()
      .required("Dinos tu estado civil")
      .oneOf(maritalIds, "Dinos tu estado civil"),
    mobile: yup
      .number()
      .typeError("Tienes que introducir tu número de teléfono")
      .required("Tienes que introducir tu número de teléfono")
      .max(999999999, "Teléfono no válido")
      .min(100000000, "Teléfono no válido")
      .nullable(),
    nationality: yup
      .string()
      .nullable()
      .required("Dinos tu nacionalidad")
      .oneOf(["España"], "Dinos en que país naciste"),
    birth_country: yup
      .string()
      .nullable()
      .required("Dinos en que país naciste"),
    birth_region: yup.string().nullable().required("En que region naciste"),
    birth_city: yup.string().nullable().required("En que ciudad naciste"),
    varias_nacionalidades: yup.boolean(),
    primera_nacionalidad_extra: yup.string().nullable(),
    province: yup
      .string()
      .nullable()
      .required("En que provincia vives")
      .oneOf(provinceNames, "En que provincia vives"),
    postal_code: yup
      .number()
      .typeError("Dinos tu código postal")
      .nullable()
      .required("Dinos tu código postal"),
    region: yup
      .string()
      .nullable()
      .required("En que región vives")
      .oneOf(regions, "En que region vives"),
    city: yup
      .string()
      .nullable()
      .required("En que ciudad vives")
      .oneOf(cityNames, "En que ciudad vives"),
    address_type: yup
      .string()
      .nullable()
      .required("Dinos el tipo de vía")
      .oneOf(streetCodes, "Dinos el tipo de vía"),
    address: yup.string().nullable().required("Dinos tu direccion"),
    address_number: yup.string().nullable().required("dinos el numero"),
    address_floor: yup.string().nullable(),
    iban: yup
      .string()
      .nullable()
      .required("Necesitamos saber el IBAN de tu cuenta")
      .test("validateIban", "Número de cuenta inválido", (val) =>
        ibanValidator(val)
      ),
    economic_activity: yup
      .string()
      .nullable()
      .required("Necesitamos saber tu ocupación")
      .oneOf(laboralIds, "Necesitamos saber tu ocupación"),
    occupation: yup
      .string()
      .nullable()
      .required("Necesitamos saber tu sector")
      .oneOf(professionIds, "Necesitamos saber tu sector"),
    company: yup.string().nullable().required("Dinos donde trabajas"),
  });

  const formik = useFormik({
    initialValues: {
      gender: userForm.gender ?? null,
      name: userForm.name ?? "",
      lastName1: userForm.lastName1 ?? "",
      lastName2: userForm.lastName2 ?? "",
      type_id: userForm.type_id ?? null,
      document: userForm.document ?? "",
      expiration_date: userForm.expiration_date ?? null,
      marital_status: userForm.marital_status ?? null,
      mobile: userForm.mobile ?? null,
      nationality: "España",
      birth_country: userForm.birth_country ?? null,
      birth_region: userForm.birth_region ?? "",
      birth_city: userForm.birth_city ?? "",
      varias_nacionalidades: false,
      primera_nacionalidad_extra: null,
      province: userForm.province ?? "",
      postal_code: userForm.postal_code ?? null,
      region: userForm.region ?? "",
      city: userForm.city ?? "",
      address_type: userForm.address_type ?? null,
      address: userForm.address ?? "",
      address_number: userForm.address_number ?? "",
      address_floor: userForm.address_floor ?? "",
      iban: userForm.iban ?? "",
      economic_activity: userForm.economic_activity ?? null,
      occupation: userForm.occupation ?? null,
      company: userForm.company ?? "",
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values, actions) => {
      const splitedDate = values.expiration_date.split("-");
      let primeraNacionalidadParsed;
      const parsedExpirationDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
      if (values.varias_nacionalidades) {
        primeraNacionalidadParsed = processedCountries.find(
          (country) => country.value === values.primera_nacionalidad_extra
        )?.label;
      }
      const parsedBirthCountry =
        processedCountries.find(
          (country) => country.value === values.birth_country
        )?.label ?? "";
      const parsedJob = professions.find(
        (profession) => profession.value === values.occupation
      )?.label;
      const parsedLaboral = laboral.find(
        (labor) => labor.value === values.economic_activity
      )?.label;
      const parsedTypeId = [
        { value: 0, label: "DNI" },
        { value: 1, label: "NIE" },
      ].find((type) => type.value === values.type_id)?.label;
      const processedData = {
        email: email ?? "",
        last_name_1: values.lastName1 ?? "",
        last_name_2: values.lastName2 ?? "",
        password: finanbestPass,
        mobile: values.mobile,
        document: values.document,
        type_id: parsedTypeId ?? "",
        gender: values.gender,
        country: values.nationality,
        birth_country: parsedBirthCountry,
        birth_city: values.birth_city,
        birth_region: values.birth_region,
        postal_code: values.postal_code,
        region: values.region,
        city: values.city,
        population: values.city,
        province: values.province,
        municipality: values.city,
        nationality: values.nationality,
        expiration_date: parsedExpirationDate,
        address_type: values.address_type,
        address: values.address,
        address_number: values.address_number,
        address_floor: values.address_floor,
        residence_country: values.nationality,
        marital_status: values.marital_status,
        iban: values.iban,
        job: parsedJob ?? "",
        laboral: parsedLaboral ?? "",
        varias_nacionalidades: values.varias_nacionalidades,
        primera_nacionalidad_extra: primeraNacionalidadParsed ?? undefined,
      };
      postRegistrationFinanbestService(processedData, userId ?? 0)
        .then(() => {
          postLoginFinanbestService(userId ?? 0, email, finanbestPass)
            .then(() => {
              router("/contratacion/cumplimiento-normativo");
              actions.setSubmitting(false);
            })
            .catch(async (e) => {
              const message = await getErrorMessage(e);
              dispatch(
                addAlert({
                  message,
                  isError: true,
                  isOpen: true,
                })
              );
              actions.setSubmitting(false);
            });
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
  const handleCheckboxChange = (e: any) => {
    const name = e.target.attributes.name.value;
    // @ts-ignore
    const value = !formik.values[name];
    formik
      // @ts-ignore
      .setFieldValue(name, value, false)
      // @ts-ignore
      .then(() => formik.validateField(name));
  };
  const handleRadioChange = (e: { target: { name: string; value: any } }) => {
    dispatch(setUserFormData({ ...userForm, [e.target.name]: e.target.value }));
    formik
      .setFieldValue(e.target.name, e.target.value, false)
      // @ts-ignore
      .then(() => formik.validateField(e.target.name));
  };
  const handleChange = (e: any) => {
    const name = e.target.attributes.name.value;
    // @ts-ignore
    if (formik.touched[name]) {
      formik
        .setFieldValue(name, e.detail.value, false)
        // @ts-ignore
        .then(() => formik.validateField(name));
    }
  };
  const handleBlur = (e: any) => {
    const name = e.target.attributes.name.value;
    dispatch(setUserFormData({ ...userForm, [name]: e.detail.value }));
    formik.setFieldTouched(name, true, false);
    // @ts-ignore
    formik
      .setFieldValue(name, e.detail.value, false)
      // @ts-ignore
      .then(() => formik.validateField(name));
  };
  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("input-text-change", handleChange);
    window.addEventListener("dropdown-focus-out", handleBlur);
    window.addEventListener("dropdown-change", handleChange);
    window.addEventListener("input-text-focus-out", handleBlur);
    window.addEventListener("input-date-focus-out", handleBlur);
    window.addEventListener("input-checkbox-change", handleCheckboxChange);
    return () => {
      // @ts-ignore
      window.removeEventListener("input-text-change", handleChange);
      window.removeEventListener("dropdown-change", handleChange);
      window.removeEventListener("dropdown-focus-out", handleBlur);
      window.removeEventListener("input-text-focus-out", handleBlur);
      window.removeEventListener("input-date-focus-out", handleBlur);
      window.removeEventListener("input-checkbox-change", handleCheckboxChange);
    };
  }, [handleCheckboxChange, handleChange, handleBlur]);
  React.useEffect(() => {
    setLoading(true);
    const storage = globalThis?.sessionStorage;
    const token = storage.getItem("token");
    if (!token) {
      router("/login");
    } else if (finanbestPass === "") {
      router("/contratacion/inicio");
    } else {
      getUserDataService(token)
        .then((res) => {
          setUserId(res.attributes.user_id);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          if (e?.response?.data?.message) {
            dispatch(
              addAlert({
                message: e.response.data.message,
                isError: true,
                isOpen: true,
              })
            );
          }
        });
    }
  }, []);
  return (
    <div className={styles.container}>
      <PageLoading open={loading || formik.isSubmitting} />
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ display: "flex" }}>
          <inv-text-xxxl
            style={
              {
                "--inv-text-xxxl-gradient": color.gradient.blue,
                "--inv-text-xxxl-font-weight": 700,
                "--inv-text-xxxl-font-size": "2em",
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Información del cliente
          </inv-text-xxxl>
        </inv-grid-col>
      </inv-grid-row>
      <div style={{ padding: "0 3.6em" }}>
        <inv-grid-row style={{ marginTop: "4.1em" }}>
          <inv-grid-col class="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Datos personales
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "3.2em" }}>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.name}
              name="name"
              error={formik.errors.name}
              label="Tu nombre"
              placeholder="Francisco"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.lastName1}
              name="lastName1"
              error={formik.errors.lastName1}
              label="Primer apellido"
              placeholder="García"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.lastName2}
              name="lastName2"
              error={formik.errors.lastName2}
              label="Segundo apellido"
              placeholder="López"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Tipo de documento"
                error={formik.errors.type_id}
                placeholder="Selecciona uno"
                value={
                  documentTypeIdOptions.find(
                    (option) => option.value === formik.values.type_id
                  )?.label
                }
                name="type_id"
                options={JSON.stringify(documentTypeIdOptions)}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              name="document"
              value={formik.values.document}
              error={formik.errors.document}
              label="Número de documento"
              placeholder="18736459S"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-date
              value={formik.values.expiration_date}
              error={formik.errors.expiration_date}
              name="expiration_date"
              label="Fecha de expiración"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Estado civil"
                error={formik.errors.marital_status}
                placeholder="Selecciona uno"
                value={
                  notSelectedOption
                    .concat(maritalStatus)
                    .find(
                      (option) => option.value === formik.values.marital_status
                    )?.label
                }
                name="marital_status"
                options={JSON.stringify(
                  notSelectedOption.concat(maritalStatus)
                )}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              name="mobile"
              value={formik.values.mobile}
              error={formik.errors.mobile}
              label="Teléfono"
              placeholder="677770700"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-dropdown
              label="Nacionalidad"
              value={formik.values.nationality}
              name="nationality"
              options={JSON.stringify(processedCountries)}
              disabled
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col class="col">
            <inv-dropdown
              placeholder="País donde naciste"
              name="birth_country"
              error={formik.errors.birth_country}
              label="País donde naciste"
              value={
                processedCountries.find(
                  (option: { value: any }) =>
                    option.value === formik.values.birth_country
                )?.label
              }
              options={JSON.stringify(processedCountries)}
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              name="birth_region"
              value={formik.values.birth_region}
              error={formik.errors.birth_region}
              label="Región donde naciste"
              placeholder="Murcia"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              name="birth_city"
              value={formik.values.birth_city}
              error={formik.errors.birth_city}
              label="Ciudad donde naciste"
              placeholder="Cartagena"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            justifyContent: "center",
            marginTop: "1.6em",
            "--inv-gutter-x": "0.6em",
          }}
        >
          <inv-grid-col class="col">
            <inv-grid-row style={{ alignItems: "center" }}>
              <inv-grid-col class="col-auto">
                <inv-input-checkbox
                  name="varias_nacionalidades"
                  style={{ "--inv-form-checkbox-font-family": "26px" }}
                />
              </inv-grid-col>
              <inv-grid-col class="col-auto">
                <inv-text-l
                  style={{
                    "--inv-text-l-font-weight": 400,
                    "--inv-text-l-color": color.greyscale.black[50],
                  }}
                >
                  Tengo varias nacionalidades
                </inv-text-l>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
        {formik.values.varias_nacionalidades === true && (
          <inv-grid-row style={{ marginTop: "1.6em" }}>
            <inv-grid-col
              style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em)" }}
              class="col-4"
            >
              <inv-dropdown
                label="Otra nacionalidad"
                error={formik.errors.primera_nacionalidad_extra}
                name="primera_nacionalidad_extra"
                placeholder="Nacionalidad extra"
                value={
                  processedCountries.find(
                    (option: { value: any }) =>
                      option.value === formik.values.primera_nacionalidad_extra
                  )?.label
                }
                options={JSON.stringify(processedCountries)}
              />
            </inv-grid-col>
          </inv-grid-row>
        )}
        <inv-grid-row style={{ marginTop: "3.2em" }}>
          <inv-grid-col class="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Cuenta para rescatar tu dinero
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col
            class="col-6"
            style={{ width: "calc(50% - var(--inv-gutter-x,1.5em)" }}
          >
            <inv-input-text
              value={formik.values.iban}
              name="iban"
              error={formik.errors.iban}
              label="IBAN de tu cuenta corriente"
              placeholder="ES5501304518933950023822"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "3.2em" }}>
          <inv-grid-col class="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Datos personales
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-auto" style={{ marginRight: "7em" }}>
            <inv-text-m
              style={{
                "--inv-text-m-font-weight": 400,
                "--inv-text-m-color": color.greyscale.black[50],
              }}
            >
              Selecciona tu género
            </inv-text-m>
          </inv-grid-col>
          <inv-grid-col class="col-auto">
            <RadioGroup
              row
              aria-labelledby="gender-radio-buttons-group"
              name="gender"
              value={formik.values.gender}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value="MALE"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        height: "0.75em",
                        width: "0.75em",
                      },
                    }}
                  />
                }
                label={
                  <inv-text-m
                    style={{
                      "--inv-text-m-font-size": "0.8em",
                      "--inv-text-m-color":
                        formik.values.gender !== "MALE"
                          ? color.greyscale.black[50]
                          : undefined,
                    }}
                  >
                    Hombre
                  </inv-text-m>
                }
              />
              <FormControlLabel
                value="FEMALE"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        height: "0.75em",
                        width: "0.75em",
                      },
                    }}
                  />
                }
                label={
                  <inv-text-m
                    style={{
                      "--inv-text-m-font-size": "0.8em",
                      "--inv-text-m-color":
                        formik.values.gender !== "FEMALE"
                          ? color.greyscale.black[50]
                          : undefined,
                    }}
                  >
                    Mujer
                  </inv-text-m>
                }
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col>
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.gender}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col
            class="col-2"
            style={{ width: "calc(16.66666% - var(--inv-gutter-x,1.5em))" }}
          >
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Tipo de via"
                error={formik.errors.address_type}
                placeholder="Selecciona uno"
                value={
                  notSelectedOption
                    .concat(streets)
                    .find(
                      (option) => option.value === formik.values.address_type
                    )?.label
                }
                name="address_type"
                options={JSON.stringify(notSelectedOption.concat(streets))}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col
            class="col-4"
            style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em))" }}
          >
            <inv-input-text
              value={formik.values.address}
              name="address"
              error={formik.errors.address}
              label="Nombre de la calle"
              placeholder="De la Vega"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.address_number}
              name="address_number"
              error={formik.errors.address_number}
              label="Número"
              placeholder="25"
            />
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.address_floor}
              name="address_floor"
              error={formik.errors.address_floor}
              label="Piso"
              placeholder="3A"
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "1.6em" }}>
          <inv-grid-col
            class="col-2"
            style={{ width: "calc(16.66666% - var(--inv-gutter-x,1.5em))" }}
          >
            <inv-input-text
              name="postal_code"
              value={formik.values.postal_code}
              error={formik.errors.postal_code}
              label="Código postal"
              placeholder="11100"
            />
          </inv-grid-col>
          <inv-grid-col
            class="col-4"
            style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em))" }}
          >
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Población"
                error={formik.errors.city}
                placeholder="Selecciona una"
                search
                value={
                  cities.find((option) => option.value === formik.values.city)
                    ?.label
                }
                name="city"
                options={JSON.stringify(cities)}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Provincia"
                error={formik.errors.province}
                placeholder="Selecciona una"
                search
                value={
                  provincesProcessed.find(
                    (option) => option.value === formik.values.province
                  )?.label
                }
                name="province"
                options={JSON.stringify(provincesProcessed)}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Comunidad Autónoma"
                error={formik.errors.region}
                placeholder="Selecciona una"
                search
                value={
                  processedRegions.find(
                    (option) => option.value === formik.values.region
                  )?.label
                }
                name="region"
                options={JSON.stringify(processedRegions)}
              />
            </div>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "3.2em" }}>
          <inv-grid-col class="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Datos profesionales
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "3.2em" }}>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Ocupación"
                error={formik.errors.economic_activity}
                placeholder="Selecciona una"
                value={
                  notSelectedOption
                    .concat(laboral)
                    .find(
                      (option) =>
                        option.value === formik.values.economic_activity
                    )?.label
                }
                name="economic_activity"
                options={JSON.stringify(notSelectedOption.concat(laboral))}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <div style={{ marginBottom: "0.4em" }}>
              <inv-dropdown
                label="Sector"
                error={formik.errors.occupation}
                placeholder="Selecciona uno"
                value={
                  notSelectedOption
                    .concat(professions)
                    .find((option) => option.value === formik.values.occupation)
                    ?.label
                }
                name="occupation"
                options={JSON.stringify(notSelectedOption.concat(professions))}
              />
            </div>
          </inv-grid-col>
          <inv-grid-col class="col">
            <inv-input-text
              value={formik.values.company}
              name="company"
              error={formik.errors.company}
              label="Empresa en la que trabajas"
              placeholder="Nombre de tu empresa"
            />
          </inv-grid-col>
        </inv-grid-row>
      </div>

      <inv-grid-row style={{ marginTop: "5.2em", padding: "" }}>
        <inv-grid-col class="col-12">
          <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x,1.5em))" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1.2em 3.6em 0",
        }}
      >
        <inv-grid-col class="col-2" />
        <inv-grid-col class="col-auto" style={{ zIndex: 1 }}>
          <DotStepsFragment steps={3} activeStep={0} />
        </inv-grid-col>
        <inv-grid-col class="col-2">
          <inv-button
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={() => formik.submitForm()}
          >
            Continuar
          </inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

InformacionUsuarioContratacion.title = "Registro finanbest";
InformacionUsuarioContratacion.layout = "EmptyLayout";
InformacionUsuarioContratacion.mobileLayout = "MobileEmptyLayout";
export default InformacionUsuarioContratacion;
