import * as React from "react";
import { color } from "../../styles/color";
import "@inveert/ui-kit/src/text/text-xs";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import adviceAlert from "../../styles/AdviceAlert.module.css";

interface IAdviceAlert {
  text: string | JSX.Element;
  icon?: JSX.Element;
  isCentered?: boolean;
}

function AdviceAlert(props: IAdviceAlert) {
  const { text, icon, isCentered } = props;
  return (
    <inv-grid-row
      class={adviceAlert.container}
      style={{
        lineHeight: 0,
        borderRadius: "0.8em",
        background: `var(--inv-advice-background,${color.turquoise[20]})`,
        padding: "0.8em",
        alignItems: "center",
        justifyContent: isCentered ? "center" : "initial",
        "--inv-gutter-x": "0.8em",
      }}
    >
      <inv-grid-col
        class={isCentered ? "col-auto" : "col-1"}
        style={{ color: color.turquoise[100] }}
      >
        {icon}
      </inv-grid-col>
      <inv-grid-col class={isCentered ? "col-auto" : "col-9 col-sm-10"}>
        <inv-text-xs
          style={{
            lineHeight: "140%",
            "--inv-text-xs-font-weight": 400,
            "--inv-text-xs-color": color.greyscale.black[50],
          }}
        >
          {text}
        </inv-text-xs>
      </inv-grid-col>
    </inv-grid-row>
  );
}

AdviceAlert.defaultProps = {
  icon: undefined,
  isCentered: false,
};

export default AdviceAlert;
