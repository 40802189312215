import * as React from "react";
import NumberFormat from "react-number-format";
import styles from "./propuesta-three-values-percentage-bar.module.css";
import { color } from "../../styles/color";
import { Size, useWindowSize } from "../../utils/hooks";

interface PropuestaThreeValuesPercentageBarProps {
  value1: number;
  value2: number;
  value3: number;
}

function PropuestaThreeValuesPercentageBar(
  props: PropuestaThreeValuesPercentageBarProps
) {
  const { value1, value2, value3 } = props;
  const size: Size = useWindowSize();
  const downMobile = (size?.width ?? 0) < 576;
  return (
    <div>
      <inv-grid-row
        style={{
          justifyContent: "space-between",
          "--inv-gutter-x": 0,
        }}
      >
        <inv-grid-col
          class="col-auto"
          style={{
            height: 17,
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value1 / (value1 + value2 + value3)
            })`,
            background: color.greyscale.black[50],
            borderRadius: 27.1011,
          }}
        />
        <inv-grid-col
          class="col-auto"
          style={{
            height: 17,
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value2 / (value1 + value2 + value3)
            })`,
            background: color.greyscale.black[30],
            borderRadius: 27.1011,
          }}
        />
        <inv-grid-col
          class="col-auto"
          style={{
            height: 17,
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value3 / (value1 + value2 + value3)
            })`,
            background: color.turquoise[100],
            borderRadius: 27.1011,
          }}
        />
      </inv-grid-row>
      <inv-grid-row class={styles.apportationsPercentageContainer}>
        <inv-grid-col
          class="col-auto"
          style={{
            display: "flex",
            justifyContent: "center",
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value1 / (value1 + value2 + value3)
            })`,
          }}
        >
          <inv-text-xs
            style={{
              "--inv-text-xs-font-weight": 600,
              "--inv-text-xs-color": color.greyscale.black[50],
            }}
          >
            <NumberFormat
              value={(value1 / (value1 + value2 + value3)) * 100}
              thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
              decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
              decimalScale={1}
              allowNegative={false}
              displayType="text"
              suffix="%"
            />
          </inv-text-xs>
        </inv-grid-col>
        <inv-grid-col
          class="col-auto"
          style={{
            display: "flex",
            justifyContent: "center",
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value2 / (value1 + value2 + value3)
            })`,
          }}
        >
          <inv-text-xs
            style={{
              "--inv-text-xs-font-weight": 600,
              "--inv-text-xs-color": color.greyscale.black[30],
            }}
          >
            <NumberFormat
              value={(value2 / (value1 + value2 + value3)) * 100}
              thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
              decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
              decimalScale={1}
              allowNegative={false}
              displayType="text"
              suffix="%"
            />
          </inv-text-xs>
        </inv-grid-col>
        <inv-grid-col
          class="col-auto"
          style={{
            display: "flex",
            justifyContent: "center",
            width: `calc((100% - ${downMobile ? "8" : "32"}px) * ${
              value3 / (value1 + value2 + value3)
            })`,
          }}
        >
          <inv-text-xs
            style={{
              "--inv-text-xs-font-weight": 600,
              "--inv-text-xs-color": color.turquoise[100],
            }}
          >
            <NumberFormat
              value={(value3 / (value1 + value2 + value3)) * 100}
              thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
              decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
              decimalScale={1}
              allowNegative={false}
              displayType="text"
              suffix="%"
            />
          </inv-text-xs>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

export default PropuestaThreeValuesPercentageBar;
