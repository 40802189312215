import * as React from "react";
import { color } from "../../styles/color";

interface IPropuestaTextStructureComponent {
  children: any;
  label: string;
  unit?: string;
  icon?: any;
  componentAfterUnit?: any;
  helper?: string;
  labelIcon?: any;
}
function PropuestaTextStructureComponent(
  props: IPropuestaTextStructureComponent
) {
  const { children, label, unit, icon, helper, labelIcon, componentAfterUnit } =
    props;
  return (
    <inv-grid-row>
      <inv-grid-col class="col-auto">
        <inv-grid-row style={{ "--inv-gutter-x": "0.4em" }}>
          <inv-grid-col class="col-auto">
            <inv-text-xxs
              style={{
                "--inv-text-xxs-color": `var(--inv-text-structure-label-color,${color.greyscale.black[50]})`,
                "--inv-text-l-font-weight": 500,
              }}
            >
              {label}
            </inv-text-xxs>
          </inv-grid-col>
          {labelIcon && (
            <inv-grid-col class="col-auto">{labelIcon}</inv-grid-col>
          )}
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col-12">
            <inv-grid-row
              style={{
                "--inv-gutter-x": "0.5em",
                alignItems: "flex-end",
              }}
            >
              <inv-grid-col class="col-auto">
                <inv-grid-row
                  style={{
                    "--inv-gutter-x": "0.1em",
                    alignItems: "baseline",
                  }}
                >
                  <inv-grid-col class="col-auto">
                    <inv-text-l
                      style={{
                        "--inv-text-l-font-weight": 600,
                      }}
                    >
                      {children}
                    </inv-text-l>
                  </inv-grid-col>
                  {unit && (
                    <inv-grid-col class="col-auto">
                      <inv-text-xs
                        style={{
                          "--inv-text-xs-font-weight": 700,
                        }}
                      >
                        {unit}
                      </inv-text-xs>
                    </inv-grid-col>
                  )}
                  {componentAfterUnit && (
                    <inv-grid-col class="col-auto">
                      {componentAfterUnit}
                    </inv-grid-col>
                  )}
                </inv-grid-row>
              </inv-grid-col>
              {icon && <inv-grid-col class="col-auto">{icon}</inv-grid-col>}
            </inv-grid-row>
            <inv-grid-row>
              <inv-grid-col class="col-auto" style={{ lineHeight: 0 }}>
                <inv-text-xxs
                  style={{
                    "--inv-text-xxs-font-weight": 500,
                  }}
                >
                  {helper}
                </inv-text-xxs>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
      </inv-grid-col>
    </inv-grid-row>
  );
}

export default PropuestaTextStructureComponent;

PropuestaTextStructureComponent.defaultProps = {
  icon: null,
  unit: null,
  helper: null,
  labelIcon: null,
  componentAfterUnit: null,
};
