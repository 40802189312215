export const color = {
  turquoise: {
    105: "#29ADB7",
    100: "#33C5D1",
    95: "#50CED8",
    80: "#6DD6DE",
    60: "#99E2E8",
    40: "#B6F0F5",
    20: "#E1F7F8",
    5: "#F3FEFF",
    T80: "rgba(51, 197, 209, 0.8)",
    T60: "rgba(51, 197, 209, 0.6)",
    T40: "rgba(51, 197, 209, 0.4)",
    T20: "rgba(51, 197, 209, 0.2)",
  },
  blue: {
    105: "#083577",
    100: "#0E4DAB",
    95: "#1B5FC5",
    80: "#548EE3",
    60: "#86ACE6",
    40: "#BAD3F7",
    20: "#E9F1FD",
    5: "#F5F9FE",
  },
  gradient: {
    headerHome: "linear-gradient(0deg, #D0D6E1 0%, #FFFFFF 31.25%)",
    blue: "linear-gradient(93.64deg, #00D5E0 1%, #15A6DA 26.85%, #004BBA 86.58%, #0F4595 99.6%)",
    1: "linear-gradient(180deg, #C7FCEC 24.15%, #6CD6DA 79.92%, #F1F1F1 84.44%)",
    2: "linear-gradient(160.84deg, #C7FCEC 38.36%, #6CD6DA 67.16%, #F1F1F1 87.04%)",
    semaforo:
      "linear-gradient(90deg, rgba(14, 231, 166, 0.6) 12.5%, rgba(255, 171, 71, 0.6) 56.36%, rgba(226, 46, 89, 0.6) 100%)",
  },
  goal: {
    1.1: "#DA1BA4",
    1.2: "#EC6CC8",
    1.3: "#FFB8EF",
    2.1: "#F5931F",
    2.2: "#FFAB47",
    2.3: "#FFF2E3",
    3.1: "#20D35D",
    3.2: "#95DE5C",
    3.3: "#DBFFB8",
    4.1: "#D3CC20",
    4.2: "#EBE561",
    4.3: "#FFFCB8",
    5.1: "#0E4DAB",
    5.2: "#548DE3",
    5.3: "#E9F1FD",
  },
  validation: {
    error: "#E22E59",
    success: "#4ADBAF",
    disabled: "#97AAC6",
  },
  greyscale: {
    black: {
      100: "#00122B",
      95: "#112645",
      50: "#6D809C",
      40: "#8BA1B6",
      30: "#AABACA",
      10: "#DBE1E7",
      5: "#F0F5F9",
      1: "#F9FCFF",
    },
    white: {
      100: "#FFFFFF",
      T80: "rgba(255, 255, 255, 0.8)",
      T60: "rgba(255, 255, 255, 0.6)",
      T40: "rgba(255, 255, 255, 0.4)",
      T20: "rgba(255, 255, 255, 0.2)",
    },
  },
};

export default color;
