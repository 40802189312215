import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../../styles/color";
import styles from "../../styles/Contratacion.module.css";

import SolidDividers from "../../elements/dividers/SolidDividers";
import { cecabankJobs } from "../../modules/contract/contract.data";
import DotStepsFragment from "../../elements/fragments/DotStepsFragment";
import SelectInput from "../../elements/input/SelectInput";
import { FileInput } from "../../elements/input/FileInput";
import { selectContract } from "../../modules/contract/contract.slice";

import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xs";
import "@inveert/ui-kit/src/text/text-xxs";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/form/label/label";
import {
  checkFinanbestAuthError,
  getBase64,
  getErrorMessage,
} from "../../utils/functions";
import {
  kycOnFinanbestApi,
  postDniService,
} from "../../modules/contract/contract.service";
import { getUserDataService } from "../../modules/user/user.service";
import { addAlert } from "../../modules/app/app.slice";
import PageLoading from "../../elements/loading/PageLoading";
import { ContractFinanbestLoginDialogComponent } from "../../modules/contract/contract-finanbest-login-dialog.component";
import ContainerDialog from "../../elements/dialog/ContainerDialog";

function CumplimientoNormativoContratacion() {
  const dispatch = useDispatch();
  const { kycForm } = useSelector(selectContract);
  const { userForm } = useSelector(selectContract);
  const router = useNavigate();
  const [isDniFrontCorrectlySent, setIsDniFrontCorrectlySent] =
    React.useState(true);
  const [isAuthOpen, setIsAuthOpen] = React.useState(false);
  const [isDniBackCorrectlySent, setIsDniBackCorrectlySent] =
    React.useState(true);
  const [userId, setUserId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);

  const cecaBankJobsProcessed = cecabankJobs.map((job) => ({
    value: job,
    label: job,
  }));
  const validationSchema = yup.object({
    haveLinkCecabank: yup.number().nullable().oneOf([0, 1], "Debes marcarlo"),
    cecabank_relationship: yup
      .string()
      .when(["haveLinkCecabank"], (haveLinkCecabank) =>
        haveLinkCecabank === true
          ? yup
              .string()
              .nullable()
              .required("Dinos tu vinculación")
              .oneOf(cecabankJobs, "Dinos tu vinculación")
          : yup.string().nullable()
      ),
    fiscal_obligations: yup.number().nullable().oneOf([0, 1], "Debes marcarlo"),
    public_functions: yup.number().nullable().oneOf([0, 1], "Debes marcarlo"),
    public_functions_family: yup
      .number()
      .nullable()
      .oneOf([0, 1], "Debes marcarlo"),
    other_sources_income: yup
      .number()
      .nullable()
      .oneOf([0, 1], "Debes marcarlo"),
    dniFrontFile: yup
      .mixed()
      .nullable()
      .required("Debes subir el fichero")
      .test(
        "format",
        "El fichero debe ser un pdf",
        (value) => !value || (value && ["application/pdf"].includes(value.type))
      ),
    dniBackFile: yup
      .mixed()
      .nullable()
      .required("Debes subir el fichero")
      .test(
        "format",
        "El fichero debe ser un pdf",
        (value) => !value || (value && ["application/pdf"].includes(value.type))
      ),
  });

  const formik = useFormik({
    initialValues: {
      haveLinkCecabank: kycForm.haveLinkCecabank ?? null,
      cecabank_relationship: cecabankJobs[cecabankJobs.length - 1],
      fiscal_obligations: kycForm.fiscal_obligations ?? null,
      public_functions: kycForm.public_functions ?? null,
      public_functions_family: kycForm.public_functions_family ?? null,
      other_sources_income: kycForm.other_sources_income ?? null,
      dniFrontFile: null,
      dniBackFile: null,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values, actions) => {
      if (isDniBackCorrectlySent && isDniFrontCorrectlySent) {
        const payload = {
          fiscal_obligations: values.fiscal_obligations,
          cecabank_relationship: values.cecabank_relationship,
          company: userForm.company ?? "",
          public_functions: values.public_functions,
          public_functions_family: values.public_functions_family,
          other_sources_income: values.other_sources_income,
        };
        kycOnFinanbestApi(payload, userId)
          .then(() => router("/contratacion/firma"))
          .catch(async (e) => {
            const isFinanbestAuthError = await checkFinanbestAuthError(e);
            if (isFinanbestAuthError) {
              setIsAuthOpen(isFinanbestAuthError);
            } else {
              const message = await getErrorMessage(e);
              dispatch(
                addAlert({
                  message,
                  isError: true,
                  isOpen: true,
                })
              );
            }
            setLoading(false);
          });
      } else {
        actions.setSubmitting(false);
        dispatch(
          addAlert({
            message: "Error al enviar el DNI, sube los documentos de nuevo",
            isError: true,
            isOpen: true,
          })
        );
      }
    },
  });

  const handleRadioChange = (e: { target: { name: string; value: any } }) =>
    formik.setFieldValue(e.target.name, parseInt(e.target.value, 10), false);

  const sendDni = (
    dniFile: Blob,
    correctFunction: (arg0: boolean) => void,
    isFront: boolean
  ) => {
    formik.setSubmitting(true);
    getBase64(dniFile)
      .then((fileInBase64) =>
        postDniService(fileInBase64, isFront, userId)
          .then(() => {
            correctFunction(true);
            formik.setSubmitting(false);
          })
          .catch(async (e) => {
            const message = await getErrorMessage(e);
            dispatch(
              addAlert({
                message,
                isError: true,
                isOpen: true,
              })
            );
            formik.setSubmitting(false);
          })
      )
      .catch(async (e) => {
        const message = await getErrorMessage(e);
        dispatch(
          addAlert({
            message,
            isError: true,
            isOpen: true,
          })
        );
        formik.setSubmitting(false);
      });
  };

  React.useEffect(() => {
    const { dniBackFile } = formik.values;
    if (dniBackFile) {
      sendDni(dniBackFile, setIsDniBackCorrectlySent, false);
    }
  }, [formik.values.dniBackFile]);
  React.useEffect(() => {
    const { dniFrontFile } = formik.values;
    if (dniFrontFile) {
      sendDni(dniFrontFile, setIsDniFrontCorrectlySent, true);
    }
  }, [formik.values.dniFrontFile]);
  React.useEffect(() => {
    setLoading(true);
    const storage = globalThis?.sessionStorage;
    const token = storage.getItem("token");
    if (!token) {
      router("/login");
    } else {
      getUserDataService(token)
        .then((res) => {
          setUserId(res.attributes.user_id);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          if (e?.response?.data?.message) {
            dispatch(
              addAlert({
                message: e.response.data.message,
                isError: true,
                isOpen: true,
              })
            );
          }
        });
    }
  }, []);

  return (
    <div className={styles.container}>
      <ContainerDialog open={isAuthOpen} onClose={() => null} scroll="body">
        <ContractFinanbestLoginDialogComponent closeFunction={setIsAuthOpen} />
      </ContainerDialog>
      <PageLoading open={loading || formik.isSubmitting} />
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ display: "flex" }}>
          <inv-text-xxxl
            style={
              {
                "--inv-text-xxxl-gradient": color.gradient.blue,
                "--inv-text-xxxl-font-weight": 700,
                "--inv-text-xxxl-font-size": "2em",
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Cumplimiento normativo
          </inv-text-xxxl>
        </inv-grid-col>
      </inv-grid-row>
      <div style={{ padding: "0 3.6em" }}>
        <inv-grid-row style={{ marginTop: "4.1em" }}>
          <inv-grid-col class="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Información a completar
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 300,
              }}
            >
              ¿Tienes alguna vinculación con Cecabank?
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col
            class="col-3"
            style={{ width: "calc(25% - var(--inv-gutter-x,1.5em))" }}
          >
            <RadioGroup
              row
              aria-labelledby="haveLinkCecabank-radio-buttons-group"
              name="haveLinkCecabank"
              value={formik.values.haveLinkCecabank}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value={1}
                control={<Radio />}
                label={<inv-text-m>Si</inv-text-m>}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={<inv-text-m>No</inv-text-m>}
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col">
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.haveLinkCecabank}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        {formik.values.haveLinkCecabank === 1 && (
          <inv-grid-row
            style={{
              marginTop: "1.6em",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <inv-grid-col
              className="col-4"
              style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em)" }}
            >
              <SelectInput
                disabled={formik.values.haveLinkCecabank !== 1}
                value={formik.values.cecabank_relationship}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="cecabank_relationship"
                label="Vinculación con CecaBank"
                // @ts-ignore
                error={formik.errors.cecabank_relationship}
                options={cecaBankJobsProcessed}
              />
            </inv-grid-col>
          </inv-grid-row>
        )}
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 300,
              }}
            >
              ¿Tienes obligaciones fiscales en otros paises?
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col
            class="col-3"
            style={{ width: "calc(25% - var(--inv-gutter-x,1.5em))" }}
          >
            <RadioGroup
              row
              aria-labelledby="fiscal_obligations-radio-buttons-group"
              name="fiscal_obligations"
              value={formik.values.fiscal_obligations}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value={1}
                control={<Radio />}
                label={<inv-text-m>Si</inv-text-m>}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={<inv-text-m>No</inv-text-m>}
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col">
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.fiscal_obligations}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col
            class="col-4"
            style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em)" }}
          >
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 300,
              }}
            >
              ¿Tienes otras fuentes de ingresos subsidios, rentas, etc.?{" "}
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col
            class="col-3"
            style={{ width: "calc(25% - var(--inv-gutter-x,1.5em))" }}
          >
            <RadioGroup
              row
              aria-labelledby="other_sources_income-radio-buttons-group"
              name="other_sources_income"
              value={formik.values.other_sources_income}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value={1}
                control={<Radio />}
                label={<inv-text-m>Si</inv-text-m>}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={<inv-text-m>No</inv-text-m>}
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col">
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.other_sources_income}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 300,
              }}
            >
              ¿Tienes o has tenido un cargo publico?{" "}
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col
            class="col-3"
            style={{ width: "calc(25% - var(--inv-gutter-x,1.5em))" }}
          >
            <RadioGroup
              row
              aria-labelledby="public_functions-radio-buttons-group"
              name="public_functions"
              value={formik.values.public_functions}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value={1}
                control={<Radio />}
                label={<inv-text-m>Si</inv-text-m>}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={<inv-text-m>No</inv-text-m>}
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col">
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.public_functions}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "1.6em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 300,
              }}
            >
              ¿Tienes algún familiar o allegado con cargo público?
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col
            class="col-3"
            style={{ width: "calc(25% - var(--inv-gutter-x,1.5em))" }}
          >
            <RadioGroup
              row
              aria-labelledby="public_functions_family-radio-buttons-group"
              name="public_functions_family"
              value={formik.values.public_functions_family}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                sx={{ marginRight: "2.3em" }}
                value={1}
                control={<Radio />}
                label={<inv-text-m>Si</inv-text-m>}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={<inv-text-m>No</inv-text-m>}
              />
            </RadioGroup>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col">
            <inv-form-label
              style={{ "--inv-form-label-color": color.validation.error }}
            >
              {formik.errors.public_functions_family}
            </inv-form-label>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginTop: "4.7em" }}>
          <inv-grid-col className="col-12">
            <inv-text-l
              style={{
                "--inv-text-l-font-weight": 600,
              }}
            >
              Fotografía de tu documentación
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            marginTop: "3em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <inv-grid-col
            class="col-4"
            style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em)" }}
          >
            <FileInput
              handleChange={formik.setFieldValue}
              label="Frontal del documento"
              formik={formik}
              name="dniFrontFile"
              error={formik.errors.dniFrontFile}
            />
          </inv-grid-col>
          <inv-grid-col
            class="col-4"
            style={{ width: "calc(33.33333% - var(--inv-gutter-x,1.5em)" }}
          >
            <FileInput
              handleChange={formik.setFieldValue}
              label="Reverso del documento"
              formik={formik}
              name="dniBackFile"
              error={formik.errors.dniBackFile}
            />
          </inv-grid-col>
        </inv-grid-row>
      </div>

      <inv-grid-row style={{ marginTop: "5.2em", padding: "" }}>
        <inv-grid-col class="col-12">
          <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x,1.5em))" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1.2em 3.6em 0",
        }}
      >
        <inv-grid-col class="col-2">
          <inv-button
            onClick={() => router("/contratacion/informacion-usuario")}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Atrás
          </inv-button>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <DotStepsFragment steps={3} activeStep={1} />
        </inv-grid-col>
        <inv-grid-col class="col-2">
          <inv-button
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={() => formik.submitForm()}
          >
            Continuar
          </inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

CumplimientoNormativoContratacion.title = "Cumplimiento normativo";
CumplimientoNormativoContratacion.layout = "EmptyLayout";
CumplimientoNormativoContratacion.mobileLayout = "MobileEmptyLayout";
export default CumplimientoNormativoContratacion;
