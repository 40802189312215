export default function parseSaml(plaintext: string) {
  // The Javascript escape and unescape functions do not correspond
  // with what browsers actually do...
  const SAFECHARS =
    "0123456789" + // Numeric
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + // Alphabetic
    "abcdefghijklmnopqrstuvwxyz" +
    "-_.!~*'()"; // RFC2396 Mark characters
  const HEX = "0123456789ABCDEF";
  let encoded = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < plaintext.length; i++) {
    const ch = plaintext.charAt(i);
    if (ch === " ") {
      encoded += "+"; // x-www-urlencoded, rather than %20
    } else if (SAFECHARS.indexOf(ch) !== -1) {
      encoded += ch;
    } else {
      const charCode = ch.charCodeAt(0);
      if (charCode > 255) {
        encoded += "+";
      } else {
        encoded += "%";
        // eslint-disable-next-line no-bitwise
        encoded += HEX.charAt((charCode >> 4) & 0xf);
        // eslint-disable-next-line no-bitwise
        encoded += HEX.charAt(charCode & 0xf);
      }
    }
  } // for
  return encoded;
}
