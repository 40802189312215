import * as yup from "yup";
import { format, sub } from "date-fns";
import { useFormik } from "formik";
import { changeUserDataApi, getUserIdApi } from "./user.service";
import { setUser } from "./user.slice";
import { getErrorMessage } from "../../utils/functions";
import { addAlert } from "../app/app.slice";

interface IuserFormik {
  name: string;
  birthday: Date | null;
  monthly_income: number | null;
  zip_code: string;
  document?: string;
  closeDialog?: any;
  finish?: () => void;
  dispatch: any;
}

export const UserFormik = (props: IuserFormik) => {
  const {
    finish,
    name,
    birthday,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    monthly_income,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    zip_code,
    closeDialog,
    dispatch,
  } = props;
  const validationSchema = yup.object({
    name: yup.string().max(20).required("Introduce tu nombre"),
    birthday: yup
      .date()
      .typeError("La fecha no es valida")
      .nullable()
      .min(sub(Date.now(), { years: 90 }), "Debes tener menos de 90")
      .max(sub(Date.now(), { years: 18 }), "Debes ser mayor de edad")
      .required("Fecha de nacimiento requerida"),
    /* email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"), */
    /*
        document: yup.string()
            .required("Dinos tu DNI/NIE")
            .test('documentValid', 'Número de documento inválido',val=>validateDniNifNie(val)),

         */
    /*
      monthly_income: yup.number().required("Ingresos requeridos").nullable(),
    zip_code: yup
      .string()
      .required("Introduce tu código postal")
      .matches(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/, "Código postal inválido"),
     */
  });
  return useFormik({
    initialValues: {
      name,
      birthday,
      monthly_income,
      zip_code,
      // document:document
    },
    validationSchema,
    onSubmit: closeDialog
      ? () => {
          closeDialog();
        }
      : (values, actions) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/naming-convention
          const { name, birthday, zip_code } = values;
          const familySituation = {
            name,
            birthday: birthday ? format(birthday, "yyyy-MM-dd") : "",
            zip_code,
          };
          const storage = globalThis?.sessionStorage;
          if (storage) {
            const token = storage.getItem("token");
            getUserIdApi(token ?? "")
              .then((res) => {
                changeUserDataApi(res.attributes.user_id.toString(), {
                  name,
                  birthday: familySituation.birthday,
                })
                  .then(() => {
                    dispatch(
                      setUser({
                        birthday: familySituation.birthday,
                        name,
                      })
                    );
                    if (finish) {
                      finish();
                      actions.setSubmitting(false);
                    }
                  })
                  .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                      addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                      })
                    );
                    actions.setSubmitting(false);
                  });
              })
              .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                  addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                  })
                );
                actions.setSubmitting(false);
              });
          }
        },
  });
};

export default UserFormik;
