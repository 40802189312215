import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../../styles/color";
import styles from "../../styles/ContratacionInicio.module.css";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/form/label/label";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/input/input-text";
import "@inveert/ui-kit/src/input/input-checkbox";

import SolidDividers from "../../elements/dividers/SolidDividers";
import TextInput from "../../elements/input/TextInput";
import {
  selectContract,
  setFinanbestEmail,
} from "../../modules/contract/contract.slice";
import {
  postCheckFinanbestMailExistenceService,
  postLoginFinanbestService,
} from "../../modules/contract/contract.service";
import { getUserDataService } from "../../modules/user/user.service";
import { addAlert } from "../../modules/app/app.slice";
import PageLoading from "../../elements/loading/PageLoading";
import { getErrorMessage } from "../../utils/functions";

function InicioContratacion(props: {
  setFinanbestPass: (pass: string) => void;
}) {
  const { setFinanbestPass } = props;
  const { email } = useSelector(selectContract);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [userId, setUserId] = React.useState<null | number>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Introduce tu contraseña")
      .min(
        8,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      )
      .matches(
        /^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u,
        "La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula"
      ),
    email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"),
    acceptPrivacy: yup
      .boolean()
      .oneOf(
        [true],
        "Tienes que aceptar el aviso legal y las condiciones de uso"
      ),
    acceptConditions: yup
      .boolean()
      .oneOf([true], "Tienes que aceptar la política de privacidad"),
  });

  const formik = useFormik({
    initialValues: {
      email,
      password: "",
      acceptPrivacy: false,
      acceptConditions: false,
    },
    validationSchema,
    onSubmit: (values, action) => {
      action.setSubmitting(true);
      // Gives error if mail exists
      postCheckFinanbestMailExistenceService(values.email)
        .then((checkEmailRes) => {
          if (
            checkEmailRes.attributes.action === "LOGIN" &&
            values.email === checkEmailRes.attributes.email
          ) {
            postLoginFinanbestService(
              userId ?? 0,
              values.email,
              values.password
            )
              .then(() => {
                dispatch(setFinanbestEmail(values.email));
                router("/contratacion/cumplimiento-normativo");
                action.setSubmitting(false);
              })
              .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                  addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                  })
                );
                action.setSubmitting(false);
              });
          } else if (checkEmailRes.attributes.action === "LOGIN") {
            dispatch(
              addAlert({
                message: `Debes introducir el siguiente email: ${checkEmailRes.attributes.email}`,
                isError: true,
                isOpen: true,
              })
            );
            action.setSubmitting(false);
          } else {
            setFinanbestPass(values.password);
            dispatch(setFinanbestEmail(values.email));
            router("/contratacion/informacion-usuario");
            action.setSubmitting(false);
          }
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          action.setSubmitting(false);
        });
    },
  });

  const handleCheckboxChange = (e: any) => {
    const name = e.target.attributes.name.value;
    // @ts-ignore
    const value = !formik.values[name];
    formik
      // @ts-ignore
      .setFieldValue(name, value, false)
      // @ts-ignore
      .then(() => formik.validateField(name));
  };
  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("input-checkbox-change", handleCheckboxChange);
    return () => {
      // @ts-ignore
      window.removeEventListener("input-checkbox-change", handleCheckboxChange);
    };
  }, [handleCheckboxChange]);

  React.useEffect(() => {
    setLoading(true);
    const storage = globalThis?.sessionStorage;
    const token = storage.getItem("token");
    if (!token) {
      router("/login");
    } else {
      getUserDataService(token)
        .then((res) => {
          setUserId(res.attributes.user_id);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          if (e?.response?.data?.message) {
            dispatch(
              addAlert({
                message: e.response.data.message,
                isError: true,
                isOpen: true,
              })
            );
          }
        });
    }
  }, []);
  return (
    <div className={styles.container}>
      <PageLoading open={loading || formik.isSubmitting} />
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ display: "flex" }}>
          <inv-text-xxxl
            style={
              {
                "--inv-text-xxxl-gradient": color.gradient.blue,
                "--inv-text-xxxl-font-weight": 700,
                "--inv-text-xxxl-font-size": "2em",
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Registra tu cuenta con Finanbest
          </inv-text-xxxl>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "1.7em" }}>
        <inv-grid-col
          class="col-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-xl
            style={
              {
                textAlign: "center",
                "--inv-text-xl-color": color.greyscale.black[50],
                "--inv-text-xl-font-weight": 300,
              } as React.CSSProperties
            }
          >
            Para contratar la cartera de inversión que te proponemos y podamos
            hacer un seguimiento de tu plan desde{" "}
            {` ${process.env.REACT_APP_SITE_TITLE ?? "la entidad"} `}, debes
            darte de alta en Finanbest.
          </inv-text-xl>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          justifyContent: "center",
          marginTop: "3.1em",
          alignItems: "flex-end",
        }}
      >
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "10em" }}
                alt="perfil-euro-flecha"
                src="/assets/images/logos/finanbest.png"
              />
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "1.6em" }}>
        <inv-grid-col class="col-8">
          <inv-text-l
            style={
              {
                "--inv-text-l-font-weight": 600,
              } as React.CSSProperties
            }
          >
            Alta de usuario
          </inv-text-l>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ "--inv-gutter-x": 0 }}>
          <inv-grid-row
            style={{
              justifyContent: "space-between",
              marginTop: "1.6em",
              "--inv-gutter-x": "1.5em",
            }}
          >
            <inv-grid-col class="col-5">
              <TextInput
                value={formik.values.email ?? ""}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="email"
                label="Correo electrónico"
                // @ts-ignore
                error={formik.errors.email}
                placeholder="luis@gmail.com"
              />
            </inv-grid-col>
            <inv-grid-col class="col-5">
              <TextInput
                isPassword
                value={formik.values.password ?? ""}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="password"
                label="Contraseña"
                // @ts-ignore
                error={formik.errors.password}
                placeholder="*******"
              />
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          justifyContent: "center",
          marginTop: "1.6em",
          "--inv-gutter-x": "0.6em",
        }}
      >
        <inv-grid-col class="col-8">
          <inv-grid-row style={{ alignItems: "center" }}>
            <inv-grid-col class="col-auto">
              <inv-input-checkbox
                name="acceptConditions"
                value={!formik.values.acceptConditions}
              />
            </inv-grid-col>
            <inv-grid-col class="col-auto">
              <inv-text-s style={{ "--inv-text-s-font-weight": 300 }}>
                He leído y acepto el del&nbsp;
              </inv-text-s>
              <a
                style={{
                  color: "#0D469D",
                  textDecoration: "underline",
                  fontSize: "0.8em",
                }}
                href="/"
              >
                aviso legal y las condiciones
              </a>
              <inv-text-s style={{ "--inv-text-s-font-weight": 300 }}>
                &nbsp;de uso del servicio.
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row>
            <inv-grid-col class="col-auto">
              <inv-form-label
                style={{ "--inv-form-label-color": color.validation.error }}
              >
                {formik.errors.acceptConditions}
              </inv-form-label>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          justifyContent: "center",
          marginTop: "0.6em",
          "--inv-gutter-x": "0.6em",
        }}
      >
        <inv-grid-col class="col-8">
          <inv-grid-row style={{ alignItems: "center" }}>
            <inv-grid-col class="col-auto">
              <inv-input-checkbox
                name="acceptPrivacy"
                value={!formik.values.acceptPrivacy}
              />
            </inv-grid-col>
            <inv-grid-col class="col-auto">
              <inv-text-s style={{ "--inv-text-s-font-weight": 300 }}>
                He leído y acepto la&nbsp;
              </inv-text-s>
              <a
                style={{
                  color: "#0D469D",
                  textDecoration: "underline",
                  fontSize: "0.8em",
                }}
                href="/"
              >
                política de privacidad
              </a>
              <inv-text-s style={{ "--inv-text-s-font-weight": 300 }}>
                &nbsp;y consiento al tratamiento de mis datos personales.
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row>
            <inv-grid-col class="col-auto">
              <inv-form-label
                style={{ "--inv-form-label-color": color.validation.error }}
              >
                {formik.errors.acceptPrivacy}
              </inv-form-label>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "3.2em" }}>
        <inv-grid-col
          class="col-8"
          style={{
            background: color.turquoise[20],
            borderRadius: "0.8em",
            "--inv-gutter-x": 0,
            padding: "1.1em 0",
          }}
        >
          <inv-grid-row
            style={{ justifyContent: "space-around", alignItems: "center" }}
          >
            <inv-grid-col
              class="col-auto"
              style={{ "--inv-gutter-x": "1.5em" }}
            >
              <inv-icon
                name="info"
                width="26"
                height="26"
                color={color.turquoise[100]}
              />
            </inv-grid-col>
            <inv-grid-col class="col-10" style={{ "--inv-gutter-x": "1.5em" }}>
              <inv-grid-row>
                <inv-grid-col class="col-auto" style={{ "--inv-gutter-x": 0 }}>
                  <inv-text-m
                    style={{ "--inv-text-m-color": color.greyscale.black[50] }}
                  >
                    En los 3 próximos pasos te pediremos la información
                    necesaria para abrir tu ficha de cliente y así poder
                    contratar los productos y servicios necesarios:
                  </inv-text-m>
                </inv-grid-col>
                <inv-grid-col
                  class="col-auto"
                  style={{ "--inv-gutter-x": "1.5em", marginTop: "1em" }}
                >
                  <ul>
                    <li style={{ color: color.greyscale.black[50] }}>
                      <inv-text-m
                        style={{
                          "--inv-text-m-color": color.greyscale.black[50],
                        }}
                      >
                        Contrato de Gestión Discrecional de Carteras
                      </inv-text-m>
                    </li>
                    <li style={{ color: color.greyscale.black[50] }}>
                      <inv-text-m
                        style={{
                          "--inv-text-m-color": color.greyscale.black[50],
                        }}
                      >
                        Apertura de una cuenta corriente asociada
                      </inv-text-m>
                    </li>
                    <li style={{ color: color.greyscale.black[50] }}>
                      <inv-text-m
                        style={{
                          "--inv-text-m-color": color.greyscale.black[50],
                        }}
                      >
                        Liquidación, custodia y administración de productos
                        financieros
                      </inv-text-m>
                    </li>
                  </ul>
                </inv-grid-col>
              </inv-grid-row>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>

      <inv-grid-row style={{ marginTop: "4em" }}>
        <inv-grid-col class="col-12">
          <div style={{ width: "calc(100% + 6em", marginLeft: "-3.7em" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{ justifyContent: "space-between", padding: "1.2em 0 0" }}
      >
        <inv-grid-col class="col-auto">
          <inv-button
            onClick={() => router("/propuesta")}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Volver al plan
          </inv-button>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-button onClick={() => formik.submitForm()}>Registrar</inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

InicioContratacion.title = "Registro finanbest";
InicioContratacion.layout = "EmptyLayout";
InicioContratacion.mobileLayout = "MobileEmptyLayout";
export default InicioContratacion;
