import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import PageLoading from "../../elements/loading/PageLoading";
import { goalColorByType } from "../../utils/componentData";
import BarsChart from "../../components/chart/BarsChart";
import { addAlert, selectApp } from "../../modules/app/app.slice";
import SolidDividers from "../../elements/dividers/SolidDividers";
import WithAndWithoutInveertCards from "../../elements/cards/WithAndWithoutInveertCards";
import styles from "../../styles/Resultado.module.css";
import {
  getGlobalConfigWithoutInveert,
  getInputChartSaving,
  getPlan,
  getPlanWithGlobalConfig,
} from "../../modules/propuesta/propuesta.service";
import { getObjectives } from "../../modules/goal/goal.service";
import { goalsApiResponse } from "../../modules/goal/goal.data";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xxs";
import { getErrorMessage } from "../../utils/functions";
import { Size, useWindowSize } from "../../utils/hooks";

function Resultado() {
  const dispatch = useDispatch();
  const { reloadApiFlag } = useSelector(selectApp);
  const router = useNavigate();
  const [planGlobalInfo, setPlanGlobalInfo] = React.useState({
    goal_cost_with_taxation: 0,
    initial_investment: 0,
    monthly_saving: 0,
    performance: 0,
    remaining_saving: 0,
    saving: 0,
    monthly_saving_without_inveert: 0,
    goal_cost: 0,
  });
  const [inputChartData, setInputChartData] = React.useState<{
    inputData: any;
    barsColor: any;
    barsId: any;
  }>({
    inputData: [{ name: "2022", bar1: 0, bar2: 0, bar3: 0, bar4: 0 }],
    barsColor: {
      bar1: undefined,
      bar2: undefined,
      bar3: undefined,
      bar4: undefined,
    },
    barsId: {
      bar1: undefined,
      bar2: undefined,
      bar3: undefined,
      bar4: undefined,
    },
  });
  const size: Size = useWindowSize();
  const [loading, setLoading] = React.useState(true);
  const [objectives, setObjectives] = React.useState<Array<any>>(
    goalsApiResponse.data
  );
  // @ts-ignore
  const [secondChartLabelData, setSecondChartLabelData] = React.useState([]);
  // @ts-ignore
  const [secondChartTotalLabel, setSecondChartTotalLabel] = React.useState("1");
  React.useEffect(() => {
    setLoading(true);
    const objectivesRes = getObjectives();
    const inputChartRes = getPlan().then((res) =>
      getInputChartSaving(res[0].id)
    );
    const withoutInveertRes = getPlan().then((res) =>
      getGlobalConfigWithoutInveert(res[0].id)
    );
    const planWithGlobalInfoRes = getPlanWithGlobalConfig();
    Promise.all([
      planWithGlobalInfoRes,
      withoutInveertRes,
      inputChartRes,
      objectivesRes,
    ])
      .then((res) => {
        // TODO change objectives value when working api
        // const objectives=res[3]
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const objectives = res[3];
        setPlanGlobalInfo({
          ...res[0].included[0].attributes,
          monthly_saving_without_inveert: res[1].attributes.monthly_savings,
        });
        setInputChartData(res[2]);
        const { barsId } = res[2];
        const orderedObjectives = Object.keys(res[2].barsId)
          .map((key: any) =>
            objectives.filter(
              // @ts-ignore
              (objective) => objective.id.toString() === barsId[key]
            )
          )
          .filter((objective) => objective.length > 0)
          .map((objective) => objective[0]);
        // @ts-ignore
        setSecondChartLabelData([
          // @ts-ignore
          res[2].inputData[0].bar1 ?? 0,
          // @ts-ignore
          res[2].inputData[0].bar2 ?? 0,
          // @ts-ignore
          res[2].inputData[0].bar3 ?? 0,
          // @ts-ignore
          res[2].inputData[0].bar4 ?? 0,
        ]);
        setObjectives(orderedObjectives);
        setSecondChartTotalLabel(res[2].inputData[0].name);
        setLoading(false);
      })
      .catch(async (e) => {
        const message = await getErrorMessage(e);
        dispatch(
          addAlert({
            message,
            isError: true,
            isOpen: true,
          })
        );
        setLoading(false);
      });
  }, [reloadApiFlag]);
  const nextStepRoute = `/${
    process.env.REACT_APP_AFTER_ONBOARDING_REDIRECTION ?? "registro"
  }`;
  return (
    <>
      <PageLoading open={loading} />
      <div className={styles.container}>
        <inv-grid-row style={{ justifyContent: "center" }}>
          <inv-grid-col class="col-8" style={{ display: "flex" }}>
            <inv-text-l
              style={
                {
                  "--inv-text-l-gradient": color.gradient.blue,
                  "--inv-text-l-font-weight": 600,
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              Resumen de tu plan
            </inv-text-l>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          class={styles.subtitle1}
          style={{ justifyContent: "center" }}
        >
          <inv-grid-col class="col-10 col-sm-8" style={{ display: "flex" }}>
            <inv-text-xs
              style={
                {
                  textAlign: "center",
                  "--inv-text-xs-color": color.greyscale.black[50],
                } as React.CSSProperties
              }
            >
              Rentabilizando tus ahorros necesitarás menos dinero. ¡Mira que
              diferencia!
            </inv-text-xs>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center" }}>
          <inv-grid-col class="col-10 col-sm-8" style={{ display: "flex" }}>
            <inv-text-xs
              style={
                {
                  textAlign: "center",
                  "--inv-text-xs-color": color.greyscale.black[50],
                } as React.CSSProperties
              }
            >
              Regístrate para acceder al plan completo y modificarlo siempre que
              quieras.
            </inv-text-xs>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          class={styles.withAndWithoutInveertContainer}
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <inv-grid-col class="col-sm-4 col-12" style={{ "--inv-gutter-y": 0 }}>
            <WithAndWithoutInveertCards
              isSelected={false}
              title="Sólo ahorrando"
              inputMoney={planGlobalInfo.goal_cost}
              performanceMoney={0}
              monthlySaving={planGlobalInfo.monthly_saving_without_inveert}
            />
          </inv-grid-col>
          <inv-grid-col class={`col-12 col-sm-1 ${styles.vsCol}`}>
            <div className={styles.vsContainer}>
              <inv-text-l
                style={
                  {
                    "--inv-text-font-weight": 500,
                    "--inv-text-l-color": color.greyscale.white[100],
                    textAlign: "center",
                  } as React.CSSProperties
                }
              >
                vs
              </inv-text-l>
            </div>
          </inv-grid-col>
          <inv-grid-col class="col-sm-4 col-12" style={{ "--inv-gutter-y": 0 }}>
            <WithAndWithoutInveertCards
              isSelected
              title="Invirtiendo tus ahorros"
              inputMoney={planGlobalInfo.saving}
              performanceMoney={planGlobalInfo.performance}
              monthlySaving={planGlobalInfo.monthly_saving}
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          class={styles.title2}
          style={{ justifyContent: "center" }}
        >
          <inv-grid-col class="col-auto col-sm-5" style={{ display: "flex" }}>
            <inv-text-m
              style={
                {
                  "--inv-text-m-font-weight": 700,
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              Aportarías mensualmente
            </inv-text-m>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "0.5em" }}>
          <inv-grid-col class="col-auto col-sm-5" style={{ display: "flex" }}>
            <inv-text-s
              style={
                {
                  "--inv-text-s-color": color.greyscale.black[50],
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              {" "}
              Sólo
              <b>
                <NumberFormat
                  value={
                    (planGlobalInfo.saving /
                      planGlobalInfo.goal_cost_with_taxation) *
                    100
                  }
                  thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                  decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                  decimalScale={0}
                  allowNegative={false}
                  displayType="text"
                  suffix="% "
                  prefix=" aportarías el "
                />
              </b>
              del coste de las metas.
            </inv-text-s>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center" }}>
          <inv-grid-col
            className="col- auto col-sm-5"
            style={{ display: "flex" }}
          >
            <inv-text-s
              style={
                {
                  "--inv-text-s-color": color.greyscale.black[50],
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              El resto se obtendría por rentabilidad.
            </inv-text-s>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          class={styles.chartInfoContainer}
          style={{
            justifyContent: "center",
          }}
        >
          <inv-grid-col
            class={`col-11 col-sm-10 ${styles.chartInfoBorderedContainer}`}
            style={{
              borderColor: color.greyscale.black[10],
            }}
          >
            <inv-grid-row style={{ alignItems: "center" }}>
              <inv-grid-col
                class="col-auto col-sm-2"
                style={{ alignSelf: "flex-start" }}
              >
                <inv-grid-row>
                  <inv-grid-col class="col-auto">
                    <inv-text-xxs
                      style={{
                        "--inv-text-xxs-color": color.greyscale.black[50],
                      }}
                    >
                      {(size?.width ?? 0) < 576
                        ? "Aport. mensual"
                        : "Aportación mensual"}
                    </inv-text-xxs>
                  </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row>
                  <inv-grid-col>
                    <inv-text-l
                      style={{
                        "--inv-text-l-font-weight": 600,
                      }}
                    >
                      <NumberFormat
                        value={secondChartLabelData.reduce(
                          (acc: any, data: any) => data + acc,
                          0
                        )}
                        thousandSeparator={
                          process.env.REACT_APP_THOUSAND_SEPARATOR
                        }
                        decimalSeparator={
                          process.env.REACT_APP_DECIMAL_SEPARATOR
                        }
                        decimalScale={0}
                        allowNegative={false}
                        displayType="text"
                      />
                    </inv-text-l>
                    <inv-text-xs
                      style={{
                        "--inv-text-xs-font-weight": 700,
                      }}
                    >
                      &nbsp;€
                    </inv-text-xs>
                  </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row style={{ lineHeight: 0 }}>
                  <inv-grid-col>
                    <inv-text-xxs
                      style={{
                        "--inv-text-xxs-font-weight": 500,
                      }}
                    >
                      {`Año ${secondChartTotalLabel}`}
                    </inv-text-xxs>
                  </inv-grid-col>
                </inv-grid-row>
              </inv-grid-col>
              <inv-grid-col class="col-auto">
                <div className={styles.chartInfoDivider}>
                  <SolidDividers orientation="vertical" />
                </div>
              </inv-grid-col>
              <inv-grid-col
                class="col-6"
                style={{ display: "flex", alignSelf: "flex-start" }}
              >
                <inv-grid-row>
                  {Object.keys(inputChartData.barsId).map(
                    (key: string, index: React.Key | null | undefined) => {
                      const goalId = inputChartData?.barsId[key];
                      const currentObjective = objectives.find(
                        (objective: { id: string }) => objective.id === goalId
                      );
                      // @ts-ignore
                      // @ts-ignore
                      const element = currentObjective && (
                        <inv-grid-col
                          key={currentObjective.id}
                          class="col-auto"
                        >
                          <inv-grid-row>
                            <inv-grid-col class="col-auto">
                              <inv-text-xxs
                                style={{
                                  "--inv-text-xxs-color":
                                    color.greyscale.black[50],
                                }}
                              >
                                {currentObjective.attributes.name}
                              </inv-text-xxs>
                            </inv-grid-col>
                          </inv-grid-row>
                          <inv-grid-row style={{ lineHeight: 0 }}>
                            <inv-grid-col>
                              <inv-text-s
                                style={{
                                  "--inv-text-s-font-weight": 600,
                                  "--inv-text-s-color":
                                    // @ts-ignore
                                    goalColorByType[
                                      currentObjective.attributes.type
                                    ],
                                }}
                              >
                                {
                                  // @ts-ignore
                                  secondChartLabelData[index] !== 0 ? (
                                    <NumberFormat
                                      // @ts-ignore
                                      value={secondChartLabelData[index]}
                                      thousandSeparator={
                                        process.env.REACT_APP_THOUSAND_SEPARATOR
                                      }
                                      decimalSeparator={
                                        process.env.REACT_APP_DECIMAL_SEPARATOR
                                      }
                                      decimalScale={0}
                                      allowNegative={false}
                                      displayType="text"
                                    />
                                  ) : (
                                    "—"
                                  )
                                }
                              </inv-text-s>
                              {
                                // @ts-ignore
                                secondChartLabelData[index] !== 0 && (
                                  <inv-text-xs
                                    style={{
                                      "--inv-text-xs-font-weight": 700,
                                      // @ts-ignore
                                      "--inv-text-xs-color":
                                        // @ts-ignore
                                        goalColorByType[
                                          currentObjective.attributes.type
                                        ],
                                    }}
                                  >
                                    &nbsp;€/mes
                                  </inv-text-xs>
                                )
                              }
                            </inv-grid-col>
                          </inv-grid-row>
                        </inv-grid-col>
                      );
                      return element;
                    }
                  )}
                </inv-grid-row>
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          class={styles.chart}
          style={{ "--inv-gutter-x": 0, justifyContent: "center" }}
        >
          <inv-grid-col class="col-12 col-sm-10">
            <BarsChart
              name="monthly"
              // @ts-ignore
              barColors={inputChartData.barsColor}
              data={inputChartData.inputData}
              barsHandler={setSecondChartLabelData}
              hideXAxisTicks
              helperHandler={setSecondChartTotalLabel}
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.divider}>
          <inv-grid-col class="col-12">
            <div style={{ width: "calc(100% + 2.4em)", marginLeft: "-1.2em" }}>
              <SolidDividers orientation="horizontal" />
            </div>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.buttonsContainer}>
          <inv-grid-col class="col-auto">
            <inv-button onClick={() => router(nextStepRoute)}>
              Ver mi plan completo
            </inv-button>
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </>
  );
}

Resultado.title = "Resultado de tu plan";
Resultado.layout = "EmptyLayout";
Resultado.mobileLayout = "MobileEmptyLayout";
export default Resultado;
