import Grid from "@mui/material/Grid";
import * as React from "react";
import * as yup from "yup";
import { differenceInYears, format, parseISO, sub } from "date-fns";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { SwipeableDrawer, useMediaQuery } from "@mui/material";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import {
  selectUser,
  setRiskProfile,
  setUser,
} from "../../modules/user/user.slice";
import SolidDividers from "../../elements/dividers/SolidDividers";
import DotStepsFragment from "../../elements/fragments/DotStepsFragment";
import {
  profileFormQuestionMainTitle,
  profileFormQuestionTitle,
  profileQuestionNameOrder,
} from "../../utils/componentData";
import { RiskProfileForm } from "../../modules/risk/risk-form.component";
import {
  getAllRiskProfilesApi,
  getFinancialProviderId,
  getQuestionsByMifidId,
  getRiskProfile,
  getTestMifidId,
  postQuestion,
} from "../../modules/risk/risk.service";
// eslint-disable-next-line import/no-cycle
import { RiskProfileDialogContent } from "../../modules/risk/risk-dialog.component";
import ContainerDialog from "../../elements/dialog/ContainerDialog";
import PageLoading from "../../elements/loading/PageLoading";
import WCIcons from "../../elements/icons/WCIcons";
import { getObjectives } from "../../modules/goal/goal.service";
import { setIsAuthDialogOpen } from "../../modules/auth/auth.slice";
import { addAlert } from "../../modules/app/app.slice";
import { setUserDataLayer } from "../../modules/gtm/gtm.script";
import { selectPlan } from "../../modules/propuesta/propuesta.slice";
import { RiskQuestion } from "../../modules/risk/risk.type";
import {
  questionsOfTestMifid,
  riskProfileQuestionsByQuestionIndex,
} from "../../modules/risk/risk.data";
import { getErrorMessage } from "../../utils/functions";
import perfil from "../../styles/Perfil.module.css";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-m";
import goalFooterMeta from "../../styles/GoalFooterMeta.module.css";
import {
  getUserIdApi,
  patchaUserNamePcAndBirhday,
  patchExistingUserNamePcAndBirthday,
} from "../../modules/user/user.service";

function Perfil(props: any) {
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const { initialInvestment, pensionPlan } = useSelector(selectPlan);
  const { finish, hideShowTest, goBack, closeDialog } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);

  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector(selectUser);
  const [loading, setLoading] = React.useState(true);
  const [testId, setTestId] = React.useState("1");
  const [objectives, setObjectives] = React.useState([]);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [apiQuestionIndex, setApiQuestionIndex] = React.useState(0);
  const [questions, setQuestions] = React.useState<Array<RiskQuestion>>(
    questionsOfTestMifid.data
  );
  const [hideBirthdayQuestion, setHideBirthdayQuestion] = React.useState(true);
  const [riskProfileArray, setRiskProfileArray] = React.useState<Array<any>>(
    []
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const validationSchema = yup.object({
    stability: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    expenses: yup.number().required("Necesitamos saber tus gastos"),
    wealth: yup
      .number()
      .nullable()
      .required("Introduce un valor numérico")
      .min(0, "La cantidad debe ser positiva")
      .max(9999999999999.99, "La cantidad excede el máximo permitido"),
    // retirement_age is term on RETIREMENT
    studies: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    years_investing: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    experience: yup
      .number()
      .oneOf([1, 2])
      .required("Tienes que introducir una opción"),
    goal: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    risk: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    attitude: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    name: yup.string().max(20).required("Introduce tu nombre"),
    birthday: yup
      .date()
      .typeError("La fecha no es valida")
      .nullable()
      .min(sub(Date.now(), { years: 90 }), "Debes tener menos de 90")
      .max(sub(Date.now(), { years: 18 }), "Debes ser mayor de edad")
      .required("Fecha de nacimiento requerida"),
    zip_code: yup
      .string()
      .required("Introduce tu código postal")
      .matches(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/, "Código postal inválido"),
    monthly_income: yup
      .number()
      .required("Introduce un valor numérico")
      .nullable(),
    rent_income: yup.number().nullable(),
    other_income: yup.number().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      stability: null,
      expenses: 75,
      wealth: null,
      studies: null,
      years_investing: null,
      experience: null,
      goal: null,
      risk: null,
      attitude: null,
      name: user.name,
      birthday: user.birthday === "" ? null : user.birthday,
      zip_code: user.zipCode,
      monthly_income: null,
      rent_income: null,
      other_income: null,
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const allIncome =
        (values.monthly_income ?? 0) +
        (values.rent_income ?? 0) +
        (values.other_income ?? 0);
      const longerTimeHorizon = objectives.reduce(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (longerTimeHorizon: number, objective: any) => {
          if (
            (objective?.attributes.init_date
              ? differenceInYears(
                  parseISO(objective.attributes.init_date),
                  new Date()
                )
              : 0) > longerTimeHorizon
          ) {
            // eslint-disable-next-line no-param-reassign
            longerTimeHorizon = objective?.attributes.init_date
              ? differenceInYears(
                  parseISO(objective.attributes.init_date),
                  new Date()
                )
              : 0;
          }
          return longerTimeHorizon;
        },
        0
      );
      const annualGrossIncome = allIncome * 12;
      const birthdayFinal = parseISO(values.birthday);
      const userAge = birthdayFinal
        ? differenceInYears(new Date(), birthdayFinal)
        : 0;
      const totalInitialInvestment = pensionPlan + initialInvestment;
      const wealth = values.wealth === null ? 0 : values.wealth;
      const investment =
        (totalInitialInvestment /
          (values.wealth === null ? 0 : values.wealth)) *
        100;
      const answers = {
        stability: values.stability,
        studies: values.studies,
        years_investing: values.years_investing,
        experience: values.experience,
        goal: values.goal,
        risk: values.risk,
        attitude: values.attitude,
        // eslint-disable-next-line no-nested-ternary
        expenses: values.expenses < 25 ? 1 : values.expenses > 75 ? 3 : 2,
        age:
          // eslint-disable-next-line no-nested-ternary
          userAge < 35
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            userAge > 67
            ? 4
            : userAge >= 35 && userAge < 55
            ? 2
            : 3,
        income:
          // eslint-disable-next-line no-nested-ternary
          annualGrossIncome < 50000
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            annualGrossIncome > 600000
            ? 4
            : annualGrossIncome >= 50000 && annualGrossIncome <= 300000
            ? 2
            : 3,
        wealth:
          // eslint-disable-next-line no-nested-ternary
          wealth < 25000
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            wealth > 100000
            ? 4
            : wealth >= 25000 && wealth <= 50000
            ? 2
            : 3,
        investment:
          // eslint-disable-next-line no-nested-ternary
          investment < 25
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            investment > 75
            ? 4
            : investment >= 25 && investment <= 50
            ? 2
            : 3,
        horizon:
          // eslint-disable-next-line no-nested-ternary
          longerTimeHorizon < 3
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            longerTimeHorizon > 10
            ? 4
            : longerTimeHorizon >= 3 && longerTimeHorizon <= 5
            ? 2
            : 3,
      };
      const incomeQuestion =
        "¿Cuáles son aproximadamente tus ingresos brutos anuales?";
      const incomeQuestionId =
        questions.find(
          (question) => question.attributes.question === incomeQuestion
        )?.id ?? "0";
      const investmentQuestion =
        "¿Qué porcentaje de tu patrimonio representa la cantidad que quieres invertir en Finanbest?";
      const investmentQuestionId =
        questions.find(
          (question) => question.attributes.question === investmentQuestion
        )?.id ?? "0";
      const horizonQuestion = "¿A qué plazo está prevista tu inversión?";
      const horizonQuestionId =
        questions.find(
          (question) => question.attributes.question === horizonQuestion
        )?.id ?? "0";
      const ageQuestion = "¿Qué edad tienes?";
      const ageQuestionId =
        questions.find(
          (question) => question.attributes.question === ageQuestion
        )?.id ?? "0";
      const ageRes = postQuestion(parseInt(ageQuestionId, 10), answers.age);
      const horizonRes = postQuestion(
        parseInt(horizonQuestionId, 10),
        answers.horizon
      );
      const incomeRes = postQuestion(
        parseInt(incomeQuestionId, 10),
        answers.income
      );
      const investmentRes = postQuestion(
        parseInt(investmentQuestionId, 10),
        answers.investment
      );
      Promise.all([horizonRes, ageRes, incomeRes, investmentRes])
        .then(() => {
          getRiskProfile(testId)
            .then((response) => {
              setUserDataLayer(
                undefined,
                undefined,
                response.attributes.risk_profile_id,
                undefined
              );
              const data = {
                profile_id: response.attributes.risk_profile_id,
                max_profile_id: response.attributes.risk_profile_id,
                external_profile_id: response.id,
              };
              dispatch(setRiskProfile(data));
              if (finish) {
                finish();
                formik.setSubmitting(false);
                hideShowTest();
              } else {
                getAllRiskProfilesApi()
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  .then((response) => {
                    // @ts-ignore
                    setRiskProfileArray(response);
                    if (downMobile === true) {
                      setShowDrawer(true);
                    } else {
                      setIsOpen(true);
                    }
                    actions.setSubmitting(false);
                  })
                  .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                      addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                      })
                    );
                    actions.setSubmitting(false);
                  });
              }
            })
            .catch(async (e) => {
              const message = await getErrorMessage(e);
              dispatch(
                addAlert({
                  message,
                  isError: true,
                  isOpen: true,
                })
              );
              actions.setSubmitting(false);
            });
        })
        .catch(async (e) => {
          const message = await getErrorMessage(e);
          dispatch(
            addAlert({
              message,
              isError: true,
              isOpen: true,
            })
          );
          actions.setSubmitting(false);
        });
    },
  });
  const handleSubmitQuestion = (value?: any) => {
    setLoading(true);
    const values = value ?? formik.values;
    const { name } = riskProfileQuestionsByQuestionIndex[questionIndex];

    const allIncome =
      (values.monthly_income ?? 0) +
      (values.rent_income ?? 0) +
      (values.other_income ?? 0);
    const longerTimeHorizon = objectives.reduce(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (longerTimeHorizon: number, objective: any) => {
        if (
          (objective?.attributes.init_date
            ? differenceInYears(
                parseISO(objective.attributes.init_date),
                new Date()
              )
            : 0) > longerTimeHorizon
        ) {
          // eslint-disable-next-line no-param-reassign
          longerTimeHorizon = objective?.attributes.init_date
            ? differenceInYears(
                parseISO(objective.attributes.init_date),
                new Date()
              )
            : 0;
        }
        return longerTimeHorizon;
      },
      0
    );
    const annualGrossIncome = allIncome * 12;
    const birthdayFinal = parseISO(values.birthday);
    const userAge = birthdayFinal
      ? differenceInYears(new Date(), birthdayFinal)
      : 0;
    const totalInitialInvestment = pensionPlan + initialInvestment;
    const wealth = values.wealth === null ? 0 : values.wealth;
    const investment =
      (totalInitialInvestment / (values.wealth === null ? 0 : values.wealth)) *
      100;

    const answers = {
      stability: values.stability,
      studies: values.studies,
      years_investing: values.years_investing,
      experience: values.experience,
      goal: values.goal,
      risk: values.risk,
      attitude: values.attitude,
      // eslint-disable-next-line no-nested-ternary
      expenses: values.expenses < 25 ? 1 : values.expenses > 75 ? 3 : 2,
      age:
        // eslint-disable-next-line no-nested-ternary
        userAge < 35
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          userAge > 67
          ? 4
          : userAge >= 35 && userAge < 55
          ? 2
          : 3,
      income:
        // eslint-disable-next-line no-nested-ternary
        annualGrossIncome < 50000
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          annualGrossIncome > 600000
          ? 4
          : annualGrossIncome >= 50000 && annualGrossIncome <= 300000
          ? 2
          : 3,
      wealth:
        // eslint-disable-next-line no-nested-ternary
        wealth < 25000
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          wealth > 100000
          ? 4
          : wealth >= 25000 && wealth <= 50000
          ? 2
          : 3,
      investment:
        // eslint-disable-next-line no-nested-ternary
        investment < 25
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          investment > 75
          ? 4
          : investment >= 25 && investment <= 50
          ? 2
          : 3,
      horizon:
        // eslint-disable-next-line no-nested-ternary
        longerTimeHorizon < 3
          ? 1
          : // eslint-disable-next-line no-nested-ternary
          longerTimeHorizon > 10
          ? 4
          : longerTimeHorizon >= 3 && longerTimeHorizon <= 5
          ? 2
          : 3,
    };
    // @ts-ignore
    postQuestion(
      parseInt(questions[apiQuestionIndex]?.id ?? 0, 10),
      // @ts-ignore
      answers[name]
    )
      .then(() => {
        if (questionIndex < riskProfileQuestionsByQuestionIndex.length - 1) {
          setQuestionIndex(questionIndex + 1);
        }
        setLoading(false);
      })
      .catch(async (e) => {
        const message = await getErrorMessage(e);
        dispatch(
          addAlert({
            message,
            isError: true,
            isOpen: true,
          })
        );
        setLoading(false);
      });
  };
  React.useEffect(() => {
    setLoading(true);
    setHideBirthdayQuestion(user.birthday !== "");
    getObjectives()
      .then((resObjectives) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        setObjectives(resObjectives);
        dispatch(setIsAuthDialogOpen(false));
        setLoading(false);
      })
      .catch(async (e) => {
        const message = await getErrorMessage(e);
        dispatch(
          addAlert({
            message,
            isError: true,
            isOpen: true,
          })
        );
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    getFinancialProviderId().then((res) =>
      getTestMifidId(res).then((mifidId) => {
        setTestId(mifidId);
        getQuestionsByMifidId(mifidId).then((response) => {
          setQuestions(
            response.length > 0 ? response : questionsOfTestMifid.data
          );
        });
      })
    );
  }, []);
  React.useEffect(() => {
    const currentQuestion =
      riskProfileQuestionsByQuestionIndex[questionIndex]?.question;
    const currentApiQuestionIndex = questions.findIndex(
      (question) => question.attributes.question === currentQuestion
    );
    if (currentApiQuestionIndex > 0) {
      setApiQuestionIndex(currentApiQuestionIndex);
    } else if (questionIndex < 2) {
      setApiQuestionIndex(questionIndex);
    }
  }, [questionIndex]);
  const handleNextClick = () => {
    if (questionIndex === 0) {
      formik.validateForm(formik.values).then((err: any) => {
        if (
          err?.birthday === undefined &&
          err?.name === undefined &&
          err?.zip_code === undefined
        ) {
          formik.setSubmitting(true);
          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow
          const { birthday, name, zip_code } = formik.values;

          const userAge = differenceInYears(
            new Date(),
            birthday ? parseISO(birthday) : 0
          );
          setUserDataLayer(undefined, userAge, undefined, undefined);

          const finalBirthday = birthday
            ? format(parseISO(birthday), "yyyy-MM-dd")
            : "";
          dispatch(
            setUser({
              name,
              zipCode: zip_code,
              birthday: finalBirthday,
            })
          );
          const storage = globalThis?.sessionStorage;
          if (storage) {
            const token = storage.getItem("token");
            getUserIdApi(token ?? "").then((res) => {
              if (
                !res?.attributes?.user_id ||
                res?.attributes?.user_id === ""
              ) {
                patchaUserNamePcAndBirhday(
                  token ?? "",
                  finalBirthday,
                  zip_code,
                  name
                )
                  .then(() => {
                    setQuestionIndex(questionIndex + 1);
                    formik.setFieldError("monthly_income", undefined);
                    formik.setSubmitting(false);
                  })
                  .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                      addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                      })
                    );
                    formik.setSubmitting(false);
                  });
              } else {
                patchExistingUserNamePcAndBirthday(
                  res.attributes.user_id
                    ? res.attributes.user_id.toString()
                    : "",
                  finalBirthday,
                  zip_code,
                  name
                )
                  .then(() => {
                    setQuestionIndex(questionIndex + 1);
                    formik.setFieldError("monthly_income", undefined);
                    formik.setSubmitting(false);
                  })
                  .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                      addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                      })
                    );
                    formik.setSubmitting(false);
                  });
              }
            });
          }
        }
      });
    } else if (questionIndex === 1) {
      formik.validateForm(formik.values).then((err: any) => {
        if (
          err?.monthly_income === undefined &&
          err?.rent_income === undefined &&
          err?.other_income === undefined
        ) {
          formik.setSubmitting(true);
          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow
          const { monthly_income, rent_income, other_income } = formik.values;
          const totalIncome =
            (monthly_income ?? 0) + (rent_income ?? 0) + (other_income ?? 0);
          setUserDataLayer(undefined, undefined, undefined, totalIncome);
          setQuestionIndex(questionIndex + 1);
          formik.setFieldError("wealth", undefined);
          formik.setSubmitting(false);
        }
      });
    } else {
      formik
        .setFieldTouched(profileQuestionNameOrder[questionIndex], true, true)
        .then((err) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            !err?.hasOwnProperty(profileQuestionNameOrder[questionIndex])
          ) {
            formik.setErrors({});
            if (questionIndex === 10) {
              handleSubmitQuestion();
              formik.handleSubmit();
            } else {
              handleSubmitQuestion();
            }
          }
        });
    }
  };
  return (
    <div className={perfil.container}>
      {closeDialog && !downMobile && (
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{ cursor: "pointer", height: downXl ? "30" : "40" }}
            onClick={closeDialog}
          >
            <WCIcons
              name="close-filled"
              height={downXl ? "30" : "40"}
              width={downXl ? "30" : "40"}
            />
          </Grid>
        </Grid>
      )}
      <PageLoading open={formik.isSubmitting || loading} />
      {downMobile ? (
        <SwipeableDrawer
          sx={{
            ".MuiDrawer-paper": {
              // 31 px are the half of the 62px height of mobile header
              maxHeight: "calc(100vh - 101px)",
              borderRadius: "1.2em 1.2em 0px 0px",
            },
          }}
          anchor="bottom"
          disableSwipeToOpen
          open={showDrawer}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          onClose={() => setShowDrawer(true)}
          onOpen={() => setShowDrawer(true)}
        >
          <RiskProfileDialogContent
            profileNumberMax={user?.riskProfile?.profile_id}
            profileNumber={user?.riskProfile?.profile_id}
            riskProfileArray={riskProfileArray}
          />
        </SwipeableDrawer>
      ) : (
        <ContainerDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          fullWidth
          maxWidth={downXl ? "sm" : "md"}
          scroll="body"
        >
          <RiskProfileDialogContent
            profileNumberMax={user?.riskProfile?.profile_id}
            profileNumber={user?.riskProfile?.profile_id}
            riskProfileArray={riskProfileArray}
          />
        </ContainerDialog>
      )}
      <inv-grid-row>
        <inv-grid-col
          class="col"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <inv-text-l
            style={{
              "--inv-text-l-color": color.gradient.blue,
              "--inv-text-l-font-weight": 600,
            }}
          >
            {profileFormQuestionMainTitle[questionIndex]}
          </inv-text-l>
        </inv-grid-col>
      </inv-grid-row>
      {
        profileFormQuestionTitle(
          (formik.values.monthly_income ?? 0) +
            (formik.values.rent_income ?? 0) +
            (formik.values.other_income ?? 0)
        )[questionIndex]
      }
      <inv-grid-row style={{ display: "flex", justifyContent: "center" }}>
        <inv-grid-col class="col">
          <RiskProfileForm
            monthly_income={
              (formik.values.monthly_income ?? 0) +
              (formik.values.rent_income ?? 0) +
              (formik.values.other_income ?? 0)
            }
            formik={formik}
            questionIndex={questionIndex}
            hideBirthdayQuestion={hideBirthdayQuestion}
            handleSubmitQuestion={handleSubmitQuestion}
          />
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={`${perfil.divider}`}>
        <inv-grid-col class="col-12">
          <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x))" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1.2em 0 0",
          "--inv-gutter-x": 0,
        }}
      >
        <inv-grid-col
          class={`${goalFooterMeta.buttonElement1} col-12 col-sm-4`}
        >
          <inv-button
            onClick={() => {
              if (questionIndex === 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                goBack ? goBack() : router("/perfil/inicio");
              } else {
                setQuestionIndex(questionIndex - 1);
              }
            }}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Atrás
          </inv-button>
        </inv-grid-col>
        <inv-grid-col
          class={`col-auto ${goalFooterMeta.buttonElement2}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <DotStepsFragment steps={11} activeStep={questionIndex} />
        </inv-grid-col>
        <inv-grid-col
          class={`${goalFooterMeta.buttonElement3} col-12 col-sm-4`}
        >
          <inv-button onClick={handleNextClick}>Continuar</inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}
Perfil.title = "Test de perfil de riesgo";
export default Perfil;
