import * as React from "react";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import withAndWithoutInveertCards from "../../styles/WithAndWithoutInveertCards.module.css";

interface IWithAndWithoutInveertCards {
  inputMoney: number;
  performanceMoney: number;
  monthlySaving: number;
  title: string;
  isSelected?: boolean;
}

function WithAndWithoutInveertCards(props: IWithAndWithoutInveertCards) {
  const { inputMoney, performanceMoney, title, isSelected, monthlySaving } =
    props;
  return (
    <inv-grid-row class={withAndWithoutInveertCards.container}>
      <inv-grid-col class="col-12">
        <div
          style={{
            border: `0.15em solid ${
              isSelected ? color.blue[100] : color.greyscale.black[10]
            }`,
            borderRadius: "0.8em",
            background: isSelected
              ? color.turquoise[5]
              : color.greyscale.white[100],
            padding: "1.2em",
          }}
        >
          <inv-grid-row class={withAndWithoutInveertCards.title}>
            <inv-grid-col class="col-auto">
              <inv-text-s
                style={
                  {
                    "--inv-text-s-font-weight": 600,
                  } as React.CSSProperties
                }
              >
                {title}
              </inv-text-s>
            </inv-grid-col>
            <inv-grid-col class="col-auto" style={{ height: "1em" }}>
              <inv-icon
                name="info"
                width="1em"
                height="1em"
                color={color.greyscale.black[50]}
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row class={withAndWithoutInveertCards.firstSubtitle}>
            <inv-grid-col>
              <inv-text-xs
                style={{
                  "--inv-text-xs-color": color.greyscale.black[50],
                  "--inv-text-xs-font-weight": 500,
                }}
              >
                Aportando el primer año
              </inv-text-xs>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row>
            <inv-grid-col>
              <inv-text-s
                style={{
                  "--inv-text-s-font-weight": 600,
                }}
              >
                <NumberFormat
                  value={monthlySaving}
                  thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                  decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                  decimalScale={0}
                  allowNegative={false}
                  displayType="text"
                />
              </inv-text-s>
              <inv-text-s
                class={withAndWithoutInveertCards.bigQuantity}
                style={{
                  "--inv-text-s-font-weight": 600,
                }}
              >
                &nbsp;€/mes
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row class={withAndWithoutInveertCards.lastRow}>
            <inv-grid-col class="col-auto" style={{ marginRight: "0.8em" }}>
              <inv-grid-row>
                <inv-grid-col>
                  <inv-text-xs
                    style={{
                      "--inv-text-xs-color": color.greyscale.black[50],
                      "--inv-text-xs-font-weight": 500,
                    }}
                  >
                    Tú debes aportar
                  </inv-text-xs>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row>
                <inv-grid-col>
                  <inv-text-s
                    style={{
                      "--inv-text-s-font-weight": 600,
                      "--inv-text-s-color": color.greyscale.black[50],
                    }}
                  >
                    <NumberFormat
                      value={inputMoney}
                      thousandSeparator={
                        process.env.REACT_APP_THOUSAND_SEPARATOR
                      }
                      decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                      decimalScale={0}
                      allowNegative={false}
                      displayType="text"
                    />
                  </inv-text-s>
                  <inv-text-xs
                    class={withAndWithoutInveertCards.smallQuantity}
                    style={{
                      "--inv-text-xs-font-weight": 700,
                      "--inv-text-xs-color": color.greyscale.black[30],
                    }}
                  >
                    &nbsp;€
                  </inv-text-xs>
                </inv-grid-col>
              </inv-grid-row>
            </inv-grid-col>
            <inv-grid-col class="col-auto">
              <inv-grid-row>
                <inv-grid-col>
                  <inv-text-xs
                    style={{
                      "--inv-text-xs-color": color.greyscale.black[50],
                      "--inv-text-xs-font-weight": 500,
                    }}
                  >
                    Rentabilidad estimada
                  </inv-text-xs>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row>
                <inv-grid-col>
                  <inv-text-s
                    style={{
                      "--inv-text-s-font-weight": 600,
                      "--inv-text-s-color": color.turquoise[100],
                    }}
                  >
                    <NumberFormat
                      value={performanceMoney}
                      thousandSeparator={
                        process.env.REACT_APP_THOUSAND_SEPARATOR
                      }
                      decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                      decimalScale={0}
                      allowNegative={false}
                      displayType="text"
                    />
                  </inv-text-s>
                  <inv-text-xs
                    class={withAndWithoutInveertCards.smallQuantity}
                    style={{
                      "--inv-text-xs-font-weight": 700,
                      "--inv-text-xs-color": color.turquoise[100],
                    }}
                  >
                    &nbsp;€
                  </inv-text-xs>
                </inv-grid-col>
              </inv-grid-row>
            </inv-grid-col>
          </inv-grid-row>
        </div>
      </inv-grid-col>
    </inv-grid-row>
  );
}

export default WithAndWithoutInveertCards;

WithAndWithoutInveertCards.defaultProps = {
  isSelected: false,
};
