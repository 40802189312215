import * as React from "react";
import NumberFormat from "react-number-format";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xxs";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import styles from "./risk-dialog.style.module.css";
import theme from "../../styles/theme";
import { color } from "../../styles/color";
import CircleChart from "../../components/chart/CircleChart";
import { Capitalize } from "../../utils/functions";
import { NotInteractiveSlider } from "../../elements/slider/NotInteractiveDiscreteSlider";
// eslint-disable-next-line import/no-cycle
import Perfil from "../../pages/perfil";
import PageLoading from "../../elements/loading/PageLoading";
import { postChangeToInferiorRiskProfile } from "./risk.service";
import riskProfileDescription from "./risk.data";
import { reloadApi } from "../app/app.slice";

interface RiskDialogComponent {
  profileNumber: number;
  profileNumberMax: number;
  riskProfileArray: Array<any>;
  isTestAvailable?: boolean;
  closeDialog?: () => void;
  financialWealth?: any;
  birthday?: any;
  userIncome?: any;
  objectives?: any;
  isSliderActive?: boolean;
  userId?: number;
}

export function RiskProfileDialogContent(props: RiskDialogComponent) {
  const dispatch = useDispatch();
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const {
    riskProfileArray,
    profileNumber,
    isTestAvailable,
    closeDialog,
    financialWealth,
    birthday,
    userIncome,
    objectives,
    isSliderActive,
    profileNumberMax,
    userId,
  } = props;
  const [showTest, setShowTest] = React.useState(false);
  const [testIsPassed, setTestIsPassed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const router = useNavigate();
  const validationSchema = yup.object({
    profile: yup.number().min(1).max(6),
  });
  const formik = useFormik({
    initialValues: {
      profile: profileNumber,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      postChangeToInferiorRiskProfile(userId ?? 0, values.profile)
        .then(() => {
          dispatch(reloadApi());
          setLoading(false);
          formik.setSubmitting(false);
          if (closeDialog) {
            closeDialog();
          }
        })
        .catch(() => {
          setLoading(false);
          formik.setSubmitting(false);
        });
    },
  });
  React.useEffect(() => {
    formik.setFieldValue("profile", profileNumber);
  }, [profileNumber]);

  return (
    <>
      <PageLoading open={loading} />
      {isTestAvailable === true && showTest === false && !downMobile && (
        <inv-grid-row style={{ "--inv-gutter-x": 0 }}>
          <inv-grid-col
            class="col-12"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
            }}
          >
            <div
              role="button"
              onKeyDown={() => null}
              tabIndex={0}
              className={styles.closeIconContainer}
              onClick={closeDialog}
            >
              <inv-icon name="close-filled" width="1.5em" height="1.5em" />
            </div>
          </inv-grid-col>
        </inv-grid-row>
      )}
      <div className={styles.container}>
        <div>
          {/* Need to wrap containers on item to apply maxwidth */}
          {showTest === true ? (
            <div>
              <Perfil
                data={{
                  financialWealth,
                  monthly_income: userIncome,
                  birthday,
                  objectives,
                }}
                finish={() => dispatch(reloadApi())}
                hideShowTest={() => {
                  setShowTest(false);
                  setTestIsPassed(true);
                }}
                goBack={() => {
                  setShowTest(false);
                }}
                closeDialog={closeDialog}
              />
            </div>
          ) : (
            <div>
              <inv-grid-row
                class={styles.titleContainer}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <inv-grid-col class="col-12" style={{ display: "flex" }}>
                  <inv-text-l
                    style={{
                      "--inv-text-l-font-weight": 600,
                      "--inv-text-l-color": color.gradient.blue,
                    }}
                  >
                    {`Perfil inversor: ${Capitalize(
                      riskProfileArray[formik.values.profile - 1]?.attributes
                        .name ?? ""
                    )}`}
                  </inv-text-l>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row class={`${styles.descriptionAndChartContainer}`}>
                <inv-grid-col
                  class="col-12 col-sm-6"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <inv-text-s
                    style={{
                      "--inv-text-s-font-weight": 500,
                      "--inv-text-s-color": color.greyscale.black[50],
                      textAlign: "center",
                    }}
                  >
                    {riskProfileDescription[formik.values.profile - 1]}
                  </inv-text-s>
                </inv-grid-col>
                <inv-grid-col class="col-12 col-sm-3">
                  <inv-grid-row
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <inv-grid-col
                      class={`col-12 ${styles.chartContainer}`}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CircleChart
                        width={120}
                        height={120}
                        data={[
                          {
                            name: "fixed",
                            value:
                              100 -
                              (riskProfileArray[formik.values.profile - 1]
                                ?.attributes.max_equity_exposure ?? 0) *
                                100,
                            color: color.blue[100],
                          },
                          {
                            name: "equity",
                            value:
                              (riskProfileArray[formik.values.profile - 1]
                                ?.attributes.max_equity_exposure ?? 0) * 100,
                            color: color.turquoise[100],
                          },
                        ]}
                      />
                    </inv-grid-col>
                    <inv-grid-col class="col-12">
                      <inv-grid-row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          "--inv-gutter-y": "0.4em",
                        }}
                      >
                        <inv-grid-col
                          class="col-12"
                          style={{ display: "flex" }}
                        >
                          <inv-text-s
                            style={{
                              "--inv-text-s-font-weight": 700,
                              "--inv-text-s-color": color.blue[100],
                            }}
                          >
                            <NumberFormat
                              value={
                                100 -
                                (riskProfileArray[formik.values.profile - 1]
                                  ?.attributes.max_equity_exposure ?? 0) *
                                  100
                              }
                              thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                              }
                              decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                              }
                              decimalScale={0}
                              allowNegative={false}
                              displayType="text"
                              suffix="% Renta fija"
                            />
                          </inv-text-s>
                        </inv-grid-col>
                        <inv-grid-col
                          class="col-12"
                          style={{ display: "flex" }}
                        >
                          <inv-text-s
                            style={{
                              "--inv-text-s-font-weight": 700,
                              "--inv-text-s-color": color.turquoise[100],
                            }}
                          >
                            <NumberFormat
                              value={
                                (riskProfileArray[formik.values.profile - 1]
                                  ?.attributes.max_equity_exposure ?? 0) * 100
                              }
                              thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                              }
                              decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                              }
                              decimalScale={0}
                              allowNegative={false}
                              displayType="text"
                              suffix="% Renta variable"
                            />
                          </inv-text-s>
                        </inv-grid-col>
                      </inv-grid-row>
                    </inv-grid-col>
                  </inv-grid-row>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row
                class={styles.sliderContainer}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <inv-grid-col
                  class="col-11 col-sm-8"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <NotInteractiveSlider
                    // @ts-ignore
                    handleChange={
                      isSliderActive === true ? formik.setFieldValue : undefined
                    }
                    name="profile"
                    marks={riskProfileArray.map((profile) => ({
                      value: parseInt(profile.id, 10),
                      label: (!downMobile ||
                        (downMobile &&
                          formik.values.profile ===
                            parseInt(profile.id, 10))) && (
                        <inv-grid-row style={{ fontSize: "20px" }}>
                          <inv-grid-col
                            class="col-12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircleChart
                              width={30}
                              height={30}
                              data={[
                                {
                                  name: "fixed",
                                  value:
                                    100 -
                                    (profile?.attributes.max_equity_exposure ??
                                      0) *
                                      100,
                                  color:
                                    formik.values.profile ===
                                    parseInt(profile.id, 10)
                                      ? color.blue[100]
                                      : color.greyscale.black[30],
                                },
                                {
                                  name: "equity",
                                  value:
                                    (profile?.attributes.max_equity_exposure ??
                                      0) * 100,
                                  color:
                                    formik.values.profile ===
                                    parseInt(profile.id, 10)
                                      ? color.turquoise[100]
                                      : color.greyscale.black[10],
                                },
                              ]}
                            />
                          </inv-grid-col>
                          <inv-grid-col
                            class="col-12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <inv-text-xxs
                              style={{
                                "--inv-text-xxs-font-weight": 600,
                                "--inv-text-xxs-color":
                                  // eslint-disable-next-line no-nested-ternary
                                  formik.values.profile ===
                                  parseInt(profile.id, 10)
                                    ? color.greyscale.black[100]
                                    : profileNumber > parseInt(profile.id, 10)
                                    ? color.greyscale.black[30]
                                    : color.greyscale.black[10],
                              }}
                            >
                              {Capitalize(profile?.attributes.name ?? "")}
                            </inv-text-xxs>
                          </inv-grid-col>
                        </inv-grid-row>
                      ),
                    }))}
                    value={formik.values.profile}
                    min={1}
                    max={6}
                    profileNumberMax={profileNumberMax}
                    step={1}
                  />
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row
                class={styles.buttonsContainer}
                style={{ "--inv-gutter-y": "0.8em" }}
              >
                {isTestAvailable === true
                  ? showTest === false && (
                      <>
                        <inv-grid-col
                          class="col-12 col-sm-auto"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <inv-grid-row
                            class={styles.testAndSaveButtonsContainer}
                          >
                            <inv-grid-col
                              class="col-12 col-sm-auto"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <inv-button
                                onClick={() => {
                                  if (formik.values.profile !== profileNumber) {
                                    formik.handleSubmit();
                                  } else {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    closeDialog ? closeDialog() : null;
                                  }
                                }}
                                disabled={
                                  formik.values.profile === profileNumber &&
                                  testIsPassed === false
                                    ? true
                                    : undefined
                                }
                              >
                                Guardar cambios
                              </inv-button>
                            </inv-grid-col>
                            <inv-grid-col
                              class="col-12 col-sm-auto"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <inv-button
                                variant="outlined"
                                onClick={() => setShowTest(true)}
                              >
                                Repetir test
                              </inv-button>
                            </inv-grid-col>
                          </inv-grid-row>
                        </inv-grid-col>
                        <inv-grid-col
                          class="col-12 col-sm-auto"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <inv-button
                            onClick={() => (closeDialog ? closeDialog() : null)}
                            style={{
                              "--inv-primary-gradient-color": "transparent",
                              "--inv-primary-font-color":
                                color.greyscale.black[50],
                              "--inv-primary-hover-color":
                                color.greyscale.black[10],
                            }}
                          >
                            Atrás
                          </inv-button>
                        </inv-grid-col>
                      </>
                    )
                  : showTest === false && (
                      <inv-grid-col
                        class={`col-12 ${styles.continueButtonContainer}`}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <inv-button
                          style={{ "--inv-button-font-size": "1.2em" }}
                          onClick={() => {
                            setLoading(true);
                            router("/propuesta/resultado");
                          }}
                        >
                          Continuar
                        </inv-button>
                      </inv-grid-col>
                    )}
              </inv-grid-row>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

RiskProfileDialogContent.defaultProps = {
  isTestAvailable: undefined,
  closeDialog: undefined,
  financialWealth: undefined,
  birthday: undefined,
  userIncome: undefined,
  objectives: undefined,
  isSliderActive: undefined,
  userId: 0,
};
export default RiskProfileDialogContent;
