export const planRiskProfileTitleMock='Tranquilo'

export const planMock={
    // eslint-disable-next-line @typescript-eslint/naming-convention
    adjusted_objective:1000,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    monthly_saving:1000,
    saving:1000,
    performance:1000,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remaining_saving:1000,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    initial_investment:1000,
}

export const patrimonyChartMockData=[
    {
        "area1": 3000,
        "name": 2023,
        "date": "2023-3",
        "real": 2857.4485346014735
    },
    {
        "area1": 3239.5848269541666,
        "name": 2023,
        "date": "2023-4",
        "real": 2267.7093788679167
    },
    {
        "area1": 3480.636601561356,
        "name": 2023,
        "date": "2023-5",
        "real": 2436.4456210929493
    },
    {
        "area1": 3723.164305756879,
        "name": 2023,
        "date": "2023-6",
        "real": 4840.113597483943
    },
    {
        "area1": 3967.1769764713,
        "name": 2023,
        "date": "2023-7",
        "real": 5157.33006941269
    },
    {
        "area1": 4212.683705967169,
        "name": 2023,
        "date": "2023-8",
        "real": 4707.52239680024
    },
    {
        "area1": 4459.693642177812,
        "name": 2023,
        "date": "2023-9",
        "real": 5797.601734831155
    },
    {
        "area1": 4708.215989048196,
        "name": 2023,
        "date": "2023-10",
        "real": 6120.680785762655
    },
    {
        "area1": 4958.260006877883,
        "name": 2023,
        "date": "2023-11",
        "real": 5417.610808591402
    },
    {
        "area1": 5209.835012666076,
        "name": 2023,
        "date": "2023-12",
        "real": 6772.7855164658995
    },
    {
        "area1": 5462.950380458793,
        "name": 2024,
        "date": "2024-1",
        "real": 3824.065266321155
    },
    {
        "area1": 5717.615541698149,
        "name": 2024,
        "date": "2024-2",
        "real": 6739.988652666732
    },
    {
        "area1": 5973.83998557379,
        "name": 2024,
        "date": "2024-3",
        "real": 4315.093626897793
    },
    {
        "area1": 6235.172718666705,
        "name": 2024,
        "date": "2024-4",
        "real": 4364.620903066693
    },
    {
        "area1": 6498.105559096727,
        "name": 2024,
        "date": "2024-5",
        "real": 7421.918425345758
    },
    {
        "area1": 6762.648304119102,
        "name": 2024,
        "date": "2024-6",
        "real": 4733.853812883372
    },
    {
        "area1": 7028.8108109764335,
        "name": 2024,
        "date": "2024-7",
        "real": 8833.971092122127
    },
    {
        "area1": 7296.602997265977,
        "name": 2024,
        "date": "2024-8",
        "real": 8165.443413037125
    },
    {
        "area1": 7566.034841309185,
        "name": 2024,
        "date": "2024-9",
        "real": 5904.2665928267
    },
    {
        "area1": 7837.116382523512,
        "name": 2024,
        "date": "2024-10",
        "real": 10188.251297280565
    },
    {
        "area1": 8109.857721796495,
        "name": 2024,
        "date": "2024-11",
        "real": 10542.815038335444
    },
    {
        "area1": 8384.269021862136,
        "name": 2024,
        "date": "2024-12",
        "real": 10899.549728420776
    },
    {
        "area1": 8660.360507679572,
        "name": 2025,
        "date": "2025-1",
        "real": 6062.2523553757
    },
    {
        "area1": 8938.142466814073,
        "name": 2025,
        "date": "2025-2",
        "real": 11619.585206858295
    },
    {
        "area1": 9217.625249820376,
        "name": 2025,
        "date": "2025-3",
        "real": 6452.337674874263
    },
    {
        "area1": 9502.415361267225,
        "name": 2025,
        "date": "2025-4",
        "real": 10170.557133157992
    },
    {
        "area1": 9788.949206617855,
        "name": 2025,
        "date": "2025-5",
        "real": 12725.633968603212
    },
    {
        "area1": 10077.237462534851,
        "name": 2025,
        "date": "2025-6",
        "real": 8944.968053651146
    },
    {
        "area1": 10367.290871052654,
        "name": 2025,
        "date": "2025-7",
        "real": 8527.515427649152
    },
    {
        "area1": 10659.120239977823,
        "name": 2025,
        "date": "2025-8",
        "real": 7461.384167984476
    },
    {
        "area1": 10952.736443291758,
        "name": 2025,
        "date": "2025-9",
        "real": 14238.557376279286
    },
    {
        "area1": 11248.150421555873,
        "name": 2025,
        "date": "2025-10",
        "real": 8225.22192700902
    },
    {
        "area1": 11545.373182319256,
        "name": 2025,
        "date": "2025-11",
        "real": 15008.985137015032
    },
    {
        "area1": 11844.415800528832,
        "name": 2025,
        "date": "2025-12",
        "real": 8291.091060370181
    },
    {
        "area1": 12145.289418942022,
        "name": 2026,
        "date": "2026-1",
        "real": 15788.87624462463
    },
    {
        "area1": 12448.005248541955,
        "name": 2026,
        "date": "2026-2",
        "real": 15450.389861453861
    },
    {
        "area1": 12752.57456895518,
        "name": 2026,
        "date": "2026-3",
        "real": 8926.802198268626
    },
    {
        "area1": 13062.66235696109,
        "name": 2026,
        "date": "2026-4",
        "real": 9143.863649872763
    },
    {
        "area1": 13374.648773351062,
        "name": 2026,
        "date": "2026-5",
        "real": 9362.254141345744
    },
    {
        "area1": 13688.545443187035,
        "name": 2026,
        "date": "2026-6",
        "real": 17795.109076143144
    },
    {
        "area1": 14004.36406270973,
        "name": 2026,
        "date": "2026-7",
        "real": 14744.33516666485
    },
    {
        "area1": 14322.116399774477,
        "name": 2026,
        "date": "2026-8",
        "real": 18618.75131970682
    },
    {
        "area1": 14641.814294289688,
        "name": 2026,
        "date": "2026-9",
        "real": 10249.270006002782
    },
    {
        "area1": 14963.469658658058,
        "name": 2026,
        "date": "2026-10",
        "real": 14422.635161769536
    },
    {
        "area1": 15287.094478220399,
        "name": 2026,
        "date": "2026-11",
        "real": 19873.22282168652
    },
    {
        "area1": 15612.70081170227,
        "name": 2026,
        "date": "2026-12",
        "real": 10928.89056819159
    },
    {
        "area1": 15940.300791663272,
        "name": 2027,
        "date": "2027-1",
        "real": 11158.21055416429
    },
    {
        "area1": 16269.906624949146,
        "name": 2027,
        "date": "2027-2",
        "real": 21150.878612433888
    },
    {
        "area1": 16601.5305931466,
        "name": 2027,
        "date": "2027-3",
        "real": 11621.07141520262
    },
    {
        "area1": 16938.89713917948,
        "name": 2027,
        "date": "2027-4",
        "real": 22020.566280933323
    },
    {
        "area1": 17278.32933798847,
        "name": 2027,
        "date": "2027-5",
        "real": null
    },
    {
        "area1": 17619.839837304775,
        "name": 2027,
        "date": "2027-6",
        "real": null
    },
    {
        "area1": 17963.44136230006,
        "name": 2027,
        "date": "2027-7",
        "real": null
    },
    {
        "area1": 18309.146716060604,
        "name": 2027,
        "date": "2027-8",
        "real": null
    },
    {
        "area1": 18656.968780064384,
        "name": 2027,
        "date": "2027-9",
        "real": null
    },
    {
        "area1": 19006.920514661026,
        "name": 2027,
        "date": "2027-10",
        "real": null
    },
    {
        "area1": 19359.014959554745,
        "name": 2027,
        "date": "2027-11",
        "real": null
    },
    {
        "area1": 19713.265234290222,
        "name": 2027,
        "date": "2027-12",
        "real": null
    },
    {
        "area1": 20069.684538741443,
        "name": 2028,
        "date": "2028-1",
        "real": null
    },
    {
        "area1": 20428.286153603578,
        "name": 2028,
        "date": "2028-2",
        "real": null
    },
    {
        "area1": 20789.083440887785,
        "name": 2028,
        "date": "2028-3",
        "real": null
    },
    {
        "area1": 21155.861323935904,
        "name": 2028,
        "date": "2028-4",
        "real": null
    },
    {
        "area1": 21524.884941664124,
        "name": 2028,
        "date": "2028-5",
        "real": null
    },
    {
        "area1": 21896.168044422426,
        "name": 2028,
        "date": "2028-6",
        "real": null
    },
    {
        "area1": 22269.72446675243,
        "name": 2028,
        "date": "2028-7",
        "real": null
    },
    {
        "area1": 22645.568127902923,
        "name": 2028,
        "date": "2028-8",
        "real": null
    },
    {
        "area1": 23023.713032348485,
        "name": 2028,
        "date": "2028-9",
        "real": null
    },
    {
        "area1": 23404.17327031133,
        "name": 2028,
        "date": "2028-10",
        "real": null
    },
    {
        "area1": 23786.96301828632,
        "name": 2028,
        "date": "2028-11",
        "real": null
    },
    {
        "area1": 24172.096539569215,
        "name": 2028,
        "date": "2028-12",
        "real": null
    },
    {
        "area1": 24559.588184788125,
        "name": 2029,
        "date": "2029-1",
        "real": null
    },
    {
        "area1": 24949.452392438252,
        "name": 2029,
        "date": "2029-2",
        "real": null
    },
    {
        "area1": 25341.703689419868,
        "name": 2029,
        "date": "2029-3",
        "real": null
    },
    {
        "area1": 25740.188514768648,
        "name": 2029,
        "date": "2029-4",
        "real": null
    },
    {
        "area1": 26141.113212402925,
        "name": 2029,
        "date": "2029-5",
        "real": null
    },
    {
        "area1": 26544.49272135272,
        "name": 2029,
        "date": "2029-6",
        "real": null
    },
    {
        "area1": 26950.342072117855,
        "name": 2029,
        "date": "2029-7",
        "real": null
    },
    {
        "area1": 27358.676387228,
        "name": 2029,
        "date": "2029-8",
        "real": null
    },
    {
        "area1": 27769.510881806156,
        "name": 2029,
        "date": "2029-9",
        "real": null
    },
    {
        "area1": 28182.860864135633,
        "name": 2029,
        "date": "2029-10",
        "real": null
    },
    {
        "area1": 28598.741736230382,
        "name": 2029,
        "date": "2029-11",
        "real": null
    },
    {
        "area1": 29017.16899440899,
        "name": 2029,
        "date": "2029-12",
        "real": null
    },
    {
        "area1": 29438.158229872002,
        "name": 2030,
        "date": "2030-1",
        "real": null
    },
    {
        "area1": 29861.725129282946,
        "name": 2030,
        "date": "2030-2",
        "real": null
    },
    {
        "area1": 30287.8854753528,
        "name": 2030,
        "date": "2030-3",
        "real": null
    },
    {
        "area1": 30720.548279788112,
        "name": 2030,
        "date": "2030-4",
        "real": null
    },
    {
        "area1": 31155.860223960637,
        "name": 2030,
        "date": "2030-5",
        "real": null
    },
    {
        "area1": 31593.83752821859,
        "name": 2030,
        "date": "2030-6",
        "real": null
    },
    {
        "area1": 32034.496512225327,
        "name": 2030,
        "date": "2030-7",
        "real": null
    },
    {
        "area1": 32477.853595567438,
        "name": 2030,
        "date": "2030-8",
        "real": null
    },
    {
        "area1": 32923.925298366594,
        "name": 2030,
        "date": "2030-9",
        "real": null
    },
    {
        "area1": 33372.728241895056,
        "name": 2030,
        "date": "2030-10",
        "real": null
    },
    {
        "area1": 33824.279149195056,
        "name": 2030,
        "date": "2030-11",
        "real": null
    },
    {
        "area1": 34278.5948457019,
        "name": 2030,
        "date": "2030-12",
        "real": null
    },
    {
        "area1": 34735.69225987088,
        "name": 2031,
        "date": "2031-1",
        "real": null
    },
    {
        "area1": 35195.588423808134,
        "name": 2031,
        "date": "2031-2",
        "real": null
    },
    {
        "area1": 35658.300473905205,
        "name": 2031,
        "date": "2031-3",
        "real": null
    },
    {
        "area1": 36127.8010739554,
        "name": 2031,
        "date": "2031-4",
        "real": null
    },
    {
        "area1": 36600.17636691537,
        "name": 2031,
        "date": "2031-5",
        "real": null
    },
    {
        "area1": 37075.44395416695,
        "name": 2031,
        "date": "2031-6",
        "real": null
    },
    {
        "area1": 37553.62154486299,
        "name": 2031,
        "date": "2031-7",
        "real": null
    },
    {
        "area1": 38034.72695658725,
        "name": 2031,
        "date": "2031-8",
        "real": null
    },
    {
        "area1": 38518.77811601832,
        "name": 2031,
        "date": "2031-9",
        "real": null
    },
    {
        "area1": 39005.79305959757,
        "name": 2031,
        "date": "2031-10",
        "real": null
    },
    {
        "area1": 39495.789934201224,
        "name": 2031,
        "date": "2031-11",
        "real": null
    },
    {
        "area1": 39988.78699781654,
        "name": 2031,
        "date": "2031-12",
        "real": null
    },
    {
        "area1": 40484.80262022212,
        "name": 2032,
        "date": "2032-1",
        "real": null
    },
    {
        "area1": 40983.85528367243,
        "name": 2032,
        "date": "2032-2",
        "real": null
    },
    {
        "area1": 41485.9635835864,
        "name": 2032,
        "date": "2032-3",
        "real": null
    },
    {
        "area1": 41995.16493847784,
        "name": 2032,
        "date": "2032-4",
        "real": null
    },
    {
        "area1": 42507.48406898961,
        "name": 2032,
        "date": "2032-5",
        "real": null
    },
    {
        "area1": 43022.94006486825,
        "name": 2032,
        "date": "2032-6",
        "real": null
    },
    {
        "area1": 43541.55213274445,
        "name": 2032,
        "date": "2032-7",
        "real": null
    },
    {
        "area1": 44063.33959684867,
        "name": 2032,
        "date": "2032-8",
        "real": null
    },
    {
        "area1": 44588.32189973119,
        "name": 2032,
        "date": "2032-9",
        "real": null
    },
    {
        "area1": 45116.51860298657,
        "name": 2032,
        "date": "2032-10",
        "real": null
    },
    {
        "area1": 45647.94938798254,
        "name": 2032,
        "date": "2032-11",
        "real": null
    },
    {
        "area1": 46182.634056593364,
        "name": 2032,
        "date": "2032-12",
        "real": null
    },
    {
        "area1": 46720.59253193769,
        "name": 2033,
        "date": "2033-1",
        "real": null
    },
    {
        "area1": 47261.844859120894,
        "name": 2033,
        "date": "2033-2",
        "real": null
    },
    {
        "area1": 47806.41120598201,
        "name": 2033,
        "date": "2033-3",
        "real": null
    },
    {
        "area1": 48358.394872430435,
        "name": 2033,
        "date": "2033-4",
        "real": null
    },
    {
        "area1": 48913.75826519249,
        "name": 2033,
        "date": "2033-5",
        "real": null
    },
    {
        "area1": 49472.52207790578,
        "name": 2033,
        "date": "2033-6",
        "real": null
    },
    {
        "area1": 50034.70713091243,
        "name": 2033,
        "date": "2033-7",
        "real": null
    },
    {
        "area1": 50600.33437203491,
        "name": 2033,
        "date": "2033-8",
        "real": null
    },
    {
        "area1": 51169.42487735654,
        "name": 2033,
        "date": "2033-9",
        "real": null
    },
    {
        "area1": 51741.999852006855,
        "name": 2033,
        "date": "2033-10",
        "real": null
    },
    {
        "area1": 52318.08063095172,
        "name": 2033,
        "date": "2033-11",
        "real": null
    },
    {
        "area1": 52897.68867978828,
        "name": 2033,
        "date": "2033-12",
        "real": null
    },
    {
        "area1": 47497.98069879542,
        "name": 2034,
        "date": "2034-1",
        "real": null
    },
    {
        "area1": 47930.528559511426,
        "name": 2034,
        "date": "2034-2",
        "real": null
    },
    {
        "area1": 48365.72485617875,
        "name": 2034,
        "date": "2034-3",
        "real": null
    },
    {
        "area1": 48805.853384364316,
        "name": 2034,
        "date": "2034-4",
        "real": null
    },
    {
        "area1": 49248.676763971234,
        "name": 2034,
        "date": "2034-5",
        "real": null
    },
    {
        "area1": 49694.21149523457,
        "name": 2034,
        "date": "2034-6",
        "real": null
    },
    {
        "area1": 50142.4741794183,
        "name": 2034,
        "date": "2034-7",
        "real": null
    },
    {
        "area1": 50593.481519433786,
        "name": 2034,
        "date": "2034-8",
        "real": null
    },
    {
        "area1": 51047.25032046223,
        "name": 2034,
        "date": "2034-9",
        "real": null
    },
    {
        "area1": 51503.797490580866,
        "name": 2034,
        "date": "2034-10",
        "real": null
    },
    {
        "area1": 51963.14004139289,
        "name": 2034,
        "date": "2034-11",
        "real": null
    },
    {
        "area1": 52425.295088661456,
        "name": 2034,
        "date": "2034-12",
        "real": null
    },
    {
        "area1": 46907.65592942591,
        "name": 2035,
        "date": "2035-1",
        "real": null
    },
    {
        "area1": 47338.85688562272,
        "name": 2035,
        "date": "2035-2",
        "real": null
    },
    {
        "area1": 47772.698030844505,
        "name": 2035,
        "date": "2035-3",
        "real": null
    },
    {
        "area1": 48211.49939143572,
        "name": 2035,
        "date": "2035-4",
        "real": null
    },
    {
        "area1": 48652.98747736863,
        "name": 2035,
        "date": "2035-5",
        "real": null
    },
    {
        "area1": 49097.17873912337,
        "name": 2035,
        "date": "2035-6",
        "real": null
    },
    {
        "area1": 49544.089727904255,
        "name": 2035,
        "date": "2035-7",
        "real": null
    },
    {
        "area1": 49993.73709625658,
        "name": 2035,
        "date": "2035-8",
        "real": null
    },
    {
        "area1": 50446.13759868704,
        "name": 2035,
        "date": "2035-9",
        "real": null
    },
    {
        "area1": 50901.308092288076,
        "name": 2035,
        "date": "2035-10",
        "real": null
    },
    {
        "area1": 51359.26553736598,
        "name": 2035,
        "date": "2035-11",
        "real": null
    },
    {
        "area1": 51820.02699807285,
        "name": 2035,
        "date": "2035-12",
        "real": null
    },
    {
        "area1": 46181.33324105057,
        "name": 2036,
        "date": "2036-1",
        "real": null
    },
    {
        "area1": 46610.39087590936,
        "name": 2036,
        "date": "2036-2",
        "real": null
    },
    {
        "area1": 47042.07557650712,
        "name": 2036,
        "date": "2036-3",
        "real": null
    },
    {
        "area1": 47478.744150608894,
        "name": 2036,
        "date": "2036-4",
        "real": null
    },
    {
        "area1": 47918.0863912699,
        "name": 2036,
        "date": "2036-5",
        "real": null
    },
    {
        "area1": 48360.118669013,
        "name": 2036,
        "date": "2036-6",
        "real": null
    },
    {
        "area1": 48804.857454595665,
        "name": 2036,
        "date": "2036-7",
        "real": null
    },
    {
        "area1": 49252.319319623784,
        "name": 2036,
        "date": "2036-8",
        "real": null
    },
    {
        "area1": 49702.520937169065,
        "name": 2036,
        "date": "2036-9",
        "real": null
    },
    {
        "area1": 50155.47908239033,
        "name": 2036,
        "date": "2036-10",
        "real": null
    },
    {
        "area1": 50611.21063315859,
        "name": 2036,
        "date": "2036-11",
        "real": null
    },
    {
        "area1": 51069.73257068593,
        "name": 2036,
        "date": "2036-12",
        "real": null
    },
    {
        "area1": 45306.740050126544,
        "name": 2037,
        "date": "2037-1",
        "real": null
    },
    {
        "area1": 45732.78338384897,
        "name": 2037,
        "date": "2037-2",
        "real": null
    },
    {
        "area1": 46161.435327124986,
        "name": 2037,
        "date": "2037-3",
        "real": null
    },
    {
        "area1": 46595.09002627594,
        "name": 2037,
        "date": "2037-4",
        "real": null
    },
    {
        "area1": 47031.39993841023,
        "name": 2037,
        "date": "2037-5",
        "real": null
    },
    {
        "area1": 47470.38132106181,
        "name": 2037,
        "date": "2037-6",
        "real": null
    },
    {
        "area1": 47912.050531307425,
        "name": 2037,
        "date": "2037-7",
        "real": null
    },
    {
        "area1": 48356.424026376175,
        "name": 2037,
        "date": "2037-8",
        "real": null
    },
    {
        "area1": 48803.518364262665,
        "name": 2037,
        "date": "2037-9",
        "real": null
    },
    {
        "area1": 49253.35020434401,
        "name": 2037,
        "date": "2037-10",
        "real": null
    },
    {
        "area1": 49705.9363080006,
        "name": 2037,
        "date": "2037-11",
        "real": null
    },
    {
        "area1": 50161.29353924066,
        "name": 2037,
        "date": "2037-12",
        "real": null
    },
    {
        "area1": 44270.63049669624,
        "name": 2038,
        "date": "2038-1",
        "real": null
    },
    {
        "area1": 44692.7080365438,
        "name": 2038,
        "date": "2038-2",
        "real": null
    },
    {
        "area1": 45117.36990388965,
        "name": 2038,
        "date": "2038-3",
        "real": null
    },
    {
        "area1": 45547.048147165326,
        "name": 2038,
        "date": "2038-4",
        "real": null
    },
    {
        "area1": 45979.357256086965,
        "name": 2038,
        "date": "2038-5",
        "real": null
    },
    {
        "area1": 46414.313339112836,
        "name": 2038,
        "date": "2038-6",
        "real": null
    },
    {
        "area1": 46851.932603331246,
        "name": 2038,
        "date": "2038-7",
        "real": null
    },
    {
        "area1": 47292.231355064476,
        "name": 2038,
        "date": "2038-8",
        "real": null
    },
    {
        "area1": 47735.22600047634,
        "name": 2038,
        "date": "2038-9",
        "real": null
    },
    {
        "area1": 48180.93304618354,
        "name": 2038,
        "date": "2038-10",
        "real": null
    },
    {
        "area1": 48629.36909987072,
        "name": 2038,
        "date": "2038-11",
        "real": null
    },
    {
        "area1": 49080.55087090923,
        "name": 2038,
        "date": "2038-12",
        "real": null
    },
    {
        "area1": 49534.49517097985,
        "name": 2039,
        "date": "2039-1",
        "real": null
    },
    {
        "area1": 49991.21891469913,
        "name": 2039,
        "date": "2039-2",
        "real": null
    },
    {
        "area1": 50450.73912024968,
        "name": 2039,
        "date": "2039-3",
        "real": null
    },
    {
        "area1": 50915.52779453348,
        "name": 2039,
        "date": "2039-4",
        "real": null
    },
    {
        "area1": 51383.16231120039,
        "name": 2039,
        "date": "2039-5",
        "real": null
    },
    {
        "area1": 51853.660094984065,
        "name": 2039,
        "date": "2039-6",
        "real": null
    },
    {
        "area1": 52327.03867730765,
        "name": 2039,
        "date": "2039-7",
        "real": null
    },
    {
        "area1": 52803.31569693698,
        "name": 2039,
        "date": "2039-8",
        "real": null
    },
    {
        "area1": 53282.5089006378,
        "name": 2039,
        "date": "2039-9",
        "real": null
    },
    {
        "area1": 53764.636143837066,
        "name": 2039,
        "date": "2039-10",
        "real": null
    },
    {
        "area1": 54249.71539128828,
        "name": 2039,
        "date": "2039-11",
        "real": null
    },
    {
        "area1": 54737.76471774084,
        "name": 2039,
        "date": "2039-12",
        "real": null
    },
    {
        "area1": 55228.80230861358,
        "name": 2040,
        "date": "2040-1",
        "real": null
    },
    {
        "area1": 10091.065108629906,
        "name": 2040,
        "date": "2040-2",
        "real": null
    },
    {
        "area1": 10179.161733967017,
        "name": 2040,
        "date": "2040-3",
        "real": null
    },
    {
        "area1": 10268.218728740068,
        "name": 2040,
        "date": "2040-4",
        "real": null
    },
    {
        "area1": 10357.82100825059,
        "name": 2040,
        "date": "2040-5",
        "real": null
    },
    {
        "area1": 10447.9719112082,
        "name": 2040,
        "date": "2040-6",
        "real": null
    },
    {
        "area1": 10538.674796765012,
        "name": 2040,
        "date": "2040-7",
        "real": null
    },
    {
        "area1": 10629.933044640806,
        "name": 2040,
        "date": "2040-8",
        "real": null
    },
    {
        "area1": 10721.750055248958,
        "name": 2040,
        "date": "2040-9",
        "real": null
    },
    {
        "area1": 10814.129249823147,
        "name": 2040,
        "date": "2040-10",
        "real": null
    },
    {
        "area1": 10907.074070544833,
        "name": 2040,
        "date": "2040-11",
        "real": null
    },
    {
        "area1": 11000.587980671518,
        "name": 2040,
        "date": "2040-12",
        "real": null
    },
    {
        "area1": 11094.674464665795,
        "name": 2041,
        "date": "2041-1",
        "real": null
    },
    {
        "area1": 11189.33702832518,
        "name": 2041,
        "date": "2041-2",
        "real": null
    },
    {
        "area1": 11284.579198912745,
        "name": 2041,
        "date": "2041-3",
        "real": null
    },
    {
        "area1": 11380.832225646558,
        "name": 2041,
        "date": "2041-4",
        "real": null
    },
    {
        "area1": 11477.674597513194,
        "name": 2041,
        "date": "2041-5",
        "real": null
    },
    {
        "area1": 11575.109922998508,
        "name": 2041,
        "date": "2041-6",
        "real": null
    },
    {
        "area1": 11673.14183268266,
        "name": 2041,
        "date": "2041-7",
        "real": null
    },
    {
        "area1": 11771.773979375395,
        "name": 2041,
        "date": "2041-8",
        "real": null
    },
    {
        "area1": 11871.010038252149,
        "name": 2041,
        "date": "2041-9",
        "real": null
    },
    {
        "area1": 11970.853706990993,
        "name": 2041,
        "date": "2041-10",
        "real": null
    },
    {
        "area1": 12071.308705910415,
        "name": 2041,
        "date": "2041-11",
        "real": null
    },
    {
        "area1": 12172.378778107945,
        "name": 2041,
        "date": "2041-12",
        "real": null
    },
    {
        "area1": 12274.067689599622,
        "name": 2042,
        "date": "2042-1",
        "real": null
    },
    {
        "area1": 6009.639628750614,
        "name": 2042,
        "date": "2042-2",
        "real": null
    },
    {
        "area1": 6046.43589361008,
        "name": 2042,
        "date": "2042-3",
        "real": null
    },
    {
        "area1": 6083.457457354545,
        "name": 2042,
        "date": "2042-4",
        "real": null
    },
    {
        "area1": 6120.705699460645,
        "name": 2042,
        "date": "2042-5",
        "real": null
    },
    {
        "area1": 6158.182007851372,
        "name": 2042,
        "date": "2042-6",
        "real": null
    },
    {
        "area1": 6195.8877789478,
        "name": 2042,
        "date": "2042-7",
        "real": null
    },
    {
        "area1": 6233.824417721112,
        "name": 2042,
        "date": "2042-8",
        "real": null
    },
    {
        "area1": 6271.99333774495,
        "name": 2042,
        "date": "2042-9",
        "real": null
    },
    {
        "area1": 6310.395961248091,
        "name": 2042,
        "date": "2042-10",
        "real": null
    },
    {
        "area1": 6349.03371916744,
        "name": 2042,
        "date": "2042-11",
        "real": null
    },
    {
        "area1": 6387.908051201345,
        "name": 2042,
        "date": "2042-12",
        "real": null
    },
    {
        "area1": 6427.020405863248,
        "name": 2043,
        "date": "2043-1",
        "real": null
    },
    {
        "area1": 0,
        "name": 2043,
        "date": "2043-2",
        "real": null
    }
]

export const customDotsPatrimonyChartMockData = [
    {
        "name": 2040,
        "area1": 55228.80230861358,
        "type": "CUSTOM",
        "date": "2040-2"
    },
    {
        "name": 2033,
        "area1": 52897.68867978828,
        "type": "EDUCATION",
        "date": "2034-1"
    },
    {
        "name": 2042,
        "area1": 12274.067689599622,
        "type": "EDUCATION",
        "date": "2042-2"
    }
]

export const planGoalsDropdownPropsMock = {
    "type": "Fondos de Inversión",
    "risk": "atrevido",
    "performance": 0.076,
    "defaultExpanded": true,
    "management": "gestión delegada",
    "commission": "entre 0.8% y 1.29%",
    "transfer": "sin coste",
    "availability": "Máx. una semana",
    "title": "Metas a corto plazo",
    "equity": 0.85,
    "fixedIncome": 0.15000000000000002,
    "services": [
        {
            "attributes": {
                "description": "Esta es la cartera de fondos de inversión recomendada para el inicio que te ayudará a alcanzar tu meta.",
                "is_active": true,
                "min_investment": 3000,
                "name": "Fondos de Inversión",
                "type": "MUTUAL"
            },
            "id": "1",
            "type": "FinancialServiceType"
        },
        {
            "attributes": {
                "alias": "PIMCO Global High Yield Bond",
                "asset": "Renta Fija",
                "asset_category": "Renta Fija Corporativa Alto Rendimiento H I",
                "asset_geography": "Global",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf",
                "currency": "EUR",
                "description": "El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.",
                "identifier": "IE00B0V9TC00",
                "name": "PIMCO GIS Global High Yield Bond Fund"
            },
            "id": "1",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "alias": "OSTRUM Euro Short Term Credit",
                "asset": "Renta Fija",
                "asset_category": "Renta Fija Corp. Euro IG Corto Plazo",
                "asset_geography": "Europa",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf",
                "currency": "EUR",
                "description": "Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.",
                "identifier": "LU0935221761",
                "name": "Ostrum Euro Short Term Credit"
            },
            "id": "4",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "alias": "Vanguard Global Stock Index",
                "asset": "Renta Variable",
                "asset_category": "Renta Variable mercados desarrollados",
                "asset_geography": "Global",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf",
                "currency": "EUR",
                "description": "El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).",
                "identifier": "IE00B03HD191",
                "name": "VANGUARD GLOBAL STOCK INDEX"
            },
            "id": "5",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "alias": "Vanguard Emerging Markets Stock Index",
                "asset": "Renta Variable",
                "asset_category": "Renta Variable mercados emergentes",
                "asset_geography": "Emergentes",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf",
                "currency": "EUR",
                "description": "El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).",
                "identifier": "IE0031786696",
                "name": "VANGUARD EM MKTS STK INDX"
            },
            "id": "6",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "alias": "Vanguard Global Small-Cap Index",
                "asset": "Renta Variable",
                "asset_category": "Renta Variable Small Caps",
                "asset_geography": "Global",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf",
                "currency": "EUR",
                "description": "El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).",
                "identifier": "IE00B42W4L06",
                "name": "VANGUARD GB SM CAP IDX"
            },
            "id": "7",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "alias": "M&G Emerging Markets Bond H",
                "asset": "Renta Fija",
                "asset_category": "Renta Fija Mercados Emergentes H C",
                "asset_geography": "Emergentes",
                "brochure": "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf",
                "currency": "EUR",
                "description": "El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.",
                "identifier": "LU1670632170",
                "name": "M&G (Lux) Em Mkts Bd C H EUR Acc"
            },
            "id": "11",
            "type": "FinancialProduct"
        },
        {
            "attributes": {
                "financial_product_id": 1,
                "financial_service_id": 5,
                "portfolio_weight": 0.1
            },
            "id": "5",
            "type": "FinancialProductFinancialService"
        },
        {
            "attributes": {
                "financial_product_id": 4,
                "financial_service_id": 5,
                "portfolio_weight": 0.05
            },
            "id": "18",
            "type": "FinancialProductFinancialService"
        },
        {
            "attributes": {
                "financial_product_id": 5,
                "financial_service_id": 5,
                "portfolio_weight": 0.42
            },
            "id": "23",
            "type": "FinancialProductFinancialService"
        },
        {
            "attributes": {
                "financial_product_id": 6,
                "financial_service_id": 5,
                "portfolio_weight": 0.26
            },
            "id": "27",
            "type": "FinancialProductFinancialService"
        },
        {
            "attributes": {
                "financial_product_id": 7,
                "financial_service_id": 5,
                "portfolio_weight": 0.12
            },
            "id": "31",
            "type": "FinancialProductFinancialService"
        },
        {
            "attributes": {
                "financial_product_id": 11,
                "financial_service_id": 5,
                "portfolio_weight": 0.05
            },
            "id": "40",
            "type": "FinancialProductFinancialService"
        }
    ],
    "initialInvestment": 3000
}

export const funnelChartProcessedDataMock = [
    {
        "date": "12-2001",
        "max_performance": [
            42.616,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -25.304000000000002,
            4.2
        ],
        "real": 7.933394628986323
    },
    {
        "date": "01-2002",
        "max_performance": [
            42.356831216306986,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -25.10989080434148,
            4.2
        ],
        "real": 12.953415383949185
    },
    {
        "date": "02-2002",
        "max_performance": [
            42.09923856501591,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.917131449612665,
            4.2
        ],
        "real": 3.768119055279362
    },
    {
        "date": "03-2002",
        "max_performance": [
            41.84321246089311,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.725712548980912,
            4.2
        ],
        "real": 11.918572920548653
    },
    {
        "date": "04-2002",
        "max_performance": [
            41.58874337699744,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.535624780889872,
            4.2
        ],
        "real": 12.902014850822852
    },
    {
        "date": "05-2002",
        "max_performance": [
            41.33582184432574,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.34685888860557,
            4.2
        ],
        "real": 11.304697881579461
    },
    {
        "date": "06-2002",
        "max_performance": [
            41.0844384514605,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.15940567976563,
            4.2
        ],
        "real": 8.29887564892655
    },
    {
        "date": "07-2002",
        "max_performance": [
            40.834583844219665,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.973256025931622,
            4.2
        ],
        "real": 13.52131956040541
    },
    {
        "date": "08-2002",
        "max_performance": [
            40.586248725308536,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.788400862144535,
            4.2
        ],
        "real": 17.3145695667857
    },
    {
        "date": "09-2002",
        "max_performance": [
            40.33942385397382,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.604831186483352,
            4.2
        ],
        "real": 19.073688724488314
    },
    {
        "date": "10-2002",
        "max_performance": [
            40.09410004565978,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.422538059626678,
            4.2
        ],
        "real": 12.06724879503287
    },
    {
        "date": "11-2002",
        "max_performance": [
            39.85026817166645,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.2415126044174,
            4.2
        ],
        "real": 13.56193265861847
    },
    {
        "date": "12-2002",
        "max_performance": [
            39.607919158809985,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.061746005430432,
            4.2
        ],
        "real": 9.35933246380667
    },
    {
        "date": "01-2003",
        "max_performance": [
            39.367043989085,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.883229508543383,
            4.2
        ],
        "real": 9.971041415281135
    },
    {
        "date": "02-2003",
        "max_performance": [
            39.12763369932903,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.705954420510302,
            4.2
        ],
        "real": 17.494016611757445
    },
    {
        "date": "03-2003",
        "max_performance": [
            38.889679380888985,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.529912108538312,
            4.2
        ],
        "real": 13.75974638697648
    },
    {
        "date": "04-2003",
        "max_performance": [
            38.653172179289676,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.355093999867226,
            4.2
        ],
        "real": 11.965036630104375
    },
    {
        "date": "05-2003",
        "max_performance": [
            38.4181032939043,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.18149158135207,
            4.2
        ],
        "real": 5.961803569389246
    },
    {
        "date": "06-2003",
        "max_performance": [
            38.184463977627,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.009096399048545,
            4.2
        ],
        "real": 9.028795941437263
    },
    {
        "date": "07-2003",
        "max_performance": [
            37.95224553654732,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.837900057801306,
            4.2
        ],
        "real": 10.09173519608102
    },
    {
        "date": "08-2003",
        "max_performance": [
            37.72143932962679,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.667894220835162,
            4.2
        ],
        "real": 13.180621263241509
    },
    {
        "date": "09-2003",
        "max_performance": [
            37.49203676837728,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.49907060934911,
            4.2
        ],
        "real": 8.190779040930662
    },
    {
        "date": "10-2003",
        "max_performance": [
            37.26402931654149,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.33142100211315,
            4.2
        ],
        "real": 10.12175071378143
    },
    {
        "date": "11-2003",
        "max_performance": [
            37.03740848977528,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.16493723506796,
            4.2
        ],
        "real": 9.639660360080725
    },
    {
        "date": "12-2003",
        "max_performance": [
            36.81216585533196,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.999611200927326,
            4.2
        ],
        "real": 11.58445784141422
    },
    {
        "date": "01-2004",
        "max_performance": [
            36.58829303174853,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.835434848783322,
            4.2
        ],
        "real": 9.48482665998105
    },
    {
        "date": "02-2004",
        "max_performance": [
            36.36578168853373,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.672400183714284,
            4.2
        ],
        "real": 11.036754052569918
    },
    {
        "date": "03-2004",
        "max_performance": [
            36.14462354585814,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.510499266395446,
            4.2
        ],
        "real": 7.58276402201663
    },
    {
        "date": "04-2004",
        "max_performance": [
            35.92481037424602,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.34972421271235,
            4.2
        ],
        "real": 10.720642057683417
    },
    {
        "date": "05-2004",
        "max_performance": [
            35.70633399426913,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.19006719337689,
            4.2
        ],
        "real": 5.92742795689889
    },
    {
        "date": "06-2004",
        "max_performance": [
            35.4891862762423,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.03152043354605,
            4.2
        ],
        "real": 6.83714313775754
    },
    {
        "date": "07-2004",
        "max_performance": [
            35.273359139921006,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.874076212443303,
            4.2
        ],
        "real": 6.62486536973057
    },
    {
        "date": "08-2004",
        "max_performance": [
            35.05884455420061,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.717726862982627,
            4.2
        ],
        "real": 5.284020880177209
    },
    {
        "date": "09-2004",
        "max_performance": [
            34.8456345368176,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.562464771395124,
            4.2
        ],
        "real": 7.40133635557203
    },
    {
        "date": "10-2004",
        "max_performance": [
            34.63372115405248,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.408282376858278,
            4.2
        ],
        "real": 4.207696021103602
    },
    {
        "date": "11-2004",
        "max_performance": [
            34.42309652043462,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.255172171127736,
            4.2
        ],
        "real": 5.099842818263278
    },
    {
        "date": "12-2004",
        "max_performance": [
            34.21375279844879,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.103126698171707,
            4.2
        ],
        "real": 7.218455469456542
    },
    {
        "date": "01-2005",
        "max_performance": [
            34.005682198243534,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.95213855380785,
            4.2
        ],
        "real": 4.418395367531969
    },
    {
        "date": "02-2005",
        "max_performance": [
            33.798876977341294,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.802200385342722,
            4.2
        ],
        "real": 5.6090290895774775
    },
    {
        "date": "03-2005",
        "max_performance": [
            33.59332944035032,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.65330489121373,
            4.2
        ],
        "real": 7.9807838590033375
    },
    {
        "date": "04-2005",
        "max_performance": [
            33.38903193867831,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.50544482063354,
            4.2
        ],
        "real": 5.854428999893879
    },
    {
        "date": "05-2005",
        "max_performance": [
            33.185976870247806,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.35861297323702,
            4.2
        ],
        "real": 6.096759868462187
    },
    {
        "date": "06-2005",
        "max_performance": [
            32.9841566792133,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.212802198730568,
            4.2
        ],
        "real": 5.127470385483757
    },
    {
        "date": "07-2005",
        "max_performance": [
            32.78356385568008,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.068005396543942,
            4.2
        ],
        "real": 6.3186153214224605
    },
    {
        "date": "08-2005",
        "max_performance": [
            32.58419093542481,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.924215515484473,
            4.2
        ],
        "real": 6.973866298966924
    },
    {
        "date": "09-2005",
        "max_performance": [
            32.38603049961772,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.78142555339367,
            4.2
        ],
        "real": 6.70460566542701
    },
    {
        "date": "10-2005",
        "max_performance": [
            32.1890751745466,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.639628556806272,
            4.2
        ],
        "real": 6.433867600846832
    },
    {
        "date": "11-2005",
        "max_performance": [
            31.9933176313424,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.498817620611604,
            4.2
        ],
        "real": 7.6851516695595645
    },
    {
        "date": "12-2005",
        "max_performance": [
            31.798750585706507,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.358985887717335,
            4.2
        ],
        "real": 5.606894241175953
    },
    {
        "date": "01-2006",
        "max_performance": [
            31.6053667976397,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.220126548715548,
            4.2
        ],
        "real": 6.443115702897972
    },
    {
        "date": "02-2006",
        "max_performance": [
            31.41315907117275,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.082232841551143,
            4.2
        ],
        "real": 6.023495290205783
    },
    {
        "date": "03-2006",
        "max_performance": [
            31.22212025409862,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.945298051192555,
            4.2
        ],
        "real": 6.449830429009484
    },
    {
        "date": "04-2006",
        "max_performance": [
            31.03224323770638,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.809315509304735,
            4.2
        ],
        "real": 6.186528984695983
    },
    {
        "date": "05-2006",
        "max_performance": [
            30.843520956516638,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.67427859392442,
            4.2
        ],
        "real": 5.074505178005939
    },
    {
        "date": "06-2006",
        "max_performance": [
            30.65594638801865,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.540180729137685,
            4.2
        ],
        "real": 4.934572869052567
    },
    {
        "date": "07-2006",
        "max_performance": [
            30.469512552408993,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.407015384759696,
            4.2
        ],
        "real": 6.914078460769613
    },
    {
        "date": "08-2006",
        "max_performance": [
            30.284212512331866,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.2747760760167,
            4.2
        ],
        "real": 6.753420993412829
    },
    {
        "date": "09-2006",
        "max_performance": [
            30.100039372620923,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.14345636323026,
            4.2
        ],
        "real": 4.506667017786237
    },
    {
        "date": "10-2006",
        "max_performance": [
            29.91698628004269,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.01304985150365,
            4.2
        ],
        "real": 3.867174566569287
    },
    {
        "date": "11-2006",
        "max_performance": [
            29.735046423041577,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.883550190410423,
            4.2
        ],
        "real": 6.516562064198594
    },
    {
        "date": "12-2006",
        "max_performance": [
            29.554213031486416,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.754951073685197,
            4.2
        ],
        "real": 6.0764450586330065
    },
    {
        "date": "01-2007",
        "max_performance": [
            29.374479376418492,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.627246238916532,
            4.2
        ],
        "real": 6.302913315406133
    },
    {
        "date": "02-2007",
        "max_performance": [
            29.195838769801217,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.500429467241984,
            4.2
        ],
        "real": 4.414183768446067
    },
    {
        "date": "03-2007",
        "max_performance": [
            29.018284564271216,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.37449458304525,
            4.2
        ],
        "real": 4.145462612218798
    },
    {
        "date": "04-2007",
        "max_performance": [
            28.841810152890982,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.249435453655446,
            4.2
        ],
        "real": 5.128490361504445
    },
    {
        "date": "05-2007",
        "max_performance": [
            28.666408968903056,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.125245989048457,
            4.2
        ],
        "real": 4.527187849069092
    },
    {
        "date": "06-2007",
        "max_performance": [
            28.492074485485627,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.001920141550363,
            4.2
        ],
        "real": 4.572888574767675
    },
    {
        "date": "07-2007",
        "max_performance": [
            28.318800215509704,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.879451905542956,
            4.2
        ],
        "real": 5.749659010867547
    },
    {
        "date": "08-2007",
        "max_performance": [
            28.146579711297694,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.757835317171258,
            4.2
        ],
        "real": 4.689975982641914
    },
    {
        "date": "09-2007",
        "max_performance": [
            27.975406564383494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.63706445405312,
            4.2
        ],
        "real": 4.756162685906307
    },
    {
        "date": "10-2007",
        "max_performance": [
            27.80527440527403,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.517133434990795,
            4.2
        ],
        "real": 5.941932181273568
    },
    {
        "date": "11-2007",
        "max_performance": [
            27.63617690321224,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.398036419684567,
            4.2
        ],
        "real": 3.9588298661495713
    },
    {
        "date": "12-2007",
        "max_performance": [
            27.46810776594149,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.279767608448333,
            4.2
        ],
        "real": 4.315438671453409
    },
    {
        "date": "01-2008",
        "max_performance": [
            27.301060739471435,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.162321241927158,
            4.2
        ],
        "real": 5.16671999092333
    },
    {
        "date": "02-2008",
        "max_performance": [
            27.13502960784534,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.04569160081685,
            4.2
        ],
        "real": 5.444963787429202
    },
    {
        "date": "03-2008",
        "max_performance": [
            26.970008192908715,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.929873005585398,
            4.2
        ],
        "real": 4.4534364341830335
    },
    {
        "date": "04-2008",
        "max_performance": [
            26.805990354079476,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.81485981619644,
            4.2
        ],
        "real": 4.634030697559965
    },
    {
        "date": "05-2008",
        "max_performance": [
            26.642969988119432,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.700646431834587,
            4.2
        ],
        "real": 4.901578938604834
    },
    {
        "date": "06-2008",
        "max_performance": [
            26.480941028907157,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.587227290632667,
            4.2
        ],
        "real": 3.8994606844261037
    },
    {
        "date": "07-2008",
        "max_performance": [
            26.319897447212295,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.4745968694009,
            4.2
        ],
        "real": 4.586285357161413
    },
    {
        "date": "08-2008",
        "max_performance": [
            26.159833250471188,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.362749683357936,
            4.2
        ],
        "real": 4.562415909620343
    },
    {
        "date": "09-2008",
        "max_performance": [
            26.000742482563908,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.251680285863738,
            4.2
        ],
        "real": 4.490519055204745
    },
    {
        "date": "10-2008",
        "max_performance": [
            25.8426192235926,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.14138326815437,
            4.2
        ],
        "real": 4.795304777887277
    },
    {
        "date": "11-2008",
        "max_performance": [
            25.68545758966121,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.03185325907859,
            4.2
        ],
        "real": 5.049467814627295
    },
    {
        "date": "12-2008",
        "max_performance": [
            25.529251732656544,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.923084924836298,
            4.2
        ],
        "real": 4.846391615685741
    },
    {
        "date": "01-2009",
        "max_performance": [
            25.373995840030645,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.8150729687188,
            4.2
        ],
        "real": 4.124847766057282
    },
    {
        "date": "02-2009",
        "max_performance": [
            25.21968413458451,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.707812130850852,
            4.2
        ],
        "real": 4.599126578442947
    },
    {
        "date": "03-2009",
        "max_performance": [
            25.06631087425313,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.601297187934552,
            4.2
        ],
        "real": 5.572222409683514
    },
    {
        "date": "04-2009",
        "max_performance": [
            24.913870351891784,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.495522952994945,
            4.2
        ],
        "real": 5.1354675318725285
    },
    {
        "date": "05-2009",
        "max_performance": [
            24.76235689506371,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.390484275127461,
            4.2
        ],
        "real": 4.616023181025186
    },
    {
        "date": "06-2009",
        "max_performance": [
            24.61176486582901,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.286176039247058,
            4.2
        ],
        "real": 4.964032900753024
    },
    {
        "date": "07-2009",
        "max_performance": [
            24.462088660534864,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.182593165839155,
            4.2
        ],
        "real": 4.047261023716242
    },
    {
        "date": "08-2009",
        "max_performance": [
            24.31332270960701,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.079730610712252,
            4.2
        ],
        "real": 4.987400119045393
    },
    {
        "date": "09-2009",
        "max_performance": [
            24.165461477342493,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.977583364752304,
            4.2
        ],
        "real": 4.239892938535728
    },
    {
        "date": "10-2009",
        "max_performance": [
            24.01849946170369,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.876146453678789,
            4.2
        ],
        "real": 4.499331668186524
    },
    {
        "date": "11-2009",
        "max_performance": [
            23.872431194113563,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.775414937802477,
            4.2
        ],
        "real": 4.362180091209167
    },
    {
        "date": "12-2009",
        "max_performance": [
            23.72725123925216,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.675383911784879,
            4.2
        ],
        "real": 4.420688726159816
    },
    {
        "date": "01-2010",
        "max_performance": [
            23.582954194854388,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.576048504399367,
            4.2
        ],
        "real": 4.0173944748264745
    },
    {
        "date": "02-2010",
        "max_performance": [
            23.439534691508964,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.477403878293966,
            4.2
        ],
        "real": 4.986262474435477
    },
    {
        "date": "03-2010",
        "max_performance": [
            23.29698739245864,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.379445229755788,
            4.2
        ],
        "real": 4.247308176803188
    },
    {
        "date": "04-2010",
        "max_performance": [
            23.155306993401602,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.282167788477098,
            4.2
        ],
        "real": 3.8067766057133356
    },
    {
        "date": "05-2010",
        "max_performance": [
            23.014488222294077,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.18556681732302,
            4.2
        ],
        "real": 5.199444857606594
    },
    {
        "date": "06-2010",
        "max_performance": [
            22.8745258391542,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.08963761210085,
            4.2
        ],
        "real": 4.397579941195251
    },
    {
        "date": "07-2010",
        "max_performance": [
            22.73541463586698,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.994375501330975,
            4.2
        ],
        "real": 4.276148441421768
    },
    {
        "date": "08-2010",
        "max_performance": [
            22.597149435990552,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.899775846019391,
            4.2
        ],
        "real": 4.0918482964467096
    },
    {
        "date": "09-2010",
        "max_performance": [
            22.459725094563506,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.80583403943178,
            4.2
        ],
        "real": 4.131374413843569
    },
    {
        "date": "10-2010",
        "max_performance": [
            22.323136497913485,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.712545506869201,
            4.2
        ],
        "real": 4.040238888522829
    },
    {
        "date": "11-2010",
        "max_performance": [
            22.187378563466858,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.619905705445287,
            4.2
        ],
        "real": 3.6085394882785504
    },
    {
        "date": "12-2010",
        "max_performance": [
            22.052446239559636,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.527910123865047,
            4.2
        ],
        "real": 3.991465442062969
    },
    {
        "date": "01-2011",
        "max_performance": [
            21.91833450524947,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.436554282205154,
            4.2
        ],
        "real": 4.24440614573256
    },
    {
        "date": "02-2011",
        "max_performance": [
            21.785038370128806,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.345833731695805,
            4.2
        ],
        "real": 3.9131761887473138
    },
    {
        "date": "03-2011",
        "max_performance": [
            21.65255287413923,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.255744054504063,
            4.2
        ],
        "real": 3.8092050320221116
    },
    {
        "date": "04-2011",
        "max_performance": [
            21.52087308738685,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.166280863518734,
            4.2
        ],
        "real": 4.403460670445991
    },
    {
        "date": "05-2011",
        "max_performance": [
            21.389994109958895,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.077439802136718,
            4.2
        ],
        "real": 3.8291458891948293
    },
    {
        "date": "06-2011",
        "max_performance": [
            21.259911071741357,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.989216544050858,
            4.2
        ],
        "real": 4.356467242879081
    },
    {
        "date": "07-2011",
        "max_performance": [
            21.13061913223777,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.901606793039267,
            4.2
        ],
        "real": 3.673781252944708
    },
    {
        "date": "08-2011",
        "max_performance": [
            21.002113480389113,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.814606282756102,
            4.2
        ],
        "real": 3.7475439841638147
    },
    {
        "date": "09-2011",
        "max_performance": [
            20.874389334394767,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.728210776523815,
            4.2
        ],
        "real": 4.784063289586826
    },
    {
        "date": "10-2011",
        "max_performance": [
            20.747441941534586,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.642416067126831,
            4.2
        ],
        "real": 4.736012115624133
    },
    {
        "date": "11-2011",
        "max_performance": [
            20.621266577992042,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.557217976606681,
            4.2
        ],
        "real": 4.096868545861088
    },
    {
        "date": "12-2011",
        "max_performance": [
            20.495858548678463,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.472612356058534,
            4.2
        ],
        "real": 3.70015939758106
    },
    {
        "date": "01-2012",
        "max_performance": [
            20.3712131870583,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.388595085429165,
            4.2
        ],
        "real": 4.236424241334191
    },
    {
        "date": "02-2012",
        "max_performance": [
            20.247325854975486,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.305162073316312,
            4.2
        ],
        "real": 3.5976689931684014
    },
    {
        "date": "03-2012",
        "max_performance": [
            20.12419194248088,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.222309256769451,
            4.2
        ],
        "real": 4.352690757440358
    },
    {
        "date": "04-2012",
        "max_performance": [
            20.001806867660687,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.140032601091924,
            4.2
        ],
        "real": 4.068808920741951
    },
    {
        "date": "05-2012",
        "max_performance": [
            19.88016607646597,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.058328099644479,
            4.2
        ],
        "real": 4.260724659858517
    },
    {
        "date": "06-2012",
        "max_performance": [
            19.7592650425432,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.977191773650139,
            4.2
        ],
        "real": 3.7181771953349374
    },
    {
        "date": "07-2012",
        "max_performance": [
            19.639099267065824,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.896619672000462,
            4.2
        ],
        "real": 3.7065620744389127
    },
    {
        "date": "08-2012",
        "max_performance": [
            19.51966427856686,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.816607871063125,
            4.2
        ],
        "real": 3.240713987792443
    },
    {
        "date": "09-2012",
        "max_performance": [
            19.400955632772508,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.737152474490864,
            4.2
        ],
        "real": 3.6652077264108796
    },
    {
        "date": "10-2012",
        "max_performance": [
            19.282968912436775,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.658249613031714,
            4.2
        ],
        "real": 3.620782264728839
    },
    {
        "date": "11-2012",
        "max_performance": [
            19.165699727177095,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.57989544434061,
            4.2
        ],
        "real": 4.1527341142483465
    },
    {
        "date": "12-2012",
        "max_performance": [
            19.049143713310986,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.502086152792259,
            4.2
        ],
        "real": 3.2395045914424436
    },
    {
        "date": "01-2013",
        "max_performance": [
            18.933296533693657,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.424817949295338,
            4.2
        ],
        "real": 3.404055801649153
    },
    {
        "date": "02-2013",
        "max_performance": [
            18.818153877556608,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.34808707110798,
            4.2
        ],
        "real": 4.060024896854109
    },
    {
        "date": "03-2013",
        "max_performance": [
            18.703711460347243,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.271889781654519,
            4.2
        ],
        "real": 3.2659534098731458
    },
    {
        "date": "04-2013",
        "max_performance": [
            18.58996502356943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.196222370343555,
            4.2
        ],
        "real": 3.6869669936953953
    },
    {
        "date": "05-2013",
        "max_performance": [
            18.47691033462504,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.121081152387248,
            4.2
        ],
        "real": 4.3768215544597755
    },
    {
        "date": "06-2013",
        "max_performance": [
            18.364543186656448,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.046462468621872,
            4.2
        ],
        "real": 3.831913172924306
    },
    {
        "date": "07-2013",
        "max_performance": [
            18.252859398389987,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.972362685329633,
            4.2
        ],
        "real": 3.7030675225603993
    },
    {
        "date": "08-2013",
        "max_performance": [
            18.141854813980366,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.898778194061718,
            4.2
        ],
        "real": 3.509156978013861
    },
    {
        "date": "09-2013",
        "max_performance": [
            18.031525302856032,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.825705411462565,
            4.2
        ],
        "real": 3.2972551873964084
    },
    {
        "date": "10-2013",
        "max_performance": [
            17.921866759565464,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.753140779095379,
            4.2
        ],
        "real": 3.4930881400358826
    },
    {
        "date": "11-2013",
        "max_performance": [
            17.81287510362439,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.681080763268826,
            4.2
        ],
        "real": 3.4613109040855683
    },
    {
        "date": "12-2013",
        "max_performance": [
            17.704546279363974,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.609521854864969,
            4.2
        ],
        "real": 3.9237140528144643
    },
    {
        "date": "01-2014",
        "max_performance": [
            17.596876255779886,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.538460569168381,
            4.2
        ],
        "real": 3.728359347895016
    },
    {
        "date": "02-2014",
        "max_performance": [
            17.489861026382314,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.46789344569644,
            4.2
        ],
        "real": 3.6501987727478147
    },
    {
        "date": "03-2014",
        "max_performance": [
            17.383496609046873,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.397817048030822,
            4.2
        ],
        "real": 3.4804026012303257
    },
    {
        "date": "04-2014",
        "max_performance": [
            17.277779045866414,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.328227963650155,
            4.2
        ],
        "real": 3.631656453423826
    },
    {
        "date": "05-2014",
        "max_performance": [
            17.172704403003785,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.259122803763843,
            4.2
        ],
        "real": 4.0717247629731945
    },
    {
        "date": "06-2014",
        "max_performance": [
            17.06826877054541,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.190498203147026,
            4.2
        ],
        "real": 3.7330526939699737
    },
    {
        "date": "07-2014",
        "max_performance": [
            16.96446826235582,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.122350819976729,
            4.2
        ],
        "real": 3.4693487110557752
    },
    {
        "date": "08-2014",
        "max_performance": [
            16.86129901593303,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.0546773356690995,
            4.2
        ],
        "real": 3.5429621908836806
    },
    {
        "date": "09-2014",
        "max_performance": [
            16.75875719226484,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.987474454717814,
            4.2
        ],
        "real": 3.385586735156565
    },
    {
        "date": "10-2014",
        "max_performance": [
            16.656838975685954,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.920738904533602,
            4.2
        ],
        "real": 3.385325028316122
    },
    {
        "date": "11-2014",
        "max_performance": [
            16.555540573736007,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.854467435284862,
            4.2
        ],
        "real": 3.461885627478134
    },
    {
        "date": "12-2014",
        "max_performance": [
            16.45485821701845,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.788656819739426,
            4.2
        ],
        "real": 4.089000565696277
    },
    {
        "date": "01-2015",
        "max_performance": [
            16.354788159060277,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.723303853107382,
            4.2
        ],
        "real": 3.1338743934920865
    },
    {
        "date": "02-2015",
        "max_performance": [
            16.255326676172615,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.658405352885023,
            4.2
        ],
        "real": 3.789371577788092
    },
    {
        "date": "03-2015",
        "max_performance": [
            16.15647006731218,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.593958158699863,
            4.2
        ],
        "real": 3.086746500922688
    },
    {
        "date": "04-2015",
        "max_performance": [
            16.058214653943544,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.529959132156744,
            4.2
        ],
        "real": 3.3392023596123366
    },
    {
        "date": "05-2015",
        "max_performance": [
            15.960556779902248,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.4664051566849885,
            4.2
        ],
        "real": 3.859937268307201
    },
    {
        "date": "06-2015",
        "max_performance": [
            15.863492811258768,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.403293137386646,
            4.2
        ],
        "real": 4.030024078504479
    },
    {
        "date": "07-2015",
        "max_performance": [
            15.767019136183285,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.3406200008857745,
            4.2
        ],
        "real": 3.8449475055768234
    },
    {
        "date": "08-2015",
        "max_performance": [
            15.67113216481129,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.278382695178781,
            4.2
        ],
        "real": 3.694889232474474
    },
    {
        "date": "09-2015",
        "max_performance": [
            15.57582832910999,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.216578189485782,
            4.2
        ],
        "real": 3.6118687093848134
    },
    {
        "date": "10-2015",
        "max_performance": [
            15.481104082745556,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.155203474103038,
            4.2
        ],
        "real": 4.007873530218853
    },
    {
        "date": "11-2015",
        "max_performance": [
            15.38695590095116,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.094255560256366,
            4.2
        ],
        "real": 3.9989910367449477
    },
    {
        "date": "12-2015",
        "max_performance": [
            15.29338028039579,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.033731479955609,
            4.2
        ],
        "real": 3.6970938552739696
    },
    {
        "date": "01-2016",
        "max_performance": [
            15.200373739053928,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.973628285850101,
            4.2
        ],
        "real": 3.984534916544047
    },
    {
        "date": "02-2016",
        "max_performance": [
            15.107932816075943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.913943051085129,
            4.2
        ],
        "real": 3.8395190584313177
    },
    {
        "date": "03-2016",
        "max_performance": [
            15.016054071659335,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.854672869159421,
            4.2
        ],
        "real": 3.7896774969380376
    },
    {
        "date": "04-2016",
        "max_performance": [
            14.924734086920727,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.795814853783591,
            4.2
        ],
        "real": 3.9669306686626715
    },
    {
        "date": "05-2016",
        "max_performance": [
            14.833969463768652,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.737366138739604,
            4.2
        ],
        "real": 3.8059248480560637
    },
    {
        "date": "06-2016",
        "max_performance": [
            14.743756824777096,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.679323877741172,
            4.2
        ],
        "real": 3.4740914789294752
    },
    {
        "date": "07-2016",
        "max_performance": [
            14.654092813059828,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.6216852442951755,
            4.2
        ],
        "real": 3.687894544370618
    },
    {
        "date": "08-2016",
        "max_performance": [
            14.564974092145494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.564447431564002,
            4.2
        ],
        "real": 3.9555633705731674
    },
    {
        "date": "09-2016",
        "max_performance": [
            14.476397345853455,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.507607652228877,
            4.2
        ],
        "real": 3.9148865493378704
    },
    {
        "date": "10-2016",
        "max_performance": [
            14.388359278170388,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.4511631383541115,
            4.2
        ],
        "real": 4.149291880841965
    },
    {
        "date": "11-2016",
        "max_performance": [
            14.300856613127646,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.395111141252327,
            4.2
        ],
        "real": 4.507608059268975
    },
    {
        "date": "12-2016",
        "max_performance": [
            14.213886094679351,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.339448931350594,
            4.2
        ],
        "real": 4.123491068549723
    },
    {
        "date": "01-2017",
        "max_performance": [
            14.12744448658124,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.284173798057511,
            4.2
        ],
        "real": 4.035780349395357
    },
    {
        "date": "02-2017",
        "max_performance": [
            14.041528572270238,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.22928304963121,
            4.2
        ],
        "real": 4.026871388338571
    },
    {
        "date": "03-2017",
        "max_performance": [
            13.956135154744754,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.174774013048271,
            4.2
        ],
        "real": 3.6468094603406165
    },
    {
        "date": "04-2017",
        "max_performance": [
            13.871261056445746,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.120644033873557,
            4.2
        ],
        "real": 3.8016988837380916
    },
    {
        "date": "05-2017",
        "max_performance": [
            13.786903119138461,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.066890476130945,
            4.2
        ],
        "real": 4.377084572084722
    },
    {
        "date": "06-2017",
        "max_performance": [
            13.703058203794916,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.013510722174974,
            4.2
        ],
        "real": 4.2786341501723335
    },
    {
        "date": "07-2017",
        "max_performance": [
            13.619723190477101,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.960502172563357,
            4.2
        ],
        "real": 3.9711357088437094
    },
    {
        "date": "08-2017",
        "max_performance": [
            13.536894978220868,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.907862245930406,
            4.2
        ],
        "real": 4.045458745598718
    },
    {
        "date": "09-2017",
        "max_performance": [
            13.45457048492057,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.855588378861327,
            4.2
        ],
        "real": 3.9064473655596954
    },
    {
        "date": "10-2017",
        "max_performance": [
            13.372746647214338,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.803678025767384,
            4.2
        ],
        "real": 4.120521651525033
    },
    {
        "date": "11-2017",
        "max_performance": [
            13.291420420370118,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.7521286587619365,
            4.2
        ],
        "real": 4.242543828194578
    },
    {
        "date": "12-2017",
        "max_performance": [
            13.210588778172356,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.700937767537344,
            4.2
        ],
        "real": 3.9893411161157077
    },
    {
        "date": "01-2018",
        "max_performance": [
            13.130248712809404,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.650102859242713,
            4.2
        ],
        "real": 3.958719385521725
    },
    {
        "date": "02-2018",
        "max_performance": [
            13.050397234761594,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.5996214583625115,
            4.2
        ],
        "real": 4.1548236326101495
    },
    {
        "date": "03-2018",
        "max_performance": [
            12.971031372689984,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.549491106596012,
            4.2
        ],
        "real": 4.384693059888556
    },
    {
        "date": "04-2018",
        "max_performance": [
            12.8921481733258,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.49970936273758,
            4.2
        ],
        "real": 4.705361610169367
    },
    {
        "date": "05-2018",
        "max_performance": [
            12.813744701360553,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.4502738025577955,
            4.2
        ],
        "real": 4.429071450235056
    },
    {
        "date": "06-2018",
        "max_performance": [
            12.735818039336795,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.401182018685402,
            4.2
        ],
        "real": 4.135092432325438
    },
    {
        "date": "07-2018",
        "max_performance": [
            12.658365287539576,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.3524316204900675,
            4.2
        ],
        "real": 4.634171951341748
    },
    {
        "date": "08-2018",
        "max_performance": [
            12.581383563888517,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.304020233965975,
            4.2
        ],
        "real": 4.286813223893127
    },
    {
        "date": "09-2018",
        "max_performance": [
            12.504870003830598,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.255945501616214,
            4.2
        ],
        "real": 4.492773581451304
    },
    {
        "date": "10-2018",
        "max_performance": [
            12.428821760233545,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.208205082337971,
            4.2
        ],
        "real": 4.419111545661036
    },
    {
        "date": "11-2018",
        "max_performance": [
            12.35323600327989,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.16079665130853,
            4.2
        ],
        "real": 4.790719085820094
    },
    {
        "date": "12-2018",
        "max_performance": [
            12.278109920361674,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.113717899872064,
            4.2
        ],
        "real": 4.46440881233201
    },
    {
        "date": "01-2019",
        "max_performance": [
            12.20344071597578,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.066966535427197,
            4.2
        ],
        "real": 4.6128935720408215
    },
    {
        "date": "02-2019",
        "max_performance": [
            12.12922561161992,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.020540281315373,
            4.2
        ],
        "real": 4.211026403638574
    },
    {
        "date": "03-2019",
        "max_performance": [
            12.055461845689246,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.9744368767099876,
            4.2
        ],
        "real": 4.385150641501823
    },
    {
        "date": "04-2019",
        "max_performance": [
            11.982146673373572,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.928654076506286,
            4.2
        ],
        "real": 4.379954628638605
    },
    {
        "date": "05-2019",
        "max_performance": [
            11.909277366555251,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.8831896512120365,
            4.2
        ],
        "real": 4.911612881731253
    },
    {
        "date": "06-2019",
        "max_performance": [
            11.836851213707662,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.8380413868389627,
            4.2
        ],
        "real": 5.08165465274113
    },
    {
        "date": "07-2019",
        "max_performance": [
            11.7648655197943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.7932070847949233,
            4.2
        ],
        "real": 4.635685597196426
    },
    {
        "date": "08-2019",
        "max_performance": [
            11.693317606168506,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.7486845617768547,
            4.2
        ],
        "real": 4.825729931489953
    },
    {
        "date": "09-2019",
        "max_performance": [
            11.622204810473772,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.70447164966444,
            4.2
        ],
        "real": 5.029980651703769
    },
    {
        "date": "10-2019",
        "max_performance": [
            11.551524486544695,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.660566195414536,
            4.2
        ],
        "real": 4.899281010510159
    },
    {
        "date": "11-2019",
        "max_performance": [
            11.481274004308494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.6169660609563263,
            4.2
        ],
        "real": 4.864471451263785
    },
    {
        "date": "12-2019",
        "max_performance": [
            11.411450749687159,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.573669123087194,
            4.2
        ],
        "real": 4.845677461412867
    },
    {
        "date": "01-2020",
        "max_performance": [
            11.342052124500162,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.530673273369342,
            4.2
        ],
        "real": 4.955321149951031
    },
    {
        "date": "02-2020",
        "max_performance": [
            11.273075546367783,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.487976418027102,
            4.2
        ],
        "real": 4.799647595072742
    },
    {
        "date": "03-2020",
        "max_performance": [
            11.204518448615023,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.445576477844989,
            4.2
        ],
        "real": 4.958649511754887
    },
    {
        "date": "04-2020",
        "max_performance": [
            11.136378280176093,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.403471388066438,
            4.2
        ],
        "real": 5.181938184137036
    },
    {
        "date": "05-2020",
        "max_performance": [
            11.068652505499482,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.3616590982932633,
            4.2
        ],
        "real": 5.066747341443034
    },
    {
        "date": "06-2020",
        "max_performance": [
            11.00133860445361,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.320137572385804,
            4.2
        ],
        "real": 4.777360125564312
    },
    {
        "date": "07-2020",
        "max_performance": [
            10.934434072233051,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.2789047883637754,
            4.2
        ],
        "real": 5.309091190304516
    },
    {
        "date": "08-2020",
        "max_performance": [
            10.867936419265337,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.237958738307801,
            4.2
        ],
        "real": 5.118381668542327
    },
    {
        "date": "09-2020",
        "max_performance": [
            10.801843171118305,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.197297428261636,
            4.2
        ],
        "real": 4.985140767117913
    },
    {
        "date": "10-2020",
        "max_performance": [
            10.736151868408022,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.1569188781350594,
            4.2
        ],
        "real": 5.197533510790553
    },
    {
        "date": "11-2020",
        "max_performance": [
            10.670860066707283,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.1168211216074586,
            4.2
        ],
        "real": 5.269843227559723
    },
    {
        "date": "12-2020",
        "max_performance": [
            10.605965336454632,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.0770022060320694,
            4.2
        ],
        "real": 5.448170293882737
    },
    {
        "date": "01-2021",
        "max_performance": [
            10.54146526286398,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.0374601923408906,
            4.2
        ],
        "real": 5.354806309405058
    },
    {
        "date": "02-2021",
        "max_performance": [
            10.47735744583473,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9981931549502554,
            4.2
        ],
        "real": 5.362955982608764
    },
    {
        "date": "03-2021",
        "max_performance": [
            10.413639499862468,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9591991816670586,
            4.2
        ],
        "real": 5.305365520724781
    },
    {
        "date": "04-2021",
        "max_performance": [
            10.350309053950209,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9204763735956463,
            4.2
        ],
        "real": 5.181065582331579
    },
    {
        "date": "05-2021",
        "max_performance": [
            10.287363751520157,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.882022845045333,
            4.2
        ],
        "real": 5.418808261554786
    },
    {
        "date": "06-2021",
        "max_performance": [
            10.224801250326028,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.8438367234385855,
            4.2
        ],
        "real": 5.800109042541202
    },
    {
        "date": "07-2021",
        "max_performance": [
            10.162619222365876,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.805916149219826,
            4.2
        ],
        "real": 5.724124662802654
    },
    {
        "date": "08-2021",
        "max_performance": [
            10.100815353795484,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.768259275764881,
            4.2
        ],
        "real": 5.4246052604567385
    },
    {
        "date": "09-2021",
        "max_performance": [
            10.039387344842254,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.730864269291056,
            4.2
        ],
        "real": 5.872774845902851
    },
    {
        "date": "10-2021",
        "max_performance": [
            9.97833290971963,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.69372930876783,
            4.2
        ],
        "real": 5.716976185125389
    },
    {
        "date": "11-2021",
        "max_performance": [
            9.917649776542042,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.6568525858281826,
            4.2
        ],
        "real": 5.516558439342578
    },
    {
        "date": "12-2021",
        "max_performance": [
            9.857335687240377,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.6202323046805325,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2022",
        "max_performance": [
            9.797388397477949,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.5838666820212812,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2022",
        "max_performance": [
            9.737805676566968,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.547753946947974,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2022",
        "max_performance": [
            9.67858530738556,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.5118923408730667,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2022",
        "max_performance": [
            9.619725086295263,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.476280117438279,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2022",
        "max_performance": [
            9.561222823059005,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.44091554242956,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2022",
        "max_performance": [
            9.503076340759632,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.405796893692632,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2022",
        "max_performance": [
            9.445283475718885,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.3709224610491275,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2022",
        "max_performance": [
            9.38784207741689,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.336290546213308,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2022",
        "max_performance": [
            9.330750008412148,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.3018994627093643,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2022",
        "max_performance": [
            9.274005144261976,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.2677475357892862,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2022",
        "max_performance": [
            9.217605373443476,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.2338331023513107,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2022",
        "max_performance": [
            9.161548597274958,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.2001545108589338,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2023",
        "max_performance": [
            9.105832729837841,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.1667101212604813,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2023",
        "max_performance": [
            9.050455697899038,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.133498304909246,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2023",
        "max_performance": [
            8.995415440833803,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.1005174444841788,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2023",
        "max_performance": [
            8.940709910549069,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.0677659339111263,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2023",
        "max_performance": [
            8.886337071407219,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.035242178284619,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2023",
        "max_performance": [
            8.832294900150352,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.0029445937902106,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2023",
        "max_performance": [
            8.778581385824982,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.9708716076273407,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2023",
        "max_performance": [
            8.725194529707224,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.9390216579327517,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2023",
        "max_performance": [
            8.672132345228409,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.9073931937044288,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2023",
        "max_performance": [
            8.619392857901168,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.87598467472607,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2023",
        "max_performance": [
            8.566974105245958,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.8447945714920793,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2023",
        "max_performance": [
            8.51487413671803,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.8138213651330894,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2024",
        "max_performance": [
            8.46309101363486,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.783063547341992,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2024",
        "max_performance": [
            8.411622809104003,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.752519620300491,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2024",
        "max_performance": [
            8.36046760795139,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.7221880966061636,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2024",
        "max_performance": [
            8.309623506650055,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.692067499200021,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2024",
        "max_performance": [
            8.25908861324933,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.6621563612945907,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2024",
        "max_performance": [
            8.208861047304412,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.6324532263024794,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2024",
        "max_performance": [
            8.15893893980641,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.6029566477654442,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2024",
        "max_performance": [
            8.109320433112792,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.5736651892839562,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2024",
        "max_performance": [
            8.060003680878262,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.5445774244472505,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2024",
        "max_performance": [
            8.010986847986052,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.5156919367638602,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2024",
        "max_performance": [
            7.962268110479639,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.4870073195926445,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2024",
        "max_performance": [
            7.913845655494878,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.4585221760742857,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2025",
        "max_performance": [
            7.86571768119253,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.4302351190632616,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2025",
        "max_performance": [
            7.817882396691233,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.402144771060303,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2025",
        "max_performance": [
            7.770338022000847,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.3742497641453117,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2025",
        "max_performance": [
            7.723082787956227,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.3465487399107414,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2025",
        "max_performance": [
            7.676114936151386,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.3190403493954554,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2025",
        "max_performance": [
            7.629432718874069,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.2917232530190268,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2025",
        "max_performance": [
            7.583034399040715,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.2645961205165137,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2025",
        "max_performance": [
            7.536918250131817,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.2376576308736729,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2025",
        "max_performance": [
            7.491082556127679,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.2109064722626304,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2025",
        "max_performance": [
            7.445525611444565,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.184341341978004,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2025",
        "max_performance": [
            7.400245720871229,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.157960946373462,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2025",
        "max_performance": [
            7.355241199505836,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.1317640007987246,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2026",
        "max_performance": [
            7.310510372693262,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.105749229537008,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2026",
        "max_performance": [
            7.266051575962783,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.0799153657429001,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2026",
        "max_performance": [
            7.2218631549661385,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.054261151380668,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2026",
        "max_performance": [
            7.177943465415971,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.0287853371629945,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2026",
        "max_performance": [
            7.13429087302464,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -1.003486682490145,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2026",
        "max_performance": [
            7.090903753443403,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.9783639553895465,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2026",
        "max_performance": [
            7.047780492201988,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.9534159324558025,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2026",
        "max_performance": [
            7.004919484648503,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.9286413987911115,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2026",
        "max_performance": [
            6.9623191358897305,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.9040391479461078,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2026",
        "max_performance": [
            6.919977860731781,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.8796079818611058,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2026",
        "max_performance": [
            6.877894083621109,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.8553467108077659,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2026",
        "max_performance": [
            6.836066238585876,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.831254153331149,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2027",
        "max_performance": [
            6.794492769177692,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.8073291361921919,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2027",
        "max_performance": [
            6.753172128413691,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.7835704943105681,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2027",
        "max_performance": [
            6.712102778718963,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.7599770707079498,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2027",
        "max_performance": [
            6.67128319186935,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.7365477164516743,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2027",
        "max_performance": [
            6.630711848934574,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.7132812905987884,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2027",
        "max_performance": [
            6.590387240221713,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.6901766601404877,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2027",
        "max_performance": [
            6.550307865219033,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.6672326999469456,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2027",
        "max_performance": [
            6.510472232540144,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.6444482927125192,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2027",
        "max_performance": [
            6.470878859868504,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.6218223289013416,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2027",
        "max_performance": [
            6.431526273902272,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.5993537066932899,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2027",
        "max_performance": [
            6.392413010299474,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.5770413319303329,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2027",
        "max_performance": [
            6.353537613623514,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.5548841180632396,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2028",
        "max_performance": [
            6.314898637289025,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.5328809860986792,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2028",
        "max_performance": [
            6.2764946435080295,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.5110308645466692,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2028",
        "max_performance": [
            6.2383242032364485,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.48933268936840024,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2028",
        "max_performance": [
            6.200385896120918,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.4677854039244189,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2028",
        "max_performance": [
            6.162678310445936,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.44638795892317207,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2028",
        "max_performance": [
            6.125200043081342,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.4251393123699123,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2028",
        "max_performance": [
            6.08794969943009,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.4040384295159529,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2028",
        "max_performance": [
            6.050925893376366,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.383084282808277,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2028",
        "max_performance": [
            6.014127247234003,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.3622758518395037,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2028",
        "max_performance": [
            5.977552391695223,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.34161212329819346,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2028",
        "max_performance": [
            5.941199965779678,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.3210920909195041,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2028",
        "max_performance": [
            5.9050686167838045,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.3007147554361871,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2029",
        "max_performance": [
            5.8691570002305005,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.28047912452992785,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2029",
        "max_performance": [
            5.8334637798190805,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.26038421278302204,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2029",
        "max_performance": [
            5.797987627375562,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.24042904163038736,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2029",
        "max_performance": [
            5.7627272228032425,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.22061263931191366,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2029",
        "max_performance": [
            5.727681254033564,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.20093404082513455,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2029",
        "max_performance": [
            5.6928484169773155,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.18139228787824146,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2029",
        "max_performance": [
            5.6582274154760785,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.16198642884341474,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2029",
        "max_performance": [
            5.6238169612540165,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.14271551871048072,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2029",
        "max_performance": [
            5.5896157738699275,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.12357861904089384,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2029",
        "max_performance": [
            5.555622580669599,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.10457479792203817,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2029",
        "max_performance": [
            5.521836116738457,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -0.08570312992184403,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2029",
        "max_performance": [
            5.488255124854487,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            -0.06696269604372285,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2030",
        "max_performance": [
            5.454878355441464,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            -0.048352583681816164,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2030",
        "max_performance": [
            5.421704566522439,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            -0.029871886576549933,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2030",
        "max_performance": [
            5.388732523673545,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            -0.011519704770508454,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2030",
        "max_performance": [
            5.355960999978044,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.006704855435395896,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2030",
        "max_performance": [
            5.323388775980684,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.024802681525441628,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2030",
        "max_performance": [
            5.291014639642314,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.04277465481231468,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2030",
        "max_performance": [
            5.2588373862947915,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.06062165048002832,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2030",
        "max_performance": [
            5.226855818596149,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.07834453762654192,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2030",
        "max_performance": [
            5.195068746486042,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.09594417930608046,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2030",
        "max_performance": [
            5.163474987141469,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.11342143257116577,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2030",
        "max_performance": [
            5.132073364932751,
            4.2
        ],
        "expected_performance": 3.5834,
        "min_performance": [
            0.13077714851435385,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2030",
        "max_performance": [
            5.100862711379791,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.1480121723096759,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2031",
        "max_performance": [
            5.069841865108593,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.16512734325380007,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2031",
        "max_performance": [
            5.039009671808046,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.18212349480690015,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2031",
        "max_performance": [
            5.008364984186969,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.1990014546332457,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2031",
        "max_performance": [
            4.977906661931421,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.21576204464150317,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2031",
        "max_performance": [
            4.947633571662271,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.23240608102476346,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2031",
        "max_performance": [
            4.917544586893021,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.24893437430028653,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2031",
        "max_performance": [
            4.887638587987886,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.2653477293489743,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2031",
        "max_performance": [
            4.8579144621201396,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.28164694545456204,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2031",
        "max_performance": [
            4.828371103230699,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.29783281634254166,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2031",
        "max_performance": [
            4.7990074119869695,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.3139061302188173,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2031",
        "max_performance": [
            4.7698222957419345,
            4.2
        ],
        "expected_performance": 3.105,
        "min_performance": [
            0.3298676698080856,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2031",
        "max_performance": [
            4.740814668493501,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.345718212391954,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2032",
        "max_performance": [
            4.711983450844086,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.3614585298467894,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2032",
        "max_performance": [
            4.683327569960453,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.3770893886813105,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2032",
        "max_performance": [
            4.654845959533794,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.39261155007391,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2032",
        "max_performance": [
            4.626537559740037,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4080257699097274,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2032",
        "max_performance": [
            4.59840131720043,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.423332798817452,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2032",
        "max_performance": [
            4.570436184942331,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4385333822058808,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2032",
        "max_performance": [
            4.542641122360248,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4536282603002171,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2032",
        "max_performance": [
            4.515015095177125,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4686181681781161,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2032",
        "max_performance": [
            4.48755707540585,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4835038358054824,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2032",
        "max_performance": [
            4.460266041311005,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.4982859880720163,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2032",
        "max_performance": [
            4.433140977370846,
            4.2
        ],
        "expected_performance": 2.4472,
        "min_performance": [
            0.5129653448265143,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2032",
        "max_performance": [
            4.406180874239515,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5275426209119232,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2033",
        "max_performance": [
            4.379384728709478,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5420185262001529,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2033",
        "max_performance": [
            4.3527515436741995,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5563937656266429,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2033",
        "max_performance": [
            4.32628032809104,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5706690392246903,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2033",
        "max_performance": [
            4.2999700969443735,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5848450421595417,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2033",
        "max_performance": [
            4.273819871208935,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.5989224647622442,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2033",
        "max_performance": [
            4.247828677813398,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.6129019925632633,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2033",
        "max_performance": [
            4.221995549604152,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.6267843063258656,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2033",
        "max_performance": [
            4.1963195253093275,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.6405700820792697,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2033",
        "max_performance": [
            4.17079964950302,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.6542599911515683,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2033",
        "max_performance": [
            4.145434972569733,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.66785470020242,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2033",
        "max_performance": [
            4.120224550669053,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.681354871255512,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2033",
        "max_performance": [
            4.095167445700521,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.6947611617307998,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2034",
        "max_performance": [
            4.070262725268725,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7080742244765212,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2034",
        "max_performance": [
            4.0455094626486074,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7212947078009897,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2034",
        "max_performance": [
            4.020906736750981,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7344232555041632,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2034",
        "max_performance": [
            3.996453632088254,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7474605069089966,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2034",
        "max_performance": [
            3.9721492387403616,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7604070968925738,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2034",
        "max_performance": [
            3.9479926523209072,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7732636559170272,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2034",
        "max_performance": [
            3.923982973943515,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7860308100602356,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2034",
        "max_performance": [
            3.9001193101883747,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.7987091810463143,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2034",
        "max_performance": [
            3.876400773069003,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8112993862758917,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2034",
        "max_performance": [
            3.852826479999194,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8238020388561749,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2034",
        "max_performance": [
            3.829395553760185,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8362177476308048,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2034",
        "max_performance": [
            3.8061071224680068,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8485471172095069,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2035",
        "max_performance": [
            3.782960319541046,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8607907479975327,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2035",
        "max_performance": [
            3.759954283667798,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8729492362248985,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2035",
        "max_performance": [
            3.7370881587748115,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8850231739754211,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2035",
        "max_performance": [
            3.714361093994841,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.8970131492155475,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2035",
        "max_performance": [
            3.6917722436351808,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9089197458229905,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2035",
        "max_performance": [
            3.669320767146196,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9207435436151594,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2035",
        "max_performance": [
            3.647005829090048,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9324851183773977,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2035",
        "max_performance": [
            3.6248265991096043,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.94414504189102,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2035",
        "max_performance": [
            3.60278225189754,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.955723881961158,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2035",
        "max_performance": [
            3.5808719671656304,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9672222024444097,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2035",
        "max_performance": [
            3.5590949296142256,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9786405632762976,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2035",
        "max_performance": [
            3.5374503289019086,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            0.9899795204985387,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2036",
        "max_performance": [
            3.515937359615351,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0012396262861172,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2036",
        "max_performance": [
            3.4945552212393336,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.012421428974178,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2036",
        "max_performance": [
            3.473303118126966,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0235254730847276,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2036",
        "max_performance": [
            3.4521802594700737,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0345522993531504,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2036",
        "max_performance": [
            3.4311858592697755,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0455024447545413,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2036",
        "max_performance": [
            3.4103191363072356,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0563764425298556,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2036",
        "max_performance": [
            3.3895793141145916,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0671748222118742,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2036",
        "max_performance": [
            3.3689656209460614,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0778981096509945,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2036",
        "max_performance": [
            3.3484772897492303,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0885468270408323,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2036",
        "max_performance": [
            3.328113558136503,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.0991214929436557,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2036",
        "max_performance": [
            3.307873668356738,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.1096226223156351,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2036",
        "max_performance": [
            3.287756867267049,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.120050726531921,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2037",
        "max_performance": [
            3.2677624063047825,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.1304063134115467,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2037",
        "max_performance": [
            3.247889541459659,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.140689887242157,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2037",
        "max_performance": [
            3.228137533246093,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.150901948804566,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2037",
        "max_performance": [
            3.2085056466756705,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.161042995397143,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2037",
        "max_performance": [
            3.1889931512298024,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.1711135208600323,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2037",
        "max_performance": [
            3.169599320832544,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.1811140155991973,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2037",
        "max_performance": [
            3.1503234338235715,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.1910449666103051,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2037",
        "max_performance": [
            3.1311647729313328,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2009068575024409,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2037",
        "max_performance": [
            3.1121226252463545,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2107001685216583,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2037",
        "max_performance": [
            3.0931962821947163,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.220425376574366,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2037",
        "max_performance": [
            3.074385039511683,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2300829552505523,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2037",
        "max_performance": [
            3.0556881972154977,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2396733748468465,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2038",
        "max_performance": [
            3.0371050595813367,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2491971023894213,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2038",
        "max_performance": [
            3.018634935115418,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2586546016567375,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2038",
        "max_performance": [
            3.000277136529274,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.268046333202126,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2038",
        "max_performance": [
            2.982030980714175,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2773727543762163,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2038",
        "max_performance": [
            2.9638957887157105,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.286634319349209,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2038",
        "max_performance": [
            2.945870885708523,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.2958314791329921,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2038",
        "max_performance": [
            2.9279556009712002,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3049646816031037,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2038",
        "max_performance": [
            2.910149267861316,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3140343715205431,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2038",
        "max_performance": [
            2.892451223790621,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3230409905534277,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2038",
        "max_performance": [
            2.874860810200393,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3319849772985026,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2038",
        "max_performance": [
            2.857377372536925,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3408667673024999,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2038",
        "max_performance": [
            2.8400002602271748,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3496867930833452,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2039",
        "max_performance": [
            2.8227288266545507,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3584454841512228,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2039",
        "max_performance": [
            2.8055624291348575,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3671432670294907,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2039",
        "max_performance": [
            2.7885004288923736,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.375780565275452,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2039",
        "max_performance": [
            2.771542191036087,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.3843577995009788,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2039",
        "max_performance": [
            2.754687084536071,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.392875387392997,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2039",
        "max_performance": [
            2.7379344821999996,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.401333743733825,
            4.2
        ],
        "real": null
    },
    {
        "date": "07-2039",
        "max_performance": [
            2.721283760649808,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4097332804213742,
            4.2
        ],
        "real": null
    },
    {
        "date": "08-2039",
        "max_performance": [
            2.7047343002985045,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4180744064892052,
            4.2
        ],
        "real": null
    },
    {
        "date": "09-2039",
        "max_performance": [
            2.688285485327106,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4263575281264473,
            4.2
        ],
        "real": null
    },
    {
        "date": "10-2039",
        "max_performance": [
            2.6719367036617263,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.434583048697579,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2039",
        "max_performance": [
            2.6556873469508027,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4427513687620712,
            4.2
        ],
        "real": null
    },
    {
        "date": "12-2039",
        "max_performance": [
            2.639536810542454,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4508628860938921,
            4.2
        ],
        "real": null
    },
    {
        "date": "01-2040",
        "max_performance": [
            2.6234844934619863,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4589179957008784,
            4.2
        ],
        "real": null
    },
    {
        "date": "02-2040",
        "max_performance": [
            2.6075297983895247,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4669170898439714,
            4.2
        ],
        "real": null
    },
    {
        "date": "03-2040",
        "max_performance": [
            2.5916721316377913,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4748605580563174,
            4.2
        ],
        "real": null
    },
    {
        "date": "04-2040",
        "max_performance": [
            2.575910903130009,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4827487871622396,
            4.2
        ],
        "real": null
    },
    {
        "date": "05-2040",
        "max_performance": [
            2.5602455263779493,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.4905821612960726,
            4.2
        ],
        "real": null
    },
    {
        "date": "06-2040",
        "max_performance": [
            2.544675418460105,
            4.2
        ],
        "expected_performance": 1.8492,
        "min_performance": [
            1.49836106192087,
            4.2
        ],
        "real": null
    },
    {
        "date": "11-2001",
        "real": 10.210000000000008,
        "min_performance": [
            null,
            4.2
        ],
        "max_performance": [
            null,
            4.2
        ]
    }
]

export const lineChartPerformanceDataMock = [
    {
        "date": "12-2001",
        "max_performance": [
            42.616,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -25.304000000000002,
            4.2
        ],
        "real": 7.933394628986323
    },
    {
        "date": "01-2002",
        "max_performance": [
            42.356831216306986,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -25.10989080434148,
            4.2
        ],
        "real": 12.953415383949185
    },
    {
        "date": "02-2002",
        "max_performance": [
            42.09923856501591,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.917131449612665,
            4.2
        ],
        "real": 3.768119055279362
    },
    {
        "date": "03-2002",
        "max_performance": [
            41.84321246089311,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.725712548980912,
            4.2
        ],
        "real": 11.918572920548653
    },
    {
        "date": "04-2002",
        "max_performance": [
            41.58874337699744,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.535624780889872,
            4.2
        ],
        "real": 12.902014850822852
    },
    {
        "date": "05-2002",
        "max_performance": [
            41.33582184432574,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.34685888860557,
            4.2
        ],
        "real": 11.304697881579461
    },
    {
        "date": "06-2002",
        "max_performance": [
            41.0844384514605,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -24.15940567976563,
            4.2
        ],
        "real": 8.29887564892655
    },
    {
        "date": "07-2002",
        "max_performance": [
            40.834583844219665,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.973256025931622,
            4.2
        ],
        "real": 13.52131956040541
    },
    {
        "date": "08-2002",
        "max_performance": [
            40.586248725308536,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.788400862144535,
            4.2
        ],
        "real": 17.3145695667857
    },
    {
        "date": "09-2002",
        "max_performance": [
            40.33942385397382,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.604831186483352,
            4.2
        ],
        "real": 19.073688724488314
    },
    {
        "date": "10-2002",
        "max_performance": [
            40.09410004565978,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.422538059626678,
            4.2
        ],
        "real": 12.06724879503287
    },
    {
        "date": "11-2002",
        "max_performance": [
            39.85026817166645,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.2415126044174,
            4.2
        ],
        "real": 13.56193265861847
    },
    {
        "date": "12-2002",
        "max_performance": [
            39.607919158809985,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -23.061746005430432,
            4.2
        ],
        "real": 9.35933246380667
    },
    {
        "date": "01-2003",
        "max_performance": [
            39.367043989085,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.883229508543383,
            4.2
        ],
        "real": 9.971041415281135
    },
    {
        "date": "02-2003",
        "max_performance": [
            39.12763369932903,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.705954420510302,
            4.2
        ],
        "real": 17.494016611757445
    },
    {
        "date": "03-2003",
        "max_performance": [
            38.889679380888985,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.529912108538312,
            4.2
        ],
        "real": 13.75974638697648
    },
    {
        "date": "04-2003",
        "max_performance": [
            38.653172179289676,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.355093999867226,
            4.2
        ],
        "real": 11.965036630104375
    },
    {
        "date": "05-2003",
        "max_performance": [
            38.4181032939043,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.18149158135207,
            4.2
        ],
        "real": 5.961803569389246
    },
    {
        "date": "06-2003",
        "max_performance": [
            38.184463977627,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -22.009096399048545,
            4.2
        ],
        "real": 9.028795941437263
    },
    {
        "date": "07-2003",
        "max_performance": [
            37.95224553654732,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.837900057801306,
            4.2
        ],
        "real": 10.09173519608102
    },
    {
        "date": "08-2003",
        "max_performance": [
            37.72143932962679,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.667894220835162,
            4.2
        ],
        "real": 13.180621263241509
    },
    {
        "date": "09-2003",
        "max_performance": [
            37.49203676837728,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.49907060934911,
            4.2
        ],
        "real": 8.190779040930662
    },
    {
        "date": "10-2003",
        "max_performance": [
            37.26402931654149,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.33142100211315,
            4.2
        ],
        "real": 10.12175071378143
    },
    {
        "date": "11-2003",
        "max_performance": [
            37.03740848977528,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -21.16493723506796,
            4.2
        ],
        "real": 9.639660360080725
    },
    {
        "date": "12-2003",
        "max_performance": [
            36.81216585533196,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.999611200927326,
            4.2
        ],
        "real": 11.58445784141422
    },
    {
        "date": "01-2004",
        "max_performance": [
            36.58829303174853,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.835434848783322,
            4.2
        ],
        "real": 9.48482665998105
    },
    {
        "date": "02-2004",
        "max_performance": [
            36.36578168853373,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.672400183714284,
            4.2
        ],
        "real": 11.036754052569918
    },
    {
        "date": "03-2004",
        "max_performance": [
            36.14462354585814,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.510499266395446,
            4.2
        ],
        "real": 7.58276402201663
    },
    {
        "date": "04-2004",
        "max_performance": [
            35.92481037424602,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.34972421271235,
            4.2
        ],
        "real": 10.720642057683417
    },
    {
        "date": "05-2004",
        "max_performance": [
            35.70633399426913,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.19006719337689,
            4.2
        ],
        "real": 5.92742795689889
    },
    {
        "date": "06-2004",
        "max_performance": [
            35.4891862762423,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -20.03152043354605,
            4.2
        ],
        "real": 6.83714313775754
    },
    {
        "date": "07-2004",
        "max_performance": [
            35.273359139921006,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.874076212443303,
            4.2
        ],
        "real": 6.62486536973057
    },
    {
        "date": "08-2004",
        "max_performance": [
            35.05884455420061,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.717726862982627,
            4.2
        ],
        "real": 5.284020880177209
    },
    {
        "date": "09-2004",
        "max_performance": [
            34.8456345368176,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.562464771395124,
            4.2
        ],
        "real": 7.40133635557203
    },
    {
        "date": "10-2004",
        "max_performance": [
            34.63372115405248,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.408282376858278,
            4.2
        ],
        "real": 4.207696021103602
    },
    {
        "date": "11-2004",
        "max_performance": [
            34.42309652043462,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.255172171127736,
            4.2
        ],
        "real": 5.099842818263278
    },
    {
        "date": "12-2004",
        "max_performance": [
            34.21375279844879,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -19.103126698171707,
            4.2
        ],
        "real": 7.218455469456542
    },
    {
        "date": "01-2005",
        "max_performance": [
            34.005682198243534,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.95213855380785,
            4.2
        ],
        "real": 4.418395367531969
    },
    {
        "date": "02-2005",
        "max_performance": [
            33.798876977341294,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.802200385342722,
            4.2
        ],
        "real": 5.6090290895774775
    },
    {
        "date": "03-2005",
        "max_performance": [
            33.59332944035032,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.65330489121373,
            4.2
        ],
        "real": 7.9807838590033375
    },
    {
        "date": "04-2005",
        "max_performance": [
            33.38903193867831,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.50544482063354,
            4.2
        ],
        "real": 5.854428999893879
    },
    {
        "date": "05-2005",
        "max_performance": [
            33.185976870247806,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.35861297323702,
            4.2
        ],
        "real": 6.096759868462187
    },
    {
        "date": "06-2005",
        "max_performance": [
            32.9841566792133,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.212802198730568,
            4.2
        ],
        "real": 5.127470385483757
    },
    {
        "date": "07-2005",
        "max_performance": [
            32.78356385568008,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -18.068005396543942,
            4.2
        ],
        "real": 6.3186153214224605
    },
    {
        "date": "08-2005",
        "max_performance": [
            32.58419093542481,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.924215515484473,
            4.2
        ],
        "real": 6.973866298966924
    },
    {
        "date": "09-2005",
        "max_performance": [
            32.38603049961772,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.78142555339367,
            4.2
        ],
        "real": 6.70460566542701
    },
    {
        "date": "10-2005",
        "max_performance": [
            32.1890751745466,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.639628556806272,
            4.2
        ],
        "real": 6.433867600846832
    },
    {
        "date": "11-2005",
        "max_performance": [
            31.9933176313424,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.498817620611604,
            4.2
        ],
        "real": 7.6851516695595645
    },
    {
        "date": "12-2005",
        "max_performance": [
            31.798750585706507,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.358985887717335,
            4.2
        ],
        "real": 5.606894241175953
    },
    {
        "date": "01-2006",
        "max_performance": [
            31.6053667976397,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.220126548715548,
            4.2
        ],
        "real": 6.443115702897972
    },
    {
        "date": "02-2006",
        "max_performance": [
            31.41315907117275,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -17.082232841551143,
            4.2
        ],
        "real": 6.023495290205783
    },
    {
        "date": "03-2006",
        "max_performance": [
            31.22212025409862,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.945298051192555,
            4.2
        ],
        "real": 6.449830429009484
    },
    {
        "date": "04-2006",
        "max_performance": [
            31.03224323770638,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.809315509304735,
            4.2
        ],
        "real": 6.186528984695983
    },
    {
        "date": "05-2006",
        "max_performance": [
            30.843520956516638,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.67427859392442,
            4.2
        ],
        "real": 5.074505178005939
    },
    {
        "date": "06-2006",
        "max_performance": [
            30.65594638801865,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.540180729137685,
            4.2
        ],
        "real": 4.934572869052567
    },
    {
        "date": "07-2006",
        "max_performance": [
            30.469512552408993,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.407015384759696,
            4.2
        ],
        "real": 6.914078460769613
    },
    {
        "date": "08-2006",
        "max_performance": [
            30.284212512331866,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.2747760760167,
            4.2
        ],
        "real": 6.753420993412829
    },
    {
        "date": "09-2006",
        "max_performance": [
            30.100039372620923,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.14345636323026,
            4.2
        ],
        "real": 4.506667017786237
    },
    {
        "date": "10-2006",
        "max_performance": [
            29.91698628004269,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -16.01304985150365,
            4.2
        ],
        "real": 3.867174566569287
    },
    {
        "date": "11-2006",
        "max_performance": [
            29.735046423041577,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.883550190410423,
            4.2
        ],
        "real": 6.516562064198594
    },
    {
        "date": "12-2006",
        "max_performance": [
            29.554213031486416,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.754951073685197,
            4.2
        ],
        "real": 6.0764450586330065
    },
    {
        "date": "01-2007",
        "max_performance": [
            29.374479376418492,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.627246238916532,
            4.2
        ],
        "real": 6.302913315406133
    },
    {
        "date": "02-2007",
        "max_performance": [
            29.195838769801217,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.500429467241984,
            4.2
        ],
        "real": 4.414183768446067
    },
    {
        "date": "03-2007",
        "max_performance": [
            29.018284564271216,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.37449458304525,
            4.2
        ],
        "real": 4.145462612218798
    },
    {
        "date": "04-2007",
        "max_performance": [
            28.841810152890982,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.249435453655446,
            4.2
        ],
        "real": 5.128490361504445
    },
    {
        "date": "05-2007",
        "max_performance": [
            28.666408968903056,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.125245989048457,
            4.2
        ],
        "real": 4.527187849069092
    },
    {
        "date": "06-2007",
        "max_performance": [
            28.492074485485627,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -15.001920141550363,
            4.2
        ],
        "real": 4.572888574767675
    },
    {
        "date": "07-2007",
        "max_performance": [
            28.318800215509704,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.879451905542956,
            4.2
        ],
        "real": 5.749659010867547
    },
    {
        "date": "08-2007",
        "max_performance": [
            28.146579711297694,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.757835317171258,
            4.2
        ],
        "real": 4.689975982641914
    },
    {
        "date": "09-2007",
        "max_performance": [
            27.975406564383494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.63706445405312,
            4.2
        ],
        "real": 4.756162685906307
    },
    {
        "date": "10-2007",
        "max_performance": [
            27.80527440527403,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.517133434990795,
            4.2
        ],
        "real": 5.941932181273568
    },
    {
        "date": "11-2007",
        "max_performance": [
            27.63617690321224,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.398036419684567,
            4.2
        ],
        "real": 3.9588298661495713
    },
    {
        "date": "12-2007",
        "max_performance": [
            27.46810776594149,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.279767608448333,
            4.2
        ],
        "real": 4.315438671453409
    },
    {
        "date": "01-2008",
        "max_performance": [
            27.301060739471435,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.162321241927158,
            4.2
        ],
        "real": 5.16671999092333
    },
    {
        "date": "02-2008",
        "max_performance": [
            27.13502960784534,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -14.04569160081685,
            4.2
        ],
        "real": 5.444963787429202
    },
    {
        "date": "03-2008",
        "max_performance": [
            26.970008192908715,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.929873005585398,
            4.2
        ],
        "real": 4.4534364341830335
    },
    {
        "date": "04-2008",
        "max_performance": [
            26.805990354079476,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.81485981619644,
            4.2
        ],
        "real": 4.634030697559965
    },
    {
        "date": "05-2008",
        "max_performance": [
            26.642969988119432,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.700646431834587,
            4.2
        ],
        "real": 4.901578938604834
    },
    {
        "date": "06-2008",
        "max_performance": [
            26.480941028907157,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.587227290632667,
            4.2
        ],
        "real": 3.8994606844261037
    },
    {
        "date": "07-2008",
        "max_performance": [
            26.319897447212295,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.4745968694009,
            4.2
        ],
        "real": 4.586285357161413
    },
    {
        "date": "08-2008",
        "max_performance": [
            26.159833250471188,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.362749683357936,
            4.2
        ],
        "real": 4.562415909620343
    },
    {
        "date": "09-2008",
        "max_performance": [
            26.000742482563908,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.251680285863738,
            4.2
        ],
        "real": 4.490519055204745
    },
    {
        "date": "10-2008",
        "max_performance": [
            25.8426192235926,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.14138326815437,
            4.2
        ],
        "real": 4.795304777887277
    },
    {
        "date": "11-2008",
        "max_performance": [
            25.68545758966121,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -13.03185325907859,
            4.2
        ],
        "real": 5.049467814627295
    },
    {
        "date": "12-2008",
        "max_performance": [
            25.529251732656544,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.923084924836298,
            4.2
        ],
        "real": 4.846391615685741
    },
    {
        "date": "01-2009",
        "max_performance": [
            25.373995840030645,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.8150729687188,
            4.2
        ],
        "real": 4.124847766057282
    },
    {
        "date": "02-2009",
        "max_performance": [
            25.21968413458451,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.707812130850852,
            4.2
        ],
        "real": 4.599126578442947
    },
    {
        "date": "03-2009",
        "max_performance": [
            25.06631087425313,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.601297187934552,
            4.2
        ],
        "real": 5.572222409683514
    },
    {
        "date": "04-2009",
        "max_performance": [
            24.913870351891784,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.495522952994945,
            4.2
        ],
        "real": 5.1354675318725285
    },
    {
        "date": "05-2009",
        "max_performance": [
            24.76235689506371,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.390484275127461,
            4.2
        ],
        "real": 4.616023181025186
    },
    {
        "date": "06-2009",
        "max_performance": [
            24.61176486582901,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.286176039247058,
            4.2
        ],
        "real": 4.964032900753024
    },
    {
        "date": "07-2009",
        "max_performance": [
            24.462088660534864,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.182593165839155,
            4.2
        ],
        "real": 4.047261023716242
    },
    {
        "date": "08-2009",
        "max_performance": [
            24.31332270960701,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -12.079730610712252,
            4.2
        ],
        "real": 4.987400119045393
    },
    {
        "date": "09-2009",
        "max_performance": [
            24.165461477342493,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.977583364752304,
            4.2
        ],
        "real": 4.239892938535728
    },
    {
        "date": "10-2009",
        "max_performance": [
            24.01849946170369,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.876146453678789,
            4.2
        ],
        "real": 4.499331668186524
    },
    {
        "date": "11-2009",
        "max_performance": [
            23.872431194113563,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.775414937802477,
            4.2
        ],
        "real": 4.362180091209167
    },
    {
        "date": "12-2009",
        "max_performance": [
            23.72725123925216,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.675383911784879,
            4.2
        ],
        "real": 4.420688726159816
    },
    {
        "date": "01-2010",
        "max_performance": [
            23.582954194854388,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.576048504399367,
            4.2
        ],
        "real": 4.0173944748264745
    },
    {
        "date": "02-2010",
        "max_performance": [
            23.439534691508964,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.477403878293966,
            4.2
        ],
        "real": 4.986262474435477
    },
    {
        "date": "03-2010",
        "max_performance": [
            23.29698739245864,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.379445229755788,
            4.2
        ],
        "real": 4.247308176803188
    },
    {
        "date": "04-2010",
        "max_performance": [
            23.155306993401602,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.282167788477098,
            4.2
        ],
        "real": 3.8067766057133356
    },
    {
        "date": "05-2010",
        "max_performance": [
            23.014488222294077,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.18556681732302,
            4.2
        ],
        "real": 5.199444857606594
    },
    {
        "date": "06-2010",
        "max_performance": [
            22.8745258391542,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -11.08963761210085,
            4.2
        ],
        "real": 4.397579941195251
    },
    {
        "date": "07-2010",
        "max_performance": [
            22.73541463586698,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.994375501330975,
            4.2
        ],
        "real": 4.276148441421768
    },
    {
        "date": "08-2010",
        "max_performance": [
            22.597149435990552,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.899775846019391,
            4.2
        ],
        "real": 4.0918482964467096
    },
    {
        "date": "09-2010",
        "max_performance": [
            22.459725094563506,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.80583403943178,
            4.2
        ],
        "real": 4.131374413843569
    },
    {
        "date": "10-2010",
        "max_performance": [
            22.323136497913485,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.712545506869201,
            4.2
        ],
        "real": 4.040238888522829
    },
    {
        "date": "11-2010",
        "max_performance": [
            22.187378563466858,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.619905705445287,
            4.2
        ],
        "real": 3.6085394882785504
    },
    {
        "date": "12-2010",
        "max_performance": [
            22.052446239559636,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.527910123865047,
            4.2
        ],
        "real": 3.991465442062969
    },
    {
        "date": "01-2011",
        "max_performance": [
            21.91833450524947,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.436554282205154,
            4.2
        ],
        "real": 4.24440614573256
    },
    {
        "date": "02-2011",
        "max_performance": [
            21.785038370128806,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.345833731695805,
            4.2
        ],
        "real": 3.9131761887473138
    },
    {
        "date": "03-2011",
        "max_performance": [
            21.65255287413923,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.255744054504063,
            4.2
        ],
        "real": 3.8092050320221116
    },
    {
        "date": "04-2011",
        "max_performance": [
            21.52087308738685,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.166280863518734,
            4.2
        ],
        "real": 4.403460670445991
    },
    {
        "date": "05-2011",
        "max_performance": [
            21.389994109958895,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -10.077439802136718,
            4.2
        ],
        "real": 3.8291458891948293
    },
    {
        "date": "06-2011",
        "max_performance": [
            21.259911071741357,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.989216544050858,
            4.2
        ],
        "real": 4.356467242879081
    },
    {
        "date": "07-2011",
        "max_performance": [
            21.13061913223777,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.901606793039267,
            4.2
        ],
        "real": 3.673781252944708
    },
    {
        "date": "08-2011",
        "max_performance": [
            21.002113480389113,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.814606282756102,
            4.2
        ],
        "real": 3.7475439841638147
    },
    {
        "date": "09-2011",
        "max_performance": [
            20.874389334394767,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.728210776523815,
            4.2
        ],
        "real": 4.784063289586826
    },
    {
        "date": "10-2011",
        "max_performance": [
            20.747441941534586,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.642416067126831,
            4.2
        ],
        "real": 4.736012115624133
    },
    {
        "date": "11-2011",
        "max_performance": [
            20.621266577992042,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.557217976606681,
            4.2
        ],
        "real": 4.096868545861088
    },
    {
        "date": "12-2011",
        "max_performance": [
            20.495858548678463,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.472612356058534,
            4.2
        ],
        "real": 3.70015939758106
    },
    {
        "date": "01-2012",
        "max_performance": [
            20.3712131870583,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.388595085429165,
            4.2
        ],
        "real": 4.236424241334191
    },
    {
        "date": "02-2012",
        "max_performance": [
            20.247325854975486,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.305162073316312,
            4.2
        ],
        "real": 3.5976689931684014
    },
    {
        "date": "03-2012",
        "max_performance": [
            20.12419194248088,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.222309256769451,
            4.2
        ],
        "real": 4.352690757440358
    },
    {
        "date": "04-2012",
        "max_performance": [
            20.001806867660687,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.140032601091924,
            4.2
        ],
        "real": 4.068808920741951
    },
    {
        "date": "05-2012",
        "max_performance": [
            19.88016607646597,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -9.058328099644479,
            4.2
        ],
        "real": 4.260724659858517
    },
    {
        "date": "06-2012",
        "max_performance": [
            19.7592650425432,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.977191773650139,
            4.2
        ],
        "real": 3.7181771953349374
    },
    {
        "date": "07-2012",
        "max_performance": [
            19.639099267065824,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.896619672000462,
            4.2
        ],
        "real": 3.7065620744389127
    },
    {
        "date": "08-2012",
        "max_performance": [
            19.51966427856686,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.816607871063125,
            4.2
        ],
        "real": 3.240713987792443
    },
    {
        "date": "09-2012",
        "max_performance": [
            19.400955632772508,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.737152474490864,
            4.2
        ],
        "real": 3.6652077264108796
    },
    {
        "date": "10-2012",
        "max_performance": [
            19.282968912436775,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.658249613031714,
            4.2
        ],
        "real": 3.620782264728839
    },
    {
        "date": "11-2012",
        "max_performance": [
            19.165699727177095,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.57989544434061,
            4.2
        ],
        "real": 4.1527341142483465
    },
    {
        "date": "12-2012",
        "max_performance": [
            19.049143713310986,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.502086152792259,
            4.2
        ],
        "real": 3.2395045914424436
    },
    {
        "date": "01-2013",
        "max_performance": [
            18.933296533693657,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.424817949295338,
            4.2
        ],
        "real": 3.404055801649153
    },
    {
        "date": "02-2013",
        "max_performance": [
            18.818153877556608,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.34808707110798,
            4.2
        ],
        "real": 4.060024896854109
    },
    {
        "date": "03-2013",
        "max_performance": [
            18.703711460347243,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.271889781654519,
            4.2
        ],
        "real": 3.2659534098731458
    },
    {
        "date": "04-2013",
        "max_performance": [
            18.58996502356943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.196222370343555,
            4.2
        ],
        "real": 3.6869669936953953
    },
    {
        "date": "05-2013",
        "max_performance": [
            18.47691033462504,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.121081152387248,
            4.2
        ],
        "real": 4.3768215544597755
    },
    {
        "date": "06-2013",
        "max_performance": [
            18.364543186656448,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -8.046462468621872,
            4.2
        ],
        "real": 3.831913172924306
    },
    {
        "date": "07-2013",
        "max_performance": [
            18.252859398389987,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.972362685329633,
            4.2
        ],
        "real": 3.7030675225603993
    },
    {
        "date": "08-2013",
        "max_performance": [
            18.141854813980366,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.898778194061718,
            4.2
        ],
        "real": 3.509156978013861
    },
    {
        "date": "09-2013",
        "max_performance": [
            18.031525302856032,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.825705411462565,
            4.2
        ],
        "real": 3.2972551873964084
    },
    {
        "date": "10-2013",
        "max_performance": [
            17.921866759565464,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.753140779095379,
            4.2
        ],
        "real": 3.4930881400358826
    },
    {
        "date": "11-2013",
        "max_performance": [
            17.81287510362439,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.681080763268826,
            4.2
        ],
        "real": 3.4613109040855683
    },
    {
        "date": "12-2013",
        "max_performance": [
            17.704546279363974,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.609521854864969,
            4.2
        ],
        "real": 3.9237140528144643
    },
    {
        "date": "01-2014",
        "max_performance": [
            17.596876255779886,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.538460569168381,
            4.2
        ],
        "real": 3.728359347895016
    },
    {
        "date": "02-2014",
        "max_performance": [
            17.489861026382314,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.46789344569644,
            4.2
        ],
        "real": 3.6501987727478147
    },
    {
        "date": "03-2014",
        "max_performance": [
            17.383496609046873,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.397817048030822,
            4.2
        ],
        "real": 3.4804026012303257
    },
    {
        "date": "04-2014",
        "max_performance": [
            17.277779045866414,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.328227963650155,
            4.2
        ],
        "real": 3.631656453423826
    },
    {
        "date": "05-2014",
        "max_performance": [
            17.172704403003785,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.259122803763843,
            4.2
        ],
        "real": 4.0717247629731945
    },
    {
        "date": "06-2014",
        "max_performance": [
            17.06826877054541,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.190498203147026,
            4.2
        ],
        "real": 3.7330526939699737
    },
    {
        "date": "07-2014",
        "max_performance": [
            16.96446826235582,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.122350819976729,
            4.2
        ],
        "real": 3.4693487110557752
    },
    {
        "date": "08-2014",
        "max_performance": [
            16.86129901593303,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -7.0546773356690995,
            4.2
        ],
        "real": 3.5429621908836806
    },
    {
        "date": "09-2014",
        "max_performance": [
            16.75875719226484,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.987474454717814,
            4.2
        ],
        "real": 3.385586735156565
    },
    {
        "date": "10-2014",
        "max_performance": [
            16.656838975685954,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.920738904533602,
            4.2
        ],
        "real": 3.385325028316122
    },
    {
        "date": "11-2014",
        "max_performance": [
            16.555540573736007,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.854467435284862,
            4.2
        ],
        "real": 3.461885627478134
    },
    {
        "date": "12-2014",
        "max_performance": [
            16.45485821701845,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.788656819739426,
            4.2
        ],
        "real": 4.089000565696277
    },
    {
        "date": "01-2015",
        "max_performance": [
            16.354788159060277,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.723303853107382,
            4.2
        ],
        "real": 3.1338743934920865
    },
    {
        "date": "02-2015",
        "max_performance": [
            16.255326676172615,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.658405352885023,
            4.2
        ],
        "real": 3.789371577788092
    },
    {
        "date": "03-2015",
        "max_performance": [
            16.15647006731218,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.593958158699863,
            4.2
        ],
        "real": 3.086746500922688
    },
    {
        "date": "04-2015",
        "max_performance": [
            16.058214653943544,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.529959132156744,
            4.2
        ],
        "real": 3.3392023596123366
    },
    {
        "date": "05-2015",
        "max_performance": [
            15.960556779902248,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.4664051566849885,
            4.2
        ],
        "real": 3.859937268307201
    },
    {
        "date": "06-2015",
        "max_performance": [
            15.863492811258768,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.403293137386646,
            4.2
        ],
        "real": 4.030024078504479
    },
    {
        "date": "07-2015",
        "max_performance": [
            15.767019136183285,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.3406200008857745,
            4.2
        ],
        "real": 3.8449475055768234
    },
    {
        "date": "08-2015",
        "max_performance": [
            15.67113216481129,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.278382695178781,
            4.2
        ],
        "real": 3.694889232474474
    },
    {
        "date": "09-2015",
        "max_performance": [
            15.57582832910999,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.216578189485782,
            4.2
        ],
        "real": 3.6118687093848134
    },
    {
        "date": "10-2015",
        "max_performance": [
            15.481104082745556,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.155203474103038,
            4.2
        ],
        "real": 4.007873530218853
    },
    {
        "date": "11-2015",
        "max_performance": [
            15.38695590095116,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.094255560256366,
            4.2
        ],
        "real": 3.9989910367449477
    },
    {
        "date": "12-2015",
        "max_performance": [
            15.29338028039579,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -6.033731479955609,
            4.2
        ],
        "real": 3.6970938552739696
    },
    {
        "date": "01-2016",
        "max_performance": [
            15.200373739053928,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.973628285850101,
            4.2
        ],
        "real": 3.984534916544047
    },
    {
        "date": "02-2016",
        "max_performance": [
            15.107932816075943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.913943051085129,
            4.2
        ],
        "real": 3.8395190584313177
    },
    {
        "date": "03-2016",
        "max_performance": [
            15.016054071659335,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.854672869159421,
            4.2
        ],
        "real": 3.7896774969380376
    },
    {
        "date": "04-2016",
        "max_performance": [
            14.924734086920727,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.795814853783591,
            4.2
        ],
        "real": 3.9669306686626715
    },
    {
        "date": "05-2016",
        "max_performance": [
            14.833969463768652,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.737366138739604,
            4.2
        ],
        "real": 3.8059248480560637
    },
    {
        "date": "06-2016",
        "max_performance": [
            14.743756824777096,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.679323877741172,
            4.2
        ],
        "real": 3.4740914789294752
    },
    {
        "date": "07-2016",
        "max_performance": [
            14.654092813059828,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.6216852442951755,
            4.2
        ],
        "real": 3.687894544370618
    },
    {
        "date": "08-2016",
        "max_performance": [
            14.564974092145494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.564447431564002,
            4.2
        ],
        "real": 3.9555633705731674
    },
    {
        "date": "09-2016",
        "max_performance": [
            14.476397345853455,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.507607652228877,
            4.2
        ],
        "real": 3.9148865493378704
    },
    {
        "date": "10-2016",
        "max_performance": [
            14.388359278170388,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.4511631383541115,
            4.2
        ],
        "real": 4.149291880841965
    },
    {
        "date": "11-2016",
        "max_performance": [
            14.300856613127646,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.395111141252327,
            4.2
        ],
        "real": 4.507608059268975
    },
    {
        "date": "12-2016",
        "max_performance": [
            14.213886094679351,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.339448931350594,
            4.2
        ],
        "real": 4.123491068549723
    },
    {
        "date": "01-2017",
        "max_performance": [
            14.12744448658124,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.284173798057511,
            4.2
        ],
        "real": 4.035780349395357
    },
    {
        "date": "02-2017",
        "max_performance": [
            14.041528572270238,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.22928304963121,
            4.2
        ],
        "real": 4.026871388338571
    },
    {
        "date": "03-2017",
        "max_performance": [
            13.956135154744754,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.174774013048271,
            4.2
        ],
        "real": 3.6468094603406165
    },
    {
        "date": "04-2017",
        "max_performance": [
            13.871261056445746,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.120644033873557,
            4.2
        ],
        "real": 3.8016988837380916
    },
    {
        "date": "05-2017",
        "max_performance": [
            13.786903119138461,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.066890476130945,
            4.2
        ],
        "real": 4.377084572084722
    },
    {
        "date": "06-2017",
        "max_performance": [
            13.703058203794916,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -5.013510722174974,
            4.2
        ],
        "real": 4.2786341501723335
    },
    {
        "date": "07-2017",
        "max_performance": [
            13.619723190477101,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.960502172563357,
            4.2
        ],
        "real": 3.9711357088437094
    },
    {
        "date": "08-2017",
        "max_performance": [
            13.536894978220868,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.907862245930406,
            4.2
        ],
        "real": 4.045458745598718
    },
    {
        "date": "09-2017",
        "max_performance": [
            13.45457048492057,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.855588378861327,
            4.2
        ],
        "real": 3.9064473655596954
    },
    {
        "date": "10-2017",
        "max_performance": [
            13.372746647214338,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.803678025767384,
            4.2
        ],
        "real": 4.120521651525033
    },
    {
        "date": "11-2017",
        "max_performance": [
            13.291420420370118,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.7521286587619365,
            4.2
        ],
        "real": 4.242543828194578
    },
    {
        "date": "12-2017",
        "max_performance": [
            13.210588778172356,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.700937767537344,
            4.2
        ],
        "real": 3.9893411161157077
    },
    {
        "date": "01-2018",
        "max_performance": [
            13.130248712809404,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.650102859242713,
            4.2
        ],
        "real": 3.958719385521725
    },
    {
        "date": "02-2018",
        "max_performance": [
            13.050397234761594,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.5996214583625115,
            4.2
        ],
        "real": 4.1548236326101495
    },
    {
        "date": "03-2018",
        "max_performance": [
            12.971031372689984,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.549491106596012,
            4.2
        ],
        "real": 4.384693059888556
    },
    {
        "date": "04-2018",
        "max_performance": [
            12.8921481733258,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.49970936273758,
            4.2
        ],
        "real": 4.705361610169367
    },
    {
        "date": "05-2018",
        "max_performance": [
            12.813744701360553,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.4502738025577955,
            4.2
        ],
        "real": 4.429071450235056
    },
    {
        "date": "06-2018",
        "max_performance": [
            12.735818039336795,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.401182018685402,
            4.2
        ],
        "real": 4.135092432325438
    },
    {
        "date": "07-2018",
        "max_performance": [
            12.658365287539576,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.3524316204900675,
            4.2
        ],
        "real": 4.634171951341748
    },
    {
        "date": "08-2018",
        "max_performance": [
            12.581383563888517,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.304020233965975,
            4.2
        ],
        "real": 4.286813223893127
    },
    {
        "date": "09-2018",
        "max_performance": [
            12.504870003830598,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.255945501616214,
            4.2
        ],
        "real": 4.492773581451304
    },
    {
        "date": "10-2018",
        "max_performance": [
            12.428821760233545,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.208205082337971,
            4.2
        ],
        "real": 4.419111545661036
    },
    {
        "date": "11-2018",
        "max_performance": [
            12.35323600327989,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.16079665130853,
            4.2
        ],
        "real": 4.790719085820094
    },
    {
        "date": "12-2018",
        "max_performance": [
            12.278109920361674,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.113717899872064,
            4.2
        ],
        "real": 4.46440881233201
    },
    {
        "date": "01-2019",
        "max_performance": [
            12.20344071597578,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.066966535427197,
            4.2
        ],
        "real": 4.6128935720408215
    },
    {
        "date": "02-2019",
        "max_performance": [
            12.12922561161992,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -4.020540281315373,
            4.2
        ],
        "real": 4.211026403638574
    },
    {
        "date": "03-2019",
        "max_performance": [
            12.055461845689246,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.9744368767099876,
            4.2
        ],
        "real": 4.385150641501823
    },
    {
        "date": "04-2019",
        "max_performance": [
            11.982146673373572,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.928654076506286,
            4.2
        ],
        "real": 4.379954628638605
    },
    {
        "date": "05-2019",
        "max_performance": [
            11.909277366555251,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.8831896512120365,
            4.2
        ],
        "real": 4.911612881731253
    },
    {
        "date": "06-2019",
        "max_performance": [
            11.836851213707662,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.8380413868389627,
            4.2
        ],
        "real": 5.08165465274113
    },
    {
        "date": "07-2019",
        "max_performance": [
            11.7648655197943,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.7932070847949233,
            4.2
        ],
        "real": 4.635685597196426
    },
    {
        "date": "08-2019",
        "max_performance": [
            11.693317606168506,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.7486845617768547,
            4.2
        ],
        "real": 4.825729931489953
    },
    {
        "date": "09-2019",
        "max_performance": [
            11.622204810473772,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.70447164966444,
            4.2
        ],
        "real": 5.029980651703769
    },
    {
        "date": "10-2019",
        "max_performance": [
            11.551524486544695,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.660566195414536,
            4.2
        ],
        "real": 4.899281010510159
    },
    {
        "date": "11-2019",
        "max_performance": [
            11.481274004308494,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.6169660609563263,
            4.2
        ],
        "real": 4.864471451263785
    },
    {
        "date": "12-2019",
        "max_performance": [
            11.411450749687159,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.573669123087194,
            4.2
        ],
        "real": 4.845677461412867
    },
    {
        "date": "01-2020",
        "max_performance": [
            11.342052124500162,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.530673273369342,
            4.2
        ],
        "real": 4.955321149951031
    },
    {
        "date": "02-2020",
        "max_performance": [
            11.273075546367783,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.487976418027102,
            4.2
        ],
        "real": 4.799647595072742
    },
    {
        "date": "03-2020",
        "max_performance": [
            11.204518448615023,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.445576477844989,
            4.2
        ],
        "real": 4.958649511754887
    },
    {
        "date": "04-2020",
        "max_performance": [
            11.136378280176093,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.403471388066438,
            4.2
        ],
        "real": 5.181938184137036
    },
    {
        "date": "05-2020",
        "max_performance": [
            11.068652505499482,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.3616590982932633,
            4.2
        ],
        "real": 5.066747341443034
    },
    {
        "date": "06-2020",
        "max_performance": [
            11.00133860445361,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.320137572385804,
            4.2
        ],
        "real": 4.777360125564312
    },
    {
        "date": "07-2020",
        "max_performance": [
            10.934434072233051,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.2789047883637754,
            4.2
        ],
        "real": 5.309091190304516
    },
    {
        "date": "08-2020",
        "max_performance": [
            10.867936419265337,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.237958738307801,
            4.2
        ],
        "real": 5.118381668542327
    },
    {
        "date": "09-2020",
        "max_performance": [
            10.801843171118305,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.197297428261636,
            4.2
        ],
        "real": 4.985140767117913
    },
    {
        "date": "10-2020",
        "max_performance": [
            10.736151868408022,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.1569188781350594,
            4.2
        ],
        "real": 5.197533510790553
    },
    {
        "date": "11-2020",
        "max_performance": [
            10.670860066707283,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.1168211216074586,
            4.2
        ],
        "real": 5.269843227559723
    },
    {
        "date": "12-2020",
        "max_performance": [
            10.605965336454632,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.0770022060320694,
            4.2
        ],
        "real": 5.448170293882737
    },
    {
        "date": "01-2021",
        "max_performance": [
            10.54146526286398,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -3.0374601923408906,
            4.2
        ],
        "real": 5.354806309405058
    },
    {
        "date": "02-2021",
        "max_performance": [
            10.47735744583473,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9981931549502554,
            4.2
        ],
        "real": 5.362955982608764
    },
    {
        "date": "03-2021",
        "max_performance": [
            10.413639499862468,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9591991816670586,
            4.2
        ],
        "real": 5.305365520724781
    },
    {
        "date": "04-2021",
        "max_performance": [
            10.350309053950209,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.9204763735956463,
            4.2
        ],
        "real": 5.181065582331579
    },
    {
        "date": "05-2021",
        "max_performance": [
            10.287363751520157,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.882022845045333,
            4.2
        ],
        "real": 5.418808261554786
    },
    {
        "date": "06-2021",
        "max_performance": [
            10.224801250326028,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.8438367234385855,
            4.2
        ],
        "real": 5.800109042541202
    },
    {
        "date": "07-2021",
        "max_performance": [
            10.162619222365876,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.805916149219826,
            4.2
        ],
        "real": 5.724124662802654
    },
    {
        "date": "08-2021",
        "max_performance": [
            10.100815353795484,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.768259275764881,
            4.2
        ],
        "real": 5.4246052604567385
    },
    {
        "date": "09-2021",
        "max_performance": [
            10.039387344842254,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.730864269291056,
            4.2
        ],
        "real": 5.872774845902851
    },
    {
        "date": "10-2021",
        "max_performance": [
            9.97833290971963,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.69372930876783,
            4.2
        ],
        "real": 5.716976185125389
    },
    {
        "date": "11-2021",
        "max_performance": [
            9.917649776542042,
            4.2
        ],
        "expected_performance": 4.002,
        "min_performance": [
            -2.6568525858281826,
            4.2
        ],
        "real": 5.516558439342578
    }]

export const planOutputChartDataRetirement = [
    {
        "name": 2059,
        "bar1": 1529.915507786779
    },
    {
        "name": 2060,
        "bar1": 1560.5138179425148
    },
    {
        "name": 2061,
        "bar1": 1591.7240943013649
    },
    {
        "name": 2062,
        "bar1": 1623.5585761873922
    },
    {
        "name": 2063,
        "bar1": 1656.02974771114
    },
    {
        "name": 2064,
        "bar1": 1689.1503426653633
    },
    {
        "name": 2065,
        "bar1": 1722.9333495186704
    },
    {
        "name": 2066,
        "bar1": 1757.392016509044
    },
    {
        "name": 2067,
        "bar1": 1792.5398568392247
    },
    {
        "name": 2068,
        "bar1": 1828.3906539760094
    },
    {
        "name": 2069,
        "bar1": 1864.9584670555296
    },
    {
        "name": 2070,
        "bar1": 1902.25763639664
    },
    {
        "name": 2071,
        "bar1": 1940.302789124573
    },
    {
        "name": 2072,
        "bar1": 1979.1088449070646
    },
    {
        "name": 2073,
        "bar1": 2018.6910218052058
    },
    {
        "name": 2074,
        "bar1": 2059.06484224131
    },
    {
        "name": 2075,
        "bar1": 2100.2461390861363
    },
    {
        "name": 2076,
        "bar1": 2142.251061867859
    },
    {
        "name": 2077,
        "bar1": 2185.096083105216
    },
    {
        "name": 2078,
        "bar1": 2228.7980047673204
    },
    {
        "name": 2079,
        "bar1": 2273.373964862667
    },
    {
        "name": 2080,
        "bar1": 2318.8414441599202
    },
    {
        "name": 2081,
        "bar1": 2365.218273043119
    },
    {
        "name": 2082,
        "bar1": 2412.5226385039814
    }
]

export default planRiskProfileTitleMock