import * as React from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../styles/color";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/button/index";
import "@inveert/ui-kit/src/icon/index";
import "@inveert/ui-kit/src/text/text-xl";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-s";
import "@inveert/ui-kit/src/text/text-xxs";
import perfilInicio from "../../styles/PerfilInicio.module.css";

import SolidDividers from "../../elements/dividers/SolidDividers";

function InicioPerfil() {
  const router = useNavigate();

  return (
    <div className={perfilInicio.container}>
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-auto" style={{ display: "flex" }}>
          <inv-text-l
            style={
              {
                "--inv-text-l-gradient": color.gradient.blue,
                "--inv-text-l-font-weight": 600,
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Necesitamos conocerte
          </inv-text-l>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "0.8em" }}>
        <inv-grid-col
          class="col-10 col-sm-auto"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-xxs
            style={
              {
                textAlign: "center",
                "--inv-text-xxs-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            Al estar regulados por la CNMV necesitamos analizar tu perfil como
            inversor
          </inv-text-xxs>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={perfilInicio.secondTitle}>
        <inv-grid-col class="col-9" style={{ display: "flex" }}>
          <inv-text-s
            style={
              {
                "--inv-text-s-font-weight": 600,
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Queremos que consigas tus metas con el menor esfuerzo
          </inv-text-s>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={perfilInicio.chartsContainer}>
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "2.5em" }}
                alt="perfil-manos-dinero"
                src="/assets/images/illustrations/perfil/hands.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                alt="perfil-ahorro"
                style={{ margin: "0 auto", width: "4em" }}
                src="/assets/images/illustrations/perfil/ahorro.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row class={perfilInicio.chartElementTitle}>
            <inv-grid-col class="col-auto">
              <inv-text-xxs style={{ "--inv-text-xxs-font-weight": 600 }}>
                Ahorro
              </inv-text-xxs>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "2.5em" }}
                alt="perfil-euro-flecha"
                src="/assets/images/illustrations/perfil/euro-up.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "4em" }}
                alt="perfil-rentabilidad"
                src="/assets/images/illustrations/perfil/rentabilidad.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row class={perfilInicio.chartElementTitle}>
            <inv-grid-col class="col-auto">
              <inv-text-xxs style={{ "--inv-text-xxs-font-weight": 600 }}>
                Rentabilidad
              </inv-text-xxs>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "2em" }}
                alt="perfil-euro"
                src="/assets/images/illustrations/perfil/euro.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "4em" }}
                alt="perfil-metas"
                src="/assets/images/illustrations/perfil/metas.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row class={perfilInicio.chartElementTitle}>
            <inv-grid-col class="col-auto">
              <inv-text-xxs style={{ "--inv-text-xxs-font-weight": 600 }}>
                Tus metas
              </inv-text-xxs>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={perfilInicio.lastTitle}>
        <inv-grid-col
          class="col-8 col-sm-7"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-xs
            style={
              {
                textAlign: "center",
                "--inv-text-xs-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            Conocer tu perfil inversor nos ayuda a entender la rentabilidad a la
            que puedes aspirar
          </inv-text-xs>
        </inv-grid-col>
      </inv-grid-row>

      <inv-grid-row class={perfilInicio.divider}>
        <inv-grid-col class="col-12">
          <div style={{ width: "calc(100% + 1em", marginLeft: "-1.25em" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row class={perfilInicio.buttonsContainer}>
        <inv-grid-col
          class={`col-12 col-sm-auto ${perfilInicio.buttonElement1}`}
        >
          <inv-button
            onClick={() => router("/meta/fin")}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Atrás
          </inv-button>
        </inv-grid-col>
        <inv-grid-col
          class={`col-12 col-sm-auto ${perfilInicio.buttonElement2}`}
        >
          <inv-button onClick={() => router("/perfil")}>
            Comenzar Test
          </inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

InicioPerfil.title = "Perfil de riesgo";
InicioPerfil.layout = "EmptyLayout";
InicioPerfil.mobileLayout = "MobileEmptyLayout";
export default InicioPerfil;
