import { FamilySituation, Income, InitialInvestment, Login } from "./user.type";
import { fetchApi } from "../../utils/functions";
import { IPlanApi } from "../propuesta/propuesta.type";

export const getUserDataService = (token: string) =>
  fetchApi(`auth/v1/sessions/${token}`, "get", {});

export const patchExistingUserNamePcAndBirthday = (
  userId: string,
  birthday: string,
  postalCode: string,
  name: string
) => {
  const payload = {
    data: {
      id: userId,
      type: "User",
      attributes: {
        birthday,
        name,
      },
      relationships: {
        address: {
          type: "Address",
          attributes: {
            postal_code: postalCode,
          },
        },
      },
    },
  };
  return fetchApi(`user/v1/users/${userId}`, "patch", payload);
};

export const patchaUserNamePcAndBirhday = (
  token: string,
  birthday: string,
  postalCode: string,
  name: string
) => {
  const payload = {
    data: {
      id: token,
      type: "Guest",
      attributes: {
        user: {
          birthday,
          name,
        },
        address: {
          postal_code: postalCode,
        },
      },
    },
  };
  return fetchApi(`guest/v1/guests/${token}`, "patch", payload);
};

export const getUserIdApi = (token: string) =>
  fetchApi(`auth/v1/sessions/${token}`, "get", {});

export const changeUserDataApi = (
  userId: string,
  userData: { name?: string; birthday?: string }
) => {
  const data = {
    data: {
      id: userId,
      type: "User",
      attributes: {
        ...userData,
      },
    },
  };
  return fetchApi(`user/v1/users/${userId}`, "patch", data);
};

export const getUserDataApi = (userId: string) =>
  fetchApi(`user/v1/users/${userId}`, "get", {});

export const userService = async (
  familySituation: FamilySituation,
  income: Income,
  userData: { email?: string; document?: string; zip_code?: string }
) => {
  const familySituationRes = fetchApi(
    "family-situation",
    "post",
    familySituation
  );
  const incomeRes = fetchApi("income", "post", income);
  const userDataRes = fetchApi("user", "put", userData);
  return Promise.all([familySituationRes, incomeRes, userDataRes]);
};

export const initialInvestmentUserApi = async (
  initialInvestment: InitialInvestment,
  planId?: number
) => {
  const requestData: IPlanApi = {
    data: {
      id: planId,
      type: "Plan",
      attributes: {
        initial_investment: initialInvestment.initial_investment ?? 0,
        pension_plan: initialInvestment.pension_plan ?? 0,
      },
    },
  };
  const url = planId ? `plan/v1/plans/${planId}` : "plan/v1/plans";
  const initialInvestmentRes = fetchApi(
    url,
    planId ? "patch" : "post",
    requestData
  );
  return initialInvestmentRes;
};

export const userApiLogin = async (login: Login) => {
  const loginRes = fetchApi("auth/login", "post", login);
  return loginRes;
};

export const userApiRefreshToken = async () => {
  fetchApi("auth/login/refresh", "post", {});
};

export const setUserData = async (data: any) => {
  const userResponse = fetchApi("user", "put", data);
  return userResponse;
};

export const getPlanStatusOfUser = async () => {
  const userResponse = fetchApi("user/me/plan", "get", {});
  return userResponse;
};

export const getUserData = async () => {
  const familySituationRes = fetchApi("family-situation", "get", {});
  const incomeRes = fetchApi("income", "get", {});
  return Promise.all([familySituationRes, incomeRes]);
};

export const getIncome = async () => {
  const incomeRes = fetchApi("income", "get", {});
  return incomeRes;
};

export const getFamilySituation = async () => {
  const familyRes = fetchApi("family-situation", "get", {});
  return familyRes;
};

export const getInitialInvestment = async () => {
  const initialInvestmentRes = fetchApi("financial-wealth", "get", {});
  return initialInvestmentRes;
};

export const deleteUserService = async () => {
  let responseData;
  let returnedRes;
  const finalUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/dev/delete-user.php`;
  const res = fetch(finalUrl, { method: "GET" });
  const resolvedResponse = await res;
  if (resolvedResponse.ok) {
    try {
      responseData = await resolvedResponse?.json();
    } catch {
      responseData = null;
    }
    if (responseData?.included) {
      returnedRes = Promise.resolve({
        data: responseData.data,
        included: responseData.included,
      });
    } else {
      returnedRes = Promise.resolve(responseData?.data ?? null);
    }
  } else {
    responseData = await resolvedResponse.text();
    returnedRes = Promise.reject(responseData);
  }
  return returnedRes;
};
