import {
  Grid,
  Link,
  useMediaQuery,
  Alert,
  Snackbar,
  SwipeableDrawer,
} from "@mui/material";
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import theme from "../../styles/theme";
import StepsFragments from "../../elements/fragments/StepsFragments";
import { color } from "../../styles/color";
import OnboardingMenu from "../../elements/menu/OnboardingMenu";
import { setIsAuthDialogOpenInStorage } from "../../utils/functions";
import PageLoading from "../../elements/loading/PageLoading";
import { selectUser } from "../../modules/user/user.slice";
import Text from "../../styles/textStyles";
import {
  closeRiskProfileDialog,
  openRiskProfileDialog,
  resetAlert,
  selectApp,
} from "../../modules/app/app.slice";
import { selectAuth } from "../../modules/auth/auth.slice";
import WCIcons from "../../elements/icons/WCIcons";
import ContainerDialog from "../../elements/dialog/ContainerDialog";
import { AuthDialogComponent } from "../../modules/auth/auth-dialog.component";
import { PersonalDataDialogContent } from "../../modules/user/user-dialog.component";
import { RiskProfileDialogContent } from "../../modules/risk/risk-dialog.component";
import { deleteSession } from "../../modules/auth/auth.service";

export function MobileHeaderNavigation(props: any) {
  const storage = globalThis?.sessionStorage;
  let token = "";
  if (storage) {
    token = storage.getItem("token") ?? "";
  }
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const router = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { alert, alertFlag } = useSelector(selectApp);
  const { isAuthDialogOpen } = useSelector(selectAuth);
  const [alertObject, setAlertObject] = React.useState(alert);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { financialWealth, userIncome, objectives } = props;
  const showNavigation = !(
    location.pathname === "/" ||
    location.pathname === "/token" ||
    location.pathname === "/verificacion" ||
    location.pathname === "/restore-password" ||
    location.pathname === "/registro" ||
    location.pathname === "/login"
  );
  const [isElHover, setIsElHover] = React.useState(false);
  const [isEl1Hover, setIsEl1Hover] = React.useState(false);
  const [isEl2Hover, setIsEl2Hover] = React.useState(false);
  const [isEl3Hover, setIsEl3Hover] = React.useState(false);
  const { birthday, riskProfile, riskProfileArray, userId } =
    useSelector(selectUser);
  const { showRiskProfileDialog } = useSelector(selectApp);
  const [isRiskProfileDialogOpen, setIsRiskProfileDialogOpen] =
    React.useState(false);
  const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const styles = window.getComputedStyle(document.body);
  /*
  
    const changeTabName = (tabName: string) =>
    dispatch(changeTabNamePlan(tabName));
    const menuOptions = [
    {
      startIcon: FinancialPlanIcon,
      title: "Plan financiero",
      onClick: () => changeTabName("plan"),
    },
    {
      startIcon: GoalIcon,
      title: "Metas",
      onClick: () => changeTabName("goals"),
    },
    {
      startIcon: BenefitsIcon,
      title: "Beneficios",
      onClick: () => changeTabName("benefit"),
    },
    {
      startIcon: HelpIcon,
      title: "Ayuda",
      onClick: () => changeTabName("help"),
    },
  ];
   */
  const menuItems =
    location.pathname === "/propuesta"
      ? [
          {
            title: "Mis datos",
            startIcon: (
              <WCIcons
                name="profile-single"
                width="32"
                height="32"
                color={
                  isEl1Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl1Hover,
            onClick: () => setIsPersonalDataDialogOpen(true),
          },
          {
            title: "Perfil inversor",
            startIcon: (
              <WCIcons
                name="financial-freedom"
                width="32"
                height="32"
                color={
                  isEl2Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl2Hover,
            onClick: () => setIsRiskProfileDialogOpen(true),
          },
          {
            title: "Cerrar sesión",
            startIcon: (
              <WCIcons
                name="log-out"
                width="32"
                height="32"
                color={
                  isEl3Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl3Hover,
            onClick: () => {
              setLoading(true);
              dispatch({ type: "reset" });
              deleteSession(token).finally(() => {
                if (storage) {
                  storage.clear();
                }
                router("/login");
              });
            },
          },
        ]
      : [
          {
            title: "Mis datos",
            startIcon: (
              <WCIcons
                name="profile-single"
                width="32"
                height="32"
                color={
                  isEl1Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl1Hover,
            onClick: () => setIsPersonalDataDialogOpen(true),
          },
          {
            title: "Cerrar sesión",
            startIcon: (
              <WCIcons
                name="log-out"
                width="32"
                height="32"
                color={
                  isEl2Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl2Hover,
            onClick: () => {
              setLoading(true);
              dispatch({ type: "reset" });
              deleteSession(token).finally(() => {
                if (storage) {
                  storage.clear();
                }
                router("/login");
              });
            },
          },
        ];
  React.useEffect(() => {
    dispatch(resetAlert());
    setLoading(true);
    if (isAuthDialogOpen === true) {
      setIsAuthDialogOpenInStorage("true");
    } else {
      setIsAuthDialogOpenInStorage("false");
    }
    setLoading(false);
  }, []);
  React.useEffect(() => {
    setAlertObject(alert);
  }, [alertFlag]);
  React.useEffect(() => {
    setLoading(false);
  }, [location.pathname]);
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetAlert());
  };
  return (
    <>
      {alertObject?.isOpen && (
        <Snackbar
          open
          autoHideDuration={
            location.pathname === "/propuesta" && !isAuthDialogOpen
              ? undefined
              : 10000
          }
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={
            location.pathname === "/propuesta" && !isAuthDialogOpen
              ? undefined
              : handleClose
          }
        >
          {/* @ts-ignore */}
          <Alert
            severity={alertObject?.isError ? "error" : "success"}
            sx={{ width: "100%" }}
            // @ts-ignore
            onClose={
              location.pathname === "/propuesta" && !isAuthDialogOpen
                ? undefined
                : handleClose
            }
          >
            {alertObject?.message}
          </Alert>
        </Snackbar>
      )}
      {alertObject?.isOpen &&
        location.pathname === "/propuesta" &&
        !isAuthDialogOpen && (
          <ContainerDialog
            open
            onClose={() => null}
            scroll="body"
            maxWidth="xs"
          >
            <Grid item sx={{ width: "100%" }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sx={{
                    background: color.greyscale.white[100],
                    borderRadius: "32px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                    padding: "40px",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={6}
                  >
                    <Grid
                      item
                      sx={{
                        color: color.validation.error,
                      }}
                    >
                      <WCIcons
                        name="message-failed"
                        viewBox="0 0 25 24"
                        height="40"
                        width="40"
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        rowSpacing={4}
                      >
                        <Grid item>
                          <Text
                            size={downMobile ? "m" : "xl"}
                            weight="semibold"
                            align="center"
                            fontColor={color.greyscale.black[100]}
                          >
                            Error al cargar
                          </Text>
                        </Grid>
                        <Grid item>
                          <Text
                            size={downMobile ? "xxs" : "xs"}
                            weight="regular"
                            align="center"
                            fontColor={color.greyscale.black[50]}
                          >
                            Se ha producido un problema al cargar la prouesta,
                            por favor, recargue la pagina de nuevo mas tarde
                          </Text>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            onClick={() => router(0)}
                            sx={{
                              fontWeight: 700,
                              cursor: "pointer",
                              fontSize: downMobile ? 12 : 10,
                              textAlign: "center",
                            }}
                          >
                            Recargar la página
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ContainerDialog>
        )}
      <PageLoading open={loading} />
      <SwipeableDrawer
        sx={{
          ".MuiDrawer-paper": {
            // 31 px are the half of the 62px height of mobile header
            maxHeight: "calc(100vh - 101px)",
            borderRadius: "1.2em 1.2em 0px 0px",
          },
        }}
        anchor="bottom"
        disableSwipeToOpen
        open={isRiskProfileDialogOpen || showRiskProfileDialog}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onClose={() => {
          dispatch(closeRiskProfileDialog());
          setIsRiskProfileDialogOpen(false);
        }}
        onOpen={() => {
          dispatch(openRiskProfileDialog());
          setIsRiskProfileDialogOpen(true);
        }}
      >
        <RiskProfileDialogContent
          userId={userId}
          isSliderActive
          birthday={birthday}
          userIncome={userIncome}
          objectives={
            objectives
              ? objectives.map((objective: any) => objective)
              : undefined
          }
          financialWealth={financialWealth}
          isTestAvailable
          closeDialog={() => {
            dispatch(closeRiskProfileDialog());
            setIsRiskProfileDialogOpen(false);
          }}
          profileNumber={riskProfile.profile_id}
          profileNumberMax={riskProfile.max_profile_id}
          riskProfileArray={riskProfileArray}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        sx={{
          ".MuiDrawer-paper": {
            // 31 px are the half of the 62px height of mobile header
            maxHeight: "calc(100vh - 101px)",
            borderRadius: "24px 24px 0px 0px",
            padding: theme.spacing(8),
          },
        }}
        anchor="bottom"
        disableSwipeToOpen
        open={isPersonalDataDialogOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onClose={() => setIsPersonalDataDialogOpen(false)}
        onOpen={() => setIsPersonalDataDialogOpen(true)}
      >
        <PersonalDataDialogContent
          closeDialog={() => setIsPersonalDataDialogOpen(false)}
        />
      </SwipeableDrawer>
      <ContainerDialog
        scroll="body"
        open={isAuthDialogOpen}
        onClose={() => null}
      >
        <AuthDialogComponent />
      </ContainerDialog>
      <Grid container direction="column">
        <Grid item>
          <Grid
            id="header"
            item
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: theme.spacing(4),
              background: color.blue[100],
            }}
          >
            <Grid
              item
              sx={{
                height: 24,
              }}
            >
              <img
                alt="logo"
                style={{
                  content: "var(--logo-mobile)",
                  maxWidth: "var(--logo-mobile-width)",
                  maxHeight: "var(--logo-mobile-height)",
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                minWidth: 139,
              }}
            >
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  onClick={() => {
                    if (
                      location.pathname !== "/propuesta" &&
                      location.pathname !== "/planes-asesoramiento"
                    ) {
                      router("/login");
                    }
                  }}
                >
                  {showNavigation && (
                    <OnboardingMenu
                      setIsHover={(
                        value: boolean | ((prevState: boolean) => boolean)
                      ) => setIsElHover(value)}
                      icon={
                        location.pathname === "/propuesta" ||
                        location.pathname ===
                          "/planes-asesoramiento" ? undefined : (
                          <WCIcons
                            name="log-out"
                            width="24"
                            height="24"
                            color={
                              isElHover
                                ? color.blue[100]
                                : color.greyscale.white[100]
                            }
                          />
                        )
                      }
                      title={"Login" || ""}
                      options={
                        location.pathname === "/propuesta" ||
                        location.pathname === "/planes-asesoramiento"
                          ? menuItems
                          : undefined
                      }
                      // menuOptions={menuOptions}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showNavigation && !location.pathname.includes("/plan") && (
        <Grid
          container
          justifyContent="center"
          sx={{
            background: color.greyscale.white.T20,
            backdropFilter: "blur(4px)",
            padding: theme.spacing(2),
          }}
        >
          <Grid item>
            <StepsFragments
              steps={[
                "Mis metas",
                "Aportación inicial",
                "Perfil inversor",
                "Resultado",
                "Propuesta",
              ]}
              activeColor={styles?.getPropertyValue(
                "--navigation-selected-color"
              )}
              inactiveColor={styles?.getPropertyValue(
                "--navigation-not-selected-color"
              )}
              activeTextStepIconColor={styles?.getPropertyValue(
                "--navigation-selected-icon-color"
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default MobileHeaderNavigation;
