import * as React from "react";
import styles from "./meta.module.css";
import PlanMetaComponent from "../../modules/plan/plan-meta.component";
import { Size, useWindowSize } from "../../utils/hooks";
import { HelpFooter } from "../../components/help/HelpFooter";
import { planOutputChartDataRetirement } from "../../modules/plan/plan.data";

function PlanMeta() {
  const size: Size = useWindowSize();
  const downMobile = (size?.width ?? 0) < 576;
  return (
    <>
      <div className={styles.pageContainer}>
        <PlanMetaComponent
          outputChartData={planOutputChartDataRetirement}
          isOutputMonthly
        />
      </div>
      {!downMobile && (
        <div style={{ marginTop: "10em" }}>
          <HelpFooter setIsContactDialogOpen={() => {}} />
        </div>
      )}
    </>
  );
}

export default PlanMeta;
