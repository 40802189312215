import { fetchApi } from "../../utils/functions";

interface IRegistrationOnFinanbest {
  email: string;
  last_name_1: string;
  last_name_2: string;
  password: string;
  mobile: string;
  document: string;
  type_id: string;
  gender: string;
  country: string;
  birth_country: string;
  birth_city: string;
  birth_region: string;
  postal_code: string;
  region: string;
  city: string;
  population: string;
  province: string;
  municipality: string;
  nationality: string;
  expiration_date: string;
  address_type: string;
  address: string;
  address_number: string;
  address_floor?: string;
  residence_country: string;
  marital_status: string;
  iban: string;
  job: string;
  laboral: string;
  varias_nacionalidades: boolean;
  primera_nacionalidad_extra?: string;
}

interface IKycOnFinanbest {
  fiscal_obligations: number;
  cecabank_relationship: string;
  company: string;
  public_functions: number;
  public_functions_family: number;
  other_sources_income: number;
}

export const getContractListService = (userId: number) =>
  fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracts`,
    "get",
    {}
  );

export const postCreateContractService = (
  userId: number,
  recommendationId: string
) => {
  const payload = {
    data: {
      type: "FinancialProvider",
      attributes: {
        identifier: recommendationId,
      },
    },
  };
  return fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracts`,
    "post",
    payload
  );
};

export const postCheckFinanbestMailExistenceService = (email: string) => {
  const payload = {
    data: {
      type: "FinancialProvider",
      attributes: {
        email,
      },
    },
  };
  return fetchApi(
    `mifid/v1/financial-providers/finanbest/check-email`,
    "post",
    payload
  );
};

export const postLoginFinanbestService = (
  userId: number,
  email: string,
  password: string
) => {
  const payload = {
    data: {
      type: "Credentials",
      attributes: {
        username: email,
        password,
      },
    },
  };
  return fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracting/login`,
    "post",
    payload
  );
};

export const postDniService = (
  base64DocData: unknown,
  isFrontSide: boolean,
  userId: number
) => {
  const payload = {
    data: {
      type: "Document",
      attributes: {
        type: isFrontSide ? "DNI_F" : "DNI_B",
        content: base64DocData,
      },
    },
  };
  return fetchApi(`user/v1/users/${userId}/documents`, "post", payload);
};

export const postRegistrationFinanbestService = (
  registrationData: IRegistrationOnFinanbest,
  userId: number
) => {
  const data = {
    data: {
      type: "User",
      attributes: {
        ...registrationData,
      },
    },
  };
  return fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracting/registration`,
    "post",
    data
  );
};

export const finanbestCreateUser = async (data: any) => {
  const finanbestCreateUserResponse = await fetchApi(
    "finanbest/user",
    "post",
    data
  );
  return finanbestCreateUserResponse;
};

export const kycOnFinanbestApi = (data: IKycOnFinanbest, userId: number) => {
  const parsedData = {
    data: {
      type: "Answers",
      attributes: {
        ...data,
      },
    },
  };
  return fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracting/test-know-your-client`,
    "post",
    parsedData
  );
};

export const finanbestAuth = async (password: string, username: string) => {
  const finanbestAuthResponse = await fetchApi(
    "finanbest/auth/authenticate",
    "post",
    {
      username,
      password,
    }
  );
  return finanbestAuthResponse;
};

export const finanbestSendDni = async (formData: FormData) => {
  const finanbestDniResponse = await fetchApi(
    "user/upload-personal-document",
    "post",
    formData
  );
  return finanbestDniResponse;
};

export const postFinanbestKycService = async (
  data: IKycOnFinanbest,
  userId: number
) => {
  const parsedData = {
    data: {
      type: "TestKnowYourClientRequest",
      attributes: {
        ...data,
        intervener_cc: "Titular",
        intervener_cv: "Titular",
      },
    },
  };

  return fetchApi(
    `user/v1/users/${userId}/financial-providers/finanbest/contracting/test-know-your-client`,
    "post",
    parsedData
  );
};

export const finanbestSendKyc = (algo: any) => algo;

export default finanbestAuth;
