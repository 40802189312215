import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  FormHelperText,
  InputLabel,
  inputLabelClasses,
  outlinedInputClasses,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";

interface INumberInput {
  value: number | string | null | undefined;
  handleChange: (
    name: string,
    value: Date | null | number | string,
    validate: boolean
  ) => Promise<any>;
  formik: any;
  error?: string;
  name: string;
  label?: string;
  helperLabel?: string;
  decimalScale?: number;
  focused?: boolean;
  endAdornment?: JSX.Element;
  placeholder?: string;
  disabled?: boolean;
  disableThousandSeparator?: boolean;
}

export const StyledTextField = styled(TextField)({
  input: {
    "&::placeholder": {
      opacity: 1,
      color: color.greyscale.black[30],
    },
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    border: `2px solid ${color.greyscale.black[30]}`,
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.validation.error}`,
    },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.blue[100]}`,
    },
  [`&:hover .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.validation.error}`,
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.turquoise[100]}`,
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.validation.error}`,
    },
  [`& .${outlinedInputClasses.input}`]: {
    color: color.greyscale.black[95],
    height: "fit-content",
    [theme.breakpoints.up("xs")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4.25)} `,
    },
    [theme.breakpoints.up("xl")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4.25)} `,
    },
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    // color: color.blue[100]
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
    {
      // color: color.turquoise[100]
    },
  [`& .${inputLabelClasses.outlined}`]: {
    color: color.greyscale.black[30],
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    // color: color.blue[100]
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    // color: color.turquoise[100]
  },
  [`& .MuiOutlinedInput-root .MuiInputAdornment-root .MuiIconButton-root`]: {
    color: color.greyscale.black[30],
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.75em 0.8em",
    fontSize: "1em",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
    {
      border: `2px solid ${color.greyscale.black[10]}`,
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]: {
    background: color.greyscale.black[5],
  },
  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: 8,
    [theme.breakpoints.up("xs")]: {
      // paddingRight:`${theme.spacing(4.25)}`
    },
    [theme.breakpoints.up("xl")]: {
      // paddingRight:`${theme.spacing(4.25)}`
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: color.greyscale.black[50],
    WebkitTextFillColor: `${color.greyscale.black[50]}`,
  },
});

function NumberInput(props: INumberInput) {
  const {
    disableThousandSeparator,
    name,
    disabled,
    value,
    handleChange,
    formik,
    error,
    label,
    helperLabel,
    decimalScale,
    focused,
    endAdornment,
    placeholder,
  } = props;
  useEffect(() => {
    formik.setErrors({});
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginBottom: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginBottom: theme.spacing(2),
          },
        }}
      >
        <Text size="s" weight="medium" fontColor={color.greyscale.black[50]}>
          {label}
        </Text>
      </InputLabel>
      <NumberFormat
        disabled={disabled}
        autoComplete="off"
        fullWidth
        inputRef={(input: { focus: () => any }) =>
          focused === true ? input && input.focus() : null
        }
        placeholder={placeholder}
        id={name}
        name={name}
        value={value}
        onValueChange={(e) => {
          if (formik.touched[name] === true) {
            handleChange(
              name,
              e.floatValue || e.floatValue === 0 ? e.floatValue : null,
              false
            ).then(() => formik.validateField(name));
          } else {
            handleChange(
              name,
              e.floatValue || e.floatValue === 0 ? e.floatValue : null,
              false
            ).then(() => null);
          }
        }}
        error={Boolean(error)}
        inputProps={{ inputMode: "numeric" }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          // eslint-disable-next-line
          endAdornment: endAdornment,
        }}
        onBlur={() =>
          formik
            .setFieldTouched(name, true, false)
            .then(() => formik.validateField(name))
        }
        thousandSeparator={
          disableThousandSeparator === true
            ? undefined
            : process.env.REACT_APP_THOUSAND_SEPARATOR
        }
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={decimalScale}
        allowNegative={false}
        // @ts-ignore
        customInput={StyledTextField}
      />
      <FormHelperText
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginTop: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginTop: theme.spacing(2),
          },
        }}
      >
        <Text size="xxs" weight="regular" fontColor={color.greyscale.black[40]}>
          {helperLabel}
        </Text>
      </FormHelperText>
      {Boolean(formik.errors[name]) && (
        <FormHelperText
          error
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginTop: theme.spacing(1),
            },
            [theme.breakpoints.up("xl")]: {
              marginTop: theme.spacing(2),
            },
          }}
        >
          <Text size="xs" weight="regular" fontColor="inherit">
            {error}
          </Text>
        </FormHelperText>
      )}
    </Box>
  );
}

NumberInput.defaultProps = {
  error: undefined,
  label: undefined,
  helperLabel: undefined,
  decimalScale: undefined,
  focused: undefined,
  endAdornment: undefined,
  placeholder: undefined,
  disabled: undefined,
  disableThousandSeparator: undefined,
};

export default NumberInput;
