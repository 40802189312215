import React from "react";

interface IWCIcons {
  name: string;
  width?: string;
  height?: string;
  viewBox?: string;
  color?: string;
  hoverColor?: string;
}

function WCIcons(props: IWCIcons) {
  const { name, width, height, viewBox, color, hoverColor } = props;
  const [isHover, setIsHover] = React.useState(false);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index.js"), []);
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      style={{
        height: "fit-content",
        cursor: hoverColor ? "pointer" : "initial",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <inv-icon
        color={isHover ? hoverColor ?? color : color}
        name={name}
        width={width}
        height={height}
        viewBox={viewBox}
      />
    </div>
  );
}

WCIcons.defaultProps = {
  width: undefined,
  height: undefined,
  viewBox: undefined,
  color: undefined,
  hoverColor: undefined,
};

export default WCIcons;
