import * as React from "react";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import styles from "./plan.module.css";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-xs";
import PropuestaTextStructureComponent from "../propuesta/propuesta-text-structure.component";
import { AreasChart } from "../../components/chart/AreasChart";
import {
  customDotsPatrimonyChartMockData,
  patrimonyChartMockData,
  planGoalsDropdownPropsMock,
} from "./plan.data";
import PlanGoalsDropdownComponent from "./plan-goals-dropdown.component";
import WCIcons from "../../elements/icons/WCIcons";
import PropuestaThreeValuesPercentageBar from "../propuesta/propuesta-three-values-percentage-bar.component";
import { Size, useWindowSize } from "../../utils/hooks";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PlanComponent(props: any) {
  const size: Size = useWindowSize();
  const downMobile = (size?.width ?? 0) < 576;

  const pendingContributions = 217326;
  const netWorth = 4533;
  const neededProfitAmount = 553207;
  return (
    <>
      <div className={styles.contentHeaderContainer}>
        <inv-grid-row class={styles.titleContainer}>
          <inv-grid-col class="col-auto">
            <inv-text-xxxl
              style={{
                "--inv-text-xxxl-font-weight": 700,
                "--inv-text-xxxl-color": color.greyscale.white[100],
              }}
            >
              Situación global del plan financiero
            </inv-text-xxxl>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.infoHeaderContainer}>
          <inv-grid-col
            class={`col-12 col-sm-auto ${styles.nextApportationContainer}`}
            style={{
              "--inv-text-structure-label-color": color.greyscale.white[100],
              "--inv-text-l-color": color.greyscale.white[100],
              "--inv-text-xs-color": color.greyscale.white[100],
            }}
          >
            <PropuestaTextStructureComponent
              label={
                (size.width ?? 0) < 576
                  ? "Próxima aportación"
                  : "Próxima aportación mensual"
              }
              unit="€"
            >
              {/*
              TODO proxima aportación mensual
              */}
              <NumberFormat
                value={610}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class={`col-auto ${styles.accumulatedContainer}`}
            style={{
              "--inv-text-structure-label-color": color.greyscale.white[100],
              "--inv-text-l-color": color.greyscale.white[100],
              "--inv-text-xs-color": color.greyscale.white[100],
            }}
          >
            <PropuestaTextStructureComponent
              label={
                (size.width ?? 0) < 576 ? "Acumulado" : "Capital acumulado"
              }
              unit="€"
            >
              {/*
              TODO capital acumulado
              */}
              <NumberFormat
                value={129066}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class={`col-auto ${styles.smallHeaderTextStructures}`}
            style={{
              "--inv-text-structure-label-color": color.greyscale.white[100],
              "--inv-text-l-color": color.greyscale.white[100],
              "--inv-text-xs-color": color.greyscale.white[100],
            }}
          >
            <PropuestaTextStructureComponent
              label={(size.width ?? 0) < 576 ? "T. aportado" : "Total aportado"}
              unit="€"
            >
              {/*
              TODO total aportado
              */}
              <NumberFormat
                value={129066}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
          <inv-grid-col
            class={`col-auto ${styles.smallHeaderTextStructures}`}
            style={{
              "--inv-text-structure-label-color": color.turquoise[100],
              "--inv-text-l-color": color.turquoise[100],
              "--inv-text-xs-color": color.turquoise[100],
            }}
          >
            <PropuestaTextStructureComponent label="Rentabilidad" unit="€">
              {/*
              TODO total aportado
              */}
              <NumberFormat
                value={129066}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                displayType="text"
              />
            </PropuestaTextStructureComponent>
          </inv-grid-col>
        </inv-grid-row>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.whiteContainer} style={{ right: 0 }} />
        <div className={styles.whiteContainer} style={{ left: 0 }} />
        <inv-grid-row class="row" style={{ "--inv-gutter-x": 0 }}>
          <inv-grid-col class="col-12">
            <AreasChart
              height={300}
              data={patrimonyChartMockData}
              name="not-accumulated-net-worth"
              customDotsData={customDotsPatrimonyChartMockData}
              xPropertyName="date"
            />
          </inv-grid-col>
        </inv-grid-row>
        <div className={styles.titleWithSubtitleContainer}>
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <inv-text-m
                style={{
                  "--inv-text-m-font-weight": 700,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                Resumen del plan
              </inv-text-m>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <inv-grid-col class="col-6">
              <inv-text-xs
                style={{
                  position: "relative",
                  zIndex: 1,
                  "--inv-text-xs-font-weight": 300,
                  "--inv-text-xs-color": color.greyscale.black[50],
                }}
              >
                El objetivo es siempre el mismo: que consigas tus metas
                aportando sólo una parte del dinero que necesitas
              </inv-text-xs>
            </inv-grid-col>
          </inv-grid-row>
        </div>
        <inv-grid-row
          style={{ justifyContent: "center", position: "relative", zIndex: 1 }}
        >
          <inv-grid-col
            class={`col-10 ${styles.contentCol10}`}
            style={{ "--inv-gutter-x": 0 }}
          >
            <div>
              <inv-grid-row class={styles.apportationsContainer}>
                <inv-grid-col class="col-auto">
                  <inv-grid-row>
                    <inv-grid-col
                      class="col-auto"
                      style={{
                        "--inv-text-l-color": color.greyscale.black[50],
                        "--inv-text-xs-color": color.greyscale.black[50],
                      }}
                    >
                      <PropuestaTextStructureComponent
                        label="Capital acumulado"
                        unit="€"
                      >
                        <NumberFormat
                          value={netWorth}
                          thousandSeparator={
                            process.env.REACT_APP_THOUSAND_SEPARATOR
                          }
                          decimalSeparator={
                            process.env.REACT_APP_DECIMAL_SEPARATOR
                          }
                          decimalScale={0}
                          displayType="text"
                        />
                      </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col
                      class="col-auto"
                      style={{
                        "--inv-text-l-color": color.greyscale.black[30],
                        "--inv-text-xs-color": color.greyscale.black[30],
                      }}
                    >
                      <PropuestaTextStructureComponent
                        label={
                          downMobile
                            ? "Ap. pendientes"
                            : "Aportaciones pendientes"
                        }
                        unit="€"
                      >
                        <NumberFormat
                          value={pendingContributions}
                          thousandSeparator={
                            process.env.REACT_APP_THOUSAND_SEPARATOR
                          }
                          decimalSeparator={
                            process.env.REACT_APP_DECIMAL_SEPARATOR
                          }
                          decimalScale={0}
                          displayType="text"
                        />
                      </PropuestaTextStructureComponent>
                    </inv-grid-col>
                  </inv-grid-row>
                </inv-grid-col>
                <inv-grid-col
                  class="col-auto"
                  style={{
                    "--inv-text-l-color": color.turquoise[100],
                    "--inv-text-xs-color": color.turquoise[100],
                  }}
                >
                  <PropuestaTextStructureComponent
                    label="Rentabilidad necesaria"
                    unit="€"
                    labelIcon={
                      <button
                        style={{
                          background: "none",
                          color: "inherit",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                          cursor: "pointer",
                          outline: "inherit",
                        }}
                        type="button"
                        onClick={() => null}
                      >
                        <WCIcons
                          hoverColor="currentColor"
                          name="blue-circle-right-arrow"
                          height="1em"
                          width="1em"
                        />
                      </button>
                    }
                  >
                    <NumberFormat
                      value={neededProfitAmount}
                      thousandSeparator={
                        process.env.REACT_APP_THOUSAND_SEPARATOR
                      }
                      decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                      decimalScale={0}
                      displayType="text"
                    />
                  </PropuestaTextStructureComponent>
                </inv-grid-col>
              </inv-grid-row>
              <inv-grid-row>
                <inv-grid-col class="col-12">
                  <PropuestaThreeValuesPercentageBar
                    value1={netWorth}
                    value2={pendingContributions}
                    value3={neededProfitAmount}
                  />
                </inv-grid-col>
              </inv-grid-row>
            </div>
            <inv-grid-row class="row" style={{ justifyContent: "center" }}>
              <inv-grid-col class="col-auto">
                <inv-text-m
                  style={{
                    "--inv-text-m-font-weight": 700,
                  }}
                >
                  ¿Qué posibilidad tienes de cumplir tu plan?
                </inv-text-m>
              </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
              <inv-grid-col class="col-12">
                <PlanGoalsDropdownComponent
                  defaultExpanded={planGoalsDropdownPropsMock.defaultExpanded}
                  title={planGoalsDropdownPropsMock.title}
                  equity={planGoalsDropdownPropsMock.equity}
                  fixedIncome={planGoalsDropdownPropsMock.fixedIncome}
                />
              </inv-grid-col>
            </inv-grid-row>
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </>
  );
}

export default PlanComponent;
