import { createSlice } from "@reduxjs/toolkit";
import { User } from "./user.type";

export const initialState: User = {
  userId: 0,
  name: "",
  birthday: "",
  loading: false,
  zipCode: "",
  netIncome: 0,
  expenses: {
    total: 0,
  },
  income: {
    total: 0,
  },
  riskProfile: {
    profile_id: 1,
    max_profile_id: 1,
    external_profile_id: "",
    alias: "",
  },
  riskProfileArray: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId(state, action) {
      return { ...state, userId: action.payload };
    },
    setRiskProfile(state, action) {
      return { ...state, riskProfile: action.payload };
    },
    setRiskProfileArray(state, action) {
      return { ...state, riskProfileArray: action.payload.riskProfileArray };
    },
    setUser(state: any, action) {
      return { ...state, ...action.payload };
    },
    setUserNameAndBirthday(state: any, action) {
      return {
        ...state,
        birthday: action.payload.birthday,
        name: action.payload.name,
      };
    },
    resetUser() {
      return initialState;
    },
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: any) => state.user;

export const {
  setRiskProfile,
  setUser,
  resetUser,
  setUserNameAndBirthday,
  setRiskProfileArray,
  setUserId,
} = userSlice.actions;

export default userSlice.reducer;
