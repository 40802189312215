import { Box, TableContainer, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import * as React from "react";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import SolidDividers from "../../elements/dividers/SolidDividers";
import { StyledTable } from "./Tables";
import WCIcons from "../../elements/icons/WCIcons";

interface IContributionsTable {
  rows: Array<{ date: string; amount: number; isPaid: boolean }>;
  is3columns?: boolean;
}

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledTableCell = styled((props: any) => <TableCell {...props} />)(
  () => ({
    minWidth: 100,
  })
);

export function ContributionsTable(props: IContributionsTable) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { rows, is3columns } = props;
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid
        container
        sx={{
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(4),
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
          }}
        >
          <SolidDividers orientation="horizontal" />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <TableContainer>
            <StyledTable aria-label="simple table">
              <TableBody sx={{ width: "100%" }}>
                {rows.map((row) => (
                  <TableRow key={row.date + Math.random() * 100}>
                    <StyledTableCell component="th" scope="row">
                      <Grid
                        container
                        alignItems="center"
                        sx={{
                          height: 24,
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            maxWidth: downMobile ? 175 : 300,
                          }}
                        >
                          <Text
                            size={downMobile ? "xs" : "s"}
                            weight="regular"
                            fontColor={
                              row.isPaid === true
                                ? color.greyscale.black[50]
                                : color.validation.error
                            }
                            noWrap
                          >
                            {row.date}
                          </Text>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                          height: 24,
                        }}
                      >
                        <Grid item>
                          <Text
                            size={downMobile ? "xs" : "s"}
                            weight="bold"
                            fontColor={
                              row.isPaid === true
                                ? color.greyscale.black[50]
                                : color.validation.error
                            }
                          >
                            <NumberFormat
                              value={row.amount}
                              thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                              }
                              decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                              }
                              decimalScale={0}
                              allowNegative={false}
                              displayType="text"
                              prefix=""
                              suffix=" €"
                            />
                          </Text>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    {is3columns !== false && (
                      <StyledTableCell sx={{ width: 130, minWidth: 120 }}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          columnSpacing={1}
                          sx={{
                            width: "auto",
                            height: 24,
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              height: 24,
                              color:
                                row.isPaid === true
                                  ? undefined
                                  : color.validation.error,
                            }}
                          >
                            {row.isPaid === true ? (
                              <WCIcons name="message-success" />
                            ) : (
                              <WCIcons name="message-failed" />
                            )}
                          </Grid>
                          <Grid item>
                            <Text
                              size={downMobile ? "xxs" : "xs"}
                              weight="regular"
                              fontColor={color.greyscale.black[50]}
                            >
                              {row.isPaid === true ? "Pagado" : "Pendiente"}
                            </Text>
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

ContributionsTable.defaultProps = {
  is3columns: undefined,
};
export default ContributionsTable;
