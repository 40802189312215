import { Container, Grid, useMediaQuery } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../LayoutComplete.module.css";

import { userMockedData } from "../../modules/user/user.data";
import { selectUser } from "../../modules/user/user.slice";
import { OnboardingHeaderNavigation } from "../../components/navigation/OnboardingHeaderNavigation";
import { FooterNavigation } from "../../components/navigation/FooterNavigation";
import theme from "../theme";
import { MobileHeaderNavigation } from "../../components/navigation/MobileHeaderNavigation";
import { selectAuth } from "../../modules/auth/auth.slice";
import { resetAlert } from "../../modules/app/app.slice";
import { setIsAuthDialogOpenInStorage } from "../../utils/functions";

function StyledContentContainerBox(props: { children: any }) {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
}

function LayoutComplete({ children }: any) {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const { isAuthDialogOpen } = auth;
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { document, email, user } = children?.props?.data || {
    document: "",
    email: "",
    user: userMockedData,
  };
  const { name } = useSelector(selectUser);
  React.useEffect(() => {
    dispatch(resetAlert());
    if (isAuthDialogOpen === true) {
      setIsAuthDialogOpenInStorage("true");
    } else {
      setIsAuthDialogOpenInStorage("false");
    }
  }, []);
  return (
    <Grid
      id="Decoration"
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
        background: "var(--background)",
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
          [theme.breakpoints.down("mobile")]: {
            background: "linear-gradient(180deg, transparent 40%, white 60%)",
            padding: 0,
          },
        }}
        maxWidth="xl"
      >
        <Grid
          container
          direction="column"
          sx={{
            minHeight: "100%",
            flex: "0 0 auto",
          }}
        >
          {downMobile ? (
            <MobileHeaderNavigation
              isAuthDialogOpen={isAuthDialogOpen}
              name={name}
              document={document}
              email={email}
              user={user}
            />
          ) : (
            <OnboardingHeaderNavigation
              isAuthDialogOpen={isAuthDialogOpen}
              name={name}
              document={document}
              email={email}
              user={user}
            />
          )}
          <StyledContentContainerBox>{children}</StyledContentContainerBox>
          {!downMobile && <FooterNavigation />}
        </Grid>
      </Container>
    </Grid>
  );
}
export default LayoutComplete;
