import { Gender } from "../user/user.type";

export type ObjectiveType =
  | "RETIREMENT"
  | "EDUCATION"
  | "REAL_STATE"
  | "HERITAGE"
  | "CUSTOM"
  | "SAFETY_NET";
export type ProtectionType = "SHORT_TERM" | "LONG_TERM" | "CAPITAL";
export type TermType = "YEAR" | "AGE";
export type DurationType = "ANNUAL" | "MONTHLY";
export enum AmountType {
  UNIQUE = "UNIQUE",
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
}
export enum OutputType {
  UNIQUE = "UNIQUE",
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
}

export interface Objectives extends Array<Objective> {}

export interface Objective {
  type: ObjectiveType | null;
  output_type: OutputType | null;
  protection_type: ProtectionType | null;
  name: string | null;
  amount: number | null;
  duration: number | undefined | null;
  duration_type: DurationType | undefined | null;
  init_date: string | undefined | null; // yyyy-MM-dd
  end_date: string | undefined | null;
  is_active: boolean | undefined | null;
  extra?: any | undefined | null;
  percentage_completed: number | undefined | null;
  probability: number | undefined | null;
  time_horizon: number | undefined | null;
  adjusted_objective?: number;
  id?: number | string;
  stops_saving?: boolean;
  plan_id?: string;
}

export interface ComplementSimulationRequiredData {
  current_children?: number;
  gender?: Gender;
  is_freelance: boolean | null;
  net_income: number | null;
  years_contributed: number | null;
  disability: number | null;
  birthday: string | null | undefined;
}

export interface SimulatedRetirementIncomes
  extends Array<{
    retirement_age: number;
    retirement_income: number;
  }> {}

export interface SimulatedRetirementIncome {
  retirement_age: number;
  retirement_income: number;
}

export interface IGoalApi {
  data: {
    id?: string;
    type: "Goal";
    attributes: Objective;
    relationships?: {
      plan: {
        type: "Plan";
        id?: number;
        attributes?: {
          initial_investment?: number;
          pension_plan?: number;
          user_id?: number;
        };
      };
    };
  };
}

export interface ISimulatedRetirementApi {
  data: {
    attributes: {
      years_contributed: number;
      net_income: number;
      is_freelance: boolean;
      disability: number;
      birthday: string;
    };
    type: "RetirementSimulatorBody";
  };
}
