import { fetchApi } from "../../utils/functions";
import {
  ComplementSimulationRequiredData,
  IGoalApi,
  ISimulatedRetirementApi,
  Objective,
  OutputType,
} from "./goal.type";
import {
  goalCostLongTermApiResponse,
  goalCostShortTermApiResponse,
} from "./goal.data";
// eslint-disable-next-line import/no-cycle
import { createPlan, getPlan } from "../propuesta/propuesta.service";

export const retirementSimulation = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  complementSimulationRequiredData: ComplementSimulationRequiredData
) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    years_contributed,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_income,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_freelance,
    disability,
    birthday,
  } = complementSimulationRequiredData;
  const requestData: ISimulatedRetirementApi = {
    data: {
      attributes: {
        years_contributed: years_contributed ?? 0,
        net_income: net_income ?? 0,
        is_freelance: is_freelance ?? false,
        disability: disability ?? 0,
        birthday: birthday ?? "",
      },
      type: "RetirementSimulatorBody",
    },
  };

  return fetchApi("plan/v1/simulations/retirement", "post", requestData)
    .then((res) =>
      res.map(
        (simulatedItem: {
          attributes: {
            year: any;
            pension: number;
            pension_with_inflation: number;
          };
        }) => ({
          retirement_age: simulatedItem.attributes.year,
          // @ts-ignore
          retirement_income: Math.round(simulatedItem.attributes.pension),
          retirement_income_inflated: Math.round(
            simulatedItem.attributes.pension_with_inflation
          ),
        })
      )
    )
    .catch((e) => Promise.reject(e));
};

export const addObjectiveApi = async (objective: Objective) => {
  let planId;
  try {
    const plans = await getPlan();
    if (plans.length < 1) {
      const newPlan = await createPlan();
      planId = newPlan.id;
    } else {
      planId = plans[0].id;
    }
    const requestData: IGoalApi = {
      data: {
        type: "Goal",
        attributes: { ...objective, plan_id: planId },
      },
    };
    const addFirstObjectiveRes = await fetchApi(
      "plan/v1/goals",
      "post",
      requestData
    );
    return addFirstObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getObjectiveCostApi = async (objective: Objective) => {
  const requestData = {
    data: {
      type: "GoalCostSimulator",
      attributes: objective,
    },
  };
  try {
    const getObjectiveCostRes = await fetchApi(
      "plan/v1/simulations/goal-cost",
      "post",
      requestData
    );
    return getObjectiveCostRes;
  } catch (e) {
    let returnedResponse = goalCostLongTermApiResponse.data;
    // return Promise.reject(e);
    if (objective.output_type === OutputType.UNIQUE) {
      returnedResponse = goalCostShortTermApiResponse.data;
    }
    return returnedResponse;
  }
};

export const relationObjectiveAndPlan = async (objective: any, plan: any) => {
  const requestData = {
    data: {
      ...objective,
      attributes: {
        ...objective.attributes,
        created_at: undefined,
        updated_at: undefined,
        plan_id: plan.id,
      },
    },
  };
  const modifyObjectiveRes = await fetchApi(
    `plan/v1/goals/${objective.id}`,
    "patch",
    requestData
  );
  return modifyObjectiveRes;
};

export const modifyObjectiveApi = async (objective: Objective, id: string) => {
  let planId;
  try {
    const plans = await getPlan();
    if (plans.length < 1) {
      const newPlan = await createPlan();
      planId = newPlan.id;
    } else {
      planId = plans[0].id;
    }
    const requestData: IGoalApi = {
      data: {
        type: "Goal",
        attributes: { ...objective, plan_id: planId },
        id,
      },
    };
    const modifyObjectiveRes = await fetchApi(
      `plan/v1/goals/${id}`,
      "patch",
      requestData
    );
    return modifyObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteObjectiveApi = async (id: number) => {
  try {
    const deleteObjectiveRes = await fetchApi(
      `plan/v1/goals/${id}`,
      "delete",
      undefined
    );
    return deleteObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getObjectives = () => {
  const res = fetchApi("plan/v1/goals", "get", undefined);
  return res;
};

export const getObjectivesWithCosts = () => {
  const res = fetchApi("plan/v1/goals?include=GoalCost", "get", undefined)
    .then((data: { data: Array<any>; included: Array<any> }) => {
      let objectivesWithCosts = [];
      if (data.included && data.data.length !== 0) {
        objectivesWithCosts = data.data.map((objective) => {
          const costsOfObjective = data.included.filter(
            (cost) =>
              objective?.id?.toString() ===
              cost?.attributes?.goal_id?.toString()
          );
          const costArray =
            costsOfObjective[0].attributes.costs.withdrawal ??
            costsOfObjective[0].attributes.costs.yearly_withdrawals ??
            costsOfObjective[0].attributes.costs.monthly_withdrawals;
          const totalCost = costArray.reduce(
            (previousValue: any, currentValue: any) =>
              previousValue + currentValue,
            0
          );
          return { ...objective, totalCost };
        });
      } else {
        return Promise.reject();
      }
      return objectivesWithCosts;
    })
    .catch((e) => Promise.reject(e));
  return res;
};
