import * as React from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../styles/color";
import styles from "../../styles/Resultado.module.css";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/text/text-xxl";
import "@inveert/ui-kit/src/text/text-xxxl";
import "@inveert/ui-kit/src/input/input-radio";

import SolidDividers from "../../elements/dividers/SolidDividers";
import {
  getFinancialProviderId,
  getQuestionsByMifidId,
  getTestMifidId,
} from "../../modules/risk/risk.service";
import { RiskQuestion } from "../../modules/risk/risk.type";
import { questionsOfTestMifid } from "../../modules/risk/risk.data";

function DinamicoPerfil() {
  const router = useNavigate();
  const [questions, setQuestions] = React.useState<Array<RiskQuestion>>(
    questionsOfTestMifid.data
  );
  const [questionIndex, setQuestionIndex] = React.useState(0);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-l.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-m.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-s.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/button/index"), []);
  React.useEffect(() => {
    getFinancialProviderId().then((res) =>
      getTestMifidId(res).then((mifidId) =>
        getQuestionsByMifidId(mifidId).then((allQuestions) =>
          setQuestions(allQuestions)
        )
      )
    );
  }, []);
  const sendQuestion = () => {};
  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("input-radio-change", sendQuestion);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", sendQuestion);
    };
  }, [sendQuestion]);
  return (
    <div className={styles.container}>
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ display: "flex" }}>
          <inv-text-xxxl
            style={
              {
                "--inv-text-xxxl-gradient": color.gradient.blue,
                "--inv-text-xxxl-font-weight": 600,
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Perfil de riesgo
          </inv-text-xxxl>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "2em" }}>
        <inv-grid-col
          class="col"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-m
            style={
              {
                textAlign: "center",
                "--inv-text-m-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            {questions[questionIndex].attributes.question}
          </inv-text-m>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          justifyContent: "center",
          marginTop: "4em",
          "--inv-form-text-font-size": "14px",
        }}
      >
        {Object.keys(
          JSON.parse(questions[questionIndex].attributes.answers)
        ).map((answerKey) => {
          const parsedAnswers = JSON.parse(
            questions[questionIndex].attributes.answers
          );
          return (
            <inv-grid-col class="col">
              <inv-input-radio
                title={parsedAnswers[answerKey]}
                value={answerKey}
              />
            </inv-grid-col>
          );
        })}
      </inv-grid-row>

      <inv-grid-row style={{ marginTop: "4em" }}>
        <inv-grid-col class="col-12">
          <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x))" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{ justifyContent: "space-between", padding: "1.2em 1.6em 0" }}
      >
        <inv-grid-col class="col-auto">
          <inv-button
            onClick={() => {
              if (questionIndex === 0) {
                router("/perfil/inicio");
              } else {
                setQuestionIndex(questionIndex - 1);
              }
            }}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Atrás
          </inv-button>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-button onClick={() => setQuestionIndex(questionIndex + 1)}>
            Continuar
          </inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

DinamicoPerfil.title = "Perfil de riesgo";
DinamicoPerfil.layout = "EmptyLayout";
DinamicoPerfil.mobileLayout = "MobileEmptyLayout";
export default DinamicoPerfil;
