import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import "@inveert/ui-kit/src/text/text-m";
import "@inveert/ui-kit/src/text/text-xs";
import styles from "../../styles/Selector.module.css";

interface ISelector {
  icon?: JSX.Element;
  iconColor?: string;
  title: string;
  subtitle?: string | JSX.Element;
  size?: "big" | "medium" | "small";
  selected: boolean;
  onClick?: () => void;
  height?: number | string;
  width?: number | string;
  disabled?: boolean;
  smallPadding?: boolean;
  id?: string;
}

const StyledSelectorContainerGrid = styled((props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Grid container alignItems="center" justifyContent="center" {...props} />
  // eslint-disable-next-line @typescript-eslint/no-shadow
))(({ theme }) => ({
  background: color.greyscale.white[100],
  border: `2px solid ${color.greyscale.black[30]}`,
  borderRadius: "1.2em",
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    paddingRight: "1.6em",
    paddingLeft: "1.6em",
    paddingTop: "1.2em",
    paddingBottom: "1.2em",
  },
  [theme.breakpoints.up("xl")]: {
    paddingRight: "1.6em",
    paddingLeft: "1.6em",
    paddingTop: "1.2em",
    paddingBottom: "1.2em",
  },
  "&:hover": {
    border: `2px solid ${color.blue[100]}`,
  },
  cursor: "pointer",
}));

function Selector(props: ISelector) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const {
    id,
    icon,
    iconColor,
    title,
    subtitle,
    size,
    selected,
    onClick,
    height,
    width,
    disabled,
    smallPadding,
  } = props;
  const borderRadius =
    size === "medium" || size === "small" ? "5.6em" : "1.2em";

  let spacing;
  if (size === "small") {
    spacing = 2;
  } else if (size === "medium") {
    spacing = 2;
  } else {
    spacing = 2;
  }
  let sx;
  if (selected === true) {
    sx = {
      [theme.breakpoints.up("xs")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
        paddingTop: smallPadding === true ? "0.8em" : "1.2em",
        paddingBottom: smallPadding === true ? "0.8em" : "1.2em",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
        paddingTop: smallPadding === true ? "0.8em" : "1.2em",
        paddingBottom: smallPadding === true ? "0.8em" : "1.2em",
      },
      border: `0.1em solid ${color.blue[100]}`,
      boxShadow: `inset 0px 0px 0px 0.1em ${color.blue[100]}`,
      borderRadius: `${borderRadius}`,
      height,
      width,
    };
  } else if (disabled === true) {
    sx = {
      cursor: "default",
      background: color.greyscale.black[5],
      borderRadius: `${borderRadius}`,
      height,
      width,
      "&:hover": {
        border: `0.1em solid ${color.greyscale.black[30]}`,
      },
      [theme.breakpoints.up("xs")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
        paddingTop: smallPadding === true ? "0.8em" : "1.2em",
        paddingBottom: smallPadding === true ? "0.8em" : "1.2em",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
        paddingTop: smallPadding === true ? "0.8em" : "1.2em",
        paddingBottom: smallPadding === true ? "0.8em" : "1.2em",
      },
    };
  } else {
    sx = {
      borderRadius: `${borderRadius}`,
      height,
      width,
      [theme.breakpoints.up("xs")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: smallPadding === true ? "0.8em" : "1.6em",
        paddingLeft: smallPadding === true ? "0.8em" : "1.6em",
      },
    };
  }
  return (
    <Box
      sx={{ width, height, margin: "0 auto" }}
      onClick={onClick}
      className={styles.container}
      id={id}
    >
      <StyledSelectorContainerGrid
        // @ts-ignore
        sx={sx}
        // @ts-ignore
        direction={downMobile && !subtitle ? "row" : "column"}
      >
        {icon && (
          <Grid
            item
            sx={{
              height: downMobile ? "2.4em" : "initial",
              color: iconColor,
              opacity: disabled === true ? 0.4 : 1,
              [theme.breakpoints.up("xs")]: {
                paddingBottom: downMobile
                  ? theme.spacing(0)
                  : theme.spacing(spacing),
              },
              [theme.breakpoints.up("xl")]: {
                paddingBottom: downMobile
                  ? theme.spacing(0)
                  : theme.spacing(spacing * 2),
              },
            }}
          >
            {icon}
          </Grid>
        )}
        <Grid
          item
          sx={{
            display: "flex",
            opacity: disabled === true ? 0.4 : 1,
            maxWidth: downMobile ? "7.69em" : "inherit",
            paddingLeft:
              icon && downMobile ? theme.spacing(6) : theme.spacing(0),
          }}
        >
          <inv-text-m
            style={{
              "--inv-text-m-font-weight": 600,
              textAlign: "center",
            }}
          >
            {title}
          </inv-text-m>
        </Grid>
        {subtitle && (
          <Grid
            item
            sx={{
              display: "flex",
              [theme.breakpoints.up("xs")]: {
                paddingTop: theme.spacing(spacing),
              },
              [theme.breakpoints.up("xl")]: {
                paddingTop: theme.spacing(spacing * 2),
              },
            }}
          >
            {/* FIGMA COLOR DONT EXISTS IN THE COLORS GIVEN BY DESIGNER SO I GIVE ANOTHER THAT EXISTS */}
            <inv-text-xs
              style={{
                "--inv-text-xs-color": color.greyscale.black[50],
                "--inv-text-xs-font-weight": 500,
                textAlign: "center",
              }}
            >
              {subtitle}
            </inv-text-xs>
          </Grid>
        )}
      </StyledSelectorContainerGrid>
    </Box>
  );
}

Selector.defaultProps = {
  icon: undefined,
  iconColor: undefined,
  subtitle: undefined,
  size: undefined,
  onClick: undefined,
  height: undefined,
  width: undefined,
  disabled: undefined,
  smallPadding: undefined,
  id: undefined,
};

export default Selector;
