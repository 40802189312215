import Grid from "@mui/material/Grid";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { parseISO, subYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../styles/color";
import Text from "../styles/textStyles";
import theme from "../styles/theme";
import TextInput from "../elements/input/TextInput";
import DateInput from "../elements/input/DateInput";
import Buttons from "../elements/buttons/Buttons";
import PageLoading from "../elements/loading/PageLoading";
import { UserFormik } from "../modules/user/user-index.script";
import {
  IndexContainer,
  IndexContainerForm,
  IndexContainerButtonItem,
  IndexContainerTitle,
} from "../modules/user/user-index.style";
import { selectUser } from "../modules/user/user.slice";

function Homepage(props: any) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { title, subtitle, disableInputs, finish } = props;
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const formik = UserFormik({
    name: user.name ?? "",
    birthday: user.birthday ? parseISO(user.birthday) : null,
    monthly_income: null,
    zip_code: "",
    // document: document,
    finish: finish ?? undefined,
    dispatch,
  });
  return (
    <>
      <PageLoading open={formik.isSubmitting} />
      <IndexContainer className="index-container">
        {/* Need to wrap containers on item to apply maxwidth */}
        <Grid item>
          <IndexContainerTitle className="index-container__title">
            <Grid item>
              <Text
                /* eslint-disable-next-line no-nested-ternary */
                size={downMobile ? "l" : downXl ? "2xl" : "3xl"}
                weight="semibold"
                gradient={color.gradient.blue}
                align={downMobile ? "center" : undefined}
              >
                {title ?? "Comenzamos con tu planificación"}
              </Text>
            </Grid>
            <Grid
              item
              sx={{
                padding: subtitle ? "0 20px" : undefined,
              }}
            >
              <Text
                size={downMobile ? "xxs" : "m"}
                weight="medium"
                align="center"
                fontColor={color.greyscale.black[50]}
              >
                {subtitle ??
                  "Para empezar necesitamos que nos confirmes estos datos:"}
              </Text>
            </Grid>
          </IndexContainerTitle>
        </Grid>
        <Grid item>
          <IndexContainerForm
            className="index-container__form"
            downmobile={downMobile}
          >
            <Grid item>
              <TextInput
                disabled={disableInputs ?? undefined}
                value={formik.values.name}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="name"
                label="Tu nombre"
                // @ts-ignore
                error={formik.errors.name}
                placeholder="Luis"
              />
            </Grid>
            <Grid item>
              <DateInput
                disabled={disableInputs ?? undefined}
                fullDate
                value={formik.values.birthday}
                handleChange={formik.setFieldValue}
                formik={formik}
                // @ts-ignore
                error={formik.errors.birthday}
                name="birthday"
                label="Fecha de nacimiento"
                maxDate={subYears(new Date(), 18)}
                minDate={subYears(new Date(), 90)}
              />
            </Grid>
            {/* email === "" && (
              <Grid item>
                <TextInput
                  value={formik.values.email ?? ""}
                  handleChange={formik.setFieldValue}
                  formik={formik}
                  name="email"
                  label="Correo electrónico"
                  // @ts-ignore
                  error={formik.errors.email}
                  placeholder="luis@gmail.com"
                  disabled={email !== ""}
                />
              </Grid>
            ) */}
            {/*
                            document==='' &&
                            <Grid
                                item
                            >
                                <TextInput
                                    value={formik.values.document??''}
                                    handleChange={formik.setFieldValue}
                                    formik={formik}
                                    name={'document'}
                                    label={'Número de DNI/NIE'}
                                    //@ts-ignore
                                    error={formik.errors.document}
                                    placeholder={'09530696W'}
                                />
                            </Grid>

                             */}
            {/*
                  <Grid item>
              <NumberInput
                value={formik.values.monthly_income}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="monthly_income"
                label="Dinero que entra en casa cada mes"
                // @ts-ignore
                error={formik.errors.monthly_income}
                decimalScale={0}
                endAdornment={
                  <Text
                    size="m"
                    weight="regular"
                    fontColor={color.greyscale.black[30]}
                  >
                    €/mes
                  </Text>
                }
                placeholder="2.500"
              />
            </Grid>
            <Grid item>
              <TextInput
                value={formik.values.zip_code}
                handleChange={formik.setFieldValue}
                formik={formik}
                name="zip_code"
                label="Código postal de tu residencia"
                // @ts-ignore
                error={formik.errors.zip_code}
                placeholder="41001"
              />
            </Grid>
                   */}
          </IndexContainerForm>
        </Grid>
        <IndexContainerButtonItem className="index-container__button-item">
          <Grid container justifyContent="center">
            <Grid
              item
              sx={{
                [theme.breakpoints.down("mobile")]: {
                  width: "100%",
                },
              }}
            >
              <Buttons
                className="index-container__button-item__button js-index-container__button-item__button"
                variant="contained"
                size={downMobile ? "medium" : undefined}
                onClick={() => formik.handleSubmit()}
              >
                {finish ? "Cambiar mis datos" : "Empezar planificación"}
              </Buttons>
            </Grid>
          </Grid>
        </IndexContainerButtonItem>
      </IndexContainer>
    </>
  );
}
Homepage.title = "Inicio";
export default Homepage;
