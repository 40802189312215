import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/color.css";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from "./App";
import { store } from "./store";
import theme from "./styles/theme";

const persistor = persistStore(store);

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "inv-button": any;
      "inv-icon": any;
      "inv-grid-row": any;
      "inv-grid-col": any;
      "inv-text-xxxl": any;
      "inv-text-xxl": any;
      "inv-text-xl": any;
      "inv-text-l": any;
      "inv-text-m": any;
      "inv-text-s": any;
      "inv-text-xs": any;
      "inv-text-xxs": any;
      "inv-input-text": any;
      "inv-input-number": any;
      "inv-input-checkbox": any;
      "inv-input-radio": any;
      "inv-input-date": any;
      "inv-dropdown": any;
      "inv-form-label": any;
      "inv-tooltip": any;
      "inv-button-fab": any;
    }
  }
}

// @ts-ignore
ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
