import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import theme from "../../styles/theme";
import { PlanComponent } from "../../modules/plan/plan.component";
import { selectPlan } from "../../modules/propuesta/propuesta.slice";
import { HelpFooter } from "../../components/help/HelpFooter";
import { planMock } from "../../modules/plan/plan.data";
import styles from "./index.module.css";

function Plan() {
  const { tabName } = useSelector(selectPlan);
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    adjusted_objective,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    monthly_saving,
    saving,
    performance,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remaining_saving,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    initial_investment,
    // eslint-disable-next-line react/destructuring-assignment
  } = planMock;
  // eslint-disable-next-line react/destructuring-assignment
  const plan = undefined;

  const [, setSelectedObjective] = React.useState<{
    type: string;
    id: number;
  }>({ type: "", id: 0 });
  React.useEffect(() => {}, [tabName]);
  // @ts-ignore
  return (
    <>
      <div className={styles.pageContainer}>
        <PlanComponent
          saving={saving}
          adjusted_objective={adjusted_objective}
          initial_investment={initial_investment}
          performance={performance}
          remaining_saving={remaining_saving}
          plan={plan}
          monthly_saving={monthly_saving}
          setSelectedObjective={setSelectedObjective}
        />
      </div>
      {!downMobile && (
        <div style={{ marginTop: "10em" }}>
          <HelpFooter setIsContactDialogOpen={() => {}} />
        </div>
      )}
    </>
  );
}

Plan.title = "Plan financiero";
Plan.layout = "EmptyLayout";
Plan.mobileLayout = "MobileEmptyLayout";
export default Plan;
