import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import * as React from "react";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import "@inveert/ui-kit/src/grid/grid-col";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/text/text-xs";
import styles from "./Tables.module.css";

interface ITables {
  data: Array<{ col1: string; col2: string; col3: string; col4: number }>;
  borderActive?: boolean;
}

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledTable = styled((props: any) => <Table {...props} />)(() => ({
  borderRadius: 0,
  boxShadow: "none",
}));

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledTableCell = styled((props: any) => <TableCell {...props} />)(
  () => ({
    borderBottom: "none",
    minWidth: 100,
  })
);

export function Tables(props: ITables) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { data, borderActive } = props;
  const sortedData = data.sort((rowA, rowB) =>
    // eslint-disable-next-line no-nested-ternary
    rowA.col2 === rowB.col2 ? 0 : rowA.col2 === "Renta Variable" ? -1 : 1
  );
  return (
    <div className={styles.container}>
      <inv-grid-row>
        <inv-grid-col class="col-12">
          <TableContainer
            sx={{
              border:
                borderActive === true
                  ? `0.1em solid ${color.greyscale.black[5]}`
                  : undefined,
              padding: borderActive === true ? `1.2em 0.8em` : undefined,
              borderRadius: borderActive === true ? "0.4em" : undefined,
              [theme.breakpoints.up("xs")]: {
                maxWidth: 788,
              },
              [theme.breakpoints.up("xl")]: {
                maxWidth: 848,
              },
              [theme.breakpoints.down("md")]: {
                border: "none",
              },
            }}
          >
            <StyledTable
              sx={{ minWidth: downMobile ? 0 : 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <inv-grid-row>
                      <inv-grid-col class="col-auto">
                        <inv-text-xs
                          style={{
                            "--inv-text-xs-font-weight": 700,
                          }}
                        >
                          Valor
                        </inv-text-xs>
                      </inv-grid-col>
                    </inv-grid-row>
                  </StyledTableCell>
                  {!downMobile && (
                    <StyledTableCell align="right">
                      <inv-grid-row style={{ justifyContent: "flex-end" }}>
                        <inv-grid-col class="col-auto">
                          <inv-text-xs
                            style={{
                              "--inv-text-xs-font-weight": 700,
                            }}
                          >
                            Clase activo
                          </inv-text-xs>
                        </inv-grid-col>
                      </inv-grid-row>
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="right">
                    <inv-grid-row style={{ justifyContent: "flex-end" }}>
                      <inv-grid-col class="col-auto">
                        <inv-text-xs
                          style={{
                            "--inv-text-xs-font-weight": 700,
                          }}
                        >
                          Geografía
                        </inv-text-xs>
                      </inv-grid-col>
                    </inv-grid-row>
                  </StyledTableCell>
                  <StyledTableCell>
                    <inv-grid-row style={{ justifyContent: "flex-end" }}>
                      <inv-grid-col class="col-auto">
                        <inv-text-xs
                          style={{
                            "--inv-text-xs-font-weight": 700,
                          }}
                        >
                          Peso
                        </inv-text-xs>
                      </inv-grid-col>
                    </inv-grid-row>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row) => (
                  <TableRow
                    key={row.col1}
                    sx={{
                      "&:last-child td": {
                        borderBottom: "hidden",
                      },
                      "&:last-child th": {
                        borderBottom: "hidden",
                      },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <inv-grid-row>
                        <inv-grid-col
                          class="col-12"
                          style={{
                            maxWidth: "15em",
                          }}
                        >
                          <inv-text-xs
                            style={{
                              "--inv-text-xs-color": color.greyscale.black[50],
                            }}
                          >
                            {row.col1}
                          </inv-text-xs>
                        </inv-grid-col>
                        {downMobile && (
                          <inv-grid-col
                            class="col-12"
                            style={{
                              maxWidth: "15em",
                            }}
                          >
                            <inv-text-xxs
                              style={{
                                "--inv-text-xxs-color":
                                  row.col2 === "Renta Variable"
                                    ? color.turquoise[100]
                                    : color.blue[100],
                              }}
                            >
                              {row.col2}
                            </inv-text-xxs>
                          </inv-grid-col>
                        )}
                      </inv-grid-row>
                    </StyledTableCell>
                    {!downMobile && (
                      <StyledTableCell align="right">
                        <inv-grid-row style={{ justifyContent: "flex-end" }}>
                          <inv-grid-col class="col-auto">
                            <inv-text-xs
                              style={{
                                "--inv-text-xs-color":
                                  row.col2 === "Renta Variable"
                                    ? color.turquoise[100]
                                    : color.blue[100],
                              }}
                            >
                              {row.col2}
                            </inv-text-xs>
                          </inv-grid-col>
                        </inv-grid-row>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">
                      <inv-grid-row
                        style={{
                          justifyContent: "flex-end",
                        }}
                      >
                        <inv-grid-col class="col-auto">
                          <inv-text-xs
                            style={{
                              "--inv-text-xs-color": color.greyscale.black[50],
                            }}
                          >
                            {row.col3}
                          </inv-text-xs>
                        </inv-grid-col>
                      </inv-grid-row>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <inv-grid-row style={{ justifyContent: "flex-end" }}>
                        <inv-grid-col class="col-auto">
                          <inv-text-xs
                            style={{
                              "--inv-text-xs-color": color.greyscale.black[50],
                            }}
                          >
                            <NumberFormat
                              value={row.col4}
                              thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                              }
                              decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                              }
                              decimalScale={0}
                              allowNegative={false}
                              displayType="text"
                              prefix=""
                              suffix="%"
                            />
                          </inv-text-xs>
                        </inv-grid-col>
                      </inv-grid-row>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}
Tables.defaultProps = {
  borderActive: undefined,
};

export default Tables;
