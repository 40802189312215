// @ts-ignore
import {
  Area,
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { color } from "../../styles/color";
import { performanceObject } from "../../utils/componentData";
import { CustomReferenceDot } from "./AreasChart";
import "@inveert/ui-kit/src/text/text-xxs";
import "@inveert/ui-kit/src/text/text-l";
import "@inveert/ui-kit/src/grid/grid-row";
import "@inveert/ui-kit/src/grid/grid-col";

function CustomLabel(props: any) {
  const { viewBox } = props;
  const { x, y } = viewBox;
  return (
    <g>
      <foreignObject x={x - 35} y={y - 45} width={70} height={70}>
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            background: color.turquoise[100],
            borderRadius: "0.4em",
            padding: "0.4em",
            margin: "auto",
            lineHeight: 0,
          }}
        >
          <inv-grid-row>
            <inv-grid-col class="col-auto">
              <inv-text-l
                style={{
                  "--inv-text-l-color": color.greyscale.white[100],
                  "--inv-text-l-font-weight": 600,
                  "--inv-text-l-font-size": "1.2em",
                }}
              >
                10.6%
              </inv-text-l>
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row>
            <inv-grid-col class="col-auto">
              <inv-text-xxs
                style={{
                  "--inv-text-xxs-color": color.greyscale.white[100],
                  "--inv-text-xxs-font-weight": 600,
                  "--inv-text-xxs-font-size": "0.6em",
                }}
              >
                Hoy
              </inv-text-xxs>
            </inv-grid-col>
          </inv-grid-row>
        </div>
      </foreignObject>
    </g>
  );
}

interface IFunnelChart {
  name: string;
  width?: number;
  height?: number;
}

function CustomReferenceLineLabel({ tir, viewBox }: any) {
  return (
    <g>
      <foreignObject
        x={viewBox.x - 30}
        y={viewBox.y - 9}
        width="100px"
        height="100px"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: color.blue[100],
            width: "2em",
            padding: "0.2em",
            borderRadius: "0.2em",
          }}
        >
          <inv-text-xxs
            style={{
              "--inv-text-xxs-color": color.greyscale.white[100],
              "--inv-text-xxs-font-weight": 600,
              "--inv-text-xxs-font-size": "0.6em",
            }}
          >
            {`${tir}%`}
          </inv-text-xxs>
        </div>
      </foreignObject>
    </g>
  );
}

export default function FunnelChart(props: IFunnelChart) {
  const tir = 4.2;
  const processedData = Object.keys(performanceObject)
    .map((key, index) =>
      // @ts-ignore
      ({
        date: `${index}-${
          Math.trunc(index / 12) === Math.trunc((index - 1) / 12)
            ? ""
            : Math.trunc(index / 12)
        }`,
        // @ts-ignore
        ...performanceObject[key],
        // @ts-ignore
        min_performance: [performanceObject[key].min_performance, tir],
        // @ts-ignore
        max_performance: [performanceObject[key].max_performance, tir],
      })
    )
    .slice(0, 250);
  const minValue = processedData.reduce((minDataValue, monthlyData) => {
    let thisMinDataValue = minDataValue;
    if (minDataValue > monthlyData.real) {
      thisMinDataValue = monthlyData.real;
    }
    return thisMinDataValue;
  }, 0);
  const maxValue = processedData.reduce((maxDataValue, monthlyData) => {
    let thisMaxDataValue = maxDataValue;
    if (thisMaxDataValue < monthlyData.real) {
      thisMaxDataValue = monthlyData.real;
    }
    return thisMaxDataValue;
  }, 0);
  const lastDate = processedData.reduce((date, item, index) => {
    let thisDate = date;
    if (
      processedData[index].real !== null &&
      processedData[index + 1]?.real === null
    ) {
      thisDate = item.date;
    }
    return thisDate;
  });
  const lastRealEquity = processedData.reduce((real, item, index) => {
    let thisReal = real;
    if (
      processedData[index].real !== null &&
      processedData[index + 1]?.real === null
    ) {
      thisReal = item.real;
    }
    return thisReal;
  });
  const lowestValue =
    minValue < processedData[0].min_performance[0]
      ? minValue
      : processedData[0].min_performance[0];
  const highestValue =
    maxValue > processedData[0].max_performance[0]
      ? maxValue
      : processedData[0].max_performance[0];

  const { name, height, width } = props;
  const containerRef = React.useRef();
  const [fontSize, setFontSize] = React.useState("0px");
  React.useEffect(() => {
    const element = containerRef.current;
    if (element) {
      const style = window.getComputedStyle(element);
      const chartFontSize = style.getPropertyValue("font-size");
      const fontSizeNumber = parseInt(chartFontSize, 10);
      setFontSize(`${fontSizeNumber * 0.7}px`);
    }
  }, []);
  return (
    // @ts-ignore
    <div ref={containerRef}>
      <ResponsiveContainer
        width={width ?? "100%"}
        height={height ?? (parseInt(fontSize, 10) / 0.7) * 20}
        key={name}
      >
        <ComposedChart data={processedData}>
          <YAxis
            type="number"
            stroke={color.greyscale.black[50]}
            fill={color.greyscale.black[50]}
            padding={{ top: 70, bottom: 20 }}
            domain={[
              Math.round(lowestValue * 10) / 10,
              Math.round(highestValue * 1.1 * 10) / 10,
            ]}
            allowDataOverflow
            tickLine={false}
            fontFamily="Inter"
            fontSize={fontSize}
            unit="%"
            axisLine={false}
            tickCount={0}
            width={40}
          />
          <XAxis
            tickMargin={-15}
            axisLine={false}
            stroke={color.greyscale.black[50]}
            fill={color.greyscale.black[50]}
            dataKey="date"
            interval={1}
            tickLine={false}
            fontFamily="Inter"
            fontSize={fontSize}
            tickFormatter={(tick: string, index: number) =>
              `${index === 0 ? "Año 0" : ""}${
                typeof tick === "string" ? tick?.split("-")[1] : ""
              }`
            }
          />
          <Area
            type="monotone"
            dataKey="max_performance"
            fill="#01BCCB"
            fillOpacity={0.2}
            stroke="transparent"
          />

          <Area
            type="monotone"
            dataKey="min_performance"
            fill={color.turquoise[60]}
            fillOpacity={0.2}
            stroke="transparent"
          />
          <ReferenceLine
            y={tir}
            label={<CustomReferenceLineLabel tir={tir} />}
            strokeWidth={3}
            stroke={color.blue[100]}
            isFront
            height={10}
            width={10}
          />
          <Line
            type="monotone"
            dataKey="real"
            dot={false}
            stroke={color.turquoise[100]}
            strokeWidth={3}
          />

          <ReferenceLine
            y={0}
            label={{
              fill: color.greyscale.black[30],
              position: "left",
              value: Math.abs(minValue) + Math.abs(maxValue) < 15 ? "0%" : "",
              fontFamily: "Inter",
              fontSize,
            }}
            isFront
            stroke="none"
            height={10}
            width={10}
          />
          <ReferenceLine
            y={Math.round(highestValue * 10) / 10}
            label={{
              position: "left",
              value: `${Math.round(highestValue * 10) / 10}%`,
              fill: color.greyscale.black[50],
              fontFamily: "Inter",
              fontSize,
            }}
            stroke={color.greyscale.black[30]}
            strokeDasharray="1.5"
            isFront
            height={10}
            width={10}
          />
          <ReferenceLine
            y={Math.round(lowestValue * 10) / 10}
            label={{
              position: "left",
              value: `${Math.round(lowestValue * 10) / 10}%`,
              fill: color.greyscale.black[50],
              fontFamily: "Inter",
              fontSize,
            }}
            stroke={color.greyscale.black[30]}
            strokeDasharray="1.5"
            isFront
            height={10}
            width={10}
          />
          <ReferenceLine
            label={<CustomLabel />}
            strokeDasharray="4"
            stroke={color.turquoise[100]}
            segment={[
              { x: lastDate, y: Math.round(lowestValue * 10) / 10 },
              { x: lastDate, y: Math.round(highestValue * 1.1 * 10) / 10 },
            ]}
          />
          <ReferenceDot
            x={lastDate}
            y={lastRealEquity}
            shape={CustomReferenceDot}
          />
          <ReferenceDot
            x={processedData[processedData.length - 1].date}
            y={processedData[processedData.length - 1].max_performance[0]}
            shape={CustomReferenceDot}
          />
          <ReferenceDot
            x={processedData[processedData.length - 1].date}
            y={processedData[processedData.length - 1].expected_performance}
            shape={CustomReferenceDot}
          />
          <ReferenceDot
            x={processedData[processedData.length - 1].date}
            y={processedData[processedData.length - 1].min_performance[0]}
            shape={<CustomReferenceDot fillColor={color.turquoise[60]} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

FunnelChart.defaultProps = {
  width: null,
  height: null,
};
