import { DataLayerObject } from "./gtm.type";

const pushToDataLayer = (obj: DataLayerObject) => {
  (<any>window).dataLayer = (<any>window).dataLayer || [];
  (<any>window).dataLayer.push(obj);
};

export const setUserDataLayer = (
  email: string | undefined,
  age: number | undefined,
  profileId: number | undefined,
  monthlyIncome: number | undefined
) => {
  pushToDataLayer({
    event: "customUser",
    userData: {
      email,
      age,
      profileId,
      monthlyIncome,
    },
  });
};

export const setGoalDataLayer = (type: string, timeHorizonInYears: number) => {
  pushToDataLayer({
    event: "customGoal",
    goalData: {
      type,
      timeHorizonInYears,
    },
  });
};

export const setInitialInvestmentDataLayer = (
  privatePensionPlan: number,
  initialInvestment: number
) => {
  pushToDataLayer({
    event: "customInitialInvestment",
    initialInvestmentData: {
      privatePensionPlan,
      initialInvestment,
    },
  });
};

export const setPlanDataLayer = (monthlySaving: number) => {
  pushToDataLayer({
    event: "customPlan",
    planData: {
      monthlySaving,
    },
  });
};

export const createPageEvent = (path: string) => {
  const obj: DataLayerObject = {
    event: "customPage",
    pageData: {
      path,
    },
  };
  pushToDataLayer(obj);
};

export const createGenericEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
) => {
  const obj: DataLayerObject = {
    event: "customEvent",
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  };
  pushToDataLayer(obj);
};

export default {
  createPageEvent,
  createGenericEvent,
  setUserDataLayer,
};

export const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: any) => {
  // @ts-ignore
  window.dataLayer({
    event: "pageview",
    page: url,
  });
};
