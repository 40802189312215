const riskProfileDescription = [
  "Buscas la protección en momentos de volatilidad, sin renunciar a una rentabilidad positiva. Para ello vamos a recomendarte invertir hasta un 15% en Renta Variable, asumiendo un riesgo Bajo.",
  "Buscas la protección en momentos de volatilidad, sin renunciar a una rentabilidad positiva. Para ello vamos a recomendarte invertir hasta un 30% en Renta Variable, asumiendo un riesgo Medio-Bajo.",
  "Buscas una rentabilidad atractiva, mientras la volatilidad sea moderada. Para ello vamos a recomendarte invertir hasta un 50% en Renta Variable, asumiendo un riesgo Medio.",
  "Buscas una rentabilidad atractiva, mientras la volatilidad sea moderada. Para ello vamos a recomendarte invertir hasta un 75% en Renta Variable, asumiendo un riesgo Medio-Alto.",
  "Buscas obtener la máxima rentabilidad, incluso en momentos de volatilidad elevada. Para ello vamos a recomendarte invertir hasta un 85% en Renta Variable, asumiendo un riesgo Alto.",
  "Buscas obtener la máxima rentabilidad, incluso en momentos de volatilidad elevada. Para ello vamos a recomendarte invertir hasta un 100% en Renta Variable, asumiendo un riesgo Muy Alto.",
];

export const riskProfileTestApiResponse = {
  external_profile_id: "57bc75453512085e4a8a7989",
  profile_id: 3,
};

export const riskProfileArrayMockedData = [
  {
    attributes: {
      created_at: "2022-09-05T17:36:25+02:00",
      max_equity_exposure: 0.1,
      name: "conservador",
      updated_at: "2022-09-05T17:36:25+02:00",
    },
    id: "1",
    type: "RiskProfile",
  },
  {
    attributes: {
      created_at: "2022-09-05T17:36:27+02:00",
      max_equity_exposure: 0.3,
      name: "tranquilo",
      updated_at: "2022-09-05T17:36:27+02:00",
    },
    id: "2",
    type: "RiskProfile",
  },
  {
    attributes: {
      created_at: "2022-09-05T17:36:28+02:00",
      max_equity_exposure: 0.5,
      name: "equilibrado",
      updated_at: "2022-09-05T17:36:28+02:00",
    },
    id: "3",
    type: "RiskProfile",
  },
  {
    attributes: {
      created_at: "2022-09-05T17:36:30+02:00",
      max_equity_exposure: 0.7,
      name: "decidido",
      updated_at: "2022-09-05T17:36:30+02:00",
    },
    id: "4",
    type: "RiskProfile",
  },
  {
    attributes: {
      created_at: "2022-09-05T17:36:31+02:00",
      max_equity_exposure: 0.85,
      name: "atrevido",
      updated_at: "2022-09-05T17:36:31+02:00",
    },
    id: "5",
    type: "RiskProfile",
  },
  {
    attributes: {
      created_at: "2022-09-05T17:36:33+02:00",
      max_equity_exposure: 1,
      name: "super",
      updated_at: "2022-09-05T17:36:33+02:00",
    },
    id: "6",
    type: "RiskProfile",
  },
];

export const financialProvidersData = {
  data: [
    {
      attributes: {
        business_size: "SMALL_SIZED_BUSINESS",
        country: "Spain",
        created_at: "2022-11-11T13:57:53+01:00",
        name: "Finanbest",
        type: "WEALTH_MANAGEMENT_FIRM",
        updated_at: "2022-11-11T13:57:53+01:00",
      },
      id: "1",
      type: "FinancialProvider",
    },
  ],
  jsonapi: {
    version: "1.0",
  },
  links: {
    self: "/api/v2/mifid/v1/financial-providers",
  },
};

export const testMifidData = {
  data: [
    {
      attributes: {
        created_at: "2022-11-11T13:58:29+01:00",
        financial_provider_id: 1,
        name: "Test para el cálculo del perfil de riesgo de Finanbest",
        updated_at: "2022-11-11T13:58:29+01:00",
      },
      id: "1",
      type: "TestMifid",
    },
  ],
  jsonapi: {
    version: "1.0",
  },
  links: {
    self: "/api/v2/mifid/v1/tests-mifid",
  },
};

export const questionsOfTestMifid = {
  data: [
    {
      attributes: {
        answers:
          '{ "1" :"< 50.000 €","2" :"50.000€ - 300.000€", "3":"300.000€ - 600.000€ ", "4" : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Cuáles son aproximadamente tus ingresos brutos anuales?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "1",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿De tus ingresos qué porcentaje dedicas a gastos?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "2",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Cómo de estables son tus ingresos?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "3",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "Tus activos líquidos son:",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "4",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question:
          "¿Qué porcentaje de tu patrimonio representa la cantidad que quieres invertir en Finanbest?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "5",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Cuántos años llevas operando en los mercados financieros?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "6",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Cuál es tu nivel de estudios?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "7",
      type: "Question",
    },
    {
      attributes: {
        answers: '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question:
          "¿Has desempeñado alguna actividad profesional relacionada con los mercados financieros?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "8",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Qué edad tienes?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "9",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Cuál es el objetivo de tu inversión?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "10",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿A qué plazo está prevista tu inversión?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "11",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question: "¿Qué te transmite la palabra riesgo?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "12",
      type: "Question",
    },
    {
      attributes: {
        answers:
          '{ 1 :"< 50.000 €",2 :"50.000€ - 300.000€", 3:"300.000€ - 600.000€ ", 4 : ">600.000€"}',
        created_at: "2020-10-10T00:00:00+02:00",
        question:
          "¿Qué rentabilidad negativa anual puedes soportar sin verte forzado a vender?",
        test_id: 1,
        updated_at: "2020-10-10T00:00:00+02:00",
      },
      id: "13",
      type: "Question",
    },
  ],
  jsonapi: {
    version: "1.0",
  },
  links: {
    self: "/api/v2/mifid/v1/questions",
  },
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const riskProfileQuestionsByQuestionIndex = [
  {
    question: "",
    questionIndex: 0,
    name: "name",
  },
  {
    question: "",
    questionIndex: 1,
    name: "income",
  },
  {
    question: "¿Cómo de estables son tus ingresos?",
    questionIndex: 2,
    name: "stability",
  },
  {
    question: "¿De tus ingresos qué porcentaje dedicas a gastos?",
    questionIndex: 3,
    name: "expenses",
  },
  {
    question: "Tus activos líquidos son:",
    questionIndex: 4,
    name: "wealth",
  },
  {
    question: "¿Cuál es tu nivel de estudios?",
    questionIndex: 5,
    name: "studies",
  },
  {
    question: "¿Cuántos años llevas operando en los mercados financieros?",
    questionIndex: 6,
    name: "years_investing",
  },
  {
    question:
      "¿Has desempeñado alguna actividad profesional relacionada con los mercados financieros?",
    questionIndex: 7,
    name: "experience",
  },
  {
    question: "¿Cuál es el objetivo de tu inversión?",
    questionIndex: 8,
    name: "goal",
  },
  {
    question: "¿Qué te transmite la palabra riesgo?",
    questionIndex: 9,
    name: "risk",
  },
  {
    question:
      "¿Qué rentabilidad negativa anual puedes soportar sin verte forzado a vender?",
    questionIndex: 10,
    name: "attitude",
  },
];

export default riskProfileDescription;
