import { createTheme } from "@mui/material/styles";
import { color } from "./color";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    error: {
      main: color.validation.error,
    },
  },

  typography: {
    fontFamily: "Inter",
    h1: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        lineHeight: "78px",
        fontSize: "60px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "56px",
        lineHeight: "73px",
      },
    },
    h2: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "56px",
        lineHeight: "73px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "48px",
        lineHeight: "62px",
      },
    },
    h3: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "48px",
        lineHeight: "62px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "36px",
        lineHeight: "44px",
      },
    },
    h4: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "36px",
        lineHeight: "44px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "32px",
        lineHeight: "39px",
      },
    },
    h5: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "32px",
        lineHeight: "39px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "24px",
        lineHeight: "29px",
      },
    },
    h6: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "24px",
        lineHeight: "29px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "20px",
        lineHeight: "26px",
      },
    },
    body1: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "20px",
        lineHeight: "26px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
    body2: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "16px",
        lineHeight: "21px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    subtitle1: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "14px",
        lineHeight: "20px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "12px",
        lineHeight: "16px",
      },
    },
    subtitle2: {
      fontStyle: "normal",
      "@media (min-width:0px)": {
        fontSize: "12px",
        lineHeight: "16px",
      },
      "@media (min-width:500px) and (max-width:2000px)": {
        fontSize: "10px",
        lineHeight: "16px",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 500,
      sm: 800,
      md: 960,
      lg: 1200,
      xl: 2000,
    },
  },
  spacing: 4,
});
// theme = responsiveFontSizes(theme);

export default theme;
