import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import { color } from "../../styles/color";
import Buttons from "../../elements/buttons/Buttons";

interface IHelpFooter {
  setIsContactDialogOpen: (open: boolean) => void;
}

export function HelpFooter(props: IHelpFooter) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { setIsContactDialogOpen } = props;
  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: theme.spacing(30),
        [theme.breakpoints.down("mobile")]: {
          marginBottom: "50px",
          marginTop: "50px",
          padding: "0 20px",
        },
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          marginRight: "auto",
          marginLeft: "auto",
          [theme.breakpoints.up("xs")]: {
            maxWidth: 460,
          },
          [theme.breakpoints.up("xl")]: {
            maxWidth: 580,
          },
        }}
        rowSpacing={{ xs: 10, xl: 10 }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            rowSpacing={{ xs: 4, xl: 4 }}
          >
            <Grid item>
              <Text
                size="l"
                weight="semibold"
                fontColor={color.greyscale.white[100]}
              >
                ¿Necesitas ayuda?
              </Text>
            </Grid>
            <Grid item>
              <Text
                size="m"
                weight="light"
                fontColor={color.greyscale.white[100]}
                align="center"
              >
                Si necesitas aclarar algo, pulsa en el botón y te llamaremos lo
                antes posible para resolver cualquier duda del plan.
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Grid
            container
            justifyContent={downMobile ? "center" : "space-between"}
            rowSpacing={4}
          >
            <Grid
              item
              sx={{
                [theme.breakpoints.up("xs")]: {
                  width: "auto",
                },
                [theme.breakpoints.down("mobile")]: {
                  width: "100%",
                },
              }}
            >
              <Buttons
                onClick={() => setIsContactDialogOpen(true)}
                variant="outlined"
                fontColor={color.greyscale.white[100]}
                borderColor={color.greyscale.white.T20}
                selected={false}
              >
                Llamadme gratis
              </Buttons>
            </Grid>
            <Grid
              item
              sx={{
                [theme.breakpoints.up("xs")]: {
                  width: "auto",
                },
                [theme.breakpoints.down("mobile")]: {
                  width: "100%",
                },
              }}
            >
              <Buttons
                variant="outlined"
                fontColor={color.greyscale.white[100]}
                borderColor={color.greyscale.white.T20}
                selected={false}
              >
                Llamar al 638 467 146
              </Buttons>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default HelpFooter;
